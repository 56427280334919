import './UploadWithPreview.scss';

import { useDialogs } from '@module/shared/dialogs';
import { formatBytes } from '@module/shared/helpers';
import { NotificationType, useNotifications } from '@module/shared/notifications';
import { Button } from '@progress/kendo-react-buttons';
import { classNames } from '@progress/kendo-react-common';
import { UploadOnAddEvent } from '@progress/kendo-react-upload';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { PreviewImageDialog } from '../../../platform/dialogs/PreviewImageDialog';
import { CustomUpload, CustomUploadHandle, CustomUploadProps, FileItem } from '../../';
import { MAX_FILE_SIZE } from '../../config/env';

export type UploadFileItem = Pick<FileItem, 'id' | 'file_name' | 'url'>;

export interface UploadWithPreviewProps extends CustomUploadProps {
  onDelete: () => void;
  item?: UploadFileItem | null;
  previewURLOnly?: boolean;
}

const ImageUrl = (url: string | undefined, name: string | undefined) => {
  if (url && name) return url + name;
};

export const UploadWithPreview = forwardRef<CustomUploadHandle, UploadWithPreviewProps>(
  (props, forwardedRef) => {
    const { item, onSave, onDelete, onAdd, previewURLOnly, ...uploadProps } = props;

    const { showDialog } = useDialogs();
    const { showNotification } = useNotifications();
    const { t } = useTranslation();

    const handleOnAdd = (e: UploadOnAddEvent) => {
      if (e.affectedFiles && e.affectedFiles.length > 0) {
        // handle validationErrors
        for (const file of e.affectedFiles) {
          if (file.validationErrors && file.validationErrors.length > 0) {
            for (const validationError of file.validationErrors) {
              showNotification(
                t(
                  `common.components.customUpload.validationErrors.${validationError}`,
                  validationError === 'invalidMaxFileSize'
                    ? { maxFileSize: formatBytes(MAX_FILE_SIZE) }
                    : {},
                ),
                NotificationType.Error,
              );
            }
          }
        }
      }
    };

    return (
      <div className="UploadWithPreview">
        <div
          className={classNames('upload-with-preview', {
            'upload-with-preview--and-delete': item && item.url,
          })}
        >
          <CustomUpload
            {...uploadProps}
            ref={forwardedRef}
            onSave={onSave}
            showFileList={false}
            onAdd={(e) => {
              onAdd?.(e);
              handleOnAdd(e);
            }}
          />
          {item && item.url && (
            <Button
              className="preview-image-button"
              style={{
                backgroundImage:
                  'url(' + (previewURLOnly ? item.url : item.url + item.file_name) + ')',
              }}
              onClick={() =>
                showDialog(
                  { image: previewURLOnly ? item.url : ImageUrl(item.url, item.file_name) },
                  PreviewImageDialog,
                )
              }
              aria-label={t('common.labels.preview')}
            />
          )}
          {item && item.url && (
            <Button
              type="button"
              className="delete-image-button"
              onClick={onDelete}
              iconClass="l-i-trash-2"
              aria-label={t('common.labels.delete')}
            />
          )}
          {!item && (
            <Button className="preview-image-button" aria-label={t('common.labels.preview')} />
          )}
        </div>
      </div>
    );
  },
);

UploadWithPreview.displayName = 'UploadWithPreview';

import { DropDownListInput } from '@module/shared/forms';
import { FieldRenderProps } from '@progress/kendo-react-form';
import { useCallback, useMemo } from 'react';

import { SalutationItem, useDropDownListDefaultItem, useSalutations } from '../../hooks';

export interface SalutationSelectProps extends FieldRenderProps {
  value: string | null;
  onChange: (event: { target?: unknown; value?: string | null }) => void;
}

export const SalutationSelect = (props: SalutationSelectProps) => {
  const { value, onChange, ...rest } = props;
  const { salutations, isLoading } = useSalutations();
  const defaultItem = useDropDownListDefaultItem('id', 'title');

  const salutation = useMemo(
    () => salutations.find((salutation) => salutation.id === value),
    [salutations, value],
  );

  const handleChange = useCallback(
    (event: { value?: SalutationItem }) => {
      if (event.value?.id === defaultItem.id) {
        onChange({
          ...event,
          value: null,
        });
      } else {
        onChange({
          ...event,
          value: event.value?.id ?? null,
        });
      }
    },
    [defaultItem.id, onChange],
  );
  return (
    <DropDownListInput
      {...rest}
      data={salutations}
      dataItemKey={'id'}
      textField={'title'}
      defaultItem={defaultItem}
      value={salutation}
      onChange={handleChange}
      isLoading={isLoading}
    />
  );
};

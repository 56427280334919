export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: string;
  DateTime: string;
  DateTimeTz: string;
  DateTimeUtc: string;
  IPv6: string;
  JSON: any;
  Mixed: any;
  Upload: File;
};

export type Activity = {
  __typename?: 'Activity';
  causer?: Maybe<User>;
  changes?: Maybe<Scalars['JSON']>;
  created_at: Scalars['DateTimeTz'];
  description: Scalars['String'];
  event: ActivityEvent;
  id: Scalars['ID'];
  log_name: Scalars['String'];
};

export enum ActivityEvent {
  created = 'created',
  deleted = 'deleted',
  login = 'login',
  updated = 'updated',
}

export type ActivityFilter = {
  AND?: InputMaybe<Array<ActivityFilter>>;
  OR?: InputMaybe<Array<ActivityFilter>>;
  column?: InputMaybe<ActivitySortAndFilterColumns>;
  operator?: InputMaybe<SQLOperator>;
  value?: InputMaybe<Scalars['Mixed']>;
};

export type ActivityPaginator = {
  __typename?: 'ActivityPaginator';
  data: Array<Activity>;
  paginatorInfo: PaginatorInfo;
};

export type ActivitySort = {
  columns?: InputMaybe<Array<ActivitySortOrder>>;
};

export enum ActivitySortAndFilterColumns {
  CREATED_AT = 'CREATED_AT',
  DESCRIPTION = 'DESCRIPTION',
  EVENT = 'EVENT',
  LOG_NAME = 'LOG_NAME',
}

export type ActivitySortOrder = {
  column: ActivitySortAndFilterColumns;
  order: SortOrder;
};

export type AdditionalAuthDataInput = {
  device_token?: InputMaybe<Scalars['String']>;
  is_mobile?: InputMaybe<Scalars['Boolean']>;
  platform?: InputMaybe<Platform>;
};

export type AddressBookEntry = {
  __typename?: 'AddressBookEntry';
  email: Scalars['String'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  query?: Maybe<Query>;
};

export type Adjuster = {
  __typename?: 'Adjuster';
  name?: Maybe<Scalars['String']>;
};

export type AdjusterForAssignment = {
  __typename?: 'AdjusterForAssignment';
  assignment_note?: Maybe<Scalars['String']>;
  availability: ClientAssignmentAvailabilityState;
  client_type: ClientType;
  id: Scalars['ID'];
  leader?: Maybe<Client>;
  name: Scalars['String'];
  open_tasks: Scalars['Int'];
  preferred_leader_client_id: Scalars['ID'];
  primary_location?: Maybe<ClientLocation>;
  rework_tasks: Scalars['Int'];
  tasks_count_color: Scalars['String'];
};

export type AdjusterList = {
  __typename?: 'AdjusterList';
  data: Array<Client>;
};

export type AdjusterWithDetails = {
  __typename?: 'AdjusterWithDetails';
  assignment_note?: Maybe<Scalars['String']>;
  availability: ClientAssignmentAvailabilityState;
  distance_to_claim?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  leader?: Maybe<Client>;
  name: Scalars['String'];
  open_tasks: Scalars['Int'];
  preferred_leader_client_id: Scalars['ID'];
  rework_tasks: Scalars['Int'];
  tasks_count_color: Scalars['String'];
};

export type AdjusterWithDetailsPaginator = {
  __typename?: 'AdjusterWithDetailsPaginator';
  data: Array<AdjusterWithDetails>;
};

export type ApiToken = {
  __typename?: 'ApiToken';
  created_at: Scalars['DateTimeTz'];
  expires_at: Scalars['DateTimeTz'];
  revoked: Scalars['Boolean'];
  user?: Maybe<ApiTokenUser>;
  user_id: Scalars['ID'];
  uuid: Scalars['ID'];
};

export type ApiTokenList = {
  __typename?: 'ApiTokenList';
  data: Array<ApiToken>;
};

export type ApiTokenUser = {
  __typename?: 'ApiTokenUser';
  client?: Maybe<Client>;
  client_id: Scalars['ID'];
};

export type AppAllowlistIpAddress = {
  __typename?: 'AppAllowlistIpAddress';
  can_be_deleted: Scalars['Boolean'];
  created_at: Scalars['DateTimeTz'];
  creator?: Maybe<Client>;
  creator_id?: Maybe<Scalars['ID']>;
  creator_ip?: Maybe<Scalars['IPv6']>;
  description?: Maybe<Scalars['String']>;
  ip: Scalars['IPv6'];
  is_current_ip: Scalars['Boolean'];
  updated_at: Scalars['DateTimeTz'];
};

export type AppAllowlistIpAddressInput = {
  description?: InputMaybe<Scalars['String']>;
  ip: Scalars['IPv6'];
};

export type AppEmailTransport = {
  __typename?: 'AppEmailTransport';
  active: Scalars['Boolean'];
  id: Scalars['ID'];
  smtp_encryption?: Maybe<MailEncryptionType>;
  smtp_host?: Maybe<Scalars['String']>;
  smtp_port?: Maybe<Scalars['Int']>;
  smtp_username?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type: AppEmailTransportType;
  type_id: AppEmailTransportTypeId;
};

export type AppEmailTransportSmtpInput = {
  smtp_encryption: MailEncryptionType;
  smtp_host: Scalars['String'];
  smtp_password?: InputMaybe<Scalars['String']>;
  smtp_port: Scalars['Int'];
  smtp_username: Scalars['String'];
};

export type AppEmailTransportType = {
  __typename?: 'AppEmailTransportType';
  description: Scalars['String'];
  id: Scalars['ID'];
  title: Scalars['String'];
  type_id: AppEmailTransportTypeId;
};

export enum AppEmailTransportTypeId {
  SMTP = 'SMTP',
}

export type AppMessage = {
  __typename?: 'AppMessage';
  active: Scalars['Boolean'];
  author?: Maybe<Client>;
  author_id: Scalars['ID'];
  body: Scalars['String'];
  created_at: Scalars['DateTimeTz'];
  id: Scalars['ID'];
  title: Scalars['String'];
  type: PageNotificationType;
  updated_at: Scalars['DateTimeTz'];
  visible_from?: Maybe<Scalars['DateTimeTz']>;
  visible_to?: Maybe<Scalars['DateTimeTz']>;
};

export enum AppMode {
  LOSS_ADJUSTMENT = 'LOSS_ADJUSTMENT',
  RISK = 'RISK',
}

export type AppModule = {
  __typename?: 'AppModule';
  active: Scalars['Boolean'];
  created_at: Scalars['DateTimeTz'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  title: Scalars['String'];
  updated_at: Scalars['DateTimeTz'];
  variables: Array<AppModuleSetting>;
};

export type AppModuleSetting = {
  __typename?: 'AppModuleSetting';
  app_module_id: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  key: Scalars['String'];
  title: Scalars['String'];
  type: AppModuleSettingType;
  value?: Maybe<Scalars['String']>;
};

export type AppModuleSettingInput = {
  key: Scalars['String'];
  value?: InputMaybe<Scalars['String']>;
};

export enum AppModuleSettingType {
  SECRET = 'SECRET',
  STRING = 'STRING',
}

export type AppNotificationSetting = {
  __typename?: 'AppNotificationSetting';
  email: Scalars['Boolean'];
  email_template?: Maybe<EmailTemplate>;
  email_template_id?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  internal: Scalars['Boolean'];
  push: Scalars['Boolean'];
  sms: Scalars['Boolean'];
  type: NotificationType;
  type_id: NotificationTypeId;
};

export type AppNotificationSettingInput = {
  email: Scalars['Boolean'];
  email_template_id?: InputMaybe<Scalars['ID']>;
  internal: Scalars['Boolean'];
  push: Scalars['Boolean'];
  sms: Scalars['Boolean'];
  type_id: NotificationTypeId;
};

export type AppSettings = {
  __typename?: 'AppSettings';
  activate_legacy_gdv: Scalars['Boolean'];
  activate_push_notifications: Scalars['Boolean'];
  activate_task_default_off: Scalars['Boolean'];
  adjuster_can_change_task_location: Scalars['Boolean'];
  adjuster_can_create_tasks: Scalars['Boolean'];
  adjuster_can_duplicate_own_tasks: Scalars['Boolean'];
  adjuster_can_send_interface: Scalars['Boolean'];
  adjuster_can_set_rework: Scalars['Boolean'];
  allow_adjuster_to_send_emails: Scalars['Boolean'];
  alternate_report_logo?: Maybe<FileItem>;
  app_mode: AppMode;
  app_title: Scalars['String'];
  assignment_auto_active: Scalars['Boolean'];
  assignment_max_tasks_max_per_day: Scalars['Int'];
  assignment_max_tasks_per_week: Scalars['Int'];
  assignment_max_tasks_reset_count: Scalars['Int'];
  assignment_max_tasks_week_start_day: AssignmentWeekDay;
  assignment_type: AssignmentType;
  auth_allowlist_active: Scalars['Boolean'];
  auth_max_session_duration?: Maybe<Scalars['Int']>;
  auth_mode_2fa: Scalars['Boolean'];
  auth_mode_2fa_email: Scalars['Boolean'];
  auth_mode_2fa_sms: Scalars['Boolean'];
  auth_password_min_length: Scalars['Int'];
  auth_password_min_numbers: Scalars['Int'];
  auth_password_min_special: Scalars['Int'];
  auth_password_min_upper_case: Scalars['Int'];
  auth_single_session_per_user: Scalars['Boolean'];
  auto_accept_task: Scalars['Boolean'];
  auto_logout_timeout?: Maybe<Scalars['Int']>;
  calendar_driving_times: DrivingTimeSetting;
  conditions?: Maybe<Scalars['String']>;
  country: Country;
  create_task_auto_fill_client: Scalars['Boolean'];
  create_task_auto_fill_client_address: Scalars['Boolean'];
  currency: Currency;
  currency_id: Scalars['ID'];
  disable_task_type_on_desk: Scalars['Boolean'];
  disable_task_type_video: Scalars['Boolean'];
  everybody_can_see_finished_tasks: Scalars['Boolean'];
  global_mail?: Maybe<Scalars['String']>;
  global_phone?: Maybe<Scalars['String']>;
  hide_drive_log: Scalars['Boolean'];
  hide_log_times: Scalars['Boolean'];
  id: Scalars['ID'];
  imprint?: Maybe<Scalars['String']>;
  invoice_from_bank_iban?: Maybe<Scalars['String']>;
  invoice_from_bank_swift?: Maybe<Scalars['String']>;
  invoice_from_bank_title?: Maybe<Scalars['String']>;
  invoice_from_ceo?: Maybe<Scalars['String']>;
  invoice_from_city?: Maybe<Scalars['String']>;
  invoice_from_commercial_register?: Maybe<Scalars['String']>;
  invoice_from_country?: Maybe<Country>;
  invoice_from_country_id?: Maybe<Scalars['ID']>;
  invoice_from_email?: Maybe<Scalars['String']>;
  invoice_from_invoice_number_prefix?: Maybe<Scalars['String']>;
  invoice_from_name?: Maybe<Scalars['String']>;
  invoice_from_phone?: Maybe<Scalars['String']>;
  invoice_from_postcode?: Maybe<Scalars['String']>;
  invoice_from_street?: Maybe<Scalars['String']>;
  invoice_from_vat_id?: Maybe<Scalars['String']>;
  invoice_from_vat_title?: Maybe<Scalars['String']>;
  invoice_from_web?: Maybe<Scalars['String']>;
  invoice_logo?: Maybe<FileItem>;
  leader_can_assign: Scalars['Boolean'];
  load_predefined_email: Scalars['Boolean'];
  log_mails_in_task_log: Scalars['Boolean'];
  login_background?: Maybe<FileItem>;
  login_logo?: Maybe<FileItem>;
  login_welcome_text?: Maybe<Scalars['String']>;
  mail_active: Scalars['Boolean'];
  mail_default_footer?: Maybe<Scalars['String']>;
  mail_encryption?: Maybe<MailEncryptionType>;
  mail_from_address?: Maybe<Scalars['String']>;
  mail_from_name?: Maybe<Scalars['String']>;
  mail_host?: Maybe<Scalars['String']>;
  mail_port?: Maybe<Scalars['Int']>;
  mail_prefix?: Maybe<Scalars['String']>;
  mail_send_failure_notice: Scalars['Boolean'];
  mail_username?: Maybe<Scalars['String']>;
  map_bounds?: Maybe<Bounds>;
  max_export_pages: Scalars['Int'];
  menu_icon?: Maybe<FileItem>;
  mobile_app_active: Scalars['Boolean'];
  mobile_can_accept: Scalars['Boolean'];
  mobile_can_set_appointments: Scalars['Boolean'];
  mobile_lock_tasks_when_mobile_active: Scalars['Boolean'];
  module_calculation_active: Scalars['Boolean'];
  module_calculation_default_net: Scalars['Boolean'];
  module_calculation_hide_column_accepted: Scalars['Boolean'];
  module_calculation_hide_column_payout_state: Scalars['Boolean'];
  module_calculation_hide_column_status: Scalars['Boolean'];
  module_customer_portal_active: Scalars['Boolean'];
  module_devices_active: Scalars['Boolean'];
  module_documents_active: Scalars['Boolean'];
  module_emails_active: Scalars['Boolean'];
  module_extended_forms_active: Scalars['Boolean'];
  module_images_active: Scalars['Boolean'];
  module_invoices_active: Scalars['Boolean'];
  module_logs_active: Scalars['Boolean'];
  module_qualifications_active: Scalars['Boolean'];
  module_room_plan_active: Scalars['Boolean'];
  module_statistic_active: Scalars['Boolean'];
  module_subtasks_active: Scalars['Boolean'];
  msg_convert_single_file: Scalars['Boolean'];
  notification_settings: Array<AppNotificationSetting>;
  notifications_leader_added_document?: Maybe<NotificationSetting>;
  notifications_leader_assigned_task?: Maybe<NotificationSetting>;
  notifications_leader_controlling_assigned?: Maybe<NotificationSetting>;
  notifications_leader_set_appointment?: Maybe<NotificationSetting>;
  notifications_leader_todo_expired?: Maybe<NotificationSetting>;
  notifications_leader_todo_will_expire?: Maybe<NotificationSetting>;
  notifications_leader_user_changed?: Maybe<NotificationSetting>;
  notifications_user_added_document?: Maybe<NotificationSetting>;
  /** notifications_xxx fields are deprecated use notification_settings */
  notifications_user_assigned_task?: Maybe<NotificationSetting>;
  notifications_user_todo_expired?: Maybe<NotificationSetting>;
  notifications_user_todo_will_expire?: Maybe<NotificationSetting>;
  permissions_delete_files_admin_only: Scalars['Boolean'];
  primary_color?: Maybe<Scalars['String']>;
  privacy?: Maybe<Scalars['String']>;
  push_notifications_config?: Maybe<Scalars['String']>;
  report_default_cover_background?: Maybe<FileItem>;
  report_logo?: Maybe<FileItem>;
  show_adjuster_email_service_provider_block: Scalars['Boolean'];
  show_summary_service_provider_block: Scalars['Boolean'];
  show_weekends_in_calendar: Scalars['Boolean'];
  statistic_required_for_completion: Scalars['Boolean'];
  statistic_required_for_controlling: Scalars['Boolean'];
  tasks_auto_assign_leader_when_empty: Scalars['Boolean'];
  tasks_auto_generate_info_file: Scalars['Boolean'];
  tasks_delete_tags_on_complete: Scalars['Boolean'];
  tasks_fields_client_contact_id_active: Scalars['Boolean'];
  tasks_fields_external_number_active: Scalars['Boolean'];
  tasks_fields_identifier_active: Scalars['Boolean'];
  tasks_fields_parallel_number_active: Scalars['Boolean'];
  tasks_fields_urgent_active: Scalars['Boolean'];
  tasks_filter_cancelled: Scalars['Boolean'];
  tasks_hide_duplicate: Scalars['Boolean'];
  tasks_leader_visible_all: Scalars['Boolean'];
  tasks_max_sub_owner_count: Scalars['Int'];
  tasks_notify_owner_on_create: Scalars['Boolean'];
  tasks_notify_owner_on_new_file: Scalars['Boolean'];
  tasks_num_routes: Scalars['Int'];
  tasks_state_rework_active: Scalars['Boolean'];
  tax: Scalars['Float'];
  theme: Theme;
  theme_color: Scalars['String'];
  url_login_background: Scalars['String'];
  url_login_logo: Scalars['String'];
  url_menu_icon: Scalars['String'];
  webservice_active: Scalars['Boolean'];
};

export type AppSettingsPublic = {
  __typename?: 'AppSettingsPublic';
  app_title: Scalars['String'];
  auth_password_min_length: Scalars['Int'];
  auth_password_min_numbers: Scalars['Int'];
  auth_password_min_special: Scalars['Int'];
  auth_password_min_upper_case: Scalars['Int'];
  conditions?: Maybe<Scalars['String']>;
  imprint?: Maybe<Scalars['String']>;
  login_background?: Maybe<FileItem>;
  login_logo?: Maybe<FileItem>;
  login_welcome_text?: Maybe<Scalars['String']>;
  menu_icon?: Maybe<FileItem>;
  privacy?: Maybe<Scalars['String']>;
  theme: Theme;
  theme_color: Scalars['String'];
  url_login_background: Scalars['String'];
  url_login_logo: Scalars['String'];
  url_menu_icon: Scalars['String'];
};

export type AppStamp = {
  __typename?: 'AppStamp';
  created_at: Scalars['DateTimeTz'];
  description?: Maybe<Scalars['String']>;
  file: FileItem;
  file_id: Scalars['ID'];
  height: Scalars['Int'];
  id: Scalars['ID'];
  title: Scalars['String'];
  updated_at: Scalars['DateTimeTz'];
  width: Scalars['Int'];
};

export type AppStampPaginator = {
  __typename?: 'AppStampPaginator';
  data: Array<AppStamp>;
};

export type AreaAssignment = {
  __typename?: 'AreaAssignment';
  area?: Maybe<Scalars['Float']>;
  city: Scalars['String'];
  client_id_leader?: Maybe<Scalars['ID']>;
  client_id_primary?: Maybe<Scalars['ID']>;
  client_id_secondary?: Maybe<Scalars['ID']>;
  client_id_tertiary?: Maybe<Scalars['ID']>;
  client_leader?: Maybe<Client>;
  client_primary?: Maybe<Client>;
  client_secondary?: Maybe<Client>;
  client_tertiary?: Maybe<Client>;
  id: Scalars['ID'];
  postcode: Scalars['String'];
  residents?: Maybe<Scalars['Int']>;
};

export type AreaAssignmentPaginator = {
  __typename?: 'AreaAssignmentPaginator';
  data: Array<AreaAssignment>;
  paginatorInfo: PaginatorInfo;
};

export enum AreaAssignmentSortAndFilterColumns {
  AREA = 'AREA',
  CITY = 'CITY',
  CLIENT_LEADER_ID = 'CLIENT_LEADER_ID',
  CLIENT_LEADER_NAME = 'CLIENT_LEADER_NAME',
  CLIENT_PRIMARY_ID = 'CLIENT_PRIMARY_ID',
  CLIENT_PRIMARY_NAME = 'CLIENT_PRIMARY_NAME',
  CLIENT_SECONDARY_ID = 'CLIENT_SECONDARY_ID',
  CLIENT_SECONDARY_NAME = 'CLIENT_SECONDARY_NAME',
  CLIENT_TERTIARY_ID = 'CLIENT_TERTIARY_ID',
  CLIENT_TERTIARY_NAME = 'CLIENT_TERTIARY_NAME',
  ID = 'ID',
  POSTCODE = 'POSTCODE',
  RESIDENTS = 'RESIDENTS',
}

export type AreaAssignmentsFilter = {
  AND?: InputMaybe<Array<AreaAssignmentsFilter>>;
  OR?: InputMaybe<Array<AreaAssignmentsFilter>>;
  column?: InputMaybe<AreaAssignmentSortAndFilterColumns>;
  operator?: InputMaybe<SQLOperator>;
  value?: InputMaybe<Scalars['Mixed']>;
};

export type AreaAssignmentsSort = {
  columns?: InputMaybe<Array<AreaAssignmentsSortOrder>>;
};

export type AreaAssignmentsSortOrder = {
  column: AreaAssignmentSortAndFilterColumns;
  order: SortOrder;
};

export type Article = {
  __typename?: 'Article';
  article_number: Scalars['String'];
  assignments: Array<ArticleClientAssignment>;
  auto_payout_max_sum?: Maybe<Scalars['Float']>;
  auto_payout_min_sum?: Maybe<Scalars['Float']>;
  created_at: Scalars['DateTimeTz'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  sales_price?: Maybe<Scalars['Float']>;
  tax: Scalars['Float'];
  title: Scalars['String'];
  unit?: Maybe<ArticleUnit>;
  updated_at: Scalars['DateTimeTz'];
};

export type ArticleClientAssignment = {
  __typename?: 'ArticleClientAssignment';
  client_id: Scalars['ID'];
};

export type ArticleClientAssignmentInput = {
  client_id: Scalars['ID'];
};

export type ArticleInput = {
  article_number?: InputMaybe<Scalars['String']>;
  assignments?: InputMaybe<Array<ArticleClientAssignmentInput>>;
  auto_payout_max_sum?: InputMaybe<Scalars['Float']>;
  auto_payout_min_sum?: InputMaybe<Scalars['Float']>;
  description?: InputMaybe<Scalars['String']>;
  sales_price?: InputMaybe<Scalars['Float']>;
  tax?: InputMaybe<Scalars['Float']>;
  title?: InputMaybe<Scalars['String']>;
  unit_id?: InputMaybe<Scalars['ID']>;
};

export type ArticlePaginator = {
  __typename?: 'ArticlePaginator';
  data: Array<Article>;
  paginatorInfo: PaginatorInfo;
};

export type ArticleUnit = {
  __typename?: 'ArticleUnit';
  id: Scalars['ID'];
  title: Scalars['String'];
};

export type ArticlesFilter = {
  AND?: InputMaybe<Array<ArticlesFilter>>;
  OR?: InputMaybe<Array<ArticlesFilter>>;
  column?: InputMaybe<ArticlesSortAndFilterColumns>;
  operator?: InputMaybe<SQLOperator>;
  value?: InputMaybe<Scalars['Mixed']>;
};

export type ArticlesSort = {
  columns?: InputMaybe<Array<ArticlesSortOrder>>;
};

export enum ArticlesSortAndFilterColumns {
  ARTICLE_NUMBER = 'ARTICLE_NUMBER',
  DESCRIPTION = 'DESCRIPTION',
  ID = 'ID',
  TITLE = 'TITLE',
}

export type ArticlesSortOrder = {
  column: ArticlesSortAndFilterColumns;
  order: SortOrder;
};

export enum AssignmentType {
  DISTANCE = 'DISTANCE',
  POSTCODE = 'POSTCODE',
}

export enum AssignmentWeekDay {
  FRIDAY = 'FRIDAY',
  MONDAY = 'MONDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
  THURSDAY = 'THURSDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
}

export type AuthData = {
  __typename?: 'AuthData';
  access_token: Scalars['String'];
  expires_at: Scalars['DateTimeTz'];
  expires_in: Scalars['Int'];
  refresh_token: Scalars['String'];
  refresh_token_expires_at: Scalars['DateTimeTz'];
  token_type: Scalars['String'];
};

export type AuthPasswordSettingsInput = {
  auth_password_min_length?: InputMaybe<Scalars['Int']>;
  auth_password_min_numbers?: InputMaybe<Scalars['Int']>;
  auth_password_min_special?: InputMaybe<Scalars['Int']>;
  auth_password_min_upper_case?: InputMaybe<Scalars['Int']>;
};

export type AuthResponse = {
  __typename?: 'AuthResponse';
  message: Scalars['String'];
};

export type AuthState = {
  __typename?: 'AuthState';
  data: AuthData;
  superUser?: Maybe<User>;
  user: User;
};

export type Bounds = {
  __typename?: 'Bounds';
  max: Point;
  min: Point;
};

export type BoundsInput = {
  max: PointInput;
  min: PointInput;
};

export enum CalculationGroupType {
  ACCEPTED = 'ACCEPTED',
  AMOUNT = 'AMOUNT',
  CATEGORY = 'CATEGORY',
  PAY_STATE = 'PAY_STATE',
  SINGLE_PRICE = 'SINGLE_PRICE',
  STATUS = 'STATUS',
  SUM = 'SUM',
  TAX = 'TAX',
  TAX_CALCULATED = 'TAX_CALCULATED',
  TIME_VALUE = 'TIME_VALUE',
  TITLE = 'TITLE',
  UNIT = 'UNIT',
}

export enum CalculationLayout {
  DEFAULT = 'DEFAULT',
}

export type CalendarDrivingEvent = {
  __typename?: 'CalendarDrivingEvent';
  belongs_to_event_id: Scalars['ID'];
  distance: Scalars['Int'];
  minutes: Scalars['Int'];
  type: CalendarDrivingEventType;
};

export enum CalendarDrivingEventType {
  DANGER = 'DANGER',
  SUCCESS = 'SUCCESS',
}

export type CalendarDrivingPaginator = {
  __typename?: 'CalendarDrivingPaginator';
  data: Array<CalendarDrivingEvent>;
  paginatorInfo: PaginatorInfo;
};

export type CalendarEvent = {
  __typename?: 'CalendarEvent';
  color: Scalars['String'];
  date_from: Scalars['DateTimeTz'];
  date_to: Scalars['DateTimeTz'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  is_all_day: Scalars['Boolean'];
  permissions?: Maybe<Array<Permission>>;
  task_event?: Maybe<TaskEvent>;
  task_id?: Maybe<Scalars['ID']>;
  title: Scalars['String'];
  type: CalendarEventType;
  type_id: CalendarEventTypeId;
};

export type CalendarEventPaginator = {
  __typename?: 'CalendarEventPaginator';
  data: Array<CalendarEvent>;
  paginatorInfo: PaginatorInfo;
};

export type CalendarEventType = {
  __typename?: 'CalendarEventType';
  color: Scalars['String'];
  id: Scalars['ID'];
  title: Scalars['String'];
  type_id: CalendarEventTypeId;
};

export enum CalendarEventTypeId {
  ADDITIONAL_INSPECTION = 'ADDITIONAL_INSPECTION',
  DRIVING_TIME_DANGER = 'DRIVING_TIME_DANGER',
  DRIVING_TIME_SUCCESS = 'DRIVING_TIME_SUCCESS',
  INITIAL_CONTACT = 'INITIAL_CONTACT',
  ON_SITE_APPOINTMENT = 'ON_SITE_APPOINTMENT',
  PRIVATE = 'PRIVATE',
  REPORT_CREATED = 'REPORT_CREATED',
  TASK_FINISHED = 'TASK_FINISHED',
}

export type CalendarEventsFilter = {
  AND?: InputMaybe<Array<CalendarEventsFilter>>;
  OR?: InputMaybe<Array<CalendarEventsFilter>>;
  column?: InputMaybe<CalendarEventsSortAndFilterColumns>;
  operator?: InputMaybe<SQLOperator>;
  value?: InputMaybe<Scalars['Mixed']>;
};

export type CalendarEventsSort = {
  columns?: InputMaybe<Array<CalendarEventsSortOrder>>;
};

export enum CalendarEventsSortAndFilterColumns {
  CLIENT_ID = 'CLIENT_ID',
  DATE_FROM = 'DATE_FROM',
  DATE_TO = 'DATE_TO',
  DESCRIPTION = 'DESCRIPTION',
  ID = 'ID',
  TASK_ID = 'TASK_ID',
  TITLE = 'TITLE',
  TYPE_ID = 'TYPE_ID',
}

export type CalendarEventsSortOrder = {
  column: CalendarEventsSortAndFilterColumns;
  order: SortOrder;
};

export type ChatChannel = {
  __typename?: 'ChatChannel';
  id: Scalars['ID'];
  info?: Maybe<ChatChannelInfo>;
  last_message?: Maybe<ChatMessage>;
  members: ChatMemberList;
  messages: ChatMessageList;
  permissions: Array<Permission>;
  title: Scalars['String'];
  type: ChatChannelType;
  type_id: ChatChannelTypeId;
};

export type ChatChannelInfo = {
  __typename?: 'ChatChannelInfo';
  id: Scalars['ID'];
  latestMessage?: Maybe<ChatMessage>;
  unread_messages_count: Scalars['Int'];
};

export type ChatChannelList = {
  __typename?: 'ChatChannelList';
  data: Array<ChatChannel>;
  id: Scalars['ID'];
};

export type ChatChannelType = {
  __typename?: 'ChatChannelType';
  id: Scalars['ID'];
  title: Scalars['String'];
  type_id: ChatChannelTypeId;
};

export enum ChatChannelTypeId {
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC',
}

export type ChatMember = {
  __typename?: 'ChatMember';
  channel: ChatChannel;
  channel_id: Scalars['ID'];
  client: Client;
  client_id: Scalars['ID'];
  hidden: Scalars['Boolean'];
  id: Scalars['ID'];
  last_read_at: Scalars['DateTimeTz'];
  type: ChatMemberType;
  type_id: ChatMemberTypeId;
};

export type ChatMemberList = {
  __typename?: 'ChatMemberList';
  data: Array<ChatMember>;
  id: Scalars['ID'];
};

export type ChatMemberType = {
  __typename?: 'ChatMemberType';
  id: Scalars['ID'];
  title: Scalars['String'];
  type_id: ChatMemberTypeId;
};

export enum ChatMemberTypeId {
  ADMIN = 'ADMIN',
  MEMBER = 'MEMBER',
  OWNER = 'OWNER',
}

export type ChatMessage = {
  __typename?: 'ChatMessage';
  body: Scalars['String'];
  channel: ChatChannel;
  channel_id: Scalars['ID'];
  created_at: Scalars['DateTimeTz'];
  edited: Scalars['Boolean'];
  id: Scalars['ID'];
  member?: Maybe<ChatMember>;
  member_id?: Maybe<Scalars['ID']>;
  permissions: Array<Permission>;
  type: ChatMemberType;
  type_id: ChatMemberTypeId;
  updated_at: Scalars['DateTimeTz'];
};

export type ChatMessageList = {
  __typename?: 'ChatMessageList';
  data: Array<ChatMessage>;
  id: Scalars['ID'];
};

export type ChatMessageType = {
  __typename?: 'ChatMessageType';
  id: Scalars['ID'];
  title: Scalars['String'];
  type_id: ChatMessageTypeId;
};

export enum ChatMessageTypeId {
  MEMBER = 'MEMBER',
  SYSTEM = 'SYSTEM',
}

export type ClassificationTag = {
  __typename?: 'ClassificationTag';
  alias: Scalars['String'];
  api_only: Scalars['Boolean'];
  color: Scalars['String'];
  id: Scalars['ID'];
  title: Scalars['String'];
};

export type ClassificationTagInput = {
  alias: Scalars['String'];
  api_only: Scalars['Boolean'];
  color: Scalars['String'];
  title: Scalars['String'];
};

export type ClassificationTagsFilter = {
  AND?: InputMaybe<Array<ClassificationTagsFilter>>;
  OR?: InputMaybe<Array<ClassificationTagsFilter>>;
  column?: InputMaybe<ClassificationTagsSortAndFilterColumns>;
  operator?: InputMaybe<SQLOperator>;
  value?: InputMaybe<Scalars['Mixed']>;
};

export type ClassificationTagsPaginator = {
  __typename?: 'ClassificationTagsPaginator';
  data: Array<ClassificationTag>;
  paginatorInfo: PaginatorInfo;
};

export type ClassificationTagsSort = {
  columns?: InputMaybe<Array<ClassificationTagsSortOrder>>;
};

export enum ClassificationTagsSortAndFilterColumns {
  ALIAS = 'ALIAS',
  API_ONLY = 'API_ONLY',
  TITLE = 'TITLE',
}

export type ClassificationTagsSortOrder = {
  column: ClassificationTagsSortAndFilterColumns;
  order: SortOrder;
};

export type Client = {
  __typename?: 'Client';
  adjusters: Array<Client>;
  alternate_email?: Maybe<Scalars['String']>;
  assigned_email_templates?: Maybe<Array<EmailTemplate>>;
  /** Notification settings for client */
  assigned_interface_message_templates?: Maybe<Array<InterfaceMessageTemplate>>;
  assigned_templates: Array<MessageTemplate>;
  assignment_notes?: Maybe<Scalars['String']>;
  availability: ClientAssignmentAvailabilityState;
  client_additional_insurance?: Maybe<ClientAdditionalInsurance>;
  client_additional_loss_adjuster?: Maybe<ClientAdditionalLossAdjuster>;
  client_additional_policy_holder?: Maybe<ClientAdditionalPolicyHolder>;
  client_banking?: Maybe<ClientBanking>;
  client_qualification?: Maybe<ClientQualification>;
  client_service_level?: Maybe<ClientServiceLevel>;
  client_tax?: Maybe<ClientTax>;
  client_type?: Maybe<ClientType>;
  /** Notification settings for client */
  created_at: Scalars['DateTimeTz'];
  dashboard_type: DashboardType;
  direct_message_email_templates?: Maybe<Array<EmailTemplate>>;
  direct_message_interface_message_templates?: Maybe<Array<InterfaceMessageTemplate>>;
  disabled_templates: Array<ClientQualificationFormDisabled>;
  email?: Maybe<Scalars['String']>;
  external_client_id?: Maybe<Scalars['String']>;
  external_number?: Maybe<Scalars['String']>;
  has_additional_loss_adjuster?: Maybe<Scalars['Boolean']>;
  hidden_insurances: Array<ClientQualificationHideInsurance>;
  id: Scalars['ID'];
  /** Notification settings for client */
  intros: Array<ClientIntro>;
  is_absent?: Maybe<Scalars['Boolean']>;
  leader?: Maybe<Client>;
  leader_client_id?: Maybe<Scalars['ID']>;
  locations: Array<ClientLocation>;
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  /** Permissions for user and Client */
  notification_settings: CombinedNotificationSettingsList;
  /** Permissions for user and Client */
  permissions: Array<Permission>;
  phone?: Maybe<Scalars['String']>;
  primary_location?: Maybe<ClientLocation>;
  qualifications: ClientQualificationList;
  salutation_id: Scalars['ID'];
  state: ClientState;
  state_id: ClientStateId;
  state_notes?: Maybe<Scalars['String']>;
  type: ClientTypeId;
  type_id: Scalars['ID'];
  updated_at: Scalars['DateTimeTz'];
  user?: Maybe<User>;
  web?: Maybe<Scalars['String']>;
};

export type ClientAbsence = {
  __typename?: 'ClientAbsence';
  client_id: Scalars['ID'];
  from: Scalars['DateTimeTz'];
  id: Scalars['ID'];
  reason: Scalars['String'];
  to: Scalars['DateTimeTz'];
};

export type ClientAbsencesFilter = {
  AND?: InputMaybe<Array<ClientAbsencesFilter>>;
  OR?: InputMaybe<Array<ClientAbsencesFilter>>;
  column?: InputMaybe<ClientAbsencesSortAndFilterColumns>;
  operator?: InputMaybe<SQLOperator>;
  value?: InputMaybe<Scalars['Mixed']>;
};

export type ClientAbsencesPaginator = {
  __typename?: 'ClientAbsencesPaginator';
  data: Array<ClientAbsence>;
  paginatorInfo: PaginatorInfo;
};

export type ClientAbsencesSort = {
  columns?: InputMaybe<Array<ClientAbsencesSortOrder>>;
};

export enum ClientAbsencesSortAndFilterColumns {
  FROM = 'FROM',
  ID = 'ID',
  REASON = 'REASON',
  TO = 'TO',
}

export type ClientAbsencesSortOrder = {
  column: ClientAbsencesSortAndFilterColumns;
  order: SortOrder;
};

export type ClientAdditionalInsurance = {
  __typename?: 'ClientAdditionalInsurance';
  client_id: Scalars['ID'];
  damage_number_mask?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  tasks_statistic_export_columns?: Maybe<Array<Scalars['String']>>;
  term_of_payment?: Maybe<Scalars['Int']>;
};

export type ClientAdditionalInsuranceInput = {
  damage_number_mask?: InputMaybe<Scalars['String']>;
  tasks_statistic_export_columns?: InputMaybe<Array<Scalars['String']>>;
  term_of_payment?: InputMaybe<Scalars['Int']>;
};

export type ClientAdditionalLossAdjuster = {
  __typename?: 'ClientAdditionalLossAdjuster';
  business_insurance: Scalars['Boolean'];
  business_payout_amount?: Maybe<Scalars['Int']>;
  business_payout_percentage?: Maybe<Scalars['Int']>;
  can_send_emails: Scalars['Boolean'];
  client_id: Scalars['ID'];
  damage_payout_amount?: Maybe<Scalars['Int']>;
  damage_payout_percentage?: Maybe<Scalars['Int']>;
  has_ipad: Scalars['Boolean'];
  has_payout: Scalars['Boolean'];
  id: Scalars['ID'];
  leader_business_payout_amount?: Maybe<Scalars['Int']>;
  leader_business_payout_percentage?: Maybe<Scalars['Int']>;
  leader_damage_payout_amount?: Maybe<Scalars['Int']>;
  leader_damage_payout_percentage?: Maybe<Scalars['Int']>;
  leader_liability_payout_amount?: Maybe<Scalars['Int']>;
  leader_liability_payout_percentage?: Maybe<Scalars['Int']>;
  liability_payout_amount?: Maybe<Scalars['Int']>;
  liability_payout_percentage?: Maybe<Scalars['Int']>;
  payout_type?: Maybe<PayoutType>;
  payout_type_tax?: Maybe<PayoutTypeTax>;
};

export type ClientAdditionalLossAdjusterInput = {
  business_insurance?: InputMaybe<Scalars['Boolean']>;
  business_payout_amount?: InputMaybe<Scalars['Int']>;
  business_payout_percentage?: InputMaybe<Scalars['Int']>;
  can_send_emails?: InputMaybe<Scalars['Boolean']>;
  damage_payout_amount?: InputMaybe<Scalars['Int']>;
  damage_payout_percentage?: InputMaybe<Scalars['Int']>;
  has_ipad?: InputMaybe<Scalars['Boolean']>;
  has_payout?: InputMaybe<Scalars['Boolean']>;
  leader_business_payout_amount?: InputMaybe<Scalars['Int']>;
  leader_business_payout_percentage?: InputMaybe<Scalars['Int']>;
  leader_damage_payout_amount?: InputMaybe<Scalars['Int']>;
  leader_damage_payout_percentage?: InputMaybe<Scalars['Int']>;
  leader_liability_payout_amount?: InputMaybe<Scalars['Int']>;
  leader_liability_payout_percentage?: InputMaybe<Scalars['Int']>;
  liability_payout_amount?: InputMaybe<Scalars['Int']>;
  liability_payout_percentage?: InputMaybe<Scalars['Int']>;
  payout_type?: InputMaybe<PayoutType>;
  payout_type_tax?: InputMaybe<PayoutTypeTax>;
};

export type ClientAdditionalPolicyHolder = {
  __typename?: 'ClientAdditionalPolicyHolder';
  client_id: Scalars['ID'];
  client_qualification?: Maybe<ClientQualification>;
  customer_number?: Maybe<Scalars['String']>;
  customer_state?: Maybe<ClientCustomerState>;
  customer_state_date?: Maybe<Scalars['DateTimeTz']>;
  customer_state_id?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  insurance_policy_number?: Maybe<Scalars['String']>;
  policy_holder_type_id?: Maybe<PolicyHolderType>;
  qualification_id?: Maybe<Scalars['Int']>;
};

export type ClientAdditionalPolicyHolderInput = {
  customer_number?: InputMaybe<Scalars['String']>;
  customer_state_date?: InputMaybe<Scalars['DateTimeTz']>;
  customer_state_id?: InputMaybe<Scalars['String']>;
  insurance_policy_number?: InputMaybe<Scalars['String']>;
  policy_holder_type_id?: InputMaybe<PolicyHolderType>;
  qualification_id?: InputMaybe<Scalars['Int']>;
};

export type ClientAssignmentAvailabilityState = {
  __typename?: 'ClientAssignmentAvailabilityState';
  color: Scalars['String'];
  id: Scalars['ID'];
  state_id: ClientAssignmentAvailabilityStateId;
  title: Scalars['String'];
};

export enum ClientAssignmentAvailabilityStateId {
  ABSENT = 'ABSENT',
  ABSENT_SOON = 'ABSENT_SOON',
  AVAILABLE = 'AVAILABLE',
  AVAILABLE_SOON = 'AVAILABLE_SOON',
}

export type ClientBanking = {
  __typename?: 'ClientBanking';
  account_number?: Maybe<Scalars['String']>;
  bank_code?: Maybe<Scalars['String']>;
  bank_name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  client_id: Scalars['ID'];
  external_identifier?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  payment_target_days?: Maybe<Scalars['Int']>;
  payout_note?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  use_alternate_address?: Maybe<Scalars['Boolean']>;
};

export type ClientBankingInput = {
  account_number?: InputMaybe<Scalars['String']>;
  bank_code?: InputMaybe<Scalars['String']>;
  bank_name?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  external_identifier?: InputMaybe<Scalars['String']>;
  iban?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  payment_target_days?: InputMaybe<Scalars['Int']>;
  payout_note?: InputMaybe<Scalars['String']>;
  postcode?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  use_alternate_address?: InputMaybe<Scalars['Boolean']>;
};

export type ClientCommonInput = {
  alternate_email?: InputMaybe<Scalars['String']>;
  assignment_notes?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  external_client_id?: InputMaybe<Scalars['String']>;
  external_number?: InputMaybe<Scalars['String']>;
  is_absent?: InputMaybe<Scalars['Boolean']>;
  leader_client_id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  salutation_id: Scalars['ID'];
  state_id?: InputMaybe<ClientStateId>;
  state_notes?: InputMaybe<Scalars['String']>;
  type_id: Scalars['ID'];
  web?: InputMaybe<Scalars['String']>;
};

export type ClientCustomerState = {
  __typename?: 'ClientCustomerState';
  color: Scalars['String'];
  id: Scalars['ID'];
  title: Scalars['String'];
};

export type ClientFilterItem = {
  __typename?: 'ClientFilterItem';
  children: Array<Client>;
  client?: Maybe<Client>;
  title?: Maybe<Scalars['String']>;
};

/**  old inputs  */
export type ClientInput = {
  email?: InputMaybe<Scalars['String']>;
  locations?: InputMaybe<Array<CreateClientLocationInput>>;
  name?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  primary_location?: InputMaybe<CreateClientLocationInput>;
  salutation_id: Scalars['ID'];
  state_id?: InputMaybe<Scalars['Int']>;
  type_id: Scalars['ID'];
  web?: InputMaybe<Scalars['String']>;
};

export type ClientIntro = {
  __typename?: 'ClientIntro';
  client_id: Scalars['ID'];
  hidden: Scalars['Boolean'];
  id: Scalars['ID'];
  intro_id: Scalars['String'];
  steps: Array<Scalars['String']>;
};

export type ClientIntroInput = {
  intro_id: Scalars['ID'];
  steps: Array<Scalars['String']>;
};

export type ClientIntroList = {
  __typename?: 'ClientIntroList';
  data: Array<ClientIntro>;
};

export type ClientList = {
  __typename?: 'ClientList';
  data: Array<Client>;
};

export type ClientLocation = {
  __typename?: 'ClientLocation';
  city?: Maybe<Scalars['String']>;
  client_id: Scalars['ID'];
  client_location_type: ClientLocationType;
  client_location_type_id: ClientLocationTypeId;
  contacts: Array<Contact>;
  country: Country;
  country_id?: Maybe<Scalars['ID']>;
  created_at: Scalars['DateTimeTz'];
  different_insurance_policy_number?: Maybe<Scalars['String']>;
  different_policy_holder?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  notes?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  primary?: Maybe<Scalars['Boolean']>;
  street?: Maybe<Scalars['String']>;
  street_full?: Maybe<Scalars['String']>;
  street_no?: Maybe<Scalars['String']>;
  updated_at: Scalars['DateTimeTz'];
};

export type ClientLocationInput = {
  city: Scalars['String'];
  client_location_type_id: ClientLocationTypeId;
  country_id: Scalars['ID'];
  different_insurance_policy_number?: InputMaybe<Scalars['String']>;
  different_policy_holder?: InputMaybe<Scalars['String']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  notes?: InputMaybe<Scalars['String']>;
  postcode: Scalars['String'];
  primary?: InputMaybe<Scalars['Boolean']>;
  street: Scalars['String'];
  street_no: Scalars['String'];
};

export type ClientLocationPaginator = {
  __typename?: 'ClientLocationPaginator';
  data: Array<ClientLocation>;
  paginatorInfo: PaginatorInfo;
};

export type ClientLocationType = {
  __typename?: 'ClientLocationType';
  color: Scalars['String'];
  id: Scalars['ID'];
  title: Scalars['String'];
  type_id: ClientLocationTypeId;
};

export enum ClientLocationTypeId {
  PRIMARY_LOCATION = 'PRIMARY_LOCATION',
  SECONDARY_LOCATION = 'SECONDARY_LOCATION',
}

export type ClientLocationsFilter = {
  AND?: InputMaybe<Array<ClientLocationsFilter>>;
  OR?: InputMaybe<Array<ClientLocationsFilter>>;
  column?: InputMaybe<ClientLocationsSortAndFilterColumns>;
  operator?: InputMaybe<SQLOperator>;
  value?: InputMaybe<Scalars['Mixed']>;
};

export type ClientLocationsSort = {
  columns?: InputMaybe<Array<ClientLocationsSortOrder>>;
};

export enum ClientLocationsSortAndFilterColumns {
  CITY = 'CITY',
  COUNTRY_ID = 'COUNTRY_ID',
  POSTCODE = 'POSTCODE',
  STREET = 'STREET',
  STREET_NO = 'STREET_NO',
}

export type ClientLocationsSortOrder = {
  column: ClientLocationsSortAndFilterColumns;
  order: SortOrder;
};

export type ClientNotificationEmailSettingsInput = {
  email: Scalars['Boolean'];
  email_contacts: Array<ClientNotificationSettingEmailContactInput>;
  email_template_id?: InputMaybe<Scalars['ID']>;
  type_id: NotificationTypeId;
};

export type ClientNotificationSetting = {
  __typename?: 'ClientNotificationSetting';
  email: Scalars['Boolean'];
  email_contacts: Array<ClientNotificationSettingEmailContact>;
  email_template?: Maybe<EmailTemplate>;
  email_template_id?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  internal: Scalars['Boolean'];
  push: Scalars['Boolean'];
  sms: Scalars['Boolean'];
  type: NotificationType;
  type_id: NotificationTypeId;
};

export type ClientNotificationSettingEmailContact = {
  __typename?: 'ClientNotificationSettingEmailContact';
  address: Scalars['String'];
  client_notification_setting_id: Scalars['ID'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  type: EmailContactType;
};

export type ClientNotificationSettingEmailContactInput = {
  address: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  type: EmailContactType;
};

export type ClientNotificationSettingsInput = {
  email: Scalars['Boolean'];
  internal: Scalars['Boolean'];
  push: Scalars['Boolean'];
  sms: Scalars['Boolean'];
  type_id: NotificationTypeId;
};

export type ClientNotificationSettingsList = {
  __typename?: 'ClientNotificationSettingsList';
  data: Array<ClientNotificationSetting>;
  id: Scalars['ID'];
};

export type ClientPaginator = {
  __typename?: 'ClientPaginator';
  data: Array<Client>;
  paginatorInfo: PaginatorInfo;
};

export type ClientPrimaryLocationInput = {
  city: Scalars['String'];
  country_id: Scalars['ID'];
  different_policy_holder?: InputMaybe<Scalars['String']>;
  postcode: Scalars['String'];
  street: Scalars['String'];
  street_no: Scalars['String'];
};

export type ClientQualification = {
  __typename?: 'ClientQualification';
  allow_additional: Scalars['Boolean'];
  allow_business: Scalars['Boolean'];
  allow_liability: Scalars['Boolean'];
  allow_property: Scalars['Boolean'];
  can_finish_tasks: Scalars['Boolean'];
  client_id: Scalars['ID'];
  id: Scalars['ID'];
  max_distance_to_claim: Scalars['Int'];
};

export type ClientQualificationDisabledFormsInput = {
  template_id: Scalars['ID'];
};

export type ClientQualificationFormDisabled = {
  __typename?: 'ClientQualificationFormDisabled';
  client_id: Scalars['ID'];
  id: Scalars['ID'];
  template_id: Scalars['ID'];
};

export type ClientQualificationHideInsurance = {
  __typename?: 'ClientQualificationHideInsurance';
  adjuster_client_id: Scalars['ID'];
  id: Scalars['ID'];
  insurance_client_id: Scalars['ID'];
};

export type ClientQualificationHideInsuranceInput = {
  insurance_client_id: Scalars['ID'];
};

export type ClientQualificationInput = {
  allow_additional: Scalars['Boolean'];
  allow_business: Scalars['Boolean'];
  allow_liability: Scalars['Boolean'];
  allow_property: Scalars['Boolean'];
  can_finish_tasks: Scalars['Boolean'];
  max_distance_to_claim: Scalars['Int'];
};

export type ClientQualificationList = {
  __typename?: 'ClientQualificationList';
  data: Array<Qualification>;
  id: Scalars['ID'];
};

export type ClientServiceLevel = {
  __typename?: 'ClientServiceLevel';
  accept_task?: Maybe<Scalars['Int']>;
  client_id: Scalars['ID'];
  id: Scalars['ID'];
  send_report?: Maybe<Scalars['Int']>;
  set_appointment?: Maybe<Scalars['Int']>;
  set_controlling?: Maybe<Scalars['Int']>;
  set_rework_done?: Maybe<Scalars['Int']>;
};

/**  create inputs  */
export type ClientServiceLevelInput = {
  accept_task?: InputMaybe<Scalars['Int']>;
  send_report?: InputMaybe<Scalars['Int']>;
  set_appointment?: InputMaybe<Scalars['Int']>;
  set_controlling?: InputMaybe<Scalars['Int']>;
  set_rework_done?: InputMaybe<Scalars['Int']>;
};

export type ClientState = {
  __typename?: 'ClientState';
  color: Scalars['String'];
  id: Scalars['ID'];
  state_id: ClientStateId;
  title: Scalars['String'];
};

export enum ClientStateId {
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED',
  INACTIVE = 'INACTIVE',
}

export type ClientTax = {
  __typename?: 'ClientTax';
  accounts_receivable_number?: Maybe<Scalars['String']>;
  client_id: Scalars['ID'];
  eligible_tax_deduction: Scalars['Boolean'];
  id: Scalars['ID'];
  vat_number?: Maybe<Scalars['String']>;
};

export type ClientTaxInput = {
  accounts_receivable_number?: InputMaybe<Scalars['String']>;
  eligible_tax_deduction: Scalars['Boolean'];
  vat_number?: InputMaybe<Scalars['String']>;
};

export type ClientType = {
  __typename?: 'ClientType';
  choose_for_tasks: Scalars['Boolean'];
  color: Scalars['String'];
  id: Scalars['ID'];
  title: Scalars['String'];
  type_id: ClientTypeId;
};

export enum ClientTypeId {
  ADJUSTER = 'ADJUSTER',
  ADMIN = 'ADMIN',
  AGENCY = 'AGENCY',
  BROKER = 'BROKER',
  EXTERNAL_ADJUSTER_ORGANISATION = 'EXTERNAL_ADJUSTER_ORGANISATION',
  INSURANCE = 'INSURANCE',
  LEADER = 'LEADER',
  POLICY_HOLDER = 'POLICY_HOLDER',
  REPAIR_SERVICE_PROVIDER = 'REPAIR_SERVICE_PROVIDER',
  SUPERVISOR = 'SUPERVISOR',
}

export type ClientsFilter = {
  AND?: InputMaybe<Array<ClientsFilter>>;
  OR?: InputMaybe<Array<ClientsFilter>>;
  column?: InputMaybe<ClientsSortAndFilterColumns>;
  operator?: InputMaybe<SQLOperator>;
  value?: InputMaybe<Scalars['Mixed']>;
};

export type ClientsForSubTaskAssigneeList = {
  __typename?: 'ClientsForSubTaskAssigneeList';
  data: Array<ClientFilterItem>;
};

export type ClientsForSubTaskControllerList = {
  __typename?: 'ClientsForSubTaskControllerList';
  data: Array<ClientFilterItem>;
};

export type ClientsLoginPaginator = {
  __typename?: 'ClientsLoginPaginator';
  data: Array<Client>;
};

export enum ClientsPrimaryLocationFilterColumns {
  CITY = 'CITY',
  STREET = 'STREET',
}

export type ClientsSort = {
  columns?: InputMaybe<Array<ClientsSortOrder>>;
};

export enum ClientsSortAndFilterColumns {
  CITY = 'CITY',
  EMAIL = 'EMAIL',
  ID = 'ID',
  NAME = 'NAME',
  PHONE = 'PHONE',
  POLICY_HOLDER_CUSTOMER_NUMBER = 'POLICY_HOLDER_CUSTOMER_NUMBER',
  POLICY_HOLDER_TYPE_ID = 'POLICY_HOLDER_TYPE_ID',
  POSTCODE = 'POSTCODE',
  QUALIFICATION_ID = 'QUALIFICATION_ID',
  STATE = 'STATE',
  STREET = 'STREET',
  TYPE = 'TYPE',
}

export type ClientsSortOrder = {
  column: ClientsSortAndFilterColumns;
  order: SortOrder;
};

export type CombinedNotificationSetting = {
  __typename?: 'CombinedNotificationSetting';
  email: Scalars['Boolean'];
  email_contacts: Array<ClientNotificationSettingEmailContact>;
  email_template?: Maybe<EmailTemplate>;
  email_template_id?: Maybe<Scalars['ID']>;
  internal: Scalars['Boolean'];
  push: Scalars['Boolean'];
  sms: Scalars['Boolean'];
  type: NotificationType;
  type_id: NotificationTypeId;
};

export type CombinedNotificationSettingsList = {
  __typename?: 'CombinedNotificationSettingsList';
  data: Array<CombinedNotificationSetting>;
  id: Scalars['ID'];
};

export type Contact = {
  __typename?: 'Contact';
  bic?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  client_location?: Maybe<ClientLocation>;
  client_location_id: Scalars['ID'];
  country?: Maybe<Country>;
  country_id?: Maybe<Scalars['ID']>;
  created_at: Scalars['DateTimeTz'];
  email?: Maybe<Scalars['String']>;
  email2?: Maybe<Scalars['String']>;
  fax_number?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  function?: Maybe<ContactFunction>;
  function_id?: Maybe<Scalars['ID']>;
  iban?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  mobile?: Maybe<Scalars['String']>;
  mobile2?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  phone2?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  role?: Maybe<ContactRole>;
  role_id?: Maybe<Scalars['ID']>;
  salutation?: Maybe<Salutation>;
  salutation_id?: Maybe<Scalars['ID']>;
  street?: Maybe<Scalars['String']>;
  street_no?: Maybe<Scalars['String']>;
  task_id?: Maybe<Scalars['ID']>;
  updated_at: Scalars['DateTimeTz'];
};

export type ContactFunction = {
  __typename?: 'ContactFunction';
  id: Scalars['ID'];
  title: Scalars['String'];
};

export type ContactPaginator = {
  __typename?: 'ContactPaginator';
  data: Array<Contact>;
  paginatorInfo: PaginatorInfo;
};

export type ContactRole = {
  __typename?: 'ContactRole';
  id: Scalars['ID'];
  title: Scalars['String'];
};

export type ContactsFilter = {
  AND?: InputMaybe<Array<ContactsFilter>>;
  OR?: InputMaybe<Array<ContactsFilter>>;
  column?: InputMaybe<ContactsSortAndFilterColumns>;
  operator?: InputMaybe<SQLOperator>;
  value?: InputMaybe<Scalars['Mixed']>;
};

export type ContactsSort = {
  columns?: InputMaybe<Array<ContactsSortOrder>>;
};

export enum ContactsSortAndFilterColumns {
  CITY = 'CITY',
  CLIENT_ID = 'CLIENT_ID',
  CLIENT_LOCATION_ID = 'CLIENT_LOCATION_ID',
  COUNTRY_ID = 'COUNTRY_ID',
  FIRSTNAME = 'FIRSTNAME',
  NAME = 'NAME',
  POSTCODE = 'POSTCODE',
  STREET = 'STREET',
  STREET_NO = 'STREET_NO',
}

export type ContactsSortOrder = {
  column: ContactsSortAndFilterColumns;
  order: SortOrder;
};

export type Country = {
  __typename?: 'Country';
  bounding_box_max_latitude: Scalars['Float'];
  bounding_box_max_longitude: Scalars['Float'];
  bounding_box_min_latitude: Scalars['Float'];
  bounding_box_min_longitude: Scalars['Float'];
  code: Scalars['String'];
  dial_code: Scalars['String'];
  id: Scalars['ID'];
  title: Scalars['String'];
};

/** A paginated list of Country items. */
export type CountryPaginator = {
  __typename?: 'CountryPaginator';
  /** A list of Country items. */
  data: Array<Country>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export enum CoverLayout {
  DEFAULT = 'DEFAULT',
  DEFAULT_CLAIMANT_COVER = 'DEFAULT_CLAIMANT_COVER',
  DEFAULT_INVOICE_CHECK = 'DEFAULT_INVOICE_CHECK',
  DEFAULT_NO_COVER_IMAGE = 'DEFAULT_NO_COVER_IMAGE',
  DEFAULT_RISK_COVER = 'DEFAULT_RISK_COVER',
}

export type CreateAddressBookEntryInput = {
  email: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
};

export type CreateApiTokenResponse = {
  __typename?: 'CreateApiTokenResponse';
  api_token: Scalars['String'];
  message: Scalars['String'];
};

export type CreateAppEmailTransportInput = {
  active: Scalars['Boolean'];
  smtp?: InputMaybe<AppEmailTransportSmtpInput>;
  title: Scalars['String'];
  type_id: AppEmailTransportTypeId;
};

export type CreateAppMessageInput = {
  active: Scalars['Boolean'];
  body: Scalars['String'];
  title: Scalars['String'];
  type: PageNotificationType;
  visible_from?: InputMaybe<Scalars['DateTimeTz']>;
  visible_to?: InputMaybe<Scalars['DateTimeTz']>;
};

export type CreateAppStampInput = {
  description?: InputMaybe<Scalars['String']>;
  file: Scalars['Upload'];
  title: Scalars['String'];
};

export type CreateCalendarEventInput = {
  date_from: Scalars['DateTimeTz'];
  date_to: Scalars['DateTimeTz'];
  description?: InputMaybe<Scalars['String']>;
  is_all_day: Scalars['Boolean'];
  task_id?: InputMaybe<Scalars['ID']>;
  title: Scalars['String'];
  type_id: CalendarEventTypeId;
};

export type CreateChatChannelInput = {
  title: Scalars['String'];
};

export type CreateClientAbsenceInput = {
  from: Scalars['DateTimeTz'];
  reason: Scalars['String'];
  to: Scalars['DateTimeTz'];
};

/**  update inputs  */
export type CreateClientInput = {
  common: ClientCommonInput;
  primary_location: ClientPrimaryLocationInput;
  qualification_ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type CreateClientLocationInput = {
  assignment_note?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  client_location_type_id: ClientLocationTypeId;
  country_id: Scalars['ID'];
  different_policy_holder?: InputMaybe<Scalars['String']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  notes?: InputMaybe<Scalars['String']>;
  postcode: Scalars['String'];
  primary?: InputMaybe<Scalars['Boolean']>;
  street: Scalars['String'];
  street_no: Scalars['String'];
};

export type CreateContactInput = {
  bic?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  client_location_id: Scalars['ID'];
  country_id?: InputMaybe<Scalars['ID']>;
  email?: InputMaybe<Scalars['String']>;
  email2?: InputMaybe<Scalars['String']>;
  fax_number?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  function_id?: InputMaybe<Scalars['ID']>;
  iban?: InputMaybe<Scalars['String']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  mobile?: InputMaybe<Scalars['String']>;
  mobile2?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  note?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  phone2?: InputMaybe<Scalars['String']>;
  postcode?: InputMaybe<Scalars['String']>;
  role_id?: InputMaybe<Scalars['ID']>;
  salutation_id?: InputMaybe<Scalars['ID']>;
  street?: InputMaybe<Scalars['String']>;
  street_no?: InputMaybe<Scalars['String']>;
};

export type CreateCustomerPortalEventInput = {
  allow_customer_form?: InputMaybe<Scalars['Boolean']>;
  /** Should i send E-Mail */
  allow_customer_upload?: InputMaybe<Scalars['Boolean']>;
  /** Number of Customer */
  customer_email?: InputMaybe<Scalars['String']>;
  customer_name?: InputMaybe<Scalars['String']>;
  /** Freetext can be set by Operator */
  customer_phone?: InputMaybe<Scalars['String']>;
  /** The Type ID if the Event */
  from?: InputMaybe<Scalars['DateTimeTz']>;
  has_video?: InputMaybe<Scalars['Boolean']>;
  /** We need to allow the user just to send an plain link */
  notes?: InputMaybe<Scalars['String']>;
  /** Should i send SMS? */
  send_email?: InputMaybe<Scalars['Boolean']>;
  /** E-Mail of Customer */
  send_sms?: InputMaybe<Scalars['Boolean']>;
  to?: InputMaybe<Scalars['DateTimeTz']>;
  type_id: CustomerPortalEventTypeId;
};

export type CreateDeviceCategoryInput = {
  title: Scalars['String'];
};

export type CreateDeviceInput = {
  category_id?: InputMaybe<Scalars['ID']>;
  device_code: Scalars['String'];
  device_type: Scalars['String'];
  name: Scalars['String'];
  state_id?: InputMaybe<Scalars['ID']>;
  unit_id?: InputMaybe<Scalars['ID']>;
  value?: InputMaybe<Scalars['Float']>;
};

export type CreateDownloadInput = {
  file_ids: Array<Scalars['ID']>;
  filename: Scalars['String'];
};

export type CreateFilesDownloadInput = {
  file_ids: Array<Scalars['ID']>;
};

export type CreatePolicyHolderInput = {
  additionalPolicyHolder?: InputMaybe<ClientAdditionalPolicyHolderInput>;
  common: PolicyHolderCommonInput;
  primary_location: PolicyHolderLocationInput;
  qualification_ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type CreateRandomTasksInput = {
  numTasks: Scalars['Int'];
  state_id: TaskStateEnum;
  urgent: Scalars['Boolean'];
};

export type CreateRandomTasksSeederInput = {
  numTaskEvents: Scalars['Int'];
  numTasks: Scalars['Int'];
  radius: Scalars['Int'];
  task_state: TaskStateEnum;
  urgent: Scalars['Boolean'];
};

export type CreateReportLayoutInput = {
  bg_dark_color: Scalars['String'];
  calculation_layout: CalculationLayout;
  cover_layout: CoverLayout;
  cover_title: Scalars['String'];
  default?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  filename: Scalars['String'];
  footer: Scalars['String'];
  footer_paginate_title: Scalars['String'];
  hide_primary_form?: InputMaybe<Scalars['Boolean']>;
  highlight_color: Scalars['String'];
  images_layout_1: ImagesLayout1;
  images_layout_2: ImagesLayout2;
  images_layout_4: ImagesLayout4;
  is_customer_report?: InputMaybe<Scalars['Boolean']>;
  order_identifiers: Array<PageType>;
  question_filter?: InputMaybe<ReportQuestionFilterType>;
  questions_layout: QuestionLayout;
  room_plan_layout: RoomPlanLayout;
  show_notes?: InputMaybe<Scalars['Boolean']>;
  summary_layout: SummaryLayout;
  tint_color: Scalars['String'];
  title: Scalars['String'];
  toc_layout: TOCLayout;
  use_alternate_logo?: InputMaybe<Scalars['Boolean']>;
};

export type CreateRoomInput = {
  send_sms: Scalars['Boolean'];
  task_id: Scalars['ID'];
};

export type CreateTaskCalculationInput = {
  accepted_alias?: InputMaybe<Scalars['String']>;
  status_alias?: InputMaybe<Scalars['String']>;
  sum_alias?: InputMaybe<Scalars['String']>;
  tax_deductible?: InputMaybe<Scalars['Boolean']>;
  time_value_alias?: InputMaybe<Scalars['String']>;
  time_value_column_active?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
};

export type CreateTaskFormInput = {
  tag_ids?: InputMaybe<Array<Scalars['ID']>>;
  template_id: Scalars['ID'];
  title: Scalars['String'];
  type_id: TaskFormTypeId;
};

export type CreateTaskInput = {
  activate?: InputMaybe<Scalars['Boolean']>;
  article_id?: InputMaybe<Scalars['ID']>;
  assigned_date?: InputMaybe<Scalars['DateTimeTz']>;
  client_contact_id?: InputMaybe<Scalars['ID']>;
  client_id?: InputMaybe<Scalars['ID']>;
  client_location_id?: InputMaybe<Scalars['ID']>;
  contacts?: InputMaybe<Array<TaskContactInput>>;
  damage_date?: InputMaybe<Scalars['DateTimeTz']>;
  damage_note?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  external_number?: InputMaybe<Scalars['String']>;
  files?: InputMaybe<Array<Scalars['Upload']>>;
  identifier?: InputMaybe<Scalars['String']>;
  leader_client_id?: InputMaybe<Scalars['ID']>;
  locations?: InputMaybe<Array<TaskLocationInput>>;
  magic_plan_id?: InputMaybe<Scalars['String']>;
  owner_client_id?: InputMaybe<Scalars['ID']>;
  parallel_number?: InputMaybe<Scalars['String']>;
  policy_number?: InputMaybe<Scalars['String']>;
  qualification_ids?: InputMaybe<Array<Scalars['ID']>>;
  reserve?: InputMaybe<Scalars['Float']>;
  task_visit_type_id?: InputMaybe<Scalars['ID']>;
  template_id?: InputMaybe<Scalars['ID']>;
  urgent?: InputMaybe<Scalars['Boolean']>;
};

export type CreateTaskInvoiceInput = {
  items: Array<TaskInvoiceItemInput>;
};

export type CreateTaskLogInput = {
  log_state_id?: InputMaybe<TaskLogStateEnum>;
  title: Scalars['String'];
};

export type CreateTaskOptions = {
  rejectIfIdentifierExists?: InputMaybe<Scalars['Boolean']>;
};

export type CreateTaskReportInput = {
  custom_report_settings?: InputMaybe<TaskCustomReportSettingInput>;
  report_template_id?: InputMaybe<Scalars['ID']>;
};

export type CreateTaskSubTaskInput = {
  assigned_client_id: Scalars['ID'];
  description?: InputMaybe<Scalars['String']>;
  due_date: Scalars['DateTimeTz'];
  file_id?: InputMaybe<Scalars['ID']>;
  title: Scalars['String'];
};

export type CreateTaskTemplateInput = {
  accepted_alias?: InputMaybe<Scalars['String']>;
  active: Scalars['Boolean'];
  assignments?: InputMaybe<Array<TaskTemplateClientAssignmentInput>>;
  classification_id?: InputMaybe<Scalars['ID']>;
  color: Scalars['String'];
  default_categories?: InputMaybe<Array<DefaultCategoryInput>>;
  default_supervisor_id?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  enable_word_convert?: InputMaybe<Scalars['Boolean']>;
  identifier?: InputMaybe<Scalars['String']>;
  json?: InputMaybe<Scalars['JSON']>;
  shortcut: Scalars['String'];
  show_time_value_calculation?: InputMaybe<Scalars['Boolean']>;
  status_alias?: InputMaybe<Scalars['String']>;
  sum_alias?: InputMaybe<Scalars['String']>;
  time_value_alias?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  type_id: TaskTemplateTypeId;
};

export type CreateUserInput = {
  client_id?: InputMaybe<Scalars['ID']>;
  email: Scalars['String'];
  name: Scalars['String'];
  role_id: Scalars['ID'];
};

export type Currency = {
  __typename?: 'Currency';
  id: Scalars['ID'];
  title: Scalars['String'];
};

export type CustomerPortalEvent = {
  __typename?: 'CustomerPortalEvent';
  allow_customer_form: Scalars['Boolean'];
  /** Lon */
  allow_customer_upload: Scalars['Boolean'];
  /** Phone of Operator */
  contact_person_email: Scalars['String'];
  /** Name of Customer, prefilled but can changed */
  contact_person_name: Scalars['String'];
  /** Name of Operator */
  contact_person_phone: Scalars['String'];
  /** Number of Customer */
  customer_email?: Maybe<Scalars['String']>;
  /** Do we have Lat/Lon of the User - should be asked when link opened */
  customer_lat?: Maybe<Scalars['Float']>;
  /** Lat */
  customer_lon?: Maybe<Scalars['Float']>;
  /** Damage_Number */
  customer_name: Scalars['String'];
  /** Freetext can be set by Operator */
  customer_phone?: Maybe<Scalars['String']>;
  documents_for_customer: Array<CustomerPortalEventDocument>;
  files: Array<CustomerPortalEventFile>;
  /** Hashed URL */
  from?: Maybe<Scalars['DateTimeTz']>;
  has_customer_form: Scalars['Boolean'];
  has_room: Scalars['Boolean'];
  /** And if, when? */
  has_user_position?: Maybe<Scalars['Boolean']>;
  /** EMail of Operator */
  has_video: Scalars['Boolean'];
  hash: Scalars['String'];
  id: Scalars['ID'];
  identifier: Scalars['String'];
  /** Theoretically we can setup only a Link to Upload without Video */
  notes?: Maybe<Scalars['String']>;
  room_identifier?: Maybe<Scalars['String']>;
  /** And if, when? */
  send_email: Scalars['Boolean'];
  /** Should we send an E-Mail with the Link */
  send_email_date?: Maybe<Scalars['DateTimeTz']>;
  /** E-Mail of Customer */
  send_sms: Scalars['Boolean'];
  /** Should we send an SMS with the Link? */
  send_sms_date?: Maybe<Scalars['DateTimeTz']>;
  task: Task;
  task_id: Scalars['ID'];
  /** If you set appointment */
  to?: Maybe<Scalars['DateTimeTz']>;
  type: CustomerPortalEventType;
  type_id: CustomerPortalEventTypeId;
  url?: Maybe<Scalars['String']>;
};

export type CustomerPortalEventDocument = {
  __typename?: 'CustomerPortalEventDocument';
  id: Scalars['ID'];
  modified_file?: Maybe<FileItem>;
  original_file?: Maybe<FileItem>;
};

export type CustomerPortalEventDocumentInput = {
  file_ids: Array<Scalars['ID']>;
};

export type CustomerPortalEventFile = {
  __typename?: 'CustomerPortalEventFile';
  file?: Maybe<FileItem>;
  id: Scalars['ID'];
};

export type CustomerPortalEventLocation = {
  __typename?: 'CustomerPortalEventLocation';
  customer_lat: Scalars['Float'];
  customer_lon: Scalars['Float'];
};

export type CustomerPortalEventLocationInput = {
  customer_lat: Scalars['Float'];
  customer_lon: Scalars['Float'];
  hash: Scalars['String'];
};

export type CustomerPortalEventType = {
  __typename?: 'CustomerPortalEventType';
  has_video: Scalars['Boolean'];
  id: Scalars['ID'];
  need_appointment: Scalars['Boolean'];
  title: Scalars['String'];
  type_id: CustomerPortalEventTypeId;
};

export enum CustomerPortalEventTypeId {
  COLLABORATE_PORTAL = 'COLLABORATE_PORTAL',
  VIDEO = 'VIDEO',
}

export type CustomerPortalInvitationInput = {
  customer_portal_event_id: Scalars['ID'];
  type: CustomerPortalInvitationType;
};

export enum CustomerPortalInvitationType {
  EMAIL = 'EMAIL',
  SMS = 'SMS',
}

export type DashboardTasksByState = {
  __typename?: 'DashboardTasksByState';
  avg_four_weeks: Scalars['Float'];
  avg_week: Scalars['Float'];
  details?: Maybe<Array<TaskDateCount>>;
  today: Scalars['Int'];
  yesterday: Scalars['Int'];
};

export enum DashboardType {
  ADMIN = 'ADMIN',
  LEADER = 'LEADER',
  SUPERVISOR = 'SUPERVISOR',
  USER = 'USER',
}

export type DefaultCategory = {
  __typename?: 'DefaultCategory';
  order_index: Scalars['Int'];
  title: Scalars['String'];
};

export type DefaultCategoryInput = {
  order_index: Scalars['Int'];
  title: Scalars['String'];
};

export type DefaultEMailText = {
  __typename?: 'DefaultEMailText';
  body: Scalars['String'];
  subject: Scalars['String'];
};

export type DefaultEmail = {
  __typename?: 'DefaultEmail';
  body: Scalars['String'];
  subject: Scalars['String'];
  template?: Maybe<EmailTemplate>;
};

export type DeleteResponse = {
  __typename?: 'DeleteResponse';
  message: Scalars['String'];
};

export type Device = {
  __typename?: 'Device';
  category?: Maybe<DeviceCategory>;
  category_id?: Maybe<Scalars['ID']>;
  created_at: Scalars['DateTimeTz'];
  device_code: Scalars['String'];
  device_type: Scalars['String'];
  echeck_expires_at?: Maybe<Scalars['DateTimeTz']>;
  /** echeck are hardware checks */
  echeck_updated_at?: Maybe<Scalars['DateTimeTz']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  state?: Maybe<DeviceState>;
  state_id?: Maybe<Scalars['ID']>;
  state_updated_at?: Maybe<Scalars['DateTimeTz']>;
  unit?: Maybe<ArticleUnit>;
  unit_id?: Maybe<Scalars['ID']>;
  updated_at: Scalars['DateTimeTz'];
  value?: Maybe<Scalars['Float']>;
};

export type DeviceCategory = {
  __typename?: 'DeviceCategory';
  created_at: Scalars['DateTimeTz'];
  id: Scalars['ID'];
  title: Scalars['String'];
  updated_at: Scalars['DateTimeTz'];
};

export type DevicePaginator = {
  __typename?: 'DevicePaginator';
  data: Array<Device>;
  paginatorInfo: PaginatorInfo;
};

export type DeviceState = {
  __typename?: 'DeviceState';
  color: Scalars['String'];
  created_at: Scalars['DateTimeTz'];
  id: Scalars['ID'];
  title: Scalars['String'];
  updated_at: Scalars['DateTimeTz'];
};

export type DevicesFilter = {
  AND?: InputMaybe<Array<DevicesFilter>>;
  OR?: InputMaybe<Array<DevicesFilter>>;
  column?: InputMaybe<DevicesSortAndFilterColumns>;
  operator?: InputMaybe<SQLOperator>;
  value?: InputMaybe<Scalars['Mixed']>;
};

export type DevicesSort = {
  columns?: InputMaybe<Array<DevicesSortOrder>>;
};

export enum DevicesSortAndFilterColumns {
  ID = 'ID',
  NAME = 'NAME',
}

export type DevicesSortOrder = {
  column: DevicesSortAndFilterColumns;
  order: SortOrder;
};

export type Download = {
  __typename?: 'Download';
  client_id: Scalars['ID'];
  file_ids: Array<Scalars['ID']>;
  filename: Scalars['String'];
  id: Scalars['ID'];
  state_id: DownloadStateId;
  url_download: Scalars['String'];
};

export enum DownloadStateId {
  CREATED = 'CREATED',
  GENERATED = 'GENERATED',
}

export enum DrivingTimeSetting {
  EXTENDED = 'EXTENDED',
  OFF = 'OFF',
  SIMPLE = 'SIMPLE',
}

export type Email = {
  __typename?: 'Email';
  attachments?: Maybe<Array<EmailAttachment>>;
  body: Scalars['String'];
  body_preview: Scalars['String'];
  contacts?: Maybe<Array<EmailContact>>;
  created_from_client?: Maybe<Client>;
  created_from_client_id?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  priority: EmailPriority;
  send_date?: Maybe<Scalars['DateTimeTz']>;
  state_id: EmailSendState;
  subject: Scalars['String'];
  task_id: Scalars['ID'];
};

export type EmailAttachment = {
  __typename?: 'EmailAttachment';
  file: FileItem;
  file_id: Scalars['ID'];
  id: Scalars['ID'];
};

export type EmailAttachmentInput = {
  file_id: Scalars['ID'];
};

export type EmailContact = {
  __typename?: 'EmailContact';
  address: Scalars['String'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  type: EmailContactType;
};

export type EmailContactInput = {
  address: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  type: EmailContactType;
};

export enum EmailContactType {
  BCC = 'BCC',
  CC = 'CC',
  TO = 'TO',
}

export type EmailInput = {
  attachments?: InputMaybe<Array<EmailAttachmentInput>>;
  body: Scalars['String'];
  /** not mandatory, can be emails without any attachment */
  contacts: Array<EmailContactInput>;
  priority: EmailPriority;
  subject: Scalars['String'];
};

export type EmailPaginator = {
  __typename?: 'EmailPaginator';
  data: Array<Email>;
};

export enum EmailPriority {
  HIGH = 'HIGH',
  HIGHEST = 'HIGHEST',
  LOW = 'LOW',
  LOWEST = 'LOWEST',
  NORMAL = 'NORMAL',
}

export enum EmailSendState {
  DELIVERED = 'DELIVERED',
  FAILED = 'FAILED',
  NEW = 'NEW',
  RETRY = 'RETRY',
  SCHEDULED = 'SCHEDULED',
}

export type EmailTemplate = {
  __typename?: 'EmailTemplate';
  assignments: Array<Client>;
  body?: Maybe<Scalars['String']>;
  category?: Maybe<EmailTemplateCategory>;
  category_id?: Maybe<Scalars['ID']>;
  contacts: Array<EmailTemplateContact>;
  created_at: Scalars['DateTimeTz'];
  default?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  file_tag?: Maybe<FileClassificationTag>;
  file_tag_id?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  subject?: Maybe<Scalars['String']>;
  task_tag?: Maybe<ClassificationTag>;
  task_tag_id?: Maybe<Scalars['ID']>;
  title: Scalars['String'];
  updated_at: Scalars['DateTimeTz'];
};

export type EmailTemplateCategory = {
  __typename?: 'EmailTemplateCategory';
  id: Scalars['ID'];
  title: Scalars['String'];
};

export type EmailTemplateContact = {
  __typename?: 'EmailTemplateContact';
  address: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  type: EmailContactType;
};

export type EmailTemplateContactInput = {
  address: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  type: EmailContactType;
};

export type EmailTemplateFilledResponse = {
  __typename?: 'EmailTemplateFilledResponse';
  body?: Maybe<Scalars['String']>;
  contacts: Array<EmailTemplateContact>;
  subject?: Maybe<Scalars['String']>;
};

export type EmailTemplateInput = {
  body?: InputMaybe<Scalars['String']>;
  category_id?: InputMaybe<Scalars['ID']>;
  contacts?: InputMaybe<Array<EmailTemplateContactInput>>;
  default?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  file_tag_id?: InputMaybe<Scalars['ID']>;
  subject?: InputMaybe<Scalars['String']>;
  task_tag_id?: InputMaybe<Scalars['ID']>;
  title: Scalars['String'];
};

export type EmailTemplateKey = {
  __typename?: 'EmailTemplateKey';
  id: Scalars['ID'];
  identifier: Scalars['String'];
  title: Scalars['String'];
};

export type EmailTemplatePaginator = {
  __typename?: 'EmailTemplatePaginator';
  data: Array<EmailTemplate>;
  paginatorInfo: PaginatorInfo;
};

export type EmailTemplatesFilter = {
  AND?: InputMaybe<Array<EmailTemplatesFilter>>;
  OR?: InputMaybe<Array<EmailTemplatesFilter>>;
  column?: InputMaybe<EmailTemplatesFilterAndSortColumns>;
  operator?: InputMaybe<SQLOperator>;
  value?: InputMaybe<Scalars['Mixed']>;
};

export enum EmailTemplatesFilterAndSortColumns {
  CATEGORY_ID = 'CATEGORY_ID',
  DEFAULT = 'DEFAULT',
  DESCRIPTION = 'DESCRIPTION',
  ID = 'ID',
  SUBJECT = 'SUBJECT',
  TITLE = 'TITLE',
}

export type EmailTemplatesSort = {
  columns?: InputMaybe<Array<EmailTemplatesSortOrder>>;
};

export type EmailTemplatesSortOrder = {
  column: EmailTemplatesFilterAndSortColumns;
  order: SortOrder;
};

export type Export = {
  __typename?: 'Export';
  file_name: Scalars['String'];
  url: Scalars['String'];
};

export type ExportConfig = {
  __typename?: 'ExportConfig';
  columns: Array<ExportConfigOption>;
  options: Array<ExportConfigOption>;
  type: ExportType;
};

export type ExportConfigInput = {
  columns?: InputMaybe<Array<ExportConfigOptionInput>>;
  options?: InputMaybe<Array<ExportConfigOptionInput>>;
  timestamp?: InputMaybe<Scalars['String']>;
  type: ExportType;
};

export type ExportConfigList = {
  __typename?: 'ExportConfigList';
  data: Array<ExportConfig>;
};

export type ExportConfigOption = {
  __typename?: 'ExportConfigOption';
  description?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  title: Scalars['String'];
  value?: Maybe<Scalars['Boolean']>;
};

export type ExportConfigOptionInput = {
  key: Scalars['String'];
  value: Scalars['Boolean'];
};

export type ExportResponse = {
  __typename?: 'ExportResponse';
  download?: Maybe<Download>;
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type ExportTaskStatisticCustomFilter = {
  client_ids: Array<Scalars['ID']>;
  created_from: Scalars['Date'];
  created_until: Scalars['Date'];
};

export enum ExportType {
  CSV = 'CSV',
  XLS = 'XLS',
  XLSX = 'XLSX',
}

export type FileClassificationTag = {
  __typename?: 'FileClassificationTag';
  alias: Scalars['String'];
  color: Scalars['String'];
  id: Scalars['ID'];
  title: Scalars['String'];
  type_id: FileClassificationTagTypeId;
};

export type FileClassificationTagInput = {
  alias: Scalars['String'];
  color: Scalars['String'];
  title: Scalars['String'];
  type_id: FileClassificationTagTypeId;
};

export type FileClassificationTagList = {
  __typename?: 'FileClassificationTagList';
  data: Array<FileClassificationTag>;
  id: Scalars['ID'];
};

export enum FileClassificationTagTypeId {
  API = 'API',
  COMMON = 'COMMON',
  SYSTEM = 'SYSTEM',
}

export type FileClassificationTagsFilter = {
  AND?: InputMaybe<Array<FileClassificationTagsFilter>>;
  OR?: InputMaybe<Array<FileClassificationTagsFilter>>;
  column?: InputMaybe<FileClassificationTagsSortAndFilterColumns>;
  operator?: InputMaybe<SQLOperator>;
  value?: InputMaybe<Scalars['Mixed']>;
};

export type FileClassificationTagsPaginator = {
  __typename?: 'FileClassificationTagsPaginator';
  data: Array<FileClassificationTag>;
  paginatorInfo: PaginatorInfo;
};

export type FileClassificationTagsSort = {
  columns?: InputMaybe<Array<FileClassificationTagsSortOrder>>;
};

export enum FileClassificationTagsSortAndFilterColumns {
  ALIAS = 'ALIAS',
  TITLE = 'TITLE',
  TYPE_ID = 'TYPE_ID',
}

export type FileClassificationTagsSortOrder = {
  column: FileClassificationTagsSortAndFilterColumns;
  order: SortOrder;
};

export type FileItem = {
  __typename?: 'FileItem';
  classification_tags: FileClassificationTagList;
  client?: Maybe<Client>;
  client_id?: Maybe<Scalars['ID']>;
  collection_name: Scalars['String'];
  created_at?: Maybe<Scalars['DateTimeTz']>;
  custom_properties: FileItemCustomProperties;
  file_name: Scalars['String'];
  generated_conversions: Scalars['JSON'];
  id: Scalars['ID'];
  is_extracting_pages: Scalars['Boolean'];
  meta_data: Scalars['JSON'];
  mime_type?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  order_column?: Maybe<Scalars['Int']>;
  original_file_name: Scalars['String'];
  permissions: Array<Permission>;
  size: Scalars['Float'];
  updated_at?: Maybe<Scalars['DateTimeTz']>;
  url: Scalars['String'];
  url_download: Scalars['String'];
  url_pages?: Maybe<Array<FileItemPage>>;
  url_thumb?: Maybe<Scalars['String']>;
  url_thumb_report?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
};

export enum FileItemBadgeType {
  LANDSCAPE = 'LANDSCAPE',
  PORTRAIT = 'PORTRAIT',
}

export type FileItemCustomProperties = {
  __typename?: 'FileItemCustomProperties';
  annotations?: Maybe<Scalars['JSON']>;
  badge?: Maybe<FileItemBadgeType>;
  description?: Maybe<Scalars['String']>;
  export_pages?: Maybe<Array<Scalars['Int']>>;
  extracted_pages?: Maybe<Array<Scalars['Int']>>;
  folder_id?: Maybe<Scalars['ID']>;
  invoice?: Maybe<FileItemInvoiceMetaData>;
  is_cover?: Maybe<Scalars['Boolean']>;
  number_of_pages?: Maybe<Scalars['Int']>;
  original_file_id?: Maybe<Scalars['ID']>;
  send_interface?: Maybe<Scalars['Boolean']>;
  visible?: Maybe<Scalars['Boolean']>;
};

export type FileItemCustomPropertiesInput = {
  annotations?: InputMaybe<Scalars['JSON']>;
  badge?: InputMaybe<FileItemBadgeType>;
  description?: InputMaybe<Scalars['String']>;
  export_pages?: InputMaybe<Array<Scalars['Int']>>;
  invoice?: InputMaybe<FileItemInvoiceMetaDataInput>;
  is_cover?: InputMaybe<Scalars['Boolean']>;
  send_interface?: InputMaybe<Scalars['Boolean']>;
  visible?: InputMaybe<Scalars['Boolean']>;
};

export type FileItemInvoiceMetaData = {
  __typename?: 'FileItemInvoiceMetaData';
  identifier: Scalars['String'];
  sum_gross: Scalars['Float'];
  sum_net: Scalars['Float'];
  sum_tax: Scalars['Float'];
};

export type FileItemInvoiceMetaDataInput = {
  identifier: Scalars['String'];
  sum_gross: Scalars['Float'];
  sum_net: Scalars['Float'];
  sum_tax: Scalars['Float'];
};

export type FileItemPage = {
  __typename?: 'FileItemPage';
  page_index: Scalars['Int'];
  url: Scalars['String'];
};

export type ForgotPasswordInput = {
  email: Scalars['String'];
};

export type Geolocate = {
  __typename?: 'Geolocate';
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export enum ImagesLayout1 {
  DEFAULT = 'DEFAULT',
}

export enum ImagesLayout2 {
  DEFAULT = 'DEFAULT',
}

export enum ImagesLayout4 {
  DEFAULT = 'DEFAULT',
}

export type ImportAreaAssignmentsInput = {
  file: Scalars['Upload'];
};

export type ImportAreaAssignmentsResponse = {
  __typename?: 'ImportAreaAssignmentsResponse';
  data: Array<AreaAssignment>;
};

export type InitialLoginInput = {
  password: Scalars['String'];
  password_confirmation: Scalars['String'];
  token: Scalars['String'];
};

export type InitialLoginResponse = {
  __typename?: 'InitialLoginResponse';
  authState: AuthState;
  message: Scalars['String'];
};

export type InsuranceList = {
  __typename?: 'InsuranceList';
  data: Array<Client>;
};

export type InterfaceError = {
  __typename?: 'InterfaceError';
  date: Scalars['DateTimeTz'];
  file_name: Scalars['String'];
  task_id: Scalars['ID'];
};

export type InterfaceMessageTemplate = {
  __typename?: 'InterfaceMessageTemplate';
  assignments: Array<Client>;
  body?: Maybe<Scalars['String']>;
  category?: Maybe<InterfaceMessageTemplateCategory>;
  category_id?: Maybe<Scalars['ID']>;
  created_at: Scalars['DateTimeTz'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  message_type?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updated_at: Scalars['DateTimeTz'];
};

export type InterfaceMessageTemplateCategory = {
  __typename?: 'InterfaceMessageTemplateCategory';
  id: Scalars['ID'];
  title: Scalars['String'];
};

export type InterfaceMessageTemplateFilledResponse = {
  __typename?: 'InterfaceMessageTemplateFilledResponse';
  body?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
};

export type InterfaceMessageTemplateInput = {
  body?: InputMaybe<Scalars['String']>;
  category_id?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  message_type?: InputMaybe<Scalars['String']>;
  subject?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type InterfaceMessageTemplatePaginator = {
  __typename?: 'InterfaceMessageTemplatePaginator';
  data: Array<InterfaceMessageTemplate>;
  paginatorInfo: PaginatorInfo;
};

export type InterfaceMessageTemplatesFilter = {
  AND?: InputMaybe<Array<InterfaceMessageTemplatesFilter>>;
  OR?: InputMaybe<Array<InterfaceMessageTemplatesFilter>>;
  column?: InputMaybe<InterfaceMessageTemplatesFilterAndSortColumns>;
  operator?: InputMaybe<SQLOperator>;
  value?: InputMaybe<Scalars['Mixed']>;
};

export enum InterfaceMessageTemplatesFilterAndSortColumns {
  CATEGORY_ID = 'CATEGORY_ID',
  DESCRIPTION = 'DESCRIPTION',
  ID = 'ID',
  SUBJECT = 'SUBJECT',
  TITLE = 'TITLE',
}

export type InterfaceMessageTemplatesSort = {
  columns?: InputMaybe<Array<InterfaceMessageTemplatesSortOrder>>;
};

export type InterfaceMessageTemplatesSortOrder = {
  column: InterfaceMessageTemplatesFilterAndSortColumns;
  order: SortOrder;
};

export type InvoiceFilter = {
  AND?: InputMaybe<Array<InvoiceFilter>>;
  OR?: InputMaybe<Array<InvoiceFilter>>;
  column?: InputMaybe<InvoiceSortAndFilterColumns>;
  operator?: InputMaybe<SQLOperator>;
  value?: InputMaybe<Scalars['Mixed']>;
};

export type InvoicePaginator = {
  __typename?: 'InvoicePaginator';
  data: Array<TaskInvoice>;
  paginatorInfo: PaginatorInfo;
  sum_gross?: Maybe<Scalars['Float']>;
  sum_net?: Maybe<Scalars['Float']>;
};

export type InvoicePayoutsFilter = {
  AND?: InputMaybe<Array<InvoicePayoutsFilter>>;
  OR?: InputMaybe<Array<InvoicePayoutsFilter>>;
  column?: InputMaybe<InvoicePayoutsSortAndFilterColumns>;
  operator?: InputMaybe<SQLOperator>;
  value?: InputMaybe<Scalars['Mixed']>;
};

export type InvoicePayoutsPaginator = {
  __typename?: 'InvoicePayoutsPaginator';
  data: Array<TaskInvoicePayout>;
  paginatorInfo: PaginatorInfo;
  sum_net?: Maybe<Scalars['Float']>;
};

export type InvoicePayoutsSort = {
  columns?: InputMaybe<Array<InvoicePayoutsSortOrder>>;
};

export enum InvoicePayoutsSortAndFilterColumns {
  CLIENT_ID = 'CLIENT_ID',
  ID = 'ID',
  INVOICE_NUMBER = 'INVOICE_NUMBER',
  PAYOUT_ID = 'PAYOUT_ID',
  STATE_ID = 'STATE_ID',
}

export type InvoicePayoutsSortOrder = {
  column: InvoicePayoutsSortAndFilterColumns;
  order: SortOrder;
};

export type InvoiceSettingsInput = {
  invoice_from_bank_iban?: InputMaybe<Scalars['String']>;
  invoice_from_bank_swift?: InputMaybe<Scalars['String']>;
  invoice_from_bank_title?: InputMaybe<Scalars['String']>;
  invoice_from_ceo?: InputMaybe<Scalars['String']>;
  invoice_from_city?: InputMaybe<Scalars['String']>;
  invoice_from_commercial_register?: InputMaybe<Scalars['String']>;
  invoice_from_country_id?: InputMaybe<Scalars['ID']>;
  invoice_from_email?: InputMaybe<Scalars['String']>;
  invoice_from_invoice_number_prefix?: InputMaybe<Scalars['String']>;
  invoice_from_name?: InputMaybe<Scalars['String']>;
  invoice_from_phone?: InputMaybe<Scalars['String']>;
  invoice_from_postcode?: InputMaybe<Scalars['String']>;
  invoice_from_street?: InputMaybe<Scalars['String']>;
  invoice_from_vat_id?: InputMaybe<Scalars['String']>;
  invoice_from_vat_title?: InputMaybe<Scalars['String']>;
  invoice_from_web?: InputMaybe<Scalars['String']>;
};

export type InvoiceSort = {
  columns?: InputMaybe<Array<InvoiceSortOrder>>;
};

export enum InvoiceSortAndFilterColumns {
  CLIENT_ID = 'CLIENT_ID',
  EXTERNAL_ID = 'EXTERNAL_ID',
  ID = 'ID',
  IDENTIFIER = 'IDENTIFIER',
  INVOICE_DATE = 'INVOICE_DATE',
  STATE_ID = 'STATE_ID',
  TASK_ID = 'TASK_ID',
}

export type InvoiceSortOrder = {
  column: InvoiceSortAndFilterColumns;
  order: SortOrder;
};

export type LeaderList = {
  __typename?: 'LeaderList';
  data: Array<Client>;
};

export type LoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  authState?: Maybe<AuthState>;
  message: Scalars['String'];
  requiresVerification: Scalars['Boolean'];
};

export enum MailEncryptionType {
  SSL = 'SSL',
  TLS = 'TLS',
}

export type MapGeoJSON = {
  __typename?: 'MapGeoJSON';
  data: Scalars['JSON'];
};

export enum MapboxLayerType {
  circle = 'circle',
}

export enum MapboxStyle {
  SATELLITE = 'SATELLITE',
  STREET = 'STREET',
}

export type MessageTemplate = EmailTemplate | InterfaceMessageTemplate;

export type Misspelling = {
  __typename?: 'Misspelling';
  lineNumber?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  suggestions: Array<Scalars['String']>;
  word: Scalars['String'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type Mutation = {
  __typename?: 'Mutation';
  acceptCustomerPortalEventDocument: ResponseMessage;
  acceptCustomerPortalEventFile: ResponseMessage;
  acceptTask?: Maybe<ResponseMessage>;
  activateAppEmailTransport: AppEmailTransport;
  activateAppMessage: AppMessage;
  /** Task States mutations */
  activateTask?: Maybe<ResponseMessage>;
  activateTaskCalculationTemplate: ResponseMessage;
  activateTaskFileForInterface: ResponseMessage;
  addAddressBookEntry?: Maybe<AddressBookEntry>;
  addCustomerPortalEventDocuments?: Maybe<CustomerPortalEvent>;
  addDefaultClassificationTags: ResponseMessage;
  addPayout: TaskInvoicePayout;
  addPdfTemplateToTaskDocuments?: Maybe<Scalars['Boolean']>;
  addRoomItemsToCalculation: ResponseMessage;
  /** Task ClassificationTag mutations */
  addTaskClassificationTag: TaskClassificationUpdateMessage;
  /** Client email templates */
  assignEmailTemplateToClients: ResponseMessage;
  assignEmailTemplatesToClientDirectMessages: ResponseMessage;
  assignInterfaceMessageTemplateToClients: ResponseMessage;
  assignInterfaceMessageTemplatesToClientDirectMessages: ResponseMessage;
  assignTaskLeader?: Maybe<Task>;
  assignTaskMembers?: Maybe<TasksBatchResponseMessage>;
  assignTaskOwner?: Maybe<Task>;
  assignTaskSubOwners?: Maybe<Task>;
  billomatSyncArticles: ResponseMessage;
  billomatSyncInvoice: TaskInvoice;
  billomatSyncInvoices: ResponseMessage;
  cancelPayout: ResponseMessage;
  cancelTask?: Maybe<ResponseMessage>;
  cancelTaskInvoice: ResponseMessage;
  changeTaskFormTemplate: TaskForm;
  changeTaskTemplate?: Maybe<Task>;
  /** CONFIRMATION MAIL */
  clientResendSignupLink: Client;
  completeTask?: Maybe<ResponseMessage>;
  completeTaskInvoice: TaskInvoice;
  completeTaskSubTask: TaskSubTask;
  copyTask: ResponseMessage;
  copyTaskFormQuestions: ResponseMessage;
  createApiToken: CreateApiTokenResponse;
  createAppAllowlistIpAddress: AppAllowlistIpAddress;
  createAppEmailTransport: AppEmailTransport;
  createAppMessage: AppMessage;
  createAppStamp: AppStamp;
  createCalendarEvent: CalendarEvent;
  createClassificationTag?: Maybe<ClassificationTag>;
  /** Client */
  createClient?: Maybe<Client>;
  /** ClientAbsence */
  createClientAbsence?: Maybe<ClientAbsence>;
  /** ClientLocation */
  createClientLocation?: Maybe<ClientLocation>;
  /** Contact */
  createContact?: Maybe<Contact>;
  createCustomerPortalEvent?: Maybe<CustomerPortalEvent>;
  createCustomerPortalEventDocument: CustomerPortalEventDocument;
  createCustomerPortalEventFile: CustomerPortalEventFile;
  createDevice: Device;
  createDeviceCategory: DeviceCategory;
  createDummy?: Maybe<Client>;
  createEmail: ResponseMessage;
  createEmailTemplate?: Maybe<EmailTemplate>;
  createFileClassificationTag?: Maybe<FileClassificationTag>;
  createInterfaceMessageTemplate?: Maybe<InterfaceMessageTemplate>;
  createPolicyHolder?: Maybe<Client>;
  createPolicyHolderLocation?: Maybe<ClientLocation>;
  createPrivateChannel: ChatChannel;
  createPublicChannel: ChatChannel;
  createQualification: Qualification;
  createRandomClients?: Maybe<Array<Maybe<Client>>>;
  createRandomTasks: Array<Task>;
  createRandomTasksSeeder?: Maybe<Array<Maybe<Task>>>;
  createReportLayout?: Maybe<ReportLayout>;
  createStaticMap: FileItem;
  createTag: Tag;
  createTask: Task;
  createTaskCalculationCategories: Array<TaskCalculationCategory>;
  createTaskCalculationCategory: TaskCalculationCategory;
  createTaskCalculationItem: TaskCalculationItem;
  createTaskCalculationItems: Array<TaskCalculationItem>;
  createTaskCalculationItemsFromCatalog: ResponseMessage;
  createTaskCalculationTemplate: TaskCalculationTemplate;
  /** Task Contacts */
  createTaskContact: Contact;
  createTaskFilesDownload: Download;
  createTaskFolder?: Maybe<TaskFolder>;
  createTaskFolderTemplate?: Maybe<TaskFolderTemplate>;
  /** Task Forms */
  createTaskForm: TaskForm;
  createTaskInvoice: TaskInvoice;
  /** Task locations */
  createTaskLocation: TaskLocation;
  createTaskLog: TaskLog;
  createTaskReport: TaskReport;
  /** task room categories */
  createTaskRoomCategory: TaskRoomCategory;
  /** task room items */
  createTaskRoomItem?: Maybe<TaskRoomItem>;
  createTaskSubTask: TaskSubTask;
  createTaskTemplate: TaskTemplate;
  declineTask?: Maybe<ResponseMessage>;
  deleteAddressBookEntry?: Maybe<AddressBookEntry>;
  deleteApiToken: ResponseMessage;
  deleteAppAllowlistIpAddress: AppAllowlistIpAddress;
  deleteAppEmailTransport: AppEmailTransport;
  deleteAppMessage: AppMessage;
  deleteAppStamp: AppStamp;
  deleteCalendarEvent: CalendarEvent;
  deleteChatMessage: ChatChannel;
  deleteClassificationTag?: Maybe<ClassificationTag>;
  deleteClient?: Maybe<Client>;
  deleteClientAbsence?: Maybe<ResponseMessage>;
  deleteClientLocation?: Maybe<ClientLocation>;
  deleteContact?: Maybe<Contact>;
  deleteCustomerPortalEventDocument: CustomerPortalEventDocument;
  deleteCustomerPortalEventFile: CustomerPortalEventFile;
  deleteEmailTemplate?: Maybe<EmailTemplate>;
  deleteFileClassificationTag?: Maybe<FileClassificationTag>;
  deleteInterfaceMessageTemplate?: Maybe<InterfaceMessageTemplate>;
  deletePdfTemplate: PdfTemplate;
  deletePolicyHolder?: Maybe<Client>;
  deletePolicyHolderLocation?: Maybe<ClientLocation>;
  deleteQualification: Qualification;
  deleteQuestionTableRow?: Maybe<TaskQuestionTableRow>;
  deleteReportLayout?: Maybe<DeleteResponse>;
  deleteServiceCatalog: ServiceCatalog;
  deleteTag: Tag;
  deleteTaskCalculationCategories: Array<TaskCalculationCategory>;
  deleteTaskCalculationCategory: TaskCalculationCategory;
  deleteTaskCalculationItem: TaskCalculationItem;
  deleteTaskCalculationItemState?: Maybe<ResponseMessage>;
  deleteTaskCalculationItems: Array<TaskCalculationItem>;
  deleteTaskCalculationTemplate: ResponseMessage;
  deleteTaskClassificationTags: TasksBatchResponseMessage;
  deleteTaskContact: Contact;
  deleteTaskFiles: Array<FileItem>;
  deleteTaskFolder?: Maybe<TaskFolder>;
  deleteTaskFolderTemplate?: Maybe<TaskFolderTemplate>;
  deleteTaskForm: TaskForm;
  deleteTaskFormQuestionComment: TaskQuestion;
  deleteTaskFormQuestionTableRow: TaskQuestionTableRow;
  deleteTaskInvoice: ResponseMessage;
  deleteTaskReport: TaskReport;
  deleteTaskRoomCategory: TaskRoomCategory;
  deleteTaskRoomItem: TaskRoomItem;
  deleteTaskSubTask: ResponseMessage;
  deleteTaskSubTaskTemplate: TaskSubTaskTemplate;
  deleteTaskTemplate: ResponseMessage;
  duplicateTask: Task;
  duplicateTaskForm: TaskForm;
  duplicateTaskTemplate: TaskTemplate;
  editChatMessage: ChatChannel;
  editDuplicateTaskFile: FileItem;
  /** EXPORT METHODS */
  exportArticles: ExportResponse;
  /** EXPORT METHODS */
  exportClassificationTags: ExportResponse;
  /** EXPORT METHODS */
  exportClients: ExportResponse;
  /** EXPORT METHODS */
  exportFileClassificationTags: ExportResponse;
  /** EXPORT METHODS */
  exportInvoicePayouts: Export;
  /** EXPORT METHODS */
  exportInvoices: Export;
  exportInvoicesDatev: Export;
  exportMagicPlan: TaskRoomMagicPlanJSON;
  /** EXPORT METHODS */
  exportServiceCatalogItems: ExportResponse;
  exportServiceCatalogPostcodeFactor: ExportResponse;
  exportTaskCalculations: Export;
  exportTaskFolderTemplates: ExportResponse;
  exportTaskSubTasks: ExportResponse;
  exportTaskTemplates: ExportResponse;
  /** EXPORT METHODS */
  exportTasks: ExportResponse;
  exportTasksFormData: ExportResponse;
  exportTasksStatisticCustom: ExportResponse;
  fillEmailTemplate?: Maybe<EmailTemplateFilledResponse>;
  fillInterfaceMessageTemplate?: Maybe<InterfaceMessageTemplateFilledResponse>;
  forgotPassword?: Maybe<AuthResponse>;
  generateTranscribeCredentials: TranscribeCredentials;
  importAllLegacy: ResponseMessage;
  importAreaAssignments?: Maybe<ImportAreaAssignmentsResponse>;
  importLegacy: TaskTemplate;
  importPolicyHolders: ResponseMessage;
  importTaskTemplates: ResponseMessage;
  initialLogin: InitialLoginResponse;
  inviteIntoChatChannel: ChatChannel;
  joinChatChannel: ChatChannel;
  kickFromChatChannel: ChatChannel;
  leaveChatChannel: ChatChannel;
  /** Task Lock States mutations */
  lockTask: ResponseMessage;
  login: LoginResponse;
  loginAsCustomer: LoginResponse;
  loginAsOtherUser: LoginResponse;
  logout?: Maybe<AuthResponse>;
  logoutOtherUser: AuthResponse;
  makePayout: ResponseMessage;
  markAllNotificationAsRead?: Maybe<Scalars['Boolean']>;
  markChatMessageRead: ChatChannel;
  markNotificationAsRead?: Maybe<Scalars['Boolean']>;
  moveTaskFilesToFolder: TaskFilesPaginator;
  previewTaskReport: TaskReportGeneration;
  refreshToken?: Maybe<RefreshTokenResponse>;
  removeTaskClassificationTag: TaskClassificationUpdateMessage;
  renameTaskFile: FileItem;
  renameTaskFolder?: Maybe<TaskFolder>;
  renameTaskFolderTemplate?: Maybe<TaskFolderTemplate>;
  reopenTaskSubTask: TaskSubTask;
  reorderTaskCalculationItems: Array<TaskCalculationItem>;
  reorderTaskDocuments: TaskDocumentsPaginator;
  reorderTaskFiles: TaskFilesPaginator;
  reorderTaskForms: ResponseMessage;
  reorderTaskImages: TaskImagesPaginator;
  resetClientNotificationSettings?: Maybe<Client>;
  resetPassword?: Maybe<AuthResponse>;
  resetTaskAppointment?: Maybe<ResponseMessage>;
  /** Task Template and assignment mutations */
  resetTaskAssignment?: Maybe<Task>;
  resetTaskFormQuestions: Array<Maybe<TaskQuestion>>;
  restoreFromOtherUser: LoginResponse;
  revertCancelTask?: Maybe<ResponseMessage>;
  revertTaskFromControlling?: Maybe<ResponseMessage>;
  reviewTaskSubTask: TaskSubTask;
  reworkTask?: Maybe<ResponseMessage>;
  reworkTaskDone?: Maybe<ResponseMessage>;
  sendChatMessage: ChatChannel;
  sendCustomerInvitationLink: ResponseMessage;
  sendDirectMessageFromEmailTemplate: ResponseMessage;
  sendDirectMessageFromInterfaceMessageTemplate: ResponseMessage;
  sendEmailVerificationLink?: Maybe<User>;
  sendQRCode: AuthResponse;
  sendTaskInvoiceReminderEmails: ResponseMessage;
  sendTaskRouteLink: ResponseMessage;
  setPayoutsPaid: ResponseMessage;
  setTaskInitialContact?: Maybe<ResponseMessage>;
  setTaskReportSent?: Maybe<ResponseMessage>;
  setTaskToControlling?: Maybe<ResponseMessage>;
  suggestTaskInvoiceItems: Array<TaskInvoiceItemTemplate>;
  syncMagicPlan: ResponseMessage;
  syncTaskCalculation: ResponseMessage;
  /** Task Forms batch mutations */
  syncTaskForms?: Maybe<ResponseMessage>;
  /** batch */
  syncTaskRoom: ResponseMessage;
  syncTaskRoomCategories: Array<TaskRoomCategory>;
  syncTaskRoomItems: Array<TaskRoomItem>;
  taskInvoiceRevertPaid: ResponseMessage;
  taskInvoiceSetPaid: ResponseMessage;
  testAppMailTransport: ResponseMessage;
  toggleAppAllowlist: ResponseMessage;
  toggleDocumentVisibility: ResponseMessage;
  toggleImageVisibility: ResponseMessage;
  unlockTask: ResponseMessage;
  updateAddressBookEntry?: Maybe<AddressBookEntry>;
  updateAllTaskTemplateAssignment: ResponseMessage;
  updateAppEmailTransport: AppEmailTransport;
  updateAppMessage: AppMessage;
  updateAppModule: AppModule;
  updateAppStamp: AppStamp;
  updateAreaAssignment?: Maybe<AreaAssignment>;
  updateAssignmentSettings: AppSettings;
  updateCalendarEvent: CalendarEvent;
  updateClassificationTag?: Maybe<ClassificationTag>;
  updateClient?: Maybe<Client>;
  /** CLIENT INTROS */
  updateClientIntros: Client;
  updateClientLocation?: Maybe<ClientLocation>;
  updateClientNotificationEmailSettings?: Maybe<Client>;
  /** ClientNotificationSetting */
  updateClientNotificationSettings?: Maybe<Client>;
  updateClientQualificationDisabledForms?: Maybe<Client>;
  updateClientQualificationHideInsurance?: Maybe<Client>;
  /** Client qualifications */
  updateClientQualifications: Client;
  updateColorSettings: AppSettings;
  updateContact?: Maybe<Contact>;
  updateCustomerLocation?: Maybe<CustomerPortalEvent>;
  updateCustomerPortalEvent?: Maybe<CustomerPortalEvent>;
  updateCustomerPortalEventDocument: CustomerPortalEventDocument;
  updateDevice: Device;
  updateDeviceCategory: DeviceCategory;
  updateEmailTemplate?: Maybe<EmailTemplate>;
  updateFileClassificationTag?: Maybe<FileClassificationTag>;
  updateGlobalSettings: AppSettings;
  updateInterfaceMessageTemplate?: Maybe<InterfaceMessageTemplate>;
  updateInvoiceSettings: AppSettings;
  updateLoginPage: AppSettings;
  updateMailSettings: AppSettings;
  updateMapBoundsSettings: AppSettings;
  updateModuleSettings: AppSettings;
  updateNotificationSettings: AppSettings;
  updateOrCreateOnSiteAppointment?: Maybe<CalendarEvent>;
  updatePayout: ResponseMessage;
  updatePdfTemplate: PdfTemplate;
  updatePolicyHolder?: Maybe<Client>;
  updatePolicyHolderLocation?: Maybe<ClientLocation>;
  updatePushNotificationSettings: AppSettings;
  updateQualification: Qualification;
  updateQuestionTableRow?: Maybe<TaskQuestionTableRow>;
  updateReportLayout?: Maybe<ReportLayout>;
  updateServiceCatalogItem?: Maybe<ServiceCatalogItem>;
  updateServiceCatalogPostcodeFactorItem: ServiceCatalogPostcodeFactor;
  updateTag: Tag;
  updateTask: Task;
  updateTaskCalculation: TaskCalculation;
  updateTaskCalculationCategories: Array<TaskCalculationCategory>;
  updateTaskCalculationCategory: TaskCalculationCategory;
  updateTaskCalculationItem: TaskCalculationItem;
  updateTaskCalculationItems: Array<TaskCalculationItem>;
  /** Allows now multiple */
  updateTaskClassificationTags: TasksBatchResponseMessage;
  updateTaskContact: Contact;
  updateTaskCustomReportSetting: TaskCustomReportSetting;
  updateTaskDocumentFiles: TaskDocumentsPaginator;
  updateTaskDriveLog: Task;
  updateTaskFile: FileItem;
  updateTaskFileProperties: Array<FileItem>;
  updateTaskFileTags: FileClassificationTagList;
  updateTaskFolderPermissions?: Maybe<TaskFolder>;
  updateTaskFollowUpDate: Task;
  updateTaskForm: TaskForm;
  updateTaskFormForcePageBreak: TaskQuestion;
  updateTaskFormQuestionComment: TaskQuestion;
  updateTaskFormQuestionTableRow: TaskQuestionTableRow;
  /** Task Questions */
  updateTaskFormQuestions: Array<Maybe<TaskQuestion>>;
  updateTaskImageFiles: TaskImagesPaginator;
  updateTaskInvoice: TaskInvoice;
  updateTaskInvoiceItems: TaskInvoice;
  updateTaskLocation: TaskLocation;
  /** Task qualifications */
  updateTaskQualifications: Task;
  updateTaskQuestion?: Maybe<TaskQuestion>;
  updateTaskQuestions?: Maybe<Array<Maybe<TaskQuestion>>>;
  updateTaskQuestionsForCustomer: ResponseMessage;
  updateTaskReminderDate: Task;
  updateTaskRoomCategory: TaskRoomCategory;
  updateTaskRoomItem?: Maybe<TaskRoomItem>;
  updateTaskSubTask: TaskSubTask;
  updateTaskTemplate: TaskTemplate;
  updateTaskTemplateAssignment: TaskTemplate;
  updateTaskTemplateForm: TaskTemplate;
  updateTasksSettings: AppSettings;
  updateUserPassword?: Maybe<User>;
  updateUserPasswordSelf?: Maybe<User>;
  uploadAlternateReportLogo: AppSettings;
  uploadClientData: ResponseMessage;
  uploadInvoiceLogo: AppSettings;
  uploadLegacyClientData: ResponseMessage;
  uploadLegacyTemplates: ResponseMessage;
  uploadLoginBackground: AppSettings;
  uploadLoginLogo: AppSettings;
  uploadMenuIcon: AppSettings;
  uploadPdfTemplate: PdfTemplate;
  uploadProfileImage: User;
  uploadReportCoverDefaultBackground: AppSettings;
  uploadReportLogo: AppSettings;
  uploadServiceCatalog: ResponseMessage;
  uploadTaskDocuments: TaskDocumentsPaginator;
  uploadTaskFiles: TaskFilesPaginator;
  uploadTaskImages: TaskImagesPaginator;
  upsertArticle?: Maybe<Article>;
  upsertClientAdditionalInsurance: Client;
  /** ClientAdditional */
  upsertClientAdditionalLossAdjuster: Client;
  upsertClientBanking?: Maybe<Client>;
  upsertClientQualification?: Maybe<Client>;
  upsertClientServiceLevel?: Maybe<Client>;
  upsertClientTax?: Maybe<Client>;
  upsertServiceCatalog: ServiceCatalog;
  upsertTaskCalculationItemState?: Maybe<TaskCalculationItemState>;
  upsertTaskFormQuestionTableRows: Array<TaskQuestionTableRow>;
  upsertTaskSubTaskTemplate: TaskSubTaskTemplate;
  verifyEmail?: Maybe<VerifyEmailResponse>;
  verifyLogin: LoginResponse;
  videoCreateAccessToken?: Maybe<Token>;
  videoCreateCustomerAccessToken?: Maybe<Token>;
  videoSendMessage?: Maybe<Scalars['Boolean']>;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationacceptCustomerPortalEventDocumentArgs = {
  id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationacceptCustomerPortalEventFileArgs = {
  id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationacceptTaskArgs = {
  id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationactivateAppEmailTransportArgs = {
  active: Scalars['Boolean'];
  id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationactivateAppMessageArgs = {
  active: Scalars['Boolean'];
  app_message_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationactivateTaskArgs = {
  id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationactivateTaskCalculationTemplateArgs = {
  task_calculation_template_id: Scalars['ID'];
  task_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationactivateTaskFileForInterfaceArgs = {
  file_ids: Array<Scalars['ID']>;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationaddAddressBookEntryArgs = {
  entry: CreateAddressBookEntryInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationaddCustomerPortalEventDocumentsArgs = {
  customer_portal_event_id: Scalars['ID'];
  input: CustomerPortalEventDocumentInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationaddDefaultClassificationTagsArgs = {
  classification_tag_ids: Array<Scalars['ID']>;
  client_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationaddPayoutArgs = {
  input: TaskInvoicePayoutInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationaddPdfTemplateToTaskDocumentsArgs = {
  pdf_template_id: Scalars['ID'];
  task_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationaddRoomItemsToCalculationArgs = {
  task_id: Scalars['ID'];
  use_service_catalog_categories: Scalars['Boolean'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationaddTaskClassificationTagArgs = {
  classification_tag_id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<ClassificationTagInput>;
  task_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationassignEmailTemplateToClientsArgs = {
  client_ids: Array<Scalars['ID']>;
  email_template_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationassignEmailTemplatesToClientDirectMessagesArgs = {
  client_id: Scalars['ID'];
  email_template_ids: Array<Scalars['ID']>;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationassignInterfaceMessageTemplateToClientsArgs = {
  client_ids: Array<Scalars['ID']>;
  interface_message_template_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationassignInterfaceMessageTemplatesToClientDirectMessagesArgs = {
  client_id: Scalars['ID'];
  interface_message_template_ids: Array<Scalars['ID']>;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationassignTaskLeaderArgs = {
  leader_client_id?: InputMaybe<Scalars['ID']>;
  task_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationassignTaskMembersArgs = {
  members: Array<TaskMemberInput>;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationassignTaskOwnerArgs = {
  owner_client_id?: InputMaybe<Scalars['ID']>;
  task_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationassignTaskSubOwnersArgs = {
  sub_owner_client_ids: Array<Scalars['ID']>;
  task_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationbillomatSyncInvoiceArgs = {
  task_invoice_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationbillomatSyncInvoicesArgs = {
  task_invoice_ids: Array<Scalars['ID']>;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationcancelPayoutArgs = {
  task_invoice_payout_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationcancelTaskArgs = {
  id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationcancelTaskInvoiceArgs = {
  id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationchangeTaskFormTemplateArgs = {
  task_form_id: Scalars['ID'];
  template_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationchangeTaskTemplateArgs = {
  task_id: Scalars['ID'];
  template_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationclientResendSignupLinkArgs = {
  client_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationcompleteTaskArgs = {
  id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationcompleteTaskInvoiceArgs = {
  id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationcompleteTaskSubTaskArgs = {
  id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationcopyTaskArgs = {
  from: Scalars['ID'];
  to: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationcopyTaskFormQuestionsArgs = {
  task_form_id_source: Scalars['ID'];
  task_form_id_target: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationcreateAppAllowlistIpAddressArgs = {
  input: AppAllowlistIpAddressInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationcreateAppEmailTransportArgs = {
  input: CreateAppEmailTransportInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationcreateAppMessageArgs = {
  input?: InputMaybe<CreateAppMessageInput>;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationcreateAppStampArgs = {
  input: CreateAppStampInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationcreateCalendarEventArgs = {
  input: CreateCalendarEventInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationcreateClassificationTagArgs = {
  input: ClassificationTagInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationcreateClientArgs = {
  input: CreateClientInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationcreateClientAbsenceArgs = {
  client_id: Scalars['ID'];
  input: CreateClientAbsenceInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationcreateClientLocationArgs = {
  client_id: Scalars['ID'];
  input: ClientLocationInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationcreateContactArgs = {
  input: CreateContactInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationcreateCustomerPortalEventArgs = {
  input: CreateCustomerPortalEventInput;
  task_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationcreateCustomerPortalEventDocumentArgs = {
  customer_portal_event_id: Scalars['ID'];
  file: Scalars['Upload'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationcreateCustomerPortalEventFileArgs = {
  customer_portal_event_id: Scalars['ID'];
  file: Scalars['Upload'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationcreateDeviceArgs = {
  input: CreateDeviceInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationcreateDeviceCategoryArgs = {
  input: CreateDeviceCategoryInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationcreateDummyArgs = {
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  salutation_id?: InputMaybe<Scalars['Int']>;
  state_id?: InputMaybe<Scalars['Int']>;
  type_id?: InputMaybe<Scalars['Int']>;
  web?: InputMaybe<Scalars['String']>;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationcreateEmailArgs = {
  input: EmailInput;
  task_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationcreateEmailTemplateArgs = {
  input: EmailTemplateInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationcreateFileClassificationTagArgs = {
  input: FileClassificationTagInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationcreateInterfaceMessageTemplateArgs = {
  input: InterfaceMessageTemplateInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationcreatePolicyHolderArgs = {
  input: CreatePolicyHolderInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationcreatePolicyHolderLocationArgs = {
  client_id: Scalars['ID'];
  input: ClientLocationInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationcreatePrivateChannelArgs = {
  receiver_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationcreatePublicChannelArgs = {
  input: CreateChatChannelInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationcreateQualificationArgs = {
  input: QualificationInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationcreateRandomClientsArgs = {
  client_type: ClientTypeId;
  domain?: InputMaybe<Scalars['String']>;
  numUsers: Scalars['Int'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationcreateRandomTasksArgs = {
  input: CreateRandomTasksInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationcreateRandomTasksSeederArgs = {
  input: CreateRandomTasksSeederInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationcreateReportLayoutArgs = {
  input: CreateReportLayoutInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationcreateStaticMapArgs = {
  input: StaticMapData;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationcreateTagArgs = {
  input: TagInput;
  type_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationcreateTaskArgs = {
  input: CreateTaskInput;
  options?: InputMaybe<CreateTaskOptions>;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationcreateTaskCalculationCategoriesArgs = {
  categories: Array<TaskCalculationCategoryInput>;
  task_calculation_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationcreateTaskCalculationCategoryArgs = {
  input: TaskCalculationCategoryInput;
  task_calculation_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationcreateTaskCalculationItemArgs = {
  input: TaskCalculationItemInput;
  task_calculation_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationcreateTaskCalculationItemsArgs = {
  items: Array<TaskCalculationItemInput>;
  task_calculation_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationcreateTaskCalculationItemsFromCatalogArgs = {
  service_catalog_item_ids: Array<Scalars['ID']>;
  task_calculation_category_id: Scalars['ID'];
  task_calculation_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationcreateTaskCalculationTemplateArgs = {
  task_id: Scalars['ID'];
  title: Scalars['String'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationcreateTaskContactArgs = {
  input: TaskContactCreateInput;
  task_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationcreateTaskFilesDownloadArgs = {
  input: CreateDownloadInput;
  task_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationcreateTaskFolderArgs = {
  input: TaskFolderInput;
  task_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationcreateTaskFolderTemplateArgs = {
  input: TaskFolderTemplateInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationcreateTaskFormArgs = {
  input: CreateTaskFormInput;
  task_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationcreateTaskInvoiceArgs = {
  input: CreateTaskInvoiceInput;
  task_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationcreateTaskLocationArgs = {
  input: TaskLocationInput;
  task_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationcreateTaskLogArgs = {
  input: CreateTaskLogInput;
  task_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationcreateTaskReportArgs = {
  input: CreateTaskReportInput;
  options: TaskReportOptionsInput;
  task_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationcreateTaskRoomCategoryArgs = {
  input: TaskRoomCategoryInput;
  task_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationcreateTaskRoomItemArgs = {
  input: TaskRoomItemInput;
  task_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationcreateTaskSubTaskArgs = {
  input: CreateTaskSubTaskInput;
  task_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationcreateTaskTemplateArgs = {
  input: CreateTaskTemplateInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationdeclineTaskArgs = {
  input: TaskDeclineReasonInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationdeleteAddressBookEntryArgs = {
  id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationdeleteApiTokenArgs = {
  uuid: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationdeleteAppAllowlistIpAddressArgs = {
  ip: Scalars['IPv6'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationdeleteAppEmailTransportArgs = {
  id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationdeleteAppMessageArgs = {
  app_message_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationdeleteAppStampArgs = {
  id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationdeleteCalendarEventArgs = {
  id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationdeleteChatMessageArgs = {
  channel_id: Scalars['ID'];
  message_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationdeleteClassificationTagArgs = {
  id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationdeleteClientArgs = {
  id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationdeleteClientAbsenceArgs = {
  id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationdeleteClientLocationArgs = {
  id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationdeleteContactArgs = {
  id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationdeleteCustomerPortalEventDocumentArgs = {
  id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationdeleteCustomerPortalEventFileArgs = {
  id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationdeleteEmailTemplateArgs = {
  id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationdeleteFileClassificationTagArgs = {
  id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationdeleteInterfaceMessageTemplateArgs = {
  id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationdeletePdfTemplateArgs = {
  id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationdeletePolicyHolderArgs = {
  id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationdeletePolicyHolderLocationArgs = {
  id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationdeleteQualificationArgs = {
  id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationdeleteQuestionTableRowArgs = {
  row_id: Scalars['String'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationdeleteReportLayoutArgs = {
  id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationdeleteServiceCatalogArgs = {
  catalog_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationdeleteTagArgs = {
  id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationdeleteTaskCalculationCategoriesArgs = {
  task_calculation_category_ids: Array<Scalars['ID']>;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationdeleteTaskCalculationCategoryArgs = {
  id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationdeleteTaskCalculationItemArgs = {
  id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationdeleteTaskCalculationItemStateArgs = {
  task_calculation_item_state_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationdeleteTaskCalculationItemsArgs = {
  task_calculation_item_ids: Array<Scalars['ID']>;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationdeleteTaskCalculationTemplateArgs = {
  task_calculation_template_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationdeleteTaskClassificationTagsArgs = {
  task_ids: Array<Scalars['ID']>;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationdeleteTaskContactArgs = {
  id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationdeleteTaskFilesArgs = {
  file_ids: Array<Scalars['ID']>;
  task_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationdeleteTaskFolderArgs = {
  id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationdeleteTaskFolderTemplateArgs = {
  id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationdeleteTaskFormArgs = {
  task_form_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationdeleteTaskFormQuestionCommentArgs = {
  question_id: Scalars['String'];
  task_form_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationdeleteTaskFormQuestionTableRowArgs = {
  row_id: Scalars['String'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationdeleteTaskInvoiceArgs = {
  id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationdeleteTaskReportArgs = {
  id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationdeleteTaskRoomCategoryArgs = {
  id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationdeleteTaskRoomItemArgs = {
  id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationdeleteTaskSubTaskArgs = {
  id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationdeleteTaskSubTaskTemplateArgs = {
  task_sub_task_template_id?: InputMaybe<Scalars['ID']>;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationdeleteTaskTemplateArgs = {
  id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationduplicateTaskArgs = {
  id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationduplicateTaskFormArgs = {
  task_form_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationduplicateTaskTemplateArgs = {
  id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationeditChatMessageArgs = {
  body: Scalars['String'];
  channel_id: Scalars['ID'];
  message_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationeditDuplicateTaskFileArgs = {
  file_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationexportArticlesArgs = {
  config: ExportConfigInput;
  filter: ArticlesFilter;
  sort: ArticlesSort;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationexportClassificationTagsArgs = {
  config: ExportConfigInput;
  filter: ClassificationTagsFilter;
  sort: ClassificationTagsSort;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationexportClientsArgs = {
  config: ExportConfigInput;
  filter: ClientsFilter;
  sort: ClientsSort;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationexportFileClassificationTagsArgs = {
  config: ExportConfigInput;
  filter: FileClassificationTagsFilter;
  sort: FileClassificationTagsSort;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationexportInvoicePayoutsArgs = {
  config: ExportConfigInput;
  filter: InvoicePayoutsFilter;
  sort?: InputMaybe<InvoicePayoutsSort>;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationexportInvoicesArgs = {
  config: ExportConfigInput;
  filter: InvoiceFilter;
  sort?: InputMaybe<InvoiceSort>;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationexportInvoicesDatevArgs = {
  filter: InvoiceFilter;
  sort?: InputMaybe<InvoiceSort>;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationexportMagicPlanArgs = {
  task_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationexportServiceCatalogItemsArgs = {
  config: ExportConfigInput;
  filter: ServiceCatalogItemsFilter;
  sort: ServiceCatalogItemsSort;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationexportServiceCatalogPostcodeFactorArgs = {
  config: ExportConfigInput;
  filter: ServiceCatalogPostcodeFactorFilter;
  sort: ServiceCatalogPostcodeFactorSort;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationexportTaskCalculationsArgs = {
  config: ExportConfigInput;
  task_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationexportTaskFolderTemplatesArgs = {
  config: ExportConfigInput;
  filter: TaskFolderTemplatesFilter;
  sort: TaskFolderTemplatesSort;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationexportTaskSubTasksArgs = {
  config: ExportConfigInput;
  filter: TaskSubTaskFilter;
  sort: TaskSubTaskSort;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationexportTaskTemplatesArgs = {
  config: ExportConfigInput;
  filter: TaskTemplatesFilter;
  sort: TaskTemplatesSort;
  type?: InputMaybe<TaskTemplateTypeId>;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationexportTasksArgs = {
  config: ExportConfigInput;
  filter: TasksFilter;
  sort: TasksSort;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationexportTasksFormDataArgs = {
  config: ExportConfigInput;
  filter: TasksFilter;
  sort: TasksSort;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationexportTasksStatisticCustomArgs = {
  config: ExportConfigInput;
  filter?: InputMaybe<ExportTaskStatisticCustomFilter>;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationfillEmailTemplateArgs = {
  id: Scalars['ID'];
  task_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationfillInterfaceMessageTemplateArgs = {
  id: Scalars['ID'];
  task_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationforgotPasswordArgs = {
  input: ForgotPasswordInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationimportAllLegacyArgs = {
  code: Scalars['String'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationimportAreaAssignmentsArgs = {
  input: ImportAreaAssignmentsInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationimportLegacyArgs = {
  code: Scalars['String'];
  id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationimportPolicyHoldersArgs = {
  file: Scalars['Upload'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationimportTaskTemplatesArgs = {
  file: Scalars['Upload'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationinitialLoginArgs = {
  input: InitialLoginInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationinviteIntoChatChannelArgs = {
  channel_id: Scalars['ID'];
  client_ids: Array<Scalars['ID']>;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationjoinChatChannelArgs = {
  channel_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationkickFromChatChannelArgs = {
  channel_id: Scalars['ID'];
  client_ids: Array<Scalars['ID']>;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationleaveChatChannelArgs = {
  channel_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationlockTaskArgs = {
  id: Scalars['ID'];
  lock_state_id: TaskLockStateId;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationloginArgs = {
  additional_data?: InputMaybe<AdditionalAuthDataInput>;
  input: LoginInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationloginAsCustomerArgs = {
  hash: Scalars['String'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationloginAsOtherUserArgs = {
  user_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationlogoutArgs = {
  logoutOnAllDevices?: InputMaybe<Scalars['Boolean']>;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationlogoutOtherUserArgs = {
  user_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationmarkChatMessageReadArgs = {
  channel_id: Scalars['ID'];
  message_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationmarkNotificationAsReadArgs = {
  id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationmoveTaskFilesToFolderArgs = {
  file_ids: Array<Scalars['ID']>;
  folder_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationpreviewTaskReportArgs = {
  input: PreviewTaskReportInput;
  options: TaskReportOptionsInput;
  task_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationrefreshTokenArgs = {
  input: RefreshTokenInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationremoveTaskClassificationTagArgs = {
  classification_tag_id: Scalars['ID'];
  task_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationrenameTaskFileArgs = {
  file_id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationrenameTaskFolderArgs = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationrenameTaskFolderTemplateArgs = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationreopenTaskSubTaskArgs = {
  id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationreorderTaskCalculationItemsArgs = {
  order: Array<Scalars['Int']>;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationreorderTaskDocumentsArgs = {
  order: Array<Scalars['Int']>;
  task_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationreorderTaskFilesArgs = {
  folder_id: Scalars['ID'];
  order: Array<Scalars['Int']>;
  task_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationreorderTaskFormsArgs = {
  task_form_ids: Array<Scalars['ID']>;
  task_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationreorderTaskImagesArgs = {
  order: Array<Scalars['Int']>;
  task_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationresetClientNotificationSettingsArgs = {
  client_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationresetPasswordArgs = {
  input: ResetPasswordInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationresetTaskAppointmentArgs = {
  id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationresetTaskAssignmentArgs = {
  task_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationresetTaskFormQuestionsArgs = {
  questions: Array<InputMaybe<ResetTaskFormQuestionInput>>;
  task_form_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationrevertCancelTaskArgs = {
  id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationrevertTaskFromControllingArgs = {
  id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationreviewTaskSubTaskArgs = {
  id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationreworkTaskArgs = {
  id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationreworkTaskDoneArgs = {
  id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationsendChatMessageArgs = {
  body: Scalars['String'];
  channel_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationsendCustomerInvitationLinkArgs = {
  input: CustomerPortalInvitationInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationsendDirectMessageFromEmailTemplateArgs = {
  email_template_id: Scalars['ID'];
  task_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationsendDirectMessageFromInterfaceMessageTemplateArgs = {
  interface_message_template_id: Scalars['ID'];
  task_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationsendEmailVerificationLinkArgs = {
  id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationsendQRCodeArgs = {
  user_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationsendTaskInvoiceReminderEmailsArgs = {
  email_template_id: Scalars['ID'];
  task_invoice_ids: Array<Scalars['ID']>;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationsendTaskRouteLinkArgs = {
  id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationsetPayoutsPaidArgs = {
  task_invoice_payout_ids?: InputMaybe<Array<Scalars['ID']>>;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationsetTaskInitialContactArgs = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationsetTaskReportSentArgs = {
  id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationsetTaskToControllingArgs = {
  id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationsuggestTaskInvoiceItemsArgs = {
  task_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationsyncMagicPlanArgs = {
  task_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationsyncTaskCalculationArgs = {
  categories: Array<SyncTaskCalculationCategoryInput>;
  input?: InputMaybe<UpdateTaskCalculationInput>;
  items: Array<SyncTaskCalculationItemInput>;
  options: SyncTaskCalculationOptionsInput;
  task_calculation_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationsyncTaskFormsArgs = {
  forms: Array<SyncTaskFormInput>;
  options: SyncTaskFormsOptionsInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationsyncTaskRoomArgs = {
  categories: Array<TaskRoomCategoryInput>;
  items: Array<TaskRoomItemInput>;
  options: SyncTaskRoomOptionsInput;
  task_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationsyncTaskRoomCategoriesArgs = {
  categories: Array<TaskRoomCategoryInput>;
  options: SyncTaskRoomCategoriesOptionsInput;
  task_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationsyncTaskRoomItemsArgs = {
  items: Array<TaskRoomItemInput>;
  options: SyncTaskRoomItemsOptionsInput;
  task_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationtaskInvoiceRevertPaidArgs = {
  id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationtaskInvoiceSetPaidArgs = {
  id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationtestAppMailTransportArgs = {
  id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationtoggleAppAllowlistArgs = {
  active: Scalars['Boolean'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationtoggleDocumentVisibilityArgs = {
  task_id: Scalars['ID'];
  visible: Scalars['Boolean'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationtoggleImageVisibilityArgs = {
  task_id: Scalars['ID'];
  visible: Scalars['Boolean'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationunlockTaskArgs = {
  id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupdateAddressBookEntryArgs = {
  entry: UpdateAddressBookEntryInput;
  id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupdateAppEmailTransportArgs = {
  id: Scalars['ID'];
  input: UpdateAppEmailTransportInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupdateAppMessageArgs = {
  app_message_id: Scalars['ID'];
  input?: InputMaybe<UpdateAppMessageInput>;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupdateAppModuleArgs = {
  app_module_id: Scalars['ID'];
  input: UpdateAppModuleInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupdateAppStampArgs = {
  id: Scalars['ID'];
  input: UpdateAppStampInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupdateAreaAssignmentArgs = {
  id: Scalars['ID'];
  input: UpdateAreaAssignmentInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupdateAssignmentSettingsArgs = {
  input: UpdateAssignmentSettingsInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupdateCalendarEventArgs = {
  id: Scalars['ID'];
  input: UpdateCalendarEventInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupdateClassificationTagArgs = {
  id: Scalars['ID'];
  input: ClassificationTagInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupdateClientArgs = {
  id: Scalars['ID'];
  input: UpdateClientInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupdateClientIntrosArgs = {
  intros: Array<ClientIntroInput>;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupdateClientLocationArgs = {
  id: Scalars['ID'];
  input: ClientLocationInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupdateClientNotificationEmailSettingsArgs = {
  client_id: Scalars['ID'];
  input: UpdateClientNotificationEmailSettingsInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupdateClientNotificationSettingsArgs = {
  client_id: Scalars['ID'];
  input: UpdateClientNotificationSettingsInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupdateClientQualificationDisabledFormsArgs = {
  client_id: Scalars['ID'];
  input: UpdateClientQualificationDisabledFormsInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupdateClientQualificationHideInsuranceArgs = {
  client_id: Scalars['ID'];
  input: UpdateClientQualificationHideInsuranceInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupdateClientQualificationsArgs = {
  client_id: Scalars['ID'];
  qualification_ids: Array<Scalars['ID']>;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupdateColorSettingsArgs = {
  input: UpdateColorSettingsInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupdateContactArgs = {
  id: Scalars['ID'];
  input: UpdateContactInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupdateCustomerLocationArgs = {
  input: CustomerPortalEventLocationInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupdateCustomerPortalEventArgs = {
  hash: Scalars['String'];
  input: UpdateCustomerPortalEventInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupdateCustomerPortalEventDocumentArgs = {
  file: Scalars['Upload'];
  id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupdateDeviceArgs = {
  device_id: Scalars['ID'];
  input: UpdateDeviceInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupdateDeviceCategoryArgs = {
  device_category_id: Scalars['ID'];
  input: UpdateDeviceCategoryInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupdateEmailTemplateArgs = {
  id: Scalars['ID'];
  input: EmailTemplateInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupdateFileClassificationTagArgs = {
  id: Scalars['ID'];
  input: FileClassificationTagInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupdateGlobalSettingsArgs = {
  input: UpdateGlobalSettingsInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupdateInterfaceMessageTemplateArgs = {
  id: Scalars['ID'];
  input: InterfaceMessageTemplateInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupdateInvoiceSettingsArgs = {
  input: InvoiceSettingsInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupdateLoginPageArgs = {
  input: UpdateLoginPageInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupdateMailSettingsArgs = {
  input: UpdateMailSettingsInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupdateMapBoundsSettingsArgs = {
  input: UpdateMapBoundsInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupdateModuleSettingsArgs = {
  input: UpdateModuleSettingsInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupdateNotificationSettingsArgs = {
  input: UpdateNotificationSettingsInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupdateOrCreateOnSiteAppointmentArgs = {
  input?: InputMaybe<UpdateCalendarEventInput>;
  task_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupdatePayoutArgs = {
  sum_net: Scalars['Float'];
  task_invoice_payout_id: Scalars['ID'];
  title: Scalars['String'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupdatePdfTemplateArgs = {
  id: Scalars['ID'];
  input: PdfTemplateInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupdatePolicyHolderArgs = {
  id: Scalars['ID'];
  input: UpdatePolicyHolderInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupdatePolicyHolderLocationArgs = {
  id: Scalars['ID'];
  input: ClientLocationInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupdatePushNotificationSettingsArgs = {
  input: PushNotificationsSettingsInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupdateQualificationArgs = {
  id: Scalars['ID'];
  input: QualificationInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupdateQuestionTableRowArgs = {
  input?: InputMaybe<TaskQuestionTableRowInput>;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupdateReportLayoutArgs = {
  id: Scalars['ID'];
  input: UpdateReportLayoutInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupdateServiceCatalogItemArgs = {
  input: UpdateServiceCatalogItemInput;
  service_catalog_item_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupdateServiceCatalogPostcodeFactorItemArgs = {
  factor?: InputMaybe<Scalars['Float']>;
  service_catalog_postcode_factor_item_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupdateTagArgs = {
  id: Scalars['ID'];
  input: TagInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupdateTaskArgs = {
  id: Scalars['ID'];
  input: UpdateTaskInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupdateTaskCalculationArgs = {
  input: UpdateTaskCalculationInput;
  task_calculation_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupdateTaskCalculationCategoriesArgs = {
  categories: Array<TaskCalculationCategoryBatchInput>;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupdateTaskCalculationCategoryArgs = {
  id: Scalars['ID'];
  input: TaskCalculationCategoryInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupdateTaskCalculationItemArgs = {
  id: Scalars['ID'];
  input: TaskCalculationItemInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupdateTaskCalculationItemsArgs = {
  items: Array<TaskCalculationItemBatchInput>;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupdateTaskClassificationTagsArgs = {
  classification_tag_ids: Array<Scalars['ID']>;
  task_ids: Array<Scalars['ID']>;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupdateTaskContactArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input: TaskContactUpdateInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupdateTaskCustomReportSettingArgs = {
  input: TaskCustomReportSettingInput;
  task_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupdateTaskDocumentFilesArgs = {
  files: Array<UpdateFileInput>;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupdateTaskDriveLogArgs = {
  id: Scalars['ID'];
  input: UpdateTaskDriveLogInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupdateTaskFileArgs = {
  file?: InputMaybe<Scalars['Upload']>;
  file_id: Scalars['ID'];
  meta_data?: InputMaybe<Scalars['JSON']>;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupdateTaskFilePropertiesArgs = {
  files: Array<UpdateFilePropertiesInput>;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupdateTaskFileTagsArgs = {
  file_classification_tag_ids: Array<Scalars['ID']>;
  file_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupdateTaskFolderPermissionsArgs = {
  id: Scalars['ID'];
  input: UpdateTaskFolderPermissionsInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupdateTaskFollowUpDateArgs = {
  id: Scalars['ID'];
  input: UpdateTaskFollowUpDateInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupdateTaskFormArgs = {
  input: UpdateTaskFormInput;
  task_form_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupdateTaskFormForcePageBreakArgs = {
  page_break_after: Scalars['Boolean'];
  question_id: Scalars['String'];
  task_form_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupdateTaskFormQuestionCommentArgs = {
  comment: Scalars['String'];
  question_id: Scalars['String'];
  task_form_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupdateTaskFormQuestionTableRowArgs = {
  input: TaskFormQuestionTableRowInput;
  task_form_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupdateTaskFormQuestionsArgs = {
  questions: Array<InputMaybe<TaskFormQuestionInput>>;
  task_form_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupdateTaskImageFilesArgs = {
  files: Array<UpdateFileInput>;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupdateTaskInvoiceArgs = {
  id: Scalars['ID'];
  input: UpdateTaskInvoiceInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupdateTaskInvoiceItemsArgs = {
  id: Scalars['ID'];
  input: UpdateTaskInvoiceItemsInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupdateTaskLocationArgs = {
  id: Scalars['ID'];
  input: TaskLocationInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupdateTaskQualificationsArgs = {
  qualification_ids: Array<Scalars['ID']>;
  task_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupdateTaskQuestionArgs = {
  question?: InputMaybe<TaskQuestionInput>;
  task_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupdateTaskQuestionsArgs = {
  questions?: InputMaybe<Array<InputMaybe<TaskQuestionInput>>>;
  task_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupdateTaskQuestionsForCustomerArgs = {
  hash: Scalars['String'];
  questions?: InputMaybe<Array<TaskQuestionInput>>;
  tableRows?: InputMaybe<Array<TaskQuestionTableRowInput>>;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupdateTaskReminderDateArgs = {
  id: Scalars['ID'];
  input: UpdateTaskReminderDateInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupdateTaskRoomCategoryArgs = {
  id: Scalars['ID'];
  input: TaskRoomCategoryInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupdateTaskRoomItemArgs = {
  id: Scalars['ID'];
  input: TaskRoomItemInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupdateTaskSubTaskArgs = {
  id: Scalars['ID'];
  input: UpdateTaskSubTaskInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupdateTaskTemplateArgs = {
  id: Scalars['ID'];
  input: UpdateTaskTemplateInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupdateTaskTemplateAssignmentArgs = {
  id: Scalars['ID'];
  input: UpdateTaskTemplateAssignmentInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupdateTaskTemplateFormArgs = {
  id: Scalars['ID'];
  input: UpdateTaskTemplateFormInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupdateTasksSettingsArgs = {
  input: UpdateTasksSettingsInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupdateUserPasswordArgs = {
  id: Scalars['ID'];
  input: UpdateUserPasswordInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupdateUserPasswordSelfArgs = {
  input: UpdateUserPasswordInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationuploadAlternateReportLogoArgs = {
  file?: InputMaybe<Scalars['Upload']>;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationuploadClientDataArgs = {
  file: Scalars['Upload'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationuploadInvoiceLogoArgs = {
  file?: InputMaybe<Scalars['Upload']>;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationuploadLegacyClientDataArgs = {
  file: Scalars['Upload'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationuploadLegacyTemplatesArgs = {
  file: Scalars['Upload'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationuploadLoginBackgroundArgs = {
  file?: InputMaybe<Scalars['Upload']>;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationuploadLoginLogoArgs = {
  file?: InputMaybe<Scalars['Upload']>;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationuploadMenuIconArgs = {
  file?: InputMaybe<Scalars['Upload']>;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationuploadPdfTemplateArgs = {
  files?: InputMaybe<Array<Scalars['Upload']>>;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationuploadProfileImageArgs = {
  file?: InputMaybe<Scalars['Upload']>;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationuploadReportCoverDefaultBackgroundArgs = {
  file?: InputMaybe<Scalars['Upload']>;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationuploadReportLogoArgs = {
  file?: InputMaybe<Scalars['Upload']>;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationuploadServiceCatalogArgs = {
  catalog_id: Scalars['ID'];
  file: Scalars['Upload'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationuploadTaskDocumentsArgs = {
  files: Array<UploadFileInput>;
  task_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationuploadTaskFilesArgs = {
  files: Array<UploadFileInput>;
  folder_id: Scalars['ID'];
  task_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationuploadTaskImagesArgs = {
  files: Array<UploadFileInput>;
  task_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupsertArticleArgs = {
  article_id?: InputMaybe<Scalars['ID']>;
  input: ArticleInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupsertClientAdditionalInsuranceArgs = {
  client_id: Scalars['ID'];
  input: ClientAdditionalInsuranceInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupsertClientAdditionalLossAdjusterArgs = {
  client_id: Scalars['ID'];
  input: ClientAdditionalLossAdjusterInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupsertClientBankingArgs = {
  client_id: Scalars['ID'];
  input: ClientBankingInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupsertClientQualificationArgs = {
  client_id: Scalars['ID'];
  input: ClientQualificationInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupsertClientServiceLevelArgs = {
  client_id: Scalars['ID'];
  input: ClientServiceLevelInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupsertClientTaxArgs = {
  client_id: Scalars['ID'];
  input: ClientTaxInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupsertServiceCatalogArgs = {
  catalog_id?: InputMaybe<Scalars['ID']>;
  input: ServiceCatalogInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupsertTaskCalculationItemStateArgs = {
  input: TaskCalculationItemStateInput;
  task_calculation_item_state_id?: InputMaybe<Scalars['ID']>;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupsertTaskFormQuestionTableRowsArgs = {
  table_rows: Array<TaskFormQuestionTableRowInput>;
  task_form_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationupsertTaskSubTaskTemplateArgs = {
  input: TaskSubTaskTemplateInput;
  task_sub_task_template_id?: InputMaybe<Scalars['ID']>;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationverifyEmailArgs = {
  input: VerifyEmailInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationverifyLoginArgs = {
  additional_data?: InputMaybe<AdditionalAuthDataInput>;
  input: VerifyLoginInput;
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationvideoCreateAccessTokenArgs = {
  task_id: Scalars['ID'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationvideoCreateCustomerAccessTokenArgs = {
  hash: Scalars['String'];
};

/** Global Mutation type to be extended in other graphql schema files */
export type MutationvideoSendMessageArgs = {
  input: VideoMessageInput;
};

export type NotAcceptedCounts = {
  __typename?: 'NotAcceptedCounts';
  older_three_days_not_accepted: Scalars['Int'];
  three_days_not_accepted: Scalars['Int'];
  two_days_not_accepted: Scalars['Int'];
};

export type Notification = {
  __typename?: 'Notification';
  admin: Scalars['Boolean'];
  body?: Maybe<Scalars['String']>;
  channel_id: NotificationChannel;
  client_id: Scalars['ID'];
  created_at: Scalars['DateTimeTz'];
  from_client?: Maybe<Client>;
  from_client_id?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  read: Scalars['Boolean'];
  task_id?: Maybe<Scalars['ID']>;
  title: Scalars['String'];
  type_id: Scalars['Int'];
};

export enum NotificationChannel {
  EMAIL = 'EMAIL',
  INTERNAL = 'INTERNAL',
  PUSH = 'PUSH',
  SMS = 'SMS',
}

export enum NotificationSetting {
  EMAIL = 'EMAIL',
  INAPP = 'INAPP',
  INAPP_EMAIL = 'INAPP_EMAIL',
  NONE = 'NONE',
}

export type NotificationType = {
  __typename?: 'NotificationType';
  group: Scalars['String'];
  id: Scalars['ID'];
  title: Scalars['String'];
  type_id: NotificationTypeId;
};

export enum NotificationTypeId {
  AGENCY_CONTACTED = 'AGENCY_CONTACTED',
  CLIENT_CREATED = 'CLIENT_CREATED',
  CLIENT_SIGNUP = 'CLIENT_SIGNUP',
  CLIENT_UPDATED = 'CLIENT_UPDATED',
  CUSTOM = 'CUSTOM',
  TASK_ACCEPTED = 'TASK_ACCEPTED',
  TASK_ACTIVATED = 'TASK_ACTIVATED',
  TASK_APPOINTMENT_SET = 'TASK_APPOINTMENT_SET',
  TASK_CANCELLED = 'TASK_CANCELLED',
  TASK_COMPLETED = 'TASK_COMPLETED',
  TASK_CONTACT_UNSUCCESSFUL = 'TASK_CONTACT_UNSUCCESSFUL',
  TASK_CONTACT_UNSUCCESSFUL_DATE_NOT_YET_POSSIBLE = 'TASK_CONTACT_UNSUCCESSFUL_DATE_NOT_YET_POSSIBLE',
  TASK_CONTACT_UNSUCCESSFUL_LEAVE_MESSAGE_ON_PHONE = 'TASK_CONTACT_UNSUCCESSFUL_LEAVE_MESSAGE_ON_PHONE',
  TASK_CONTACT_UNSUCCESSFUL_SENT_MESSAGE = 'TASK_CONTACT_UNSUCCESSFUL_SENT_MESSAGE',
  TASK_CONTROLLING = 'TASK_CONTROLLING',
  TASK_CONTROLLING_REVERTED = 'TASK_CONTROLLING_REVERTED',
  TASK_CREATED = 'TASK_CREATED',
  TASK_DECLINED = 'TASK_DECLINED',
  TASK_LEADER_CHANGED = 'TASK_LEADER_CHANGED',
  TASK_OWNER_CHANGED = 'TASK_OWNER_CHANGED',
  TASK_SERVICE_LEVEL = 'TASK_SERVICE_LEVEL',
  TASK_SET_IN_PROGRESS = 'TASK_SET_IN_PROGRESS',
}

export type NumSubTasksOpenResponse = {
  __typename?: 'NumSubTasksOpenResponse';
  count: Scalars['Int'];
};

export enum OpenSubTaskStateColor {
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
}

/** Allows ordering a list of records. */
export type OrderByClause = {
  /** The column that is used for ordering. */
  column: Scalars['String'];
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Aggregate functions when ordering by a relation without specifying a column. */
export enum OrderByRelationAggregateFunction {
  /** Amount of items. */
  COUNT = 'COUNT',
}

/** Aggregate functions when ordering by a relation that may specify a column. */
export enum OrderByRelationWithColumnAggregateFunction {
  /** Average. */
  AVG = 'AVG',
  /** Amount of items. */
  COUNT = 'COUNT',
  /** Maximum. */
  MAX = 'MAX',
  /** Minimum. */
  MIN = 'MIN',
  /** Sum. */
  SUM = 'SUM',
}

export enum PageNotificationType {
  ERROR = 'ERROR',
  INFO = 'INFO',
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
}

export enum PageType {
  CALCULATION = 'CALCULATION',
  COVER = 'COVER',
  DOCUMENTS = 'DOCUMENTS',
  FREETEXT = 'FREETEXT',
  IMAGES = 'IMAGES',
  QUESTIONS = 'QUESTIONS',
  ROOM_PLAN = 'ROOM_PLAN',
  SUMMARY = 'SUMMARY',
  TOC = 'TOC',
}

export type PaginationInput = {
  skip: Scalars['Int'];
  take: Scalars['Int'];
};

/** Information about pagination using a fully featured paginator. */
export type PaginatorInfo = {
  __typename?: 'PaginatorInfo';
  /** Number of items in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** Index of the first item in the current page. */
  firstItem?: Maybe<Scalars['Int']>;
  /** Are there more pages after this one? */
  hasMorePages: Scalars['Boolean'];
  /** Index of the last item in the current page. */
  lastItem?: Maybe<Scalars['Int']>;
  /** Index of the last available page. */
  lastPage: Scalars['Int'];
  /** Number of items per page. */
  perPage: Scalars['Int'];
  /** Number of total available items. */
  total: Scalars['Int'];
};

export type Payout = {
  __typename?: 'Payout';
  from: Scalars['DateTimeTz'];
  id: Scalars['ID'];
  to: Scalars['DateTimeTz'];
};

export enum PayoutType {
  AFTER_INVOICE = 'AFTER_INVOICE',
  AFTER_PAYMENT = 'AFTER_PAYMENT',
}

export enum PayoutTypeTax {
  GROSS = 'GROSS',
  NET = 'NET',
}

export type PdfTemplate = {
  __typename?: 'PdfTemplate';
  assignment?: Maybe<Array<PdfTemplateAssigment>>;
  custom: Scalars['Boolean'];
  file: FileItem;
  id: Scalars['ID'];
  title: Scalars['String'];
};

export type PdfTemplateAssigment = {
  __typename?: 'PdfTemplateAssigment';
  id: Scalars['ID'];
  pdf_template_id: Scalars['ID'];
  task_template_id: Scalars['ID'];
  template: TaskTemplate;
};

export type PdfTemplateAssigmentInput = {
  task_template_id: Scalars['ID'];
};

export type PdfTemplateInput = {
  assignment?: InputMaybe<Array<PdfTemplateAssigmentInput>>;
  file?: InputMaybe<Scalars['Upload']>;
  title?: InputMaybe<Scalars['String']>;
};

export type PdfTemplatePaginator = {
  __typename?: 'PdfTemplatePaginator';
  data: Array<PdfTemplate>;
  paginatorInfo: PaginatorInfo;
};

export type PdfTemplatesFilter = {
  AND?: InputMaybe<Array<PdfTemplatesFilter>>;
  OR?: InputMaybe<Array<PdfTemplatesFilter>>;
  column?: InputMaybe<PdfTemplatesSortAndFilterColumns>;
  operator?: InputMaybe<SQLOperator>;
  value?: InputMaybe<Scalars['Mixed']>;
};

export type PdfTemplatesSort = {
  columns?: InputMaybe<Array<PdfTemplatesSortOrder>>;
};

export enum PdfTemplatesSortAndFilterColumns {
  ID = 'ID',
  TITLE = 'TITLE',
}

export type PdfTemplatesSortOrder = {
  column: PdfTemplatesSortAndFilterColumns;
  order: SortOrder;
};

export enum Permission {
  ACTIVITY_CREATE = 'ACTIVITY_CREATE',
  ACTIVITY_DELETE = 'ACTIVITY_DELETE',
  ACTIVITY_INDEX = 'ACTIVITY_INDEX',
  ACTIVITY_READ = 'ACTIVITY_READ',
  ACTIVITY_UPDATE = 'ACTIVITY_UPDATE',
  ADDRESS_BOOK_CREATE = 'ADDRESS_BOOK_CREATE',
  ADDRESS_BOOK_DELETE = 'ADDRESS_BOOK_DELETE',
  ADDRESS_BOOK_GET = 'ADDRESS_BOOK_GET',
  ADDRESS_BOOK_LIST = 'ADDRESS_BOOK_LIST',
  ADDRESS_BOOK_UPDATE = 'ADDRESS_BOOK_UPDATE',
  ADMIN_CHANGE_USER = 'ADMIN_CHANGE_USER',
  ADMIN_CREATE = 'ADMIN_CREATE',
  ADMIN_DELETE = 'ADMIN_DELETE',
  ADMIN_INDEX = 'ADMIN_INDEX',
  ADMIN_READ = 'ADMIN_READ',
  ADMIN_UPDATE = 'ADMIN_UPDATE',
  APP_ALLOWLIST_CREATE = 'APP_ALLOWLIST_CREATE',
  APP_ALLOWLIST_DELETE = 'APP_ALLOWLIST_DELETE',
  APP_ALLOWLIST_INDEX = 'APP_ALLOWLIST_INDEX',
  APP_ALLOWLIST_TOGGLE = 'APP_ALLOWLIST_TOGGLE',
  APP_MESSAGES_CREATE = 'APP_MESSAGES_CREATE',
  APP_MESSAGES_DELETE = 'APP_MESSAGES_DELETE',
  APP_MESSAGES_INDEX = 'APP_MESSAGES_INDEX',
  APP_MESSAGES_ME = 'APP_MESSAGES_ME',
  APP_MESSAGES_UPDATE = 'APP_MESSAGES_UPDATE',
  APP_MODULES_INDEX = 'APP_MODULES_INDEX',
  APP_MODULES_UPDATE = 'APP_MODULES_UPDATE',
  APP_SETTINGS_CREATE_STAMPS = 'APP_SETTINGS_CREATE_STAMPS',
  APP_SETTINGS_DELETE_STAMPS = 'APP_SETTINGS_DELETE_STAMPS',
  APP_SETTINGS_READ = 'APP_SETTINGS_READ',
  APP_SETTINGS_READ_STAMPS = 'APP_SETTINGS_READ_STAMPS',
  APP_SETTINGS_UPDATE = 'APP_SETTINGS_UPDATE',
  APP_SETTINGS_UPDATE_ASSIGNMENT_SETTINGS = 'APP_SETTINGS_UPDATE_ASSIGNMENT_SETTINGS',
  APP_SETTINGS_UPDATE_COLORS = 'APP_SETTINGS_UPDATE_COLORS',
  APP_SETTINGS_UPDATE_EMAIL = 'APP_SETTINGS_UPDATE_EMAIL',
  APP_SETTINGS_UPDATE_IMAGES = 'APP_SETTINGS_UPDATE_IMAGES',
  APP_SETTINGS_UPDATE_INVOICES_SETTINGS = 'APP_SETTINGS_UPDATE_INVOICES_SETTINGS',
  APP_SETTINGS_UPDATE_MAP_BOUNDS_SETTINGS = 'APP_SETTINGS_UPDATE_MAP_BOUNDS_SETTINGS',
  APP_SETTINGS_UPDATE_MODULES = 'APP_SETTINGS_UPDATE_MODULES',
  APP_SETTINGS_UPDATE_NOTIFICATIONS = 'APP_SETTINGS_UPDATE_NOTIFICATIONS',
  APP_SETTINGS_UPDATE_PAGE_SETTINGS = 'APP_SETTINGS_UPDATE_PAGE_SETTINGS',
  APP_SETTINGS_UPDATE_PUSH_NOTIFICATION_SETTINGS = 'APP_SETTINGS_UPDATE_PUSH_NOTIFICATION_SETTINGS',
  APP_SETTINGS_UPDATE_STAMPS = 'APP_SETTINGS_UPDATE_STAMPS',
  APP_SETTINGS_UPDATE_TASKS_SETTINGS = 'APP_SETTINGS_UPDATE_TASKS_SETTINGS',
  AREA_ASSIGNMENT_EXPORT = 'AREA_ASSIGNMENT_EXPORT',
  AREA_ASSIGNMENT_IMPORT = 'AREA_ASSIGNMENT_IMPORT',
  AREA_ASSIGNMENT_INDEX = 'AREA_ASSIGNMENT_INDEX',
  AREA_ASSIGNMENT_READ = 'AREA_ASSIGNMENT_READ',
  AREA_ASSIGNMENT_UPDATE = 'AREA_ASSIGNMENT_UPDATE',
  ARTICLE_CREATE = 'ARTICLE_CREATE',
  ARTICLE_INDEX = 'ARTICLE_INDEX',
  ARTICLE_READ = 'ARTICLE_READ',
  ARTICLE_UPDATE = 'ARTICLE_UPDATE',
  CALENDAR_EVENT_CREATE = 'CALENDAR_EVENT_CREATE',
  CALENDAR_EVENT_DELETE = 'CALENDAR_EVENT_DELETE',
  CALENDAR_EVENT_FILTER_ADJUSTERS = 'CALENDAR_EVENT_FILTER_ADJUSTERS',
  CALENDAR_EVENT_INDEX = 'CALENDAR_EVENT_INDEX',
  CALENDAR_EVENT_READ = 'CALENDAR_EVENT_READ',
  CALENDAR_EVENT_UPDATE = 'CALENDAR_EVENT_UPDATE',
  CHAT_CREATE_PRIVATE_CHANNEL = 'CHAT_CREATE_PRIVATE_CHANNEL',
  CHAT_CREATE_PUBLIC_CHANNEL = 'CHAT_CREATE_PUBLIC_CHANNEL',
  CHAT_DELETE_MESSAGE = 'CHAT_DELETE_MESSAGE',
  CHAT_EDIT_MESSAGE = 'CHAT_EDIT_MESSAGE',
  CHAT_INDEX = 'CHAT_INDEX',
  CHAT_INVITE_INTO_CHANNEL = 'CHAT_INVITE_INTO_CHANNEL',
  CHAT_JOIN_CHANNEL = 'CHAT_JOIN_CHANNEL',
  CHAT_KICK_FROM_CHANNEL = 'CHAT_KICK_FROM_CHANNEL',
  CHAT_LEAVE_CHANNEL = 'CHAT_LEAVE_CHANNEL',
  CHAT_READ = 'CHAT_READ',
  CHAT_SEND_MESSAGE = 'CHAT_SEND_MESSAGE',
  CLASSIFICATION_TAG_CREATE = 'CLASSIFICATION_TAG_CREATE',
  CLASSIFICATION_TAG_DELETE = 'CLASSIFICATION_TAG_DELETE',
  CLASSIFICATION_TAG_EXPORT = 'CLASSIFICATION_TAG_EXPORT',
  CLASSIFICATION_TAG_INDEX = 'CLASSIFICATION_TAG_INDEX',
  CLASSIFICATION_TAG_READ = 'CLASSIFICATION_TAG_READ',
  CLASSIFICATION_TAG_UPDATE = 'CLASSIFICATION_TAG_UPDATE',
  CLIENT_ASSIGN_EMAIL_TEMPLATES = 'CLIENT_ASSIGN_EMAIL_TEMPLATES',
  CLIENT_ASSIGN_INTERFACE_MESSAGE_TEMPLATES = 'CLIENT_ASSIGN_INTERFACE_MESSAGE_TEMPLATES',
  CLIENT_ASSIGN_TEMPLATES_TO_DIRECT_MESSAGES = 'CLIENT_ASSIGN_TEMPLATES_TO_DIRECT_MESSAGES',
  CLIENT_CREATE = 'CLIENT_CREATE',
  CLIENT_CREATE_LOCATIONS = 'CLIENT_CREATE_LOCATIONS',
  CLIENT_DELETE = 'CLIENT_DELETE',
  CLIENT_DELETE_LOCATIONS = 'CLIENT_DELETE_LOCATIONS',
  CLIENT_INDEX = 'CLIENT_INDEX',
  CLIENT_READ = 'CLIENT_READ',
  CLIENT_READ_ABSENCE = 'CLIENT_READ_ABSENCE',
  CLIENT_READ_ADDRESS = 'CLIENT_READ_ADDRESS',
  CLIENT_READ_BANKING = 'CLIENT_READ_BANKING',
  CLIENT_READ_CLIENT_QUALIFICATION = 'CLIENT_READ_CLIENT_QUALIFICATION',
  CLIENT_READ_COMMON = 'CLIENT_READ_COMMON',
  CLIENT_READ_CONTACTS = 'CLIENT_READ_CONTACTS',
  CLIENT_READ_INSURANCE = 'CLIENT_READ_INSURANCE',
  CLIENT_READ_INTROS = 'CLIENT_READ_INTROS',
  CLIENT_READ_INVOICES = 'CLIENT_READ_INVOICES',
  CLIENT_READ_LOCATION = 'CLIENT_READ_LOCATION',
  CLIENT_READ_LOCATIONS = 'CLIENT_READ_LOCATIONS',
  CLIENT_READ_NOTIFICATION_EMAIL_SETTINGS = 'CLIENT_READ_NOTIFICATION_EMAIL_SETTINGS',
  CLIENT_READ_NOTIFICATION_SETTINGS = 'CLIENT_READ_NOTIFICATION_SETTINGS',
  CLIENT_READ_PAYROLL = 'CLIENT_READ_PAYROLL',
  CLIENT_READ_POLICY_HOLDER = 'CLIENT_READ_POLICY_HOLDER',
  CLIENT_READ_QUALIFICATIONS = 'CLIENT_READ_QUALIFICATIONS',
  CLIENT_READ_SERVICE_LEVEL = 'CLIENT_READ_SERVICE_LEVEL',
  CLIENT_READ_TAXATION = 'CLIENT_READ_TAXATION',
  CLIENT_RISK_CREATE = 'CLIENT_RISK_CREATE',
  CLIENT_RISK_DELETE = 'CLIENT_RISK_DELETE',
  CLIENT_RISK_IMPORT = 'CLIENT_RISK_IMPORT',
  CLIENT_RISK_INDEX = 'CLIENT_RISK_INDEX',
  CLIENT_RISK_READ = 'CLIENT_RISK_READ',
  CLIENT_RISK_UPDATE = 'CLIENT_RISK_UPDATE',
  CLIENT_UPDATE = 'CLIENT_UPDATE',
  CLIENT_UPDATE_ABSENCE = 'CLIENT_UPDATE_ABSENCE',
  CLIENT_UPDATE_ADDRESS = 'CLIENT_UPDATE_ADDRESS',
  CLIENT_UPDATE_BANKING = 'CLIENT_UPDATE_BANKING',
  CLIENT_UPDATE_CLIENT_QUALIFICATION = 'CLIENT_UPDATE_CLIENT_QUALIFICATION',
  CLIENT_UPDATE_COMMON = 'CLIENT_UPDATE_COMMON',
  CLIENT_UPDATE_CONTACTS = 'CLIENT_UPDATE_CONTACTS',
  CLIENT_UPDATE_INSURANCE = 'CLIENT_UPDATE_INSURANCE',
  CLIENT_UPDATE_INTROS = 'CLIENT_UPDATE_INTROS',
  CLIENT_UPDATE_LOCATION = 'CLIENT_UPDATE_LOCATION',
  CLIENT_UPDATE_LOCATIONS = 'CLIENT_UPDATE_LOCATIONS',
  CLIENT_UPDATE_NOTIFICATION_EMAIL_SETTINGS = 'CLIENT_UPDATE_NOTIFICATION_EMAIL_SETTINGS',
  CLIENT_UPDATE_NOTIFICATION_SETTINGS = 'CLIENT_UPDATE_NOTIFICATION_SETTINGS',
  CLIENT_UPDATE_PAYROLL = 'CLIENT_UPDATE_PAYROLL',
  CLIENT_UPDATE_POLICY_HOLDER = 'CLIENT_UPDATE_POLICY_HOLDER',
  CLIENT_UPDATE_QUALIFICATIONS = 'CLIENT_UPDATE_QUALIFICATIONS',
  CLIENT_UPDATE_SERVICE_LEVEL = 'CLIENT_UPDATE_SERVICE_LEVEL',
  CLIENT_UPDATE_TAXATION = 'CLIENT_UPDATE_TAXATION',
  CONTACT_CREATE = 'CONTACT_CREATE',
  CONTACT_DELETE = 'CONTACT_DELETE',
  CONTACT_INDEX = 'CONTACT_INDEX',
  CONTACT_READ = 'CONTACT_READ',
  CONTACT_UPDATE = 'CONTACT_UPDATE',
  DEVICE_CREATE = 'DEVICE_CREATE',
  DEVICE_DELETE = 'DEVICE_DELETE',
  DEVICE_EXPORT = 'DEVICE_EXPORT',
  DEVICE_IMPORT = 'DEVICE_IMPORT',
  DEVICE_INDEX = 'DEVICE_INDEX',
  DEVICE_UPDATE = 'DEVICE_UPDATE',
  EMAIL_TEMPLATE_CREATE = 'EMAIL_TEMPLATE_CREATE',
  EMAIL_TEMPLATE_DELETE = 'EMAIL_TEMPLATE_DELETE',
  EMAIL_TEMPLATE_INDEX = 'EMAIL_TEMPLATE_INDEX',
  EMAIL_TEMPLATE_READ = 'EMAIL_TEMPLATE_READ',
  EMAIL_TEMPLATE_UPDATE = 'EMAIL_TEMPLATE_UPDATE',
  FILE_CLASSIFICATION_TAG_CREATE = 'FILE_CLASSIFICATION_TAG_CREATE',
  FILE_CLASSIFICATION_TAG_DELETE = 'FILE_CLASSIFICATION_TAG_DELETE',
  FILE_CLASSIFICATION_TAG_EXPORT = 'FILE_CLASSIFICATION_TAG_EXPORT',
  FILE_CLASSIFICATION_TAG_INDEX = 'FILE_CLASSIFICATION_TAG_INDEX',
  FILE_CLASSIFICATION_TAG_UPDATE = 'FILE_CLASSIFICATION_TAG_UPDATE',
  INTERFACE_MESSAGE_TEMPLATE_CREATE = 'INTERFACE_MESSAGE_TEMPLATE_CREATE',
  INTERFACE_MESSAGE_TEMPLATE_DELETE = 'INTERFACE_MESSAGE_TEMPLATE_DELETE',
  INTERFACE_MESSAGE_TEMPLATE_INDEX = 'INTERFACE_MESSAGE_TEMPLATE_INDEX',
  INTERFACE_MESSAGE_TEMPLATE_READ = 'INTERFACE_MESSAGE_TEMPLATE_READ',
  INTERFACE_MESSAGE_TEMPLATE_UPDATE = 'INTERFACE_MESSAGE_TEMPLATE_UPDATE',
  PAYOUT_CANCEL = 'PAYOUT_CANCEL',
  PAYOUT_CREATE = 'PAYOUT_CREATE',
  PAYOUT_INDEX = 'PAYOUT_INDEX',
  PAYOUT_SET_PAID = 'PAYOUT_SET_PAID',
  PAYOUT_UPDATE = 'PAYOUT_UPDATE',
  PDF_TEMPLATE_CREATE = 'PDF_TEMPLATE_CREATE',
  PDF_TEMPLATE_DELETE = 'PDF_TEMPLATE_DELETE',
  PDF_TEMPLATE_INDEX = 'PDF_TEMPLATE_INDEX',
  PDF_TEMPLATE_READ = 'PDF_TEMPLATE_READ',
  PDF_TEMPLATE_UPDATE = 'PDF_TEMPLATE_UPDATE',
  QUALIFICATION_CREATE = 'QUALIFICATION_CREATE',
  QUALIFICATION_DELETE = 'QUALIFICATION_DELETE',
  QUALIFICATION_EXPORT = 'QUALIFICATION_EXPORT',
  QUALIFICATION_INDEX = 'QUALIFICATION_INDEX',
  QUALIFICATION_READ = 'QUALIFICATION_READ',
  QUALIFICATION_UPDATE = 'QUALIFICATION_UPDATE',
  REPORT_TEMPLATE_CREATE = 'REPORT_TEMPLATE_CREATE',
  REPORT_TEMPLATE_DELETE = 'REPORT_TEMPLATE_DELETE',
  REPORT_TEMPLATE_INDEX = 'REPORT_TEMPLATE_INDEX',
  REPORT_TEMPLATE_READ = 'REPORT_TEMPLATE_READ',
  REPORT_TEMPLATE_UPDATE = 'REPORT_TEMPLATE_UPDATE',
  SERVICE_CATALOG_ITEM_EXPORT = 'SERVICE_CATALOG_ITEM_EXPORT',
  SERVICE_CATALOG_ITEM_IMPORT = 'SERVICE_CATALOG_ITEM_IMPORT',
  SERVICE_CATALOG_ITEM_INDEX = 'SERVICE_CATALOG_ITEM_INDEX',
  SERVICE_CATALOG_ITEM_POSTCODE_FACTORS = 'SERVICE_CATALOG_ITEM_POSTCODE_FACTORS',
  SERVICE_CATALOG_ITEM_TRADE_STATUS = 'SERVICE_CATALOG_ITEM_TRADE_STATUS',
  SYNC_ARTICLES = 'SYNC_ARTICLES',
  SYNC_INVOICES = 'SYNC_INVOICES',
  TAG_CREATE = 'TAG_CREATE',
  TAG_DELETE = 'TAG_DELETE',
  TAG_INDEX = 'TAG_INDEX',
  TAG_UPDATE = 'TAG_UPDATE',
  TASK_ACCEPT = 'TASK_ACCEPT',
  TASK_ACTIVATE = 'TASK_ACTIVATE',
  TASK_ASSIGN_LEADER = 'TASK_ASSIGN_LEADER',
  TASK_ASSIGN_OWNER = 'TASK_ASSIGN_OWNER',
  TASK_BATCH_ASSIGN = 'TASK_BATCH_ASSIGN',
  TASK_BATCH_DELETE_CLASSIFICATION = 'TASK_BATCH_DELETE_CLASSIFICATION',
  TASK_BATCH_UPDATE_CLASSIFICATION = 'TASK_BATCH_UPDATE_CLASSIFICATION',
  TASK_CALCULATION_ITEM_STATE_CREATE = 'TASK_CALCULATION_ITEM_STATE_CREATE',
  TASK_CALCULATION_ITEM_STATE_DELETE = 'TASK_CALCULATION_ITEM_STATE_DELETE',
  TASK_CALCULATION_ITEM_STATE_INDEX = 'TASK_CALCULATION_ITEM_STATE_INDEX',
  TASK_CALCULATION_TEMPLATE_CREATE = 'TASK_CALCULATION_TEMPLATE_CREATE',
  TASK_CALCULATION_TEMPLATE_DELETE = 'TASK_CALCULATION_TEMPLATE_DELETE',
  TASK_CALCULATION_TEMPLATE_INDEX = 'TASK_CALCULATION_TEMPLATE_INDEX',
  TASK_CANCEL = 'TASK_CANCEL',
  TASK_CHANGE_FORM_TEMPLATE = 'TASK_CHANGE_FORM_TEMPLATE',
  TASK_COMPLETE = 'TASK_COMPLETE',
  TASK_CONTROLLING = 'TASK_CONTROLLING',
  TASK_CREATE = 'TASK_CREATE',
  TASK_CREATE_CONTACTS = 'TASK_CREATE_CONTACTS',
  TASK_CREATE_FORM = 'TASK_CREATE_FORM',
  TASK_CREATE_LOCATION = 'TASK_CREATE_LOCATION',
  TASK_DECLINE = 'TASK_DECLINE',
  TASK_DELETE = 'TASK_DELETE',
  TASK_DELETE_CLASSIFICATION = 'TASK_DELETE_CLASSIFICATION',
  TASK_DELETE_CONTACTS = 'TASK_DELETE_CONTACTS',
  TASK_DELETE_FILES = 'TASK_DELETE_FILES',
  TASK_DELETE_FORM = 'TASK_DELETE_FORM',
  TASK_DUPLICATE = 'TASK_DUPLICATE',
  TASK_EDIT = 'TASK_EDIT',
  TASK_EXPORT = 'TASK_EXPORT',
  TASK_EXPORT_STATISTICS = 'TASK_EXPORT_STATISTICS',
  TASK_FILE_ACTIVATE_FILE_FOR_INTERFACE = 'TASK_FILE_ACTIVATE_FILE_FOR_INTERFACE',
  TASK_FILE_DELETE_FILE = 'TASK_FILE_DELETE_FILE',
  TASK_FILE_RENAME_FILE = 'TASK_FILE_RENAME_FILE',
  TASK_FILE_UPDATE_FILE = 'TASK_FILE_UPDATE_FILE',
  TASK_FILE_UPDATE_FILE_PROPERTIES = 'TASK_FILE_UPDATE_FILE_PROPERTIES',
  TASK_FILE_UPDATE_FILE_TAGS = 'TASK_FILE_UPDATE_FILE_TAGS',
  TASK_FILTER = 'TASK_FILTER',
  TASK_FILTER_ADJUSTERS = 'TASK_FILTER_ADJUSTERS',
  TASK_FILTER_LEADERS = 'TASK_FILTER_LEADERS',
  TASK_FOLDER_TEMPLATE_CREATE = 'TASK_FOLDER_TEMPLATE_CREATE',
  TASK_FOLDER_TEMPLATE_DELETE = 'TASK_FOLDER_TEMPLATE_DELETE',
  TASK_FOLDER_TEMPLATE_INDEX = 'TASK_FOLDER_TEMPLATE_INDEX',
  TASK_FOLDER_TEMPLATE_READ = 'TASK_FOLDER_TEMPLATE_READ',
  TASK_FOLDER_TEMPLATE_UPDATE = 'TASK_FOLDER_TEMPLATE_UPDATE',
  TASK_INDEX = 'TASK_INDEX',
  TASK_INVOICE_CANCEL = 'TASK_INVOICE_CANCEL',
  TASK_INVOICE_COMPLETE = 'TASK_INVOICE_COMPLETE',
  TASK_INVOICE_CREATE = 'TASK_INVOICE_CREATE',
  TASK_INVOICE_DELETE = 'TASK_INVOICE_DELETE',
  TASK_INVOICE_INDEX = 'TASK_INVOICE_INDEX',
  TASK_INVOICE_READ = 'TASK_INVOICE_READ',
  TASK_INVOICE_REVERT_PAID = 'TASK_INVOICE_REVERT_PAID',
  TASK_INVOICE_SEND_REMINDER = 'TASK_INVOICE_SEND_REMINDER',
  TASK_INVOICE_SET_PAID = 'TASK_INVOICE_SET_PAID',
  TASK_INVOICE_SYNC = 'TASK_INVOICE_SYNC',
  TASK_INVOICE_UPDATE = 'TASK_INVOICE_UPDATE',
  TASK_LOCK = 'TASK_LOCK',
  TASK_READ = 'TASK_READ',
  TASK_READ_CALCULATION = 'TASK_READ_CALCULATION',
  TASK_READ_CALENDAR = 'TASK_READ_CALENDAR',
  TASK_READ_CUSTOMER_PORTAL = 'TASK_READ_CUSTOMER_PORTAL',
  TASK_READ_DAMAGE_SECTION = 'TASK_READ_DAMAGE_SECTION',
  TASK_READ_DOCUMENTS = 'TASK_READ_DOCUMENTS',
  TASK_READ_DRIVE_LOG = 'TASK_READ_DRIVE_LOG',
  TASK_READ_EMAILS = 'TASK_READ_EMAILS',
  TASK_READ_FILES = 'TASK_READ_FILES',
  TASK_READ_FORM = 'TASK_READ_FORM',
  TASK_READ_FORM_EXTENDED = 'TASK_READ_FORM_EXTENDED',
  TASK_READ_GLOBAL = 'TASK_READ_GLOBAL',
  TASK_READ_IMAGES = 'TASK_READ_IMAGES',
  TASK_READ_INVOICES = 'TASK_READ_INVOICES',
  TASK_READ_LOG = 'TASK_READ_LOG',
  TASK_READ_QUALIFICATIONS = 'TASK_READ_QUALIFICATIONS',
  TASK_READ_QUESTIONS = 'TASK_READ_QUESTIONS',
  TASK_READ_REPORTS = 'TASK_READ_REPORTS',
  TASK_READ_ROOM_PLAN = 'TASK_READ_ROOM_PLAN',
  TASK_READ_STATISTICS = 'TASK_READ_STATISTICS',
  TASK_READ_SUBTASKS = 'TASK_READ_SUBTASKS',
  TASK_RESET_APPOINTMENT = 'TASK_RESET_APPOINTMENT',
  TASK_RESET_ASSIGNMENT = 'TASK_RESET_ASSIGNMENT',
  TASK_REVERT_CANCEL = 'TASK_REVERT_CANCEL',
  TASK_REVERT_CONTROLLING = 'TASK_REVERT_CONTROLLING',
  TASK_REWORK = 'TASK_REWORK',
  TASK_REWORK_DONE = 'TASK_REWORK_DONE',
  TASK_SEND_DIRECT_MESSAGE_FROM_EMAIL_TEMPLATE = 'TASK_SEND_DIRECT_MESSAGE_FROM_EMAIL_TEMPLATE',
  TASK_SEND_DIRECT_MESSAGE_FROM_INTERFACE_MESSAGE_TEMPLATE = 'TASK_SEND_DIRECT_MESSAGE_FROM_INTERFACE_MESSAGE_TEMPLATE',
  TASK_SET_APPOINTMENT = 'TASK_SET_APPOINTMENT',
  TASK_SET_INITIAL_CONTACT = 'TASK_SET_INITIAL_CONTACT',
  TASK_SET_REPORT_SENT = 'TASK_SET_REPORT_SENT',
  TASK_SUBTASK_TEMPLATE_CREATE = 'TASK_SUBTASK_TEMPLATE_CREATE',
  TASK_SUBTASK_TEMPLATE_DELETE = 'TASK_SUBTASK_TEMPLATE_DELETE',
  TASK_SUBTASK_TEMPLATE_INDEX = 'TASK_SUBTASK_TEMPLATE_INDEX',
  TASK_SUBTASK_TEMPLATE_READ = 'TASK_SUBTASK_TEMPLATE_READ',
  TASK_SUBTASK_TEMPLATE_UPDATE = 'TASK_SUBTASK_TEMPLATE_UPDATE',
  TASK_SUB_TASK_COMPLETE = 'TASK_SUB_TASK_COMPLETE',
  TASK_SUB_TASK_CREATE = 'TASK_SUB_TASK_CREATE',
  TASK_SUB_TASK_DELETE = 'TASK_SUB_TASK_DELETE',
  TASK_SUB_TASK_EXPORT = 'TASK_SUB_TASK_EXPORT',
  TASK_SUB_TASK_INDEX = 'TASK_SUB_TASK_INDEX',
  TASK_SUB_TASK_READ = 'TASK_SUB_TASK_READ',
  TASK_SUB_TASK_REOPEN = 'TASK_SUB_TASK_REOPEN',
  TASK_SUB_TASK_REVIEW = 'TASK_SUB_TASK_REVIEW',
  TASK_SUB_TASK_UPDATE = 'TASK_SUB_TASK_UPDATE',
  TASK_SUB_TASK_UPDATE_ASSIGNEE = 'TASK_SUB_TASK_UPDATE_ASSIGNEE',
  TASK_SUB_TASK_UPDATE_DESCRIPTION = 'TASK_SUB_TASK_UPDATE_DESCRIPTION',
  TASK_SUB_TASK_UPDATE_DUE_DATE = 'TASK_SUB_TASK_UPDATE_DUE_DATE',
  TASK_SUB_TASK_UPDATE_FILE = 'TASK_SUB_TASK_UPDATE_FILE',
  TASK_SUB_TASK_UPDATE_TITLE = 'TASK_SUB_TASK_UPDATE_TITLE',
  TASK_TEMPLATE_ASSIGN = 'TASK_TEMPLATE_ASSIGN',
  TASK_TEMPLATE_CREATE = 'TASK_TEMPLATE_CREATE',
  TASK_TEMPLATE_DELETE = 'TASK_TEMPLATE_DELETE',
  TASK_TEMPLATE_DESIGNER = 'TASK_TEMPLATE_DESIGNER',
  TASK_TEMPLATE_DUPLICATE = 'TASK_TEMPLATE_DUPLICATE',
  TASK_TEMPLATE_INDEX = 'TASK_TEMPLATE_INDEX',
  TASK_TEMPLATE_READ = 'TASK_TEMPLATE_READ',
  TASK_TEMPLATE_UPDATE = 'TASK_TEMPLATE_UPDATE',
  TASK_UNLOCK = 'TASK_UNLOCK',
  TASK_UPDATE = 'TASK_UPDATE',
  TASK_UPDATE_CALCULATION = 'TASK_UPDATE_CALCULATION',
  TASK_UPDATE_CALENDAR = 'TASK_UPDATE_CALENDAR',
  TASK_UPDATE_CLASSIFICATION = 'TASK_UPDATE_CLASSIFICATION',
  TASK_UPDATE_CONTACTS = 'TASK_UPDATE_CONTACTS',
  TASK_UPDATE_CUSTOMER_PORTAL = 'TASK_UPDATE_CUSTOMER_PORTAL',
  TASK_UPDATE_DAMAGE_SECTION = 'TASK_UPDATE_DAMAGE_SECTION',
  TASK_UPDATE_DOCUMENTS = 'TASK_UPDATE_DOCUMENTS',
  TASK_UPDATE_DRIVE_LOG = 'TASK_UPDATE_DRIVE_LOG',
  TASK_UPDATE_EMAILS = 'TASK_UPDATE_EMAILS',
  TASK_UPDATE_FILES = 'TASK_UPDATE_FILES',
  TASK_UPDATE_FOLDER_PERMISSIONS = 'TASK_UPDATE_FOLDER_PERMISSIONS',
  TASK_UPDATE_FOLLOW_UP_DATE = 'TASK_UPDATE_FOLLOW_UP_DATE',
  TASK_UPDATE_FORM = 'TASK_UPDATE_FORM',
  TASK_UPDATE_FORM_QUESTION_COMMENTS = 'TASK_UPDATE_FORM_QUESTION_COMMENTS',
  TASK_UPDATE_FORM_QUESTION_FORCE_PAGE_BREAK = 'TASK_UPDATE_FORM_QUESTION_FORCE_PAGE_BREAK',
  TASK_UPDATE_GLOBAL = 'TASK_UPDATE_GLOBAL',
  TASK_UPDATE_IMAGES = 'TASK_UPDATE_IMAGES',
  TASK_UPDATE_INVOICES = 'TASK_UPDATE_INVOICES',
  TASK_UPDATE_LOCATION = 'TASK_UPDATE_LOCATION',
  TASK_UPDATE_LOG = 'TASK_UPDATE_LOG',
  TASK_UPDATE_QUALIFICATIONS = 'TASK_UPDATE_QUALIFICATIONS',
  TASK_UPDATE_QUESTIONS = 'TASK_UPDATE_QUESTIONS',
  TASK_UPDATE_REMINDER_DATE = 'TASK_UPDATE_REMINDER_DATE',
  TASK_UPDATE_REPORTS = 'TASK_UPDATE_REPORTS',
  TASK_UPDATE_ROOM_PLAN = 'TASK_UPDATE_ROOM_PLAN',
  TASK_UPDATE_STATISTICS = 'TASK_UPDATE_STATISTICS',
  TASK_UPDATE_SUBTASKS = 'TASK_UPDATE_SUBTASKS',
  TRANSCRIBE_GENERATE_CREDENTIALS = 'TRANSCRIBE_GENERATE_CREDENTIALS',
  USER_CREATE = 'USER_CREATE',
  USER_CREATE_API_TOKEN = 'USER_CREATE_API_TOKEN',
  USER_DELETE_API_TOKEN = 'USER_DELETE_API_TOKEN',
  USER_INDEX = 'USER_INDEX',
  USER_INDEX_API_TOKENS = 'USER_INDEX_API_TOKENS',
  USER_LOGOUT_OTHER_USER = 'USER_LOGOUT_OTHER_USER',
  USER_READ = 'USER_READ',
  USER_READ_ME = 'USER_READ_ME',
  USER_READ_MY_API_TOKENS = 'USER_READ_MY_API_TOKENS',
  USER_SEND_QRCODE = 'USER_SEND_QRCODE',
  USER_SEND_VERIFICATION_LINK = 'USER_SEND_VERIFICATION_LINK',
  USER_UPDATE = 'USER_UPDATE',
  USER_UPDATE_PASSWORD = 'USER_UPDATE_PASSWORD',
  USER_UPDATE_PASSWORD_SELF = 'USER_UPDATE_PASSWORD_SELF',
  USER_UPDATE_PROFILE_IMAGE = 'USER_UPDATE_PROFILE_IMAGE',
}

export enum Platform {
  ANDROID = 'ANDROID',
  IOS = 'IOS',
  WEB = 'WEB',
}

export type Point = {
  __typename?: 'Point';
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type PointInput = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type PolicyHolderCommonInput = {
  email?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  salutation_id: Scalars['ID'];
};

export type PolicyHolderLocationInput = {
  city: Scalars['String'];
  country_id: Scalars['ID'];
  postcode: Scalars['String'];
  street: Scalars['String'];
  street_no: Scalars['String'];
};

export enum PolicyHolderType {
  BUSINESS = 'BUSINESS',
  INDUSTRY = 'INDUSTRY',
}

export type PostCodeAutocomplete = {
  __typename?: 'PostCodeAutocomplete';
  city: Scalars['String'];
  success: Scalars['Boolean'];
};

export type PreviewTaskCalculationReportResponse = {
  __typename?: 'PreviewTaskCalculationReportResponse';
  download_url: Scalars['String'];
  preview_url: Scalars['String'];
};

export type PreviewTaskReportInput = {
  custom_report_settings?: InputMaybe<TaskCustomReportSettingInput>;
  report_template_id?: InputMaybe<Scalars['ID']>;
};

export type PreviewTaskReportResponse = {
  __typename?: 'PreviewTaskReportResponse';
  preview_title: Scalars['String'];
  preview_url: Scalars['String'];
};

export type PushNotificationsSettingsInput = {
  activate_push_notifications: Scalars['Boolean'];
  push_notifications_config: Scalars['String'];
};

export type Qualification = {
  __typename?: 'Qualification';
  color: Scalars['String'];
  id: Scalars['ID'];
  title: Scalars['String'];
};

export type QualificationInput = {
  color: Scalars['String'];
  title: Scalars['String'];
};

export type QualificationPaginator = {
  __typename?: 'QualificationPaginator';
  data: Array<Qualification>;
  paginatorInfo: PaginatorInfo;
};

export type QualificationsFilter = {
  AND?: InputMaybe<Array<QualificationsFilter>>;
  OR?: InputMaybe<Array<QualificationsFilter>>;
  column?: InputMaybe<QualificationsSortAndFilterColumns>;
  operator?: InputMaybe<SQLOperator>;
  value?: InputMaybe<Scalars['Mixed']>;
};

export type QualificationsForClientList = {
  __typename?: 'QualificationsForClientList';
  data: Array<Qualification>;
};

export type QualificationsSort = {
  columns?: InputMaybe<Array<QualificationsSortOrder>>;
};

export enum QualificationsSortAndFilterColumns {
  ID = 'ID',
  TITLE = 'TITLE',
}

export type QualificationsSortOrder = {
  column: QualificationsSortAndFilterColumns;
  order: SortOrder;
};

/** Global Query type to be extended in other graphql schema files */
export type Query = {
  __typename?: 'Query';
  activity: ActivityPaginator;
  addressBookEntries?: Maybe<Array<Maybe<AddressBookEntry>>>;
  addressBookEntry?: Maybe<AddressBookEntry>;
  adjusters: AdjusterList;
  adjustersForAssignment?: Maybe<Array<AdjusterForAssignment>>;
  adjustersForTaskFilter: AdjusterList;
  adjustersWithDetails: AdjusterWithDetailsPaginator;
  adjustersWithDistanceToLocation: AdjusterWithDetailsPaginator;
  allTasksPerState: Array<TasksPerState>;
  apiTokens: ApiTokenList;
  appAllowlistIpAddresses: Array<AppAllowlistIpAddress>;
  appEmailTransports: Array<AppEmailTransport>;
  appMessages: Array<Maybe<AppMessage>>;
  appModules: Array<AppModule>;
  appSettings: AppSettings;
  appSettingsPublic: AppSettingsPublic;
  appStamps: AppStampPaginator;
  areaAssignment?: Maybe<AreaAssignment>;
  areaAssignments: AreaAssignmentPaginator;
  areaAssignmentsGeoJSON: MapGeoJSON;
  areaAssignmentsMap: MapGeoJSON;
  articleUnits?: Maybe<Array<ArticleUnit>>;
  articles: ArticlePaginator;
  articlesForClient: Array<Article>;
  articles_list: Array<Article>;
  autocomplete_city: PostCodeAutocomplete;
  calendarEventDrivingTimes: CalendarDrivingPaginator;
  calendarEventTypes: Array<CalendarEventType>;
  calendarEvents: CalendarEventPaginator;
  chatChannel: ChatChannel;
  chatChannelTypes: Array<ChatChannelType>;
  chatChannels: ChatChannelList;
  chatMemberTypes: Array<ChatMemberType>;
  chatMessageTypes: Array<ChatMessageType>;
  checkIfIdentifierExists: Scalars['Boolean'];
  classificationTags: ClassificationTagsPaginator;
  client?: Maybe<Client>;
  clientAbsences: ClientAbsencesPaginator;
  clientByEmail?: Maybe<Client>;
  clientCustomerStates: Array<ClientCustomerState>;
  clientEmailNotificationSettings: CombinedNotificationSettingsList;
  clientEmailNotificationTypes: Array<NotificationType>;
  clientIntros: ClientIntroList;
  clientLocation?: Maybe<ClientLocation>;
  clientLocationTypes: Array<ClientLocationType>;
  clientLocations: ClientLocationPaginator;
  clientStates: Array<ClientState>;
  clientTypes: Array<ClientType>;
  clientTypesForFilter: Array<ClientType>;
  clients: ClientPaginator;
  clientsForSubTaskAssignee: ClientsForSubTaskAssigneeList;
  clientsForSubTaskController: ClientsForSubTaskControllerList;
  clientsForTask: ClientList;
  clientsLogin: ClientsLoginPaginator;
  completedTasksForDashboard: DashboardTasksByState;
  contact?: Maybe<Contact>;
  contactFunctions: Array<ContactFunction>;
  contactRoles: Array<ContactRole>;
  contacts: ContactPaginator;
  countries: Array<Country>;
  countries_dummy: Array<Country>;
  createdTasksForDashboard: DashboardTasksByState;
  currencies?: Maybe<Array<Currency>>;
  customerPortalEvent?: Maybe<CustomerPortalEvent>;
  customerPortalEventForCustomer?: Maybe<CustomerPortalEvent>;
  customerPortalEventLocation?: Maybe<CustomerPortalEventLocation>;
  customerPortalEventTypes: Array<CustomerPortalEventType>;
  defaultClassificationTags: ClassificationTagsPaginator;
  defaultText: DefaultEMailText;
  defaultTextForTask: DefaultEmail;
  deviceCategories: Array<Maybe<DeviceCategory>>;
  deviceStates: Array<DeviceState>;
  devices: DevicePaginator;
  emailTemplate?: Maybe<EmailTemplate>;
  emailTemplateCategories: Array<EmailTemplateCategory>;
  emailTemplateContactTags: Array<TaskTag>;
  emailTemplateKeys: Array<EmailTemplateKey>;
  emailTemplates: EmailTemplatePaginator;
  emailTemplatesAll: Array<EmailTemplate>;
  emails: EmailPaginator;
  exportTasksAvailableColumns: TasksExportColumns;
  /** EXPORT METHODS */
  exportTasksConfigs: ExportConfigList;
  fileClassificationTags: FileClassificationTagsPaginator;
  fix_paginator_info_only_when_used: CountryPaginator;
  geolocate?: Maybe<Geolocate>;
  insurances: InsuranceList;
  interfaceErrorsForDashboard?: Maybe<Array<InterfaceError>>;
  interfaceMessageTemplate?: Maybe<InterfaceMessageTemplate>;
  interfaceMessageTemplateCategories: Array<InterfaceMessageTemplateCategory>;
  interfaceMessageTemplates: InterfaceMessageTemplatePaginator;
  invoicePayouts: InvoicePayoutsPaginator;
  invoices: InvoicePaginator;
  invoicesForClient: InvoicePaginator;
  leaders: LeaderList;
  me?: Maybe<User>;
  myApiTokens: ApiTokenList;
  notAcceptedTasksForDashboard: NotAcceptedCounts;
  notificationTypes: Array<NotificationType>;
  notifications?: Maybe<Array<Notification>>;
  numSubTasksOpen: NumSubTasksOpenResponse;
  numberOfTasks: Array<TaskCount>;
  payouts?: Maybe<Array<Payout>>;
  pdfTemplates: PdfTemplatePaginator;
  pdfTemplatesForTask: PdfTemplatePaginator;
  pdfTemplatesWithCustom: PdfTemplatePaginator;
  policyHolder?: Maybe<Client>;
  policyHolderLocation?: Maybe<ClientLocation>;
  policyHolderLocations: ClientLocationPaginator;
  policyHolders: ClientPaginator;
  previewTaskIdentifier: previewIdentifier;
  qualifications: QualificationPaginator;
  qualificationsForClient: QualificationsForClientList;
  reportLayoutsWithFilter: ReportLayoutPaginator;
  reportTemplateFilenameTags: Array<TaskTag>;
  reportTemplates: Array<ReportTemplate>;
  salutations: Array<Salutation>;
  serviceCatalogCategories: ServiceCatalogCategoryList;
  serviceCatalogItems: ServiceCatalogItemPaginator;
  serviceCatalogPostcodeFactorItems: ServiceCatalogPostcodeFactorPaginator;
  service_catalog_units?: Maybe<Array<ServiceCatalogUnit>>;
  service_catalogs?: Maybe<Array<ServiceCatalog>>;
  spellCheck: Array<SpellCheckResult>;
  sync_data: Response;
  tagTypes: Array<TagType>;
  tags: TagPaginator;
  task: Task;
  taskArticles: TaskArticlesPaginator;
  taskCalculationCategories?: Maybe<Array<Maybe<TaskCalculationCategory>>>;
  taskCalculationItemPayoutStates?: Maybe<Array<TaskCalculationItemPayoutState>>;
  taskCalculationItemStateList: TaskCalculationItemStatePaginator;
  taskCalculationItemStates?: Maybe<Array<TaskCalculationItemState>>;
  taskCalculationItems?: Maybe<Array<Maybe<TaskCalculationItem>>>;
  taskCalculationTemplatesForUser?: Maybe<Array<TaskCalculationTemplate>>;
  taskCalculations?: Maybe<Array<TaskCalculation>>;
  taskCustomReportSetting?: Maybe<TaskCustomReportSetting>;
  taskDeclineReasons: Array<TaskDeclineReason>;
  taskDocuments: TaskDocumentsPaginator;
  taskEmailContacts: TaskEmailContactsResponse;
  taskFiles: TaskFilesPaginator;
  taskFilesByFolderType: TaskFilesPaginator;
  taskFolderTemplate: TaskFolderTemplate;
  taskFolderTemplates: TaskFolderTemplatesPaginator;
  taskFolderTemplatesAll: TaskFolderTemplatesPaginator;
  taskFolders: Array<TaskFolder>;
  taskFormQuestions: TaskQuestionsResponse;
  taskFormTypes: Array<TaskFormType>;
  taskImages: TaskImagesPaginator;
  taskInvoicePayoutStates?: Maybe<Array<TaskInvoicePayoutState>>;
  taskInvoiceStates?: Maybe<Array<TaskInvoiceState>>;
  taskInvoices: TaskInvoicesPaginator;
  taskLogs?: Maybe<Array<TaskLog>>;
  taskPdfTemplates: Array<PdfTemplate>;
  taskQuestionHistory?: Maybe<Array<TaskQuestionHistory>>;
  taskQuestions: TaskQuestionsResponse;
  taskQuestionsForCustomer: TaskQuestionsResponse;
  taskReports: TaskReportPaginator;
  taskRoomCategories: Array<TaskRoomCategory>;
  taskRoomItems: Array<TaskRoomItem>;
  taskRoutes: TaskRoutePaginator;
  taskStates: Array<TaskState>;
  taskSubTaskStates?: Maybe<Array<TaskSubTaskState>>;
  taskSubTaskTemplates: TaskSubTaskTemplatePaginator;
  taskSubTasks: TaskSubTaskPaginator;
  taskSubTasksForDashboard: TaskSubTaskPaginator;
  taskTags: Array<TaskTag>;
  taskTagsResolve: TaskTagResolveResult;
  taskTagsResolveArray: TaskTagsResolveArrayResponse;
  taskTemplate?: Maybe<TaskTemplate>;
  taskTemplateClassifications: Array<TaskTemplateClassification>;
  taskTemplateTypes: Array<TaskTemplateType>;
  taskTemplates: TaskTemplatePaginator;
  taskTemplatesAll: Array<TaskTemplate>;
  taskTemplatesForClient: Array<TaskTemplate>;
  taskVisitTypes: Array<TaskVisitType>;
  tasks: TaskRowPaginator;
  tasksByOwnerClientId: Array<Task>;
  tasksForPrimaryFormSelect: TaskListResponse;
  tasksMap: TasksGeoJSON;
  tasksMapAdjusters: TasksMapAdjusterList;
  tasksMapLayers: TasksGeoJSONLayers;
  user?: Maybe<User>;
  users: UserPaginator;
  visibleMessages: Array<Maybe<AppMessage>>;
  workloadForDashboard: WorkloadCounts;
};

/** Global Query type to be extended in other graphql schema files */
export type QueryactivityArgs = {
  filter: ActivityFilter;
  pagination: PaginationInput;
  sort?: InputMaybe<ActivitySort>;
};

/** Global Query type to be extended in other graphql schema files */
export type QueryaddressBookEntryArgs = {
  id: Scalars['ID'];
};

/** Global Query type to be extended in other graphql schema files */
export type QueryadjustersArgs = {
  state_ids?: InputMaybe<Array<ClientStateId>>;
};

/** Global Query type to be extended in other graphql schema files */
export type QueryadjustersWithDetailsArgs = {
  client_id?: InputMaybe<Scalars['ID']>;
  target_claim_lat?: InputMaybe<Scalars['Float']>;
  target_claim_lng?: InputMaybe<Scalars['Float']>;
  task_visit_type?: InputMaybe<Scalars['ID']>;
};

/** Global Query type to be extended in other graphql schema files */
export type QueryadjustersWithDistanceToLocationArgs = {
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

/** Global Query type to be extended in other graphql schema files */
export type QueryareaAssignmentArgs = {
  id: Scalars['ID'];
};

/** Global Query type to be extended in other graphql schema files */
export type QueryareaAssignmentsArgs = {
  filter: AreaAssignmentsFilter;
  pagination: PaginationInput;
  sort: AreaAssignmentsSort;
};

/** Global Query type to be extended in other graphql schema files */
export type QueryareaAssignmentsMapArgs = {
  filter: AreaAssignmentsFilter;
};

/** Global Query type to be extended in other graphql schema files */
export type QueryarticlesArgs = {
  filter: ArticlesFilter;
  pagination: PaginationInput;
  sort?: InputMaybe<ArticlesSort>;
};

/** Global Query type to be extended in other graphql schema files */
export type QueryarticlesForClientArgs = {
  client_id: Scalars['ID'];
};

/** Global Query type to be extended in other graphql schema files */
export type Queryarticles_listArgs = {
  title: Scalars['String'];
};

/** Global Query type to be extended in other graphql schema files */
export type Queryautocomplete_cityArgs = {
  postcode: Scalars['String'];
};

/** Global Query type to be extended in other graphql schema files */
export type QuerycalendarEventDrivingTimesArgs = {
  filter: CalendarEventsFilter;
  pagination: PaginationInput;
  sort: CalendarEventsSort;
};

/** Global Query type to be extended in other graphql schema files */
export type QuerycalendarEventsArgs = {
  filter: CalendarEventsFilter;
  pagination: PaginationInput;
  sort: CalendarEventsSort;
  task_id?: InputMaybe<Scalars['ID']>;
};

/** Global Query type to be extended in other graphql schema files */
export type QuerychatChannelArgs = {
  channel_id: Scalars['ID'];
};

/** Global Query type to be extended in other graphql schema files */
export type QuerycheckIfIdentifierExistsArgs = {
  identifier: Scalars['String'];
};

/** Global Query type to be extended in other graphql schema files */
export type QueryclassificationTagsArgs = {
  filter: ClassificationTagsFilter;
  pagination: PaginationInput;
  sort?: InputMaybe<ClassificationTagsSort>;
};

/** Global Query type to be extended in other graphql schema files */
export type QueryclientArgs = {
  id: Scalars['ID'];
};

/** Global Query type to be extended in other graphql schema files */
export type QueryclientAbsencesArgs = {
  client_id: Scalars['ID'];
  filter: ClientAbsencesFilter;
  pagination: PaginationInput;
  sort: ClientAbsencesSort;
};

/** Global Query type to be extended in other graphql schema files */
export type QueryclientByEmailArgs = {
  email: Scalars['String'];
};

/** Global Query type to be extended in other graphql schema files */
export type QueryclientEmailNotificationSettingsArgs = {
  id: Scalars['ID'];
};

/** Global Query type to be extended in other graphql schema files */
export type QueryclientLocationArgs = {
  id: Scalars['ID'];
};

/** Global Query type to be extended in other graphql schema files */
export type QueryclientLocationsArgs = {
  client_id: Scalars['ID'];
  filter: ClientLocationsFilter;
  pagination: PaginationInput;
  sort: ClientLocationsSort;
};

/** Global Query type to be extended in other graphql schema files */
export type QueryclientsArgs = {
  filter: ClientsFilter;
  pagination: PaginationInput;
  sort: ClientsSort;
};

/** Global Query type to be extended in other graphql schema files */
export type QuerycompletedTasksForDashboardArgs = {
  leader_id?: InputMaybe<Scalars['ID']>;
};

/** Global Query type to be extended in other graphql schema files */
export type QuerycontactArgs = {
  id: Scalars['ID'];
};

/** Global Query type to be extended in other graphql schema files */
export type QuerycontactsArgs = {
  filter: ContactsFilter;
  pagination: PaginationInput;
  sort: ContactsSort;
};

/** Global Query type to be extended in other graphql schema files */
export type QuerycustomerPortalEventArgs = {
  hash: Scalars['String'];
};

/** Global Query type to be extended in other graphql schema files */
export type QuerycustomerPortalEventLocationArgs = {
  hash: Scalars['String'];
};

/** Global Query type to be extended in other graphql schema files */
export type QuerydefaultClassificationTagsArgs = {
  client_id: Scalars['ID'];
};

/** Global Query type to be extended in other graphql schema files */
export type QuerydefaultTextForTaskArgs = {
  id: Scalars['ID'];
};

/** Global Query type to be extended in other graphql schema files */
export type QuerydevicesArgs = {
  filter: DevicesFilter;
  pagination: PaginationInput;
  sort?: InputMaybe<DevicesSort>;
};

/** Global Query type to be extended in other graphql schema files */
export type QueryemailTemplateArgs = {
  id: Scalars['ID'];
};

/** Global Query type to be extended in other graphql schema files */
export type QueryemailTemplatesArgs = {
  filter: EmailTemplatesFilter;
  pagination: PaginationInput;
  sort: EmailTemplatesSort;
};

/** Global Query type to be extended in other graphql schema files */
export type QueryemailsArgs = {
  task_id: Scalars['ID'];
};

/** Global Query type to be extended in other graphql schema files */
export type QueryexportTasksAvailableColumnsArgs = {
  include_statistics?: Scalars['Boolean'];
};

/** Global Query type to be extended in other graphql schema files */
export type QueryfileClassificationTagsArgs = {
  filter: FileClassificationTagsFilter;
  pagination: PaginationInput;
  sort?: InputMaybe<FileClassificationTagsSort>;
};

/** Global Query type to be extended in other graphql schema files */
export type Queryfix_paginator_info_only_when_usedArgs = {
  first: Scalars['Int'];
  page?: InputMaybe<Scalars['Int']>;
};

/** Global Query type to be extended in other graphql schema files */
export type QuerygeolocateArgs = {
  city: Scalars['String'];
  country_id: Scalars['Int'];
  postcode: Scalars['String'];
  street: Scalars['String'];
};

/** Global Query type to be extended in other graphql schema files */
export type QueryinterfaceMessageTemplateArgs = {
  id: Scalars['ID'];
};

/** Global Query type to be extended in other graphql schema files */
export type QueryinterfaceMessageTemplatesArgs = {
  filter: InterfaceMessageTemplatesFilter;
  pagination: PaginationInput;
  sort: InterfaceMessageTemplatesSort;
};

/** Global Query type to be extended in other graphql schema files */
export type QueryinvoicePayoutsArgs = {
  filter: InvoicePayoutsFilter;
  pagination: PaginationInput;
  sort?: InputMaybe<InvoicePayoutsSort>;
};

/** Global Query type to be extended in other graphql schema files */
export type QueryinvoicesArgs = {
  filter: InvoiceFilter;
  pagination: PaginationInput;
  sort: InvoiceSort;
};

/** Global Query type to be extended in other graphql schema files */
export type QueryinvoicesForClientArgs = {
  client_id: Scalars['ID'];
  filter: InvoiceFilter;
  pagination: PaginationInput;
  sort: InvoiceSort;
};

/** Global Query type to be extended in other graphql schema files */
export type QuerynumSubTasksOpenArgs = {
  task_id: Scalars['ID'];
};

/** Global Query type to be extended in other graphql schema files */
export type QuerynumberOfTasksArgs = {
  from: Scalars['Date'];
  state_id: Scalars['ID'];
  to: Scalars['Date'];
};

/** Global Query type to be extended in other graphql schema files */
export type QuerypdfTemplatesArgs = {
  filter: PdfTemplatesFilter;
  pagination: PaginationInput;
  sort: PdfTemplatesSort;
};

/** Global Query type to be extended in other graphql schema files */
export type QuerypdfTemplatesForTaskArgs = {
  task_id: Scalars['ID'];
};

/** Global Query type to be extended in other graphql schema files */
export type QuerypdfTemplatesWithCustomArgs = {
  filter: PdfTemplatesFilter;
  pagination: PaginationInput;
  sort: PdfTemplatesSort;
};

/** Global Query type to be extended in other graphql schema files */
export type QuerypolicyHolderArgs = {
  id: Scalars['ID'];
};

/** Global Query type to be extended in other graphql schema files */
export type QuerypolicyHolderLocationArgs = {
  id: Scalars['ID'];
};

/** Global Query type to be extended in other graphql schema files */
export type QuerypolicyHolderLocationsArgs = {
  client_id: Scalars['ID'];
  filter: ClientLocationsFilter;
  pagination: PaginationInput;
  sort: ClientLocationsSort;
};

/** Global Query type to be extended in other graphql schema files */
export type QuerypolicyHoldersArgs = {
  filter: ClientsFilter;
  pagination: PaginationInput;
  sort: ClientsSort;
};

/** Global Query type to be extended in other graphql schema files */
export type QueryqualificationsArgs = {
  filter: QualificationsFilter;
  pagination: PaginationInput;
  sort: QualificationsSort;
};

/** Global Query type to be extended in other graphql schema files */
export type QueryreportLayoutsWithFilterArgs = {
  filter: ReportLayoutFilter;
  pagination: PaginationInput;
  sort: ReportLayoutSort;
};

/** Global Query type to be extended in other graphql schema files */
export type QueryserviceCatalogItemsArgs = {
  filter: ServiceCatalogItemsFilter;
  pagination: PaginationInput;
  sort: ServiceCatalogItemsSort;
};

/** Global Query type to be extended in other graphql schema files */
export type QueryserviceCatalogPostcodeFactorItemsArgs = {
  filter: ServiceCatalogPostcodeFactorFilter;
  pagination: PaginationInput;
  sort: ServiceCatalogPostcodeFactorSort;
};

/** Global Query type to be extended in other graphql schema files */
export type QueryspellCheckArgs = {
  locale: Scalars['String'];
  values: Array<Scalars['String']>;
};

/** Global Query type to be extended in other graphql schema files */
export type Querysync_dataArgs = {
  date_from?: InputMaybe<Scalars['DateTimeTz']>;
  date_to?: InputMaybe<Scalars['DateTimeTz']>;
  last_sync_date?: InputMaybe<Scalars['DateTime']>;
};

/** Global Query type to be extended in other graphql schema files */
export type QuerytagsArgs = {
  filter: TagsFilter;
  pagination: PaginationInput;
  sort: TagsSort;
};

/** Global Query type to be extended in other graphql schema files */
export type QuerytaskArgs = {
  id: Scalars['ID'];
};

/** Global Query type to be extended in other graphql schema files */
export type QuerytaskArticlesArgs = {
  task_id: Scalars['ID'];
};

/** Global Query type to be extended in other graphql schema files */
export type QuerytaskCalculationCategoriesArgs = {
  task_calculation_id: Scalars['ID'];
};

/** Global Query type to be extended in other graphql schema files */
export type QuerytaskCalculationItemStateListArgs = {
  filter: TaskCalculationItemStateFilter;
  pagination: PaginationInput;
  sort?: InputMaybe<TaskCalculationItemStateSort>;
};

/** Global Query type to be extended in other graphql schema files */
export type QuerytaskCalculationItemsArgs = {
  task_calculation_id: Scalars['ID'];
};

/** Global Query type to be extended in other graphql schema files */
export type QuerytaskCalculationsArgs = {
  task_id: Scalars['ID'];
};

/** Global Query type to be extended in other graphql schema files */
export type QuerytaskCustomReportSettingArgs = {
  task_id: Scalars['ID'];
};

/** Global Query type to be extended in other graphql schema files */
export type QuerytaskDocumentsArgs = {
  search?: InputMaybe<Scalars['String']>;
  task_id: Scalars['ID'];
};

/** Global Query type to be extended in other graphql schema files */
export type QuerytaskEmailContactsArgs = {
  task_id: Scalars['ID'];
};

/** Global Query type to be extended in other graphql schema files */
export type QuerytaskFilesArgs = {
  task_id: Scalars['ID'];
};

/** Global Query type to be extended in other graphql schema files */
export type QuerytaskFilesByFolderTypeArgs = {
  folder_type: TaskFolderType;
  task_id: Scalars['ID'];
};

/** Global Query type to be extended in other graphql schema files */
export type QuerytaskFolderTemplateArgs = {
  id: Scalars['ID'];
};

/** Global Query type to be extended in other graphql schema files */
export type QuerytaskFolderTemplatesArgs = {
  filter: TaskFolderTemplatesFilter;
  pagination: PaginationInput;
  sort: TaskFolderTemplatesSort;
};

/** Global Query type to be extended in other graphql schema files */
export type QuerytaskFoldersArgs = {
  task_id: Scalars['ID'];
};

/** Global Query type to be extended in other graphql schema files */
export type QuerytaskFormQuestionsArgs = {
  task_form_id: Scalars['ID'];
};

/** Global Query type to be extended in other graphql schema files */
export type QuerytaskImagesArgs = {
  search?: InputMaybe<Scalars['String']>;
  task_id: Scalars['ID'];
};

/** Global Query type to be extended in other graphql schema files */
export type QuerytaskInvoicesArgs = {
  task_id: Scalars['ID'];
};

/** Global Query type to be extended in other graphql schema files */
export type QuerytaskLogsArgs = {
  task_id: Scalars['ID'];
};

/** Global Query type to be extended in other graphql schema files */
export type QuerytaskPdfTemplatesArgs = {
  task_id: Scalars['ID'];
};

/** Global Query type to be extended in other graphql schema files */
export type QuerytaskQuestionHistoryArgs = {
  id: Scalars['ID'];
};

/** Global Query type to be extended in other graphql schema files */
export type QuerytaskQuestionsArgs = {
  task_id: Scalars['ID'];
};

/** Global Query type to be extended in other graphql schema files */
export type QuerytaskQuestionsForCustomerArgs = {
  task_id: Scalars['ID'];
};

/** Global Query type to be extended in other graphql schema files */
export type QuerytaskReportsArgs = {
  task_id: Scalars['ID'];
};

/** Global Query type to be extended in other graphql schema files */
export type QuerytaskRoomCategoriesArgs = {
  task_id: Scalars['ID'];
};

/** Global Query type to be extended in other graphql schema files */
export type QuerytaskRoomItemsArgs = {
  task_id: Scalars['ID'];
};

/** Global Query type to be extended in other graphql schema files */
export type QuerytaskSubTaskTemplatesArgs = {
  filter: TaskSubTaskTemplateFilter;
  pagination: PaginationInput;
  sort?: InputMaybe<TaskSubTaskTemplateSort>;
};

/** Global Query type to be extended in other graphql schema files */
export type QuerytaskSubTasksArgs = {
  filter: TaskSubTaskFilter;
  pagination: PaginationInput;
  sort?: InputMaybe<TaskSubTaskSort>;
  task_id?: InputMaybe<Scalars['ID']>;
};

/** Global Query type to be extended in other graphql schema files */
export type QuerytaskSubTasksForDashboardArgs = {
  filter: TaskSubTaskFilter;
  pagination: PaginationInput;
  sort?: InputMaybe<TaskSubTaskSort>;
};

/** Global Query type to be extended in other graphql schema files */
export type QuerytaskTagsResolveArgs = {
  task_id: Scalars['ID'];
  text: Scalars['String'];
};

/** Global Query type to be extended in other graphql schema files */
export type QuerytaskTagsResolveArrayArgs = {
  tags: Array<Scalars['String']>;
  task_id: Scalars['ID'];
};

/** Global Query type to be extended in other graphql schema files */
export type QuerytaskTemplateArgs = {
  id: Scalars['ID'];
};

/** Global Query type to be extended in other graphql schema files */
export type QuerytaskTemplatesArgs = {
  filter: TaskTemplatesFilter;
  pagination: PaginationInput;
  sort: TaskTemplatesSort;
};

/** Global Query type to be extended in other graphql schema files */
export type QuerytaskTemplatesForClientArgs = {
  client_id: Scalars['ID'];
};

/** Global Query type to be extended in other graphql schema files */
export type QuerytasksArgs = {
  filter: TasksFilter;
  pagination: PaginationInput;
  sort: TasksSort;
};

/** Global Query type to be extended in other graphql schema files */
export type QuerytasksByOwnerClientIdArgs = {
  owner_client_id: Scalars['ID'];
};

/** Global Query type to be extended in other graphql schema files */
export type QuerytasksForPrimaryFormSelectArgs = {
  task_id: Scalars['ID'];
};

/** Global Query type to be extended in other graphql schema files */
export type QuerytasksMapArgs = {
  filter: TasksFilter;
  sort: TasksSort;
};

/** Global Query type to be extended in other graphql schema files */
export type QueryuserArgs = {
  id: Scalars['ID'];
};

/** Global Query type to be extended in other graphql schema files */
export type QueryusersArgs = {
  filter: UsersFilter;
  pagination: PaginationInput;
  sort: UsersSort;
};

export enum QuestionLayout {
  COMPACT = 'COMPACT',
  DEFAULT = 'DEFAULT',
}

export type RefreshTokenInput = {
  refresh_token: Scalars['String'];
};

export type RefreshTokenResponse = {
  __typename?: 'RefreshTokenResponse';
  authState: AuthState;
  message: Scalars['String'];
};

export type ReportLayout = {
  __typename?: 'ReportLayout';
  bg_dark_color: Scalars['String'];
  calculation_layout: CalculationLayout;
  cover_layout: CoverLayout;
  cover_title: Scalars['String'];
  default: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  filename: Scalars['String'];
  footer: Scalars['String'];
  footer_paginate_title: Scalars['String'];
  hide_primary_form: Scalars['Boolean'];
  highlight_color: Scalars['String'];
  id: Scalars['ID'];
  images_layout_1: ImagesLayout1;
  images_layout_2: ImagesLayout2;
  images_layout_4: ImagesLayout4;
  is_customer_report: Scalars['Boolean'];
  order_identifiers: Array<PageType>;
  question_filter: ReportQuestionFilterType;
  questions_layout: QuestionLayout;
  room_plan_layout: RoomPlanLayout;
  show_notes: Scalars['Boolean'];
  summary_layout: SummaryLayout;
  tint_color: Scalars['String'];
  title: Scalars['String'];
  toc_layout: TOCLayout;
  use_alternate_logo: Scalars['Boolean'];
};

export type ReportLayoutFilter = {
  AND?: InputMaybe<Array<ReportLayoutFilter>>;
  OR?: InputMaybe<Array<ReportLayoutFilter>>;
  column?: InputMaybe<ReportLayoutFilterAndSortColumns>;
  operator?: InputMaybe<SQLOperator>;
  value?: InputMaybe<Scalars['Mixed']>;
};

export enum ReportLayoutFilterAndSortColumns {
  DESCRIPTION = 'DESCRIPTION',
  ID = 'ID',
  TITLE = 'TITLE',
}

export type ReportLayoutPaginator = {
  __typename?: 'ReportLayoutPaginator';
  data: Array<ReportLayout>;
  paginatorInfo: PaginatorInfo;
};

export type ReportLayoutSort = {
  columns?: InputMaybe<Array<ReportLayoutSortOrder>>;
};

export type ReportLayoutSortOrder = {
  column: ReportLayoutFilterAndSortColumns;
  order: SortOrder;
};

export enum ReportQuestionFilterType {
  SHOW_ALL = 'SHOW_ALL',
  SHOW_EXTERNAL_ONLY = 'SHOW_EXTERNAL_ONLY',
  SHOW_INTERNAL_ONLY = 'SHOW_INTERNAL_ONLY',
}

export type ReportTemplate = {
  __typename?: 'ReportTemplate';
  default: Scalars['Boolean'];
  description: Scalars['String'];
  id: Scalars['ID'];
  title: Scalars['String'];
};

export type ResetPasswordInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  password_confirmation: Scalars['String'];
  token: Scalars['String'];
};

export type ResetTaskFormQuestionInput = {
  history_id: Scalars['ID'];
  question_id: Scalars['String'];
};

export type Response = {
  __typename?: 'Response';
  enableLocalReport: Scalars['Boolean'];
  lastSyncDate: Scalars['DateTime'];
  pdf_templates?: Maybe<Array<PdfTemplate>>;
  serviceCatalog?: Maybe<Array<ServiceCatalogCategory>>;
  task_files?: Maybe<Array<TaskFileGroup>>;
  tasks?: Maybe<Array<Task>>;
  templates?: Maybe<Array<TaskTemplate>>;
};

export type ResponseMessage = {
  __typename?: 'ResponseMessage';
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type Room = {
  __typename?: 'Room';
  unique_name: Scalars['String'];
  url: Scalars['String'];
};

export enum RoomPlanLayout {
  DEFAULT = 'DEFAULT',
}

/** The available SQL operators that are used to filter query results. */
export enum SQLOperator {
  /** Whether a value is within a range of values (`BETWEEN`) */
  BETWEEN = 'BETWEEN',
  /** Equal operator (`=`) */
  EQ = 'EQ',
  /** Greater than operator (`>`) */
  GT = 'GT',
  /** Greater than or equal operator (`>=`) */
  GTE = 'GTE',
  /** Whether a value is within a set of values (`IN`) */
  IN = 'IN',
  /** Whether a value is not null (`IS NOT NULL`) */
  IS_NOT_NULL = 'IS_NOT_NULL',
  /** Whether a value is null (`IS NULL`) */
  IS_NULL = 'IS_NULL',
  /** Simple pattern matching (`LIKE`) */
  LIKE = 'LIKE',
  /** Less than operator (`<`) */
  LT = 'LT',
  /** Less than or equal operator (`<=`) */
  LTE = 'LTE',
  /** Not equal operator (`!=`) */
  NEQ = 'NEQ',
  /** Whether a value is not within a range of values (`NOT BETWEEN`) */
  NOT_BETWEEN = 'NOT_BETWEEN',
  /** Whether a value is not within a set of values (`NOT IN`) */
  NOT_IN = 'NOT_IN',
  /** Negation of simple pattern matching (`NOT LIKE`) */
  NOT_LIKE = 'NOT_LIKE',
}

export type Salutation = {
  __typename?: 'Salutation';
  id: Scalars['ID'];
  title: Scalars['String'];
};

export type ServiceCatalog = {
  __typename?: 'ServiceCatalog';
  categories: Array<ServiceCatalogCategory>;
  id: Scalars['ID'];
  items: Array<ServiceCatalogItem>;
  title: Scalars['String'];
};

export type ServiceCatalogCategory = {
  __typename?: 'ServiceCatalogCategory';
  catalog: ServiceCatalog;
  catalog_id: Scalars['ID'];
  created_at: Scalars['DateTimeTz'];
  id: Scalars['ID'];
  items: Array<ServiceCatalogItem>;
  title: Scalars['String'];
  updated_at: Scalars['DateTimeTz'];
};

export type ServiceCatalogCategoryList = {
  __typename?: 'ServiceCatalogCategoryList';
  data: Array<ServiceCatalogCategory>;
};

export type ServiceCatalogInput = {
  title: Scalars['String'];
};

export type ServiceCatalogItem = {
  __typename?: 'ServiceCatalogItem';
  catalog: ServiceCatalog;
  catalog_id: Scalars['ID'];
  category: ServiceCatalogCategory;
  category_id: Scalars['ID'];
  created_at: Scalars['DateTimeTz'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  identifier: Scalars['String'];
  price?: Maybe<Scalars['Float']>;
  title: Scalars['String'];
  unit: ServiceCatalogUnit;
  unit_id: Scalars['ID'];
  updated_at: Scalars['DateTimeTz'];
};

export type ServiceCatalogItemPaginator = {
  __typename?: 'ServiceCatalogItemPaginator';
  data: Array<ServiceCatalogItem>;
  paginatorInfo: PaginatorInfo;
};

export type ServiceCatalogItemsFilter = {
  AND?: InputMaybe<Array<ServiceCatalogItemsFilter>>;
  OR?: InputMaybe<Array<ServiceCatalogItemsFilter>>;
  column?: InputMaybe<ServiceCatalogItemsSortAndFilterColumns>;
  operator?: InputMaybe<SQLOperator>;
  value?: InputMaybe<Scalars['Mixed']>;
};

export type ServiceCatalogItemsSort = {
  columns?: InputMaybe<Array<ServiceCatalogItemsSortOrder>>;
};

export enum ServiceCatalogItemsSortAndFilterColumns {
  CATALOG = 'CATALOG',
  CATEGORY = 'CATEGORY',
  CATEGORY_TITLE = 'CATEGORY_TITLE',
  DESCRIPTION = 'DESCRIPTION',
  ID = 'ID',
  TITLE = 'TITLE',
  UNIT = 'UNIT',
}

export type ServiceCatalogItemsSortOrder = {
  column: ServiceCatalogItemsSortAndFilterColumns;
  order: SortOrder;
};

export type ServiceCatalogPostcodeFactor = {
  __typename?: 'ServiceCatalogPostcodeFactor';
  city: Scalars['String'];
  factor: Scalars['Float'];
  id: Scalars['ID'];
  postcode: Scalars['String'];
};

export type ServiceCatalogPostcodeFactorFilter = {
  AND?: InputMaybe<Array<ServiceCatalogPostcodeFactorFilter>>;
  OR?: InputMaybe<Array<ServiceCatalogPostcodeFactorFilter>>;
  column?: InputMaybe<ServiceCatalogPostcodeFactorSortAndFilterColumns>;
  operator?: InputMaybe<SQLOperator>;
  value?: InputMaybe<Scalars['Mixed']>;
};

export type ServiceCatalogPostcodeFactorPaginator = {
  __typename?: 'ServiceCatalogPostcodeFactorPaginator';
  data: Array<ServiceCatalogPostcodeFactor>;
  paginatorInfo: PaginatorInfo;
};

export type ServiceCatalogPostcodeFactorSort = {
  columns?: InputMaybe<Array<ServiceCatalogPostcodeFactorSortOrder>>;
};

export enum ServiceCatalogPostcodeFactorSortAndFilterColumns {
  CITY = 'CITY',
  FACTOR = 'FACTOR',
  ID = 'ID',
  POSTCODE = 'POSTCODE',
}

export type ServiceCatalogPostcodeFactorSortOrder = {
  column: ServiceCatalogPostcodeFactorSortAndFilterColumns;
  order: SortOrder;
};

export type ServiceCatalogUnit = {
  __typename?: 'ServiceCatalogUnit';
  id: Scalars['ID'];
  title: Scalars['String'];
};

/** Directions for ordering a list of records. */
export enum SortOrder {
  /** Sort records in ascending order. */
  ASC = 'ASC',
  /** Sort records in descending order. */
  DESC = 'DESC',
}

export type SpellCheckResult = {
  __typename?: 'SpellCheckResult';
  misspellings: Array<Misspelling>;
  value: Scalars['String'];
};

export type StaticMapData = {
  description: Scalars['String'];
  northEastLatitude: Scalars['Float'];
  northEastLongitude: Scalars['Float'];
  overlay?: InputMaybe<Scalars['JSON']>;
  southWestLatitude: Scalars['Float'];
  southWestLongitude: Scalars['Float'];
  style?: InputMaybe<MapboxStyle>;
  task_id: Scalars['ID'];
};

export type Subscription = {
  __typename?: 'Subscription';
  chatChannelCreated?: Maybe<ChatChannel>;
  chatChannelMembersChanged?: Maybe<ChatMemberList>;
  chatChannelMessagesChanged?: Maybe<ChatMessageList>;
  chatChannelsChanged?: Maybe<ChatChannelList>;
  chatMessageDeleted?: Maybe<ChatMessage>;
  chatMessageUpdated?: Maybe<ChatMessage>;
  downloadCreated?: Maybe<Download>;
  downloadGenerated?: Maybe<Download>;
  fileChanged?: Maybe<FileItem>;
  taskReportGenerated?: Maybe<TaskReportGeneration>;
};

export type SubscriptionchatChannelCreatedArgs = {
  client_id: Scalars['ID'];
};

export type SubscriptionchatChannelMembersChangedArgs = {
  channel_id: Scalars['ID'];
};

export type SubscriptionchatChannelMessagesChangedArgs = {
  channel_id: Scalars['ID'];
};

export type SubscriptionchatMessageDeletedArgs = {
  channel_id: Scalars['ID'];
};

export type SubscriptionchatMessageUpdatedArgs = {
  channel_id: Scalars['ID'];
};

export type SubscriptionfileChangedArgs = {
  file_ids: Array<Scalars['ID']>;
};

export enum SummaryLayout {
  DEFAULT = 'DEFAULT',
}

export type SyncTaskCalculationCategoriesOptionsInput = {
  mode: TaskCalculationCategorySyncMode;
};

export type SyncTaskCalculationCategoryInput = {
  sort_index?: InputMaybe<Scalars['Int']>;
  title: Scalars['String'];
  uuid: Scalars['ID'];
};

export type SyncTaskCalculationItemInput = {
  accepted?: InputMaybe<Scalars['Float']>;
  age?: InputMaybe<Scalars['Int']>;
  amount?: InputMaybe<Scalars['Float']>;
  category_uuid: Scalars['ID'];
  correction_condition?: InputMaybe<Scalars['Float']>;
  description?: InputMaybe<Scalars['String']>;
  max_usage_years?: InputMaybe<Scalars['Int']>;
  own_contribution?: InputMaybe<Scalars['Boolean']>;
  payout_state_id?: InputMaybe<Scalars['ID']>;
  single_price?: InputMaybe<Scalars['Float']>;
  sort_index?: InputMaybe<Scalars['Int']>;
  state_id?: InputMaybe<Scalars['ID']>;
  tax?: InputMaybe<Scalars['Float']>;
  time_value?: InputMaybe<Scalars['Float']>;
  title?: InputMaybe<Scalars['String']>;
  unit_id?: InputMaybe<Scalars['ID']>;
  uuid: Scalars['ID'];
};

export type SyncTaskCalculationItemsOptionsInput = {
  mode: TaskCalculationItemSyncMode;
};

export type SyncTaskCalculationOptionsInput = {
  categories: SyncTaskCalculationCategoriesOptionsInput;
  items: SyncTaskCalculationItemsOptionsInput;
};

export type SyncTaskFormInput = {
  questions?: InputMaybe<Array<TaskFormQuestionInput>>;
  rows?: InputMaybe<Array<TaskFormQuestionTableRowInput>>;
  task_form_id: Scalars['ID'];
};

export type SyncTaskFormQuestionsOptionsInput = {
  mode: TaskQuestionSyncMode;
};

export type SyncTaskFormRowsOptionsInput = {
  mode: TaskQuestionTableRowSyncMode;
};

export type SyncTaskFormsOptionsInput = {
  questions: SyncTaskFormQuestionsOptionsInput;
  rows: SyncTaskFormRowsOptionsInput;
};

export type SyncTaskRoomCategoriesOptionsInput = {
  mode: TaskRoomCategorySyncMode;
};

export type SyncTaskRoomItemsOptionsInput = {
  mode: TaskRoomItemSyncMode;
};

export type SyncTaskRoomOptionsInput = {
  categories: SyncTaskRoomCategoriesOptionsInput;
  items: SyncTaskRoomItemsOptionsInput;
};

export enum TOCLayout {
  DEFAULT = 'DEFAULT',
}

export type Tag = {
  __typename?: 'Tag';
  color: Scalars['String'];
  id: Scalars['ID'];
  title: Scalars['String'];
  type: TagType;
  type_id: Scalars['ID'];
};

export type TagInput = {
  color: Scalars['String'];
  title: Scalars['String'];
};

export type TagPaginator = {
  __typename?: 'TagPaginator';
  data: Array<Tag>;
  paginatorInfo: PaginatorInfo;
};

export type TagType = {
  __typename?: 'TagType';
  color: Scalars['String'];
  id: Scalars['ID'];
  title: Scalars['String'];
};

export type TagsFilter = {
  AND?: InputMaybe<Array<TagsFilter>>;
  OR?: InputMaybe<Array<TagsFilter>>;
  column?: InputMaybe<TagsSortAndFilterColumns>;
  operator?: InputMaybe<SQLOperator>;
  value?: InputMaybe<Scalars['Mixed']>;
};

export type TagsSort = {
  columns?: InputMaybe<Array<TagsSortOrder>>;
};

export enum TagsSortAndFilterColumns {
  ID = 'ID',
  TITLE = 'TITLE',
  TYPE_ID = 'TYPE_ID',
}

export type TagsSortOrder = {
  column: TagsSortAndFilterColumns;
  order: SortOrder;
};

export type Task = {
  __typename?: 'Task';
  accepted_date?: Maybe<Scalars['DateTimeTz']>;
  allowed_clients_for_sub_task_assignment: TaskSubTaskAllowedClientsForAssignment;
  appointment?: Maybe<TaskEvent>;
  /** ID From Article, its not mandatory */
  article?: Maybe<Article>;
  /** Date of Assignment */
  article_id?: Maybe<Scalars['ID']>;
  /** Date of Damage */
  assigned_date?: Maybe<Scalars['DateTimeTz']>;
  /** Customer for Claim */
  claimant?: Maybe<Contact>;
  /** Forms of task */
  classification_tags: TaskClassificationTagList;
  /** ID from Client */
  client?: Maybe<Client>;
  /** ID from ClientContact (from its Location) */
  client_contact?: Maybe<Contact>;
  client_contact_id?: Maybe<Scalars['ID']>;
  /** filled automatically from system as identifier */
  client_id?: Maybe<Scalars['ID']>;
  /** ID from ClientLocation */
  client_location?: Maybe<ClientLocation>;
  /** Insurance */
  client_location_id?: Maybe<Scalars['ID']>;
  completion_date?: Maybe<Scalars['DateTimeTz']>;
  /** Article */
  contacts: Array<Contact>;
  /** Date for Follow Up */
  controlling_date?: Maybe<Scalars['DateTimeTz']>;
  created_at: Scalars['DateTimeTz'];
  /** Contacts belong to Claim */
  customer?: Maybe<Contact>;
  /** TaskInvoice */
  customer_portal_events?: Maybe<Array<CustomerPortalEvent>>;
  /** Numeric Value for Reserve */
  damage_date?: Maybe<Scalars['DateTimeTz']>;
  damage_note?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  /** Date state has changed last time */
  drive_log_distance?: Maybe<Scalars['Float']>;
  drive_log_note?: Maybe<Scalars['String']>;
  /** Damage Number */
  external_number?: Maybe<Scalars['String']>;
  /** Folders of task */
  files: Array<FileItem>;
  final_mileage?: Maybe<Scalars['Float']>;
  /** Locations belong to Claim */
  folders: Array<TaskFolder>;
  follow_up_date?: Maybe<Scalars['DateTimeTz']>;
  /** Files of task */
  forms: Array<TaskForm>;
  /** Primary location */
  geoData?: Maybe<TaskGeoData>;
  id: Scalars['ID'];
  identifier?: Maybe<Scalars['String']>;
  info_counts: TaskInfoCountList;
  /** Notifications for a Task */
  info_file?: Maybe<FileItem>;
  info_file_id?: Maybe<Scalars['Int']>;
  initial_mileage?: Maybe<Scalars['Float']>;
  /** Date for Follow Up */
  invoice_date?: Maybe<Scalars['DateTimeTz']>;
  /** TaskEvent */
  invoices?: Maybe<Array<TaskInvoice>>;
  /** Loss Adjuster for Claim */
  leader?: Maybe<Client>;
  locations: Array<TaskLocation>;
  /** task lock state */
  lock_state: TaskLockState;
  /** State of task */
  lock_state_id: TaskLockStateId;
  magic_plan_id?: Maybe<Scalars['String']>;
  members: Array<TaskMember>;
  notifications?: Maybe<Array<TaskNotification>>;
  open_subtasks_color: OpenSubTaskStateColor;
  /** Permissions for a Task */
  open_subtasks_count: Scalars['Int'];
  /** Claimant for Claim */
  owner?: Maybe<Client>;
  /** Policy Number */
  parallel_number?: Maybe<Scalars['String']>;
  /** If there are any Customer Portal Events (Video or Portal Link send to client) */
  permissions: Array<Permission>;
  /** Another Identifier that can be defined */
  policy_number?: Maybe<Scalars['String']>;
  /** Forms of task */
  primary_form?: Maybe<TaskForm>;
  /** Lock state of task */
  primary_location?: Maybe<TaskLocation>;
  /** Classification Tags for a Task */
  qualifications: TaskQualificationList;
  questions?: Maybe<Array<TaskQuestion>>;
  /** Date for Follow Up */
  reminder_date?: Maybe<Scalars['DateTimeTz']>;
  /** Boolean if Urgent Yes/No */
  reserve?: Maybe<Scalars['Float']>;
  /** Date for Follow Up */
  rework_date?: Maybe<Scalars['DateTimeTz']>;
  set_initial_contact_date?: Maybe<Scalars['DateTimeTz']>;
  set_on_site_appointment_date?: Maybe<Scalars['DateTimeTz']>;
  set_report_sent_date?: Maybe<Scalars['DateTimeTz']>;
  /** task state */
  state: TaskState;
  /** Date for Follow Up */
  state_changed_date?: Maybe<Scalars['DateTimeTz']>;
  /** Qualifications for a Task */
  state_id: TaskStateEnum;
  /** Primary form of task */
  statistics_form: TaskForm;
  /** Leader for Claim  deprecated will be removed once ios app was updated */
  sub_owners: Array<TaskMember>;
  /** Leader for Claim */
  supervisor?: Maybe<Client>;
  /** ID of Visit Type */
  task_visit_type?: Maybe<TaskVisitType>;
  /** Parallel Claim Number */
  task_visit_type_id?: Maybe<Scalars['ID']>;
  template: TaskTemplate;
  template_id: Scalars['ID'];
  template_statistic?: Maybe<TaskTemplate>;
  template_statistic_id?: Maybe<Scalars['ID']>;
  updated_at: Scalars['DateTimeTz'];
  urgent: Scalars['Boolean'];
  /** Internal Number, i.g. Rekon Nr */
  uuid: Scalars['String'];
  visit_type?: Maybe<TaskVisitType>;
  was_created_by_system: Scalars['Boolean'];
};

export type TaskAppointmentInput = {
  date_from: Scalars['DateTimeTz'];
  date_to?: InputMaybe<Scalars['DateTimeTz']>;
  id?: InputMaybe<Scalars['ID']>;
  task_id: Scalars['ID'];
};

export type TaskArticlesPaginator = {
  __typename?: 'TaskArticlesPaginator';
  data: Array<Article>;
};

export type TaskCalculation = {
  __typename?: 'TaskCalculation';
  accepted_alias?: Maybe<Scalars['String']>;
  active_catalog_id: Scalars['ID'];
  categories?: Maybe<Array<TaskCalculationCategory>>;
  created_at: Scalars['DateTimeTz'];
  group_by?: Maybe<Array<Maybe<TaskCalculationGroupType>>>;
  hide_column_accepted: Scalars['Boolean'];
  hide_column_payout_state: Scalars['Boolean'];
  hide_column_status: Scalars['Boolean'];
  id: Scalars['ID'];
  items?: Maybe<Array<TaskCalculationItem>>;
  net_calculation: Scalars['Boolean'];
  status_alias?: Maybe<Scalars['String']>;
  sum_alias?: Maybe<Scalars['String']>;
  task_id: Scalars['ID'];
  tax_deductible?: Maybe<Scalars['Boolean']>;
  time_value_alias?: Maybe<Scalars['String']>;
  time_value_column_active?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  updated_at: Scalars['DateTimeTz'];
};

export type TaskCalculationCategory = {
  __typename?: 'TaskCalculationCategory';
  created_at: Scalars['DateTimeTz'];
  id: Scalars['ID'];
  sort_index: Scalars['Int'];
  task_calculation_id: Scalars['ID'];
  title: Scalars['String'];
  updated_at: Scalars['DateTimeTz'];
  uuid: Scalars['ID'];
};

export type TaskCalculationCategoryBatchInput = {
  id: Scalars['ID'];
  input: TaskCalculationCategoryInput;
};

export type TaskCalculationCategoryInput = {
  sort_index?: InputMaybe<Scalars['Int']>;
  title: Scalars['String'];
};

export enum TaskCalculationCategorySyncMode {
  REPLACE = 'REPLACE',
  UPSERT = 'UPSERT',
}

export type TaskCalculationGroupType = {
  __typename?: 'TaskCalculationGroupType';
  group_type_id: CalculationGroupType;
  id: Scalars['ID'];
  task_calculation_id: Scalars['ID'];
};

export type TaskCalculationItem = {
  __typename?: 'TaskCalculationItem';
  accepted?: Maybe<Scalars['Float']>;
  age?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['Float']>;
  category?: Maybe<TaskCalculationCategory>;
  category_id?: Maybe<Scalars['ID']>;
  correction_condition?: Maybe<Scalars['Float']>;
  created_at: Scalars['DateTimeTz'];
  deleted_at?: Maybe<Scalars['DateTimeTz']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  max_usage_years?: Maybe<Scalars['Int']>;
  net_calculation: Scalars['Boolean'];
  own_contribution: Scalars['Boolean'];
  payout_state?: Maybe<TaskCalculationItemPayoutState>;
  payout_state_id?: Maybe<Scalars['ID']>;
  single_price?: Maybe<Scalars['Float']>;
  sort_index?: Maybe<Scalars['Int']>;
  state?: Maybe<TaskCalculationItemState>;
  state_id?: Maybe<Scalars['ID']>;
  task_calculation_id: Scalars['ID'];
  tax: Scalars['Float'];
  time_value?: Maybe<Scalars['Float']>;
  title?: Maybe<Scalars['String']>;
  unit?: Maybe<ServiceCatalogUnit>;
  unit_id?: Maybe<Scalars['ID']>;
  updated_at: Scalars['DateTimeTz'];
  uuid: Scalars['ID'];
};

export type TaskCalculationItemBatchInput = {
  id: Scalars['ID'];
  input: TaskCalculationItemInput;
};

export type TaskCalculationItemInput = {
  accepted?: InputMaybe<Scalars['Float']>;
  age?: InputMaybe<Scalars['Int']>;
  amount?: InputMaybe<Scalars['Float']>;
  category_id?: InputMaybe<Scalars['ID']>;
  category_uuid?: InputMaybe<Scalars['ID']>;
  correction_condition?: InputMaybe<Scalars['Float']>;
  description?: InputMaybe<Scalars['String']>;
  max_usage_years?: InputMaybe<Scalars['Int']>;
  own_contribution?: InputMaybe<Scalars['Boolean']>;
  payout_state_id?: InputMaybe<Scalars['ID']>;
  single_price?: InputMaybe<Scalars['Float']>;
  sort_index?: InputMaybe<Scalars['Int']>;
  state_id?: InputMaybe<Scalars['ID']>;
  tax?: InputMaybe<Scalars['Float']>;
  time_value?: InputMaybe<Scalars['Float']>;
  title?: InputMaybe<Scalars['String']>;
  unit_id?: InputMaybe<Scalars['ID']>;
};

export type TaskCalculationItemPayoutState = {
  __typename?: 'TaskCalculationItemPayoutState';
  color: Scalars['String'];
  id: Scalars['ID'];
  title: Scalars['String'];
};

export type TaskCalculationItemState = {
  __typename?: 'TaskCalculationItemState';
  color: Scalars['String'];
  id: Scalars['ID'];
  title: Scalars['String'];
};

export type TaskCalculationItemStateFilter = {
  AND?: InputMaybe<Array<TaskCalculationItemStateFilter>>;
  OR?: InputMaybe<Array<TaskCalculationItemStateFilter>>;
  column?: InputMaybe<TaskCalculationItemStateSortAndFilterColumns>;
  operator?: InputMaybe<SQLOperator>;
  value?: InputMaybe<Scalars['Mixed']>;
};

export type TaskCalculationItemStateInput = {
  color?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type TaskCalculationItemStatePaginator = {
  __typename?: 'TaskCalculationItemStatePaginator';
  data: Array<TaskCalculationItemState>;
  paginatorInfo: PaginatorInfo;
};

export type TaskCalculationItemStateSort = {
  columns?: InputMaybe<Array<TaskCalculationItemStateSortOrder>>;
};

export enum TaskCalculationItemStateSortAndFilterColumns {
  TITLE = 'TITLE',
}

export type TaskCalculationItemStateSortOrder = {
  column: TaskCalculationItemStateSortAndFilterColumns;
  order: SortOrder;
};

export enum TaskCalculationItemSyncMode {
  REPLACE = 'REPLACE',
  UPSERT = 'UPSERT',
}

export type TaskCalculationTemplate = {
  __typename?: 'TaskCalculationTemplate';
  id: Scalars['ID'];
  title: Scalars['String'];
};

export type TaskClassificationTagList = {
  __typename?: 'TaskClassificationTagList';
  data: Array<ClassificationTag>;
  id: Scalars['ID'];
};

export type TaskClassificationUpdateMessage = {
  __typename?: 'TaskClassificationUpdateMessage';
  ids: Array<Scalars['ID']>;
};

export type TaskContactCreateInput = {
  city?: InputMaybe<Scalars['String']>;
  country_id?: InputMaybe<Scalars['ID']>;
  email?: InputMaybe<Scalars['String']>;
  email2?: InputMaybe<Scalars['String']>;
  fax_number?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  function_id?: InputMaybe<Scalars['ID']>;
  mobile?: InputMaybe<Scalars['String']>;
  mobile2?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  note?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  phone2?: InputMaybe<Scalars['String']>;
  postcode?: InputMaybe<Scalars['String']>;
  role_id: Scalars['ID'];
  salutation_id?: InputMaybe<Scalars['ID']>;
  street?: InputMaybe<Scalars['String']>;
  street_no?: InputMaybe<Scalars['String']>;
};

export type TaskContactInput = {
  city?: InputMaybe<Scalars['String']>;
  country_id?: InputMaybe<Scalars['ID']>;
  email?: InputMaybe<Scalars['String']>;
  email2?: InputMaybe<Scalars['String']>;
  fax_number?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  function_id?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  mobile?: InputMaybe<Scalars['String']>;
  mobile2?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  note?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  phone2?: InputMaybe<Scalars['String']>;
  postcode?: InputMaybe<Scalars['String']>;
  role_id?: InputMaybe<Scalars['ID']>;
  salutation_id?: InputMaybe<Scalars['ID']>;
  street?: InputMaybe<Scalars['String']>;
  street_no?: InputMaybe<Scalars['String']>;
};

export type TaskContactUpdateInput = {
  city?: InputMaybe<Scalars['String']>;
  country_id?: InputMaybe<Scalars['ID']>;
  email?: InputMaybe<Scalars['String']>;
  email2?: InputMaybe<Scalars['String']>;
  fax_number?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  function_id?: InputMaybe<Scalars['ID']>;
  mobile?: InputMaybe<Scalars['String']>;
  mobile2?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  phone2?: InputMaybe<Scalars['String']>;
  postcode?: InputMaybe<Scalars['String']>;
  role_id?: InputMaybe<Scalars['ID']>;
  salutation_id?: InputMaybe<Scalars['ID']>;
  street?: InputMaybe<Scalars['String']>;
  street_no?: InputMaybe<Scalars['String']>;
};

export type TaskCount = {
  __typename?: 'TaskCount';
  amount: Scalars['Int'];
  day: Scalars['DateTimeTz'];
};

export type TaskCustomReportSetting = {
  __typename?: 'TaskCustomReportSetting';
  expanded: Scalars['Boolean'];
  form_ids: Array<Scalars['ID']>;
  freetext_description?: Maybe<Scalars['String']>;
  freetext_on_first_page: Scalars['Boolean'];
  freetext_title?: Maybe<Scalars['String']>;
  has_calculation: Scalars['Boolean'];
  has_cover: Scalars['Boolean'];
  has_documents: Scalars['Boolean'];
  has_freetext: Scalars['Boolean'];
  has_images: Scalars['Boolean'];
  has_questions: Scalars['Boolean'];
  has_room_plan: Scalars['Boolean'];
  has_summary: Scalars['Boolean'];
  has_toc: Scalars['Boolean'];
  hidden_questions_id?: Maybe<Array<TaskCustomReportSettingQuestion>>;
  images_per_page: Scalars['Int'];
  manual_task_id?: Maybe<Scalars['String']>;
  question_filter: ReportQuestionFilterType;
  questions_alternate_title?: Maybe<Scalars['String']>;
  questions_hide_description: Scalars['Boolean'];
  show_questions_without_answers: Scalars['Boolean'];
  task_id: Scalars['ID'];
  title: Scalars['String'];
  type_id: Scalars['ID'];
  use_alternate_logo: Scalars['Boolean'];
};

export type TaskCustomReportSettingInput = {
  expanded?: InputMaybe<Scalars['Boolean']>;
  form_ids?: InputMaybe<Array<Scalars['ID']>>;
  freetext_description?: InputMaybe<Scalars['String']>;
  freetext_on_first_page?: InputMaybe<Scalars['Boolean']>;
  freetext_title?: InputMaybe<Scalars['String']>;
  has_calculation?: InputMaybe<Scalars['Boolean']>;
  has_cover?: InputMaybe<Scalars['Boolean']>;
  has_documents?: InputMaybe<Scalars['Boolean']>;
  has_freetext?: InputMaybe<Scalars['Boolean']>;
  has_images?: InputMaybe<Scalars['Boolean']>;
  has_questions?: InputMaybe<Scalars['Boolean']>;
  has_room_plan?: InputMaybe<Scalars['Boolean']>;
  has_summary?: InputMaybe<Scalars['Boolean']>;
  has_toc?: InputMaybe<Scalars['Boolean']>;
  hidden_questions_id?: InputMaybe<Array<Scalars['String']>>;
  images_per_page?: InputMaybe<Scalars['Int']>;
  manual_task_id?: InputMaybe<Scalars['String']>;
  question_filter?: InputMaybe<ReportQuestionFilterType>;
  questions_alternate_title?: InputMaybe<Scalars['String']>;
  questions_hide_description?: InputMaybe<Scalars['Boolean']>;
  show_questions_without_answers?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
  type_id?: InputMaybe<Scalars['ID']>;
  use_alternate_logo?: InputMaybe<Scalars['Boolean']>;
};

export type TaskCustomReportSettingQuestion = {
  __typename?: 'TaskCustomReportSettingQuestion';
  question_id: Scalars['String'];
};

export type TaskCustomReportSettingQuestionInput = {
  __typename?: 'TaskCustomReportSettingQuestionInput';
  question_id: Scalars['String'];
};

export type TaskDateCount = {
  __typename?: 'TaskDateCount';
  amount: Scalars['Int'];
  day: Scalars['Date'];
};

export type TaskDeclineReason = {
  __typename?: 'TaskDeclineReason';
  id: Scalars['ID'];
  required_note?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
};

export type TaskDeclineReasonInput = {
  decline_reason_id: Scalars['ID'];
  id: Scalars['ID'];
  title?: InputMaybe<Scalars['String']>;
};

export type TaskDocumentsPaginator = {
  __typename?: 'TaskDocumentsPaginator';
  data: Array<FileItem>;
};

export type TaskEmailContact = {
  __typename?: 'TaskEmailContact';
  default: Scalars['Boolean'];
  email: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
};

export type TaskEmailContactsResponse = {
  __typename?: 'TaskEmailContactsResponse';
  data: Array<TaskEmailContact>;
};

export type TaskEvent = {
  __typename?: 'TaskEvent';
  calendar_event?: Maybe<CalendarEvent>;
  date_from: Scalars['DateTimeTz'];
  date_to: Scalars['DateTimeTz'];
  description?: Maybe<Scalars['String']>;
  event_type: TaskEventType;
  id: Scalars['ID'];
  task: Task;
};

export type TaskEventType = {
  __typename?: 'TaskEventType';
  color: Scalars['String'];
  id: Scalars['ID'];
  title: Scalars['String'];
};

export type TaskExportColumn = {
  __typename?: 'TaskExportColumn';
  label: Scalars['String'];
  name: Scalars['String'];
};

export type TaskFileGroup = {
  __typename?: 'TaskFileGroup';
  documents?: Maybe<Array<FileItem>>;
  files?: Maybe<Array<FileItem>>;
  images?: Maybe<Array<FileItem>>;
  task_id: Scalars['ID'];
};

export type TaskFilesPaginator = {
  __typename?: 'TaskFilesPaginator';
  data: Array<FileItem>;
};

export type TaskFolder = {
  __typename?: 'TaskFolder';
  allowed_client_type_ids: Array<ClientTypeId>;
  folder_type: TaskFolderType;
  id: Scalars['ID'];
  name: Scalars['String'];
  parent_id?: Maybe<Scalars['ID']>;
  task_id: Scalars['ID'];
};

export type TaskFolderInput = {
  name: Scalars['String'];
  parent_id?: InputMaybe<Scalars['ID']>;
};

export type TaskFolderTemplate = {
  __typename?: 'TaskFolderTemplate';
  created_at: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updated_at: Scalars['String'];
};

export type TaskFolderTemplateInput = {
  name: Scalars['String'];
};

export type TaskFolderTemplatesFilter = {
  AND?: InputMaybe<Array<TaskFolderTemplatesFilter>>;
  OR?: InputMaybe<Array<TaskFolderTemplatesFilter>>;
  column?: InputMaybe<TaskFolderTemplatesSortAndFilterColumns>;
  operator?: InputMaybe<SQLOperator>;
  value?: InputMaybe<Scalars['Mixed']>;
};

export type TaskFolderTemplatesPaginator = {
  __typename?: 'TaskFolderTemplatesPaginator';
  data: Array<TaskFolderTemplate>;
  paginatorInfo: PaginatorInfo;
};

export type TaskFolderTemplatesSort = {
  columns?: InputMaybe<Array<TaskFolderTemplatesSortOrder>>;
};

export enum TaskFolderTemplatesSortAndFilterColumns {
  ID = 'ID',
  NAME = 'NAME',
}

export type TaskFolderTemplatesSortOrder = {
  column: TaskFolderTemplatesSortAndFilterColumns;
  order: SortOrder;
};

export enum TaskFolderType {
  CALCULATIONS = 'CALCULATIONS',
  DEFAULT = 'DEFAULT',
  DOCUMENTS = 'DOCUMENTS',
  IMAGES = 'IMAGES',
  INTERN = 'INTERN',
  INVOICES = 'INVOICES',
  REPORTS = 'REPORTS',
  ROOT = 'ROOT',
  TASK = 'TASK',
}

export type TaskForm = {
  __typename?: 'TaskForm';
  created_at: Scalars['DateTimeTz'];
  id: Scalars['ID'];
  questions: Array<TaskQuestion>;
  sort_index: Scalars['Int'];
  tags?: Maybe<Array<Tag>>;
  task: Task;
  task_id: Scalars['ID'];
  template: TaskTemplate;
  template_id: Scalars['ID'];
  title: Scalars['String'];
  type: TaskFormType;
  type_id: TaskFormTypeId;
  updated_at: Scalars['DateTimeTz'];
};

export type TaskFormQuestionInput = {
  answer?: InputMaybe<Scalars['String']>;
  answer_code?: InputMaybe<Scalars['String']>;
  hidden?: InputMaybe<Scalars['Boolean']>;
  highlighted?: InputMaybe<Scalars['Boolean']>;
  images?: InputMaybe<Array<Scalars['String']>>;
  note?: InputMaybe<Scalars['String']>;
  question_id?: InputMaybe<Scalars['String']>;
};

export type TaskFormQuestionTableRowInput = {
  column1?: InputMaybe<Scalars['String']>;
  column2?: InputMaybe<Scalars['String']>;
  column3?: InputMaybe<Scalars['String']>;
  column4?: InputMaybe<Scalars['String']>;
  column5?: InputMaybe<Scalars['String']>;
  column6?: InputMaybe<Scalars['String']>;
  column7?: InputMaybe<Scalars['String']>;
  column8?: InputMaybe<Scalars['String']>;
  question_id: Scalars['String'];
  row_id: Scalars['String'];
};

export type TaskFormType = {
  __typename?: 'TaskFormType';
  color: Scalars['String'];
  id: Scalars['ID'];
  title: Scalars['String'];
  type_id: TaskFormTypeId;
};

export enum TaskFormTypeId {
  ADDITIONAL = 'ADDITIONAL',
  PRIMARY = 'PRIMARY',
  STATISTICS = 'STATISTICS',
}

export type TaskGeoData = {
  __typename?: 'TaskGeoData';
  layer_id: Scalars['ID'];
  location: TaskLocation;
};

export type TaskImagesPaginator = {
  __typename?: 'TaskImagesPaginator';
  data: Array<FileItem>;
};

export type TaskInfoCount = {
  __typename?: 'TaskInfoCount';
  count: Scalars['Int'];
  type_id: TaskInfoCountType;
};

export type TaskInfoCountList = {
  __typename?: 'TaskInfoCountList';
  data: Array<TaskInfoCount>;
};

export enum TaskInfoCountType {
  CALCULATION = 'CALCULATION',
  DOCUMENTS = 'DOCUMENTS',
  EMAILS = 'EMAILS',
  FILES = 'FILES',
  IMAGES = 'IMAGES',
  INVOICES = 'INVOICES',
  LOGS = 'LOGS',
  OPEN_TASKS = 'OPEN_TASKS',
  REPORTS = 'REPORTS',
  ROOM_PLAN = 'ROOM_PLAN',
  SUB_FORMS = 'SUB_FORMS',
  VIDEO_EVENTS = 'VIDEO_EVENTS',
}

export type TaskInvoice = {
  __typename?: 'TaskInvoice';
  address: Scalars['String'];
  client?: Maybe<Client>;
  client_id: Scalars['ID'];
  date: Scalars['DateTimeTz'];
  external_id?: Maybe<Scalars['Int']>;
  external_invoice_id?: Maybe<Scalars['String']>;
  external_invoice_number?: Maybe<Scalars['String']>;
  external_invoice_url?: Maybe<Scalars['String']>;
  external_number: Scalars['String'];
  file?: Maybe<FileItem>;
  id: Scalars['ID'];
  identifier: Scalars['String'];
  items: Array<TaskInvoiceItem>;
  note?: Maybe<Scalars['String']>;
  overdue_date?: Maybe<Scalars['DateTimeTz']>;
  pay_date?: Maybe<Scalars['DateTimeTz']>;
  payouts?: Maybe<Array<TaskInvoicePayout>>;
  permissions: Array<Permission>;
  reminder?: Maybe<Scalars['Boolean']>;
  state: TaskInvoiceState;
  state_id: TaskInvoiceStateId;
  sum_gross: Scalars['Float'];
  sum_net: Scalars['Float'];
  sum_tax: Scalars['Float'];
  task: Task;
  task_id: Scalars['ID'];
  url_preview: Scalars['String'];
};

export type TaskInvoiceItem = {
  __typename?: 'TaskInvoiceItem';
  amount: Scalars['Float'];
  id: Scalars['ID'];
  invoice_id: Scalars['ID'];
  position: Scalars['String'];
  sum_gross: Scalars['Float'];
  sum_net: Scalars['Float'];
  tax: Scalars['Float'];
  title: Scalars['String'];
  unit?: Maybe<Scalars['String']>;
  unit_price: Scalars['Float'];
};

export type TaskInvoiceItemInput = {
  amount: Scalars['Float'];
  position: Scalars['String'];
  sum_gross: Scalars['Float'];
  sum_net: Scalars['Float'];
  tax: Scalars['Float'];
  title: Scalars['String'];
  unit?: InputMaybe<Scalars['String']>;
  unit_price: Scalars['Float'];
};

export type TaskInvoiceItemTemplate = {
  __typename?: 'TaskInvoiceItemTemplate';
  amount: Scalars['Float'];
  sum_gross: Scalars['Float'];
  sum_net: Scalars['Float'];
  tax: Scalars['Float'];
  title: Scalars['String'];
  unit?: Maybe<Scalars['String']>;
  unit_price: Scalars['Float'];
};

export type TaskInvoicePayout = {
  __typename?: 'TaskInvoicePayout';
  client: Client;
  client_id: Scalars['ID'];
  id: Scalars['ID'];
  invoice_id: Scalars['ID'];
  payout?: Maybe<Payout>;
  payout_date?: Maybe<Scalars['DateTime']>;
  state: TaskInvoicePayoutState;
  state_id: TaskInvoicePayoutStateId;
  sum_net: Scalars['Float'];
  task_invoice: TaskInvoice;
  title: Scalars['String'];
};

export type TaskInvoicePayoutInput = {
  client_id: Scalars['ID'];
  invoice_id: Scalars['ID'];
  sum_net: Scalars['Float'];
  title?: InputMaybe<Scalars['String']>;
};

export type TaskInvoicePayoutState = {
  __typename?: 'TaskInvoicePayoutState';
  color: Scalars['String'];
  id: Scalars['ID'];
  state_id: TaskInvoicePayoutStateId;
  title: Scalars['String'];
};

export enum TaskInvoicePayoutStateId {
  BOOKMARKED = 'BOOKMARKED',
  CANCELED = 'CANCELED',
  OPEN = 'OPEN',
  PAID = 'PAID',
}

export type TaskInvoicePayoutsPaginator = {
  __typename?: 'TaskInvoicePayoutsPaginator';
  data: Array<TaskInvoicePayout>;
};

export type TaskInvoicePreview = {
  __typename?: 'TaskInvoicePreview';
  id: Scalars['ID'];
  url: Scalars['String'];
};

export type TaskInvoiceState = {
  __typename?: 'TaskInvoiceState';
  color: Scalars['String'];
  id: Scalars['ID'];
  state_id: TaskInvoiceStateId;
  title: Scalars['String'];
};

export enum TaskInvoiceStateId {
  CANCELLED = 'CANCELLED',
  DRAFT = 'DRAFT',
  OVERDUE = 'OVERDUE',
  PAID = 'PAID',
  PENDING = 'PENDING',
}

export type TaskInvoicesPaginator = {
  __typename?: 'TaskInvoicesPaginator';
  data: Array<TaskInvoice>;
};

export type TaskListResponse = {
  __typename?: 'TaskListResponse';
  data: Array<Task>;
};

export type TaskLocation = {
  __typename?: 'TaskLocation';
  city?: Maybe<Scalars['String']>;
  country: Country;
  country_id?: Maybe<Scalars['ID']>;
  created_at: Scalars['DateTimeTz'];
  id: Scalars['ID'];
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  notes?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  street_full?: Maybe<Scalars['String']>;
  street_no?: Maybe<Scalars['String']>;
  task_id: Scalars['ID'];
  task_location_type_id?: Maybe<Scalars['ID']>;
  updated_at: Scalars['DateTimeTz'];
};

export type TaskLocationInput = {
  city: Scalars['String'];
  country_id: Scalars['ID'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  notes?: InputMaybe<Scalars['String']>;
  postcode: Scalars['String'];
  street: Scalars['String'];
  street_no: Scalars['String'];
  task_location_type_id?: InputMaybe<Scalars['ID']>;
};

export type TaskLockState = {
  __typename?: 'TaskLockState';
  color: Scalars['String'];
  id: Scalars['ID'];
  state_id: TaskLockStateId;
  title: Scalars['String'];
};

export enum TaskLockStateId {
  IOS = 'IOS',
  OFFLINE = 'OFFLINE',
  WEB = 'WEB',
}

export type TaskLog = {
  __typename?: 'TaskLog';
  client?: Maybe<Client>;
  created_at?: Maybe<Scalars['DateTimeTz']>;
  id: Scalars['ID'];
  state: TaskLogState;
  state_id: TaskLogStateEnum;
  task_id: Scalars['ID'];
  title: Scalars['String'];
};

export type TaskLogState = {
  __typename?: 'TaskLogState';
  color: Scalars['String'];
  id: Scalars['ID'];
  title: Scalars['String'];
};

export enum TaskLogStateEnum {
  ACCEPTED = 'ACCEPTED',
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
  CONTROLLING = 'CONTROLLING',
  EMAIL = 'EMAIL',
  INTERFACE_MESSAGE = 'INTERFACE_MESSAGE',
  IN_PROGRESS = 'IN_PROGRESS',
  MESSAGE = 'MESSAGE',
  NEW = 'NEW',
  NOT_ACCEPTED = 'NOT_ACCEPTED',
  NOT_ASSIGNED = 'NOT_ASSIGNED',
  REPORT_SENT = 'REPORT_SENT',
  RETURNED = 'RETURNED',
  REWORK = 'REWORK',
  REWORK_DONE = 'REWORK_DONE',
  SET_INITIAL_CONTACT = 'SET_INITIAL_CONTACT',
  SET_ON_SITE_APPOINTMENT = 'SET_ON_SITE_APPOINTMENT',
  SMS = 'SMS',
}

export type TaskMember = {
  __typename?: 'TaskMember';
  client?: Maybe<Client>;
  client_id: Scalars['ID'];
  id: Scalars['ID'];
  task_id: Scalars['ID'];
  type: TaskMemberType;
};

export type TaskMemberInput = {
  client_id?: InputMaybe<Scalars['ID']>;
  task_id: Scalars['ID'];
  type: TaskMemberType;
};

export enum TaskMemberType {
  LEADER = 'LEADER',
  OWNER = 'OWNER',
  SUB_OWNER = 'SUB_OWNER',
}

export type TaskNotification = {
  __typename?: 'TaskNotification';
  description: Scalars['String'];
  title: Scalars['String'];
  type: PageNotificationType;
};

export type TaskQualificationList = {
  __typename?: 'TaskQualificationList';
  data: Array<Qualification>;
  id: Scalars['ID'];
};

export type TaskQuestion = {
  __typename?: 'TaskQuestion';
  answer?: Maybe<Scalars['String']>;
  answer_code?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  created_at: Scalars['DateTimeTz'];
  force_page_break_after: Scalars['Boolean'];
  hidden: Scalars['Boolean'];
  highlighted: Scalars['Boolean'];
  id: Scalars['ID'];
  images?: Maybe<Array<Scalars['String']>>;
  note?: Maybe<Scalars['String']>;
  question_id: Scalars['String'];
  rows?: Maybe<Array<Maybe<TaskQuestionTableRow>>>;
  task_form_id: Scalars['ID'];
  task_id: Scalars['String'];
  updated_at: Scalars['DateTimeTz'];
};

export type TaskQuestionHistory = {
  __typename?: 'TaskQuestionHistory';
  id: Scalars['ID'];
  is_api: Scalars['Boolean'];
  is_mobile: Scalars['Boolean'];
  model_id?: Maybe<Scalars['ID']>;
  new_value?: Maybe<Scalars['String']>;
  old_value?: Maybe<Scalars['String']>;
  performed_at: Scalars['DateTimeTz'];
  platform?: Maybe<Platform>;
  user_name: Scalars['String'];
};

export type TaskQuestionHistoryResponse = {
  __typename?: 'TaskQuestionHistoryResponse';
  data: Array<TaskQuestion>;
};

export type TaskQuestionInput = {
  answer?: InputMaybe<Scalars['String']>;
  answer_code?: InputMaybe<Scalars['String']>;
  hidden?: InputMaybe<Scalars['Boolean']>;
  images?: InputMaybe<Array<Scalars['String']>>;
  note?: InputMaybe<Scalars['String']>;
  question_id?: InputMaybe<Scalars['String']>;
};

export enum TaskQuestionSyncMode {
  REPLACE = 'REPLACE',
  UPSERT = 'UPSERT',
}

export type TaskQuestionTableRow = {
  __typename?: 'TaskQuestionTableRow';
  column1?: Maybe<Scalars['String']>;
  column2?: Maybe<Scalars['String']>;
  column3?: Maybe<Scalars['String']>;
  column4?: Maybe<Scalars['String']>;
  column5?: Maybe<Scalars['String']>;
  column6?: Maybe<Scalars['String']>;
  column7?: Maybe<Scalars['String']>;
  column8?: Maybe<Scalars['String']>;
  created_at: Scalars['DateTimeTz'];
  /** Unique ID, coming from frontend */
  question_id: Scalars['String'];
  row_id: Scalars['String'];
  task_id: Scalars['ID'];
  updated_at: Scalars['DateTimeTz'];
};

export type TaskQuestionTableRowInput = {
  /** This is the ID from the TaskTemplate, not the internal ID as Int from the TaskQuestion Object */
  column1?: InputMaybe<Scalars['String']>;
  column2?: InputMaybe<Scalars['String']>;
  column3?: InputMaybe<Scalars['String']>;
  column4?: InputMaybe<Scalars['String']>;
  column5?: InputMaybe<Scalars['String']>;
  column6?: InputMaybe<Scalars['String']>;
  column7?: InputMaybe<Scalars['String']>;
  column8?: InputMaybe<Scalars['String']>;
  question_id: Scalars['String'];
  row_id: Scalars['String'];
  task_id: Scalars['ID'];
};

export enum TaskQuestionTableRowSyncMode {
  REPLACE = 'REPLACE',
  UPSERT = 'UPSERT',
}

export type TaskQuestionsResponse = {
  __typename?: 'TaskQuestionsResponse';
  data: Array<TaskQuestion>;
};

export type TaskReassignInput = {
  owner_client_id: Scalars['ID'];
  task_ids: Array<Scalars['ID']>;
};

export type TaskReport = {
  __typename?: 'TaskReport';
  created_at: Scalars['DateTimeTz'];
  file?: Maybe<FileItem>;
  id: Scalars['ID'];
  title: Scalars['String'];
};

export type TaskReportGeneration = {
  __typename?: 'TaskReportGeneration';
  created_at: Scalars['DateTimeTz'];
  failed_reason?: Maybe<Scalars['String']>;
  filename: Scalars['String'];
  id: Scalars['ID'];
  state_id: TaskReportStateId;
  task: Task;
  task_id: Scalars['ID'];
  task_report?: Maybe<TaskReport>;
  task_report_id?: Maybe<Scalars['ID']>;
  title: Scalars['String'];
  type_id: TaskReportTypeId;
  updated_at: Scalars['DateTimeTz'];
  url_download?: Maybe<Scalars['String']>;
  url_preview?: Maybe<Scalars['String']>;
};

export type TaskReportOptionsInput = {
  sync: Scalars['Boolean'];
  type_id: TaskReportTypeId;
};

export type TaskReportPaginator = {
  __typename?: 'TaskReportPaginator';
  data: Array<TaskReport>;
};

export enum TaskReportStateId {
  CREATED = 'CREATED',
  FAILED = 'FAILED',
  GENERATED = 'GENERATED',
  GENERATING = 'GENERATING',
}

export enum TaskReportTypeId {
  CALCULATION = 'CALCULATION',
  CUSTOM = 'CUSTOM',
  PHOTO = 'PHOTO',
  STANDARD = 'STANDARD',
}

export type TaskRoomCategory = {
  __typename?: 'TaskRoomCategory';
  id: Scalars['ID'];
  task_id: Scalars['ID'];
  title: Scalars['String'];
  uuid: Scalars['ID'];
};

export type TaskRoomCategoryInput = {
  title: Scalars['String'];
  uuid: Scalars['ID'];
};

export enum TaskRoomCategorySyncMode {
  REPLACE = 'REPLACE',
  UPSERT = 'UPSERT',
}

export type TaskRoomItem = {
  __typename?: 'TaskRoomItem';
  category?: Maybe<TaskRoomCategory>;
  description?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  length?: Maybe<Scalars['Float']>;
  service_catalog_items?: Maybe<Array<ServiceCatalogItem>>;
  size?: Maybe<Scalars['Float']>;
  task_id: Scalars['ID'];
  task_room_category_id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  uuid: Scalars['ID'];
  width?: Maybe<Scalars['Float']>;
};

export type TaskRoomItemInput = {
  description?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<Scalars['Float']>;
  length?: InputMaybe<Scalars['Float']>;
  service_catalog_items?: InputMaybe<Array<Scalars['ID']>>;
  size?: InputMaybe<Scalars['Float']>;
  task_room_category_uuid: Scalars['ID'];
  title: Scalars['String'];
  uuid: Scalars['ID'];
  width?: InputMaybe<Scalars['Float']>;
};

export enum TaskRoomItemSyncMode {
  REPLACE = 'REPLACE',
  UPSERT = 'UPSERT',
}

export type TaskRoomMagicPlanJSON = {
  __typename?: 'TaskRoomMagicPlanJSON';
  data?: Maybe<Scalars['JSON']>;
};

export type TaskRoute = {
  __typename?: 'TaskRoute';
  date: Scalars['DateTimeTz'];
  distance?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  route?: Maybe<Scalars['JSON']>;
  waypoints: Array<TaskRouteWaypoint>;
};

export type TaskRoutePaginator = {
  __typename?: 'TaskRoutePaginator';
  data: Array<TaskRoute>;
  paginatorInfo: PaginatorInfo;
};

export enum TaskRouteTypeEnum {
  CLIENT = 'CLIENT',
  EVENT = 'EVENT',
}

export type TaskRouteWaypoint = {
  __typename?: 'TaskRouteWaypoint';
  client?: Maybe<Client>;
  distance?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  route?: Maybe<Scalars['JSON']>;
  task_event?: Maybe<TaskEvent>;
  type_id: TaskRouteTypeEnum;
};

/** This is the Model only for the Row of all Tasks */
export type TaskRow = {
  __typename?: 'TaskRow';
  /** Should not be visible in the row */
  created_at: Scalars['DateTimeTz'];
  /** The postcode of the Customer */
  customer_city?: Maybe<Scalars['String']>;
  /** One of the Leaders, these are Client Objects */
  customer_name?: Maybe<Scalars['String']>;
  /** The street of the Customer */
  customer_postcode?: Maybe<Scalars['String']>;
  /** The name of the Customer */
  customer_street?: Maybe<Scalars['String']>;
  /** ID of Visit Type, should be a select of all VisitTypes */
  damage_date?: Maybe<Scalars['DateTimeTz']>;
  /** Damage Number */
  external_number?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** TaskTemplate - color */
  identifier?: Maybe<Scalars['String']>;
  /** One of the Loss Adjusters, these are Client Objects */
  leader_client_id?: Maybe<Scalars['ID']>;
  /** Policy Number */
  owner_client_id?: Maybe<Scalars['ID']>;
  /** Rekon Number */
  policy_number?: Maybe<Scalars['String']>;
  /** Optional, if i have the date of the damage */
  reserve?: Maybe<Scalars['Float']>;
  /** Yes/No */
  task_visit_type_id?: Maybe<Scalars['ID']>;
  /** TaskTemplate - title */
  template_color?: Maybe<Scalars['String']>;
  /** Created Date of Task */
  template_id?: Maybe<Scalars['ID']>;
  /** TaskTemplate - Should be a Select */
  template_title?: Maybe<Scalars['String']>;
  /** The city of the Customer */
  urgent?: Maybe<Scalars['Boolean']>;
};

export type TaskRowPaginator = {
  __typename?: 'TaskRowPaginator';
  data: Array<Task>;
  paginatorInfo: PaginatorInfo;
};

export type TaskState = {
  __typename?: 'TaskState';
  color: Scalars['String'];
  id: Scalars['ID'];
  state_id: TaskStateEnum;
  title: Scalars['String'];
};

export enum TaskStateEnum {
  ACCEPTED = 'ACCEPTED',
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
  CONTROLLING = 'CONTROLLING',
  IN_PROGRESS = 'IN_PROGRESS',
  NEW = 'NEW',
  NOT_ACCEPTED = 'NOT_ACCEPTED',
  NOT_ASSIGNED = 'NOT_ASSIGNED',
  REPORT_SENT = 'REPORT_SENT',
  RETURNED = 'RETURNED',
  REWORK = 'REWORK',
  REWORK_DONE = 'REWORK_DONE',
}

export type TaskSubTask = {
  __typename?: 'TaskSubTask';
  assigned_client_id: Scalars['ID'];
  client?: Maybe<Client>;
  completed_date?: Maybe<Scalars['DateTimeTz']>;
  controller?: Maybe<Client>;
  created_at?: Maybe<Scalars['DateTimeTz']>;
  created_client_id: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  due_date?: Maybe<Scalars['DateTimeTz']>;
  file?: Maybe<FileItem>;
  file_id?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  identifier?: Maybe<TaskSubTaskWorkflowIdentifier>;
  permissions: Array<Permission>;
  state: TaskSubTaskState;
  state_id: TaskSubTaskStateEnum;
  task: Task;
  task_id: Scalars['ID'];
  title: Scalars['String'];
  /** As a User its always the workflow ID 1 - the other ones are automatically set by the backend */
  type_id: TaskSubTaskType;
  workflow_id: Scalars['Int'];
};

export type TaskSubTaskAllowedClientsForAssignment = {
  __typename?: 'TaskSubTaskAllowedClientsForAssignment';
  clients: Array<Client>;
};

export type TaskSubTaskFilter = {
  AND?: InputMaybe<Array<TaskSubTaskFilter>>;
  OR?: InputMaybe<Array<TaskSubTaskFilter>>;
  column?: InputMaybe<TaskSubTaskSortAndFilterColumns>;
  operator?: InputMaybe<SQLOperator>;
  value?: InputMaybe<Scalars['Mixed']>;
};

export type TaskSubTaskPaginator = {
  __typename?: 'TaskSubTaskPaginator';
  data: Array<TaskSubTask>;
  paginatorInfo: PaginatorInfo;
};

export type TaskSubTaskSort = {
  columns?: InputMaybe<Array<TaskSubTaskSortOrder>>;
};

export enum TaskSubTaskSortAndFilterColumns {
  ASSIGNED_CLIENT_ID = 'ASSIGNED_CLIENT_ID',
  ASSIGNED_NAME = 'ASSIGNED_NAME',
  COMPLETED_DATE = 'COMPLETED_DATE',
  CONTROLLER_CLIENT_ID = 'CONTROLLER_CLIENT_ID',
  CONTROLLER_NAME = 'CONTROLLER_NAME',
  DESCRIPTION = 'DESCRIPTION',
  DUE_DATE = 'DUE_DATE',
  LEADER_CLIENT_ID = 'LEADER_CLIENT_ID',
  STATE_ID = 'STATE_ID',
  TASK_ID = 'TASK_ID',
  TITLE = 'TITLE',
  TYPE_ID = 'TYPE_ID',
}

export type TaskSubTaskSortOrder = {
  column: TaskSubTaskSortAndFilterColumns;
  order: SortOrder;
};

export type TaskSubTaskState = {
  __typename?: 'TaskSubTaskState';
  color: Scalars['String'];
  id: Scalars['ID'];
  state_id: TaskSubTaskStateEnum;
  title: Scalars['String'];
};

export enum TaskSubTaskStateEnum {
  COMPLETED = 'COMPLETED',
  OPEN = 'OPEN',
  REVIEW = 'REVIEW',
}

export enum TaskSubTaskStateType {
  COMPLETED = 'COMPLETED',
  OPEN = 'OPEN',
  WAIT_FOR_COMPLETION = 'WAIT_FOR_COMPLETION',
}

export type TaskSubTaskTemplate = {
  __typename?: 'TaskSubTaskTemplate';
  description: Scalars['String'];
  finish_date_default_days: Scalars['Int'];
  id: Scalars['ID'];
  permissions: Array<Permission>;
  title: Scalars['String'];
};

export type TaskSubTaskTemplateFilter = {
  AND?: InputMaybe<Array<TaskSubTaskTemplateFilter>>;
  OR?: InputMaybe<Array<TaskSubTaskTemplateFilter>>;
  column?: InputMaybe<TaskSubTaskTemplateSortAndFilterColumns>;
  operator?: InputMaybe<SQLOperator>;
  value?: InputMaybe<Scalars['Mixed']>;
};

export type TaskSubTaskTemplateInput = {
  description: Scalars['String'];
  finish_date_default_days: Scalars['Int'];
  title: Scalars['String'];
};

export type TaskSubTaskTemplatePaginator = {
  __typename?: 'TaskSubTaskTemplatePaginator';
  data: Array<TaskSubTaskTemplate>;
  paginatorInfo: PaginatorInfo;
};

export type TaskSubTaskTemplateSort = {
  columns?: InputMaybe<Array<TaskSubTaskTemplateSortOrder>>;
};

export enum TaskSubTaskTemplateSortAndFilterColumns {
  DESCRIPTION = 'DESCRIPTION',
  ID = 'ID',
  TITLE = 'TITLE',
}

export type TaskSubTaskTemplateSortOrder = {
  column: TaskSubTaskTemplateSortAndFilterColumns;
  order: SortOrder;
};

export enum TaskSubTaskType {
  MANUAL = 'MANUAL',
  SLA = 'SLA',
}

export enum TaskSubTaskWorkflowIdentifier {
  OWN_TODO = 'OWN_TODO',
  SET_APPOINTMENT = 'SET_APPOINTMENT',
}

export type TaskTag = {
  __typename?: 'TaskTag';
  key: Scalars['String'];
  title: Scalars['String'];
};

export type TaskTagResolveItem = {
  __typename?: 'TaskTagResolveItem';
  tag: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

export type TaskTagResolveResult = {
  __typename?: 'TaskTagResolveResult';
  data?: Maybe<Scalars['String']>;
};

export type TaskTagsResolveArrayResponse = {
  __typename?: 'TaskTagsResolveArrayResponse';
  data: Array<TaskTagResolveItem>;
};

/** This is the Template if assigned to a Task */
export type TaskTemplate = {
  __typename?: 'TaskTemplate';
  accepted_alias?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  assignments: Array<TaskTemplateClientAssignment>;
  classification: TaskTemplateClassification;
  classification_id: Scalars['ID'];
  color: Scalars['String'];
  created_at: Scalars['DateTimeTz'];
  default_categories?: Maybe<Array<DefaultCategory>>;
  default_supervisor_id?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  enable_word_convert?: Maybe<Scalars['Boolean']>;
  estimated_appointment_time_seconds: Scalars['Int'];
  id: Scalars['ID'];
  identifier?: Maybe<Scalars['String']>;
  is_in_use: Scalars['Boolean'];
  json?: Maybe<Scalars['JSON']>;
  shortcut?: Maybe<Scalars['String']>;
  show_time_value_calculation?: Maybe<Scalars['Boolean']>;
  status_alias?: Maybe<Scalars['String']>;
  sum_alias?: Maybe<Scalars['String']>;
  task_id: Scalars['ID'];
  time_value_alias?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type: TaskTemplateType;
  type_id: TaskTemplateTypeId;
  updated_at: Scalars['DateTimeTz'];
  usage_count: Scalars['Int'];
  version: Scalars['String'];
};

export type TaskTemplateClassification = {
  __typename?: 'TaskTemplateClassification';
  color: Scalars['String'];
  id: Scalars['ID'];
  title: Scalars['String'];
};

export type TaskTemplateClientAssignment = {
  __typename?: 'TaskTemplateClientAssignment';
  client_id: Scalars['Int'];
  id: Scalars['ID'];
  task_template_id: Scalars['Int'];
};

export type TaskTemplateClientAssignmentInput = {
  client_id: Scalars['Int'];
};

export type TaskTemplatePaginator = {
  __typename?: 'TaskTemplatePaginator';
  data: Array<TaskTemplate>;
  paginatorInfo: PaginatorInfo;
};

export type TaskTemplateType = {
  __typename?: 'TaskTemplateType';
  color: Scalars['String'];
  id: Scalars['ID'];
  title: Scalars['String'];
  type_id: TaskTemplateTypeId;
};

export enum TaskTemplateTypeId {
  ADDITIONAL = 'ADDITIONAL',
  PRIMARY = 'PRIMARY',
  STATISTICS = 'STATISTICS',
}

export type TaskTemplatesFilter = {
  AND?: InputMaybe<Array<TaskTemplatesFilter>>;
  OR?: InputMaybe<Array<TaskTemplatesFilter>>;
  column?: InputMaybe<TaskTemplatesSortAndFilterColumns>;
  operator?: InputMaybe<SQLOperator>;
  value?: InputMaybe<Scalars['Mixed']>;
};

export type TaskTemplatesSort = {
  columns?: InputMaybe<Array<TaskTemplatesSortOrder>>;
};

export enum TaskTemplatesSortAndFilterColumns {
  ACTIVE = 'ACTIVE',
  CLASSIFICATION_ID = 'CLASSIFICATION_ID',
  ID = 'ID',
  IDENTIFIER = 'IDENTIFIER',
  SHORTCUT = 'SHORTCUT',
  TITLE = 'TITLE',
  TYPE_ID = 'TYPE_ID',
  UPDATED_AT = 'UPDATED_AT',
  VERSION = 'VERSION',
}

export type TaskTemplatesSortOrder = {
  column: TaskTemplatesSortAndFilterColumns;
  order: SortOrder;
};

export type TaskVisitType = {
  __typename?: 'TaskVisitType';
  id: Scalars['ID'];
  need_appointment: Scalars['Boolean'];
  need_location: Scalars['Boolean'];
  show_appointment: Scalars['Boolean'];
  show_location: Scalars['Boolean'];
  title: Scalars['String'];
};

export type TasksBatchResponseMessage = {
  __typename?: 'TasksBatchResponseMessage';
  message: Scalars['String'];
  success: Scalars['Boolean'];
  tasks: Array<Task>;
};

export type TasksExportColumns = {
  __typename?: 'TasksExportColumns';
  columns: Array<TaskExportColumn>;
};

export type TasksFilter = {
  AND?: InputMaybe<Array<TasksFilter>>;
  OR?: InputMaybe<Array<TasksFilter>>;
  column?: InputMaybe<TasksSortAndFilterColumns>;
  operator?: InputMaybe<SQLOperator>;
  value?: InputMaybe<Scalars['Mixed']>;
};

export type TasksGeoJSON = {
  __typename?: 'TasksGeoJSON';
  data: Array<Task>;
};

/**
 * Layer definitions can be found here
 * https://docs.mapbox.com/mapbox-gl-js/style-spec/layers/
 */
export type TasksGeoJSONLayer = {
  __typename?: 'TasksGeoJSONLayer';
  filter?: Maybe<Scalars['Mixed']>;
  id: Scalars['ID'];
  layout?: Maybe<Scalars['Mixed']>;
  maxzoom?: Maybe<Scalars['Int']>;
  metadata?: Maybe<Scalars['Mixed']>;
  minzoom?: Maybe<Scalars['Int']>;
  paint?: Maybe<Scalars['Mixed']>;
  type: MapboxLayerType;
};

export type TasksGeoJSONLayers = {
  __typename?: 'TasksGeoJSONLayers';
  layers: Array<TasksGeoJSONLayer>;
};

export type TasksMapAdjusterList = {
  __typename?: 'TasksMapAdjusterList';
  data: Array<Client>;
};

export type TasksPerState = {
  __typename?: 'TasksPerState';
  count: Scalars['Int'];
  state: TaskState;
};

export type TasksSort = {
  columns?: InputMaybe<Array<TasksSortOrder>>;
};

export enum TasksSortAndFilterColumns {
  APPOINTMENT_DATE_FROM = 'APPOINTMENT_DATE_FROM',
  APPOINTMENT_DATE_TO = 'APPOINTMENT_DATE_TO',
  CLASSIFICATION_TAGS_ID = 'CLASSIFICATION_TAGS_ID',
  CLIENT_ID = 'CLIENT_ID',
  CLIENT_NAME = 'CLIENT_NAME',
  CONTROLLING_DATE = 'CONTROLLING_DATE',
  CREATED_AT = 'CREATED_AT',
  CUSTOMER_NAME = 'CUSTOMER_NAME',
  EXTERNAL_NUMBER = 'EXTERNAL_NUMBER',
  FOLLOW_UP_DATE = 'FOLLOW_UP_DATE',
  ID = 'ID',
  IDENTIFIER = 'IDENTIFIER',
  INVOICE_DATE = 'INVOICE_DATE',
  LEADER_CLIENT_ID = 'LEADER_CLIENT_ID',
  LEADER_NAME = 'LEADER_NAME',
  OWNER_CLIENT_ID = 'OWNER_CLIENT_ID',
  OWNER_NAME = 'OWNER_NAME',
  PARALLEL_NUMBER = 'PARALLEL_NUMBER',
  POLICY_HOLDER_CUSTOMER_NUMBER = 'POLICY_HOLDER_CUSTOMER_NUMBER',
  POLICY_HOLDER_INSURANCE_POLICY_NUMBER = 'POLICY_HOLDER_INSURANCE_POLICY_NUMBER',
  POLICY_NUMBER = 'POLICY_NUMBER',
  PRIMARY_FORM_TEMPLATE_ID = 'PRIMARY_FORM_TEMPLATE_ID',
  PRIMARY_LOCATION_CITY = 'PRIMARY_LOCATION_CITY',
  PRIMARY_LOCATION_POSTCODE = 'PRIMARY_LOCATION_POSTCODE',
  PRIMARY_LOCATION_STREET = 'PRIMARY_LOCATION_STREET',
  PRIMARY_LOCATION_STREET_NO = 'PRIMARY_LOCATION_STREET_NO',
  REMINDER_DATE = 'REMINDER_DATE',
  REWORK_DATE = 'REWORK_DATE',
  SET_REPORT_SENT_DATE = 'SET_REPORT_SENT_DATE',
  STATE_CHANGED_DATE = 'STATE_CHANGED_DATE',
  STATE_ID = 'STATE_ID',
  SUB_FORM_TAGS_ID = 'SUB_FORM_TAGS_ID',
  TASK_VISIT_TYPE_ID = 'TASK_VISIT_TYPE_ID',
  TASK_VISIT_TYPE_TITLE = 'TASK_VISIT_TYPE_TITLE',
  UPDATED_AT = 'UPDATED_AT',
}

export type TasksSortOrder = {
  column: TasksSortAndFilterColumns;
  order: SortOrder;
};

export enum Theme {
  CADET_GREY = 'CADET_GREY',
  DARK_LIVER = 'DARK_LIVER',
  DARK_SEA_GREEN = 'DARK_SEA_GREEN',
  INDIGO = 'INDIGO',
  LIGHT_BLUE = 'LIGHT_BLUE',
  MEDIUM_BLUE = 'MEDIUM_BLUE',
  MEDIUM_GREEN = 'MEDIUM_GREEN',
  MODERN_GREEN = 'MODERN_GREEN',
  ORANGE = 'ORANGE',
  PURPLE = 'PURPLE',
  QUICK_SILVER = 'QUICK_SILVER',
  RED = 'RED',
  YELLOW = 'YELLOW',
}

export enum ThemeColor {
  DARK = 'DARK',
  ERROR = 'ERROR',
  INFO = 'INFO',
  INVERSE = 'INVERSE',
  LIGHT = 'LIGHT',
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  SUCCESS = 'SUCCESS',
  TERTIARY = 'TERTIARY',
  WARNING = 'WARNING',
}

export type Token = {
  __typename?: 'Token';
  identity: Scalars['String'];
  key: Scalars['String'];
  unique_name: Scalars['String'];
};

export type TranscribeCredentials = {
  __typename?: 'TranscribeCredentials';
  accessKey: Scalars['String'];
  secretAccessKey: Scalars['String'];
  sessionToken: Scalars['String'];
};

/** Specify if you want to include or exclude trashed results from a query. */
export enum Trashed {
  /** Only return trashed results. */
  ONLY = 'ONLY',
  /** Return both trashed and non-trashed results. */
  WITH = 'WITH',
  /** Only return non-trashed results. */
  WITHOUT = 'WITHOUT',
}

export type UpdateAddressBookEntryInput = {
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateAppEmailTransportInput = {
  active: Scalars['Boolean'];
  smtp?: InputMaybe<AppEmailTransportSmtpInput>;
  title: Scalars['String'];
};

export type UpdateAppMessageInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  body?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<PageNotificationType>;
  visible_from?: InputMaybe<Scalars['DateTimeTz']>;
  visible_to?: InputMaybe<Scalars['DateTimeTz']>;
};

export type UpdateAppModuleInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  variables?: InputMaybe<Array<AppModuleSettingInput>>;
};

export type UpdateAppStampInput = {
  description?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type UpdateAreaAssignmentInput = {
  client_id_leader?: InputMaybe<Scalars['ID']>;
  client_id_primary?: InputMaybe<Scalars['ID']>;
  client_id_secondary?: InputMaybe<Scalars['ID']>;
  client_id_tertiary?: InputMaybe<Scalars['ID']>;
};

export type UpdateAssignmentSettingsInput = {
  assignment_auto_active: Scalars['Boolean'];
  assignment_max_tasks_max_per_day: Scalars['Int'];
  assignment_max_tasks_per_week: Scalars['Int'];
  assignment_max_tasks_week_start_day: AssignmentWeekDay;
  assignment_type: AssignmentType;
};

export type UpdateCalendarEventInput = {
  date_from: Scalars['DateTimeTz'];
  date_to: Scalars['DateTimeTz'];
  description?: InputMaybe<Scalars['String']>;
  is_all_day: Scalars['Boolean'];
  title: Scalars['String'];
  type_id: CalendarEventTypeId;
};

export type UpdateClientInput = {
  common?: InputMaybe<ClientCommonInput>;
  primary_location?: InputMaybe<ClientPrimaryLocationInput>;
  qualification_ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type UpdateClientLocationInput = {
  city: Scalars['String'];
  country_id: Scalars['ID'];
  id: Scalars['ID'];
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  notes?: InputMaybe<Scalars['String']>;
  postcode: Scalars['String'];
  street: Scalars['String'];
  street_no: Scalars['String'];
};

export type UpdateClientNotificationEmailSettingsInput = {
  notification_settings: Array<ClientNotificationEmailSettingsInput>;
};

export type UpdateClientNotificationSettingsInput = {
  notification_settings: Array<ClientNotificationSettingsInput>;
};

export type UpdateClientQualificationDisabledFormsInput = {
  disabled?: InputMaybe<Array<ClientQualificationDisabledFormsInput>>;
};

export type UpdateClientQualificationHideInsuranceInput = {
  hidden?: InputMaybe<Array<ClientQualificationHideInsuranceInput>>;
};

export type UpdateColorSettingsInput = {
  primary_color: Scalars['String'];
};

export type UpdateContactInput = {
  bic?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  client_location_id: Scalars['ID'];
  country_id?: InputMaybe<Scalars['ID']>;
  email?: InputMaybe<Scalars['String']>;
  email2?: InputMaybe<Scalars['String']>;
  fax_number?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  function_id?: InputMaybe<Scalars['ID']>;
  iban?: InputMaybe<Scalars['String']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  mobile?: InputMaybe<Scalars['String']>;
  mobile2?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  phone2?: InputMaybe<Scalars['String']>;
  postcode?: InputMaybe<Scalars['String']>;
  role_id?: InputMaybe<Scalars['ID']>;
  salutation_id?: InputMaybe<Scalars['ID']>;
  street?: InputMaybe<Scalars['String']>;
  street_no?: InputMaybe<Scalars['String']>;
};

export type UpdateCustomerPortalEventInput = {
  allow_customer_form?: InputMaybe<Scalars['Boolean']>;
  /** Should i send E-Mail */
  allow_customer_upload?: InputMaybe<Scalars['Boolean']>;
  /** Number of Customer */
  customer_email?: InputMaybe<Scalars['String']>;
  customer_name?: InputMaybe<Scalars['String']>;
  /** Freetext can be set by Operator */
  customer_phone?: InputMaybe<Scalars['String']>;
  /** The Type ID if the Event */
  from?: InputMaybe<Scalars['DateTimeTz']>;
  has_video?: InputMaybe<Scalars['Boolean']>;
  /** We need to allow the user just to send an plain link */
  notes?: InputMaybe<Scalars['String']>;
  /** Should i send SMS? */
  send_email?: InputMaybe<Scalars['Boolean']>;
  /** E-Mail of Customer */
  send_sms?: InputMaybe<Scalars['Boolean']>;
  to?: InputMaybe<Scalars['DateTimeTz']>;
  type_id: CustomerPortalEventTypeId;
};

export type UpdateDeviceCategoryInput = {
  title: Scalars['String'];
};

export type UpdateDeviceInput = {
  category_id?: InputMaybe<Scalars['ID']>;
  device_code?: InputMaybe<Scalars['String']>;
  device_type?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  state_id?: InputMaybe<Scalars['ID']>;
  unit_id?: InputMaybe<Scalars['ID']>;
  value?: InputMaybe<Scalars['Float']>;
};

export type UpdateFileInput = {
  file: Scalars['Upload'];
  file_id: Scalars['ID'];
  properties?: InputMaybe<FileItemCustomPropertiesInput>;
};

export type UpdateFilePropertiesInput = {
  file_id: Scalars['ID'];
  properties: FileItemCustomPropertiesInput;
};

export type UpdateGlobalSettingsInput = {
  activate_legacy_gdv: Scalars['Boolean'];
  app_title: Scalars['String'];
  auth_max_session_duration?: InputMaybe<Scalars['Int']>;
  auth_mode_2fa: Scalars['Boolean'];
  auth_mode_2fa_email: Scalars['Boolean'];
  auth_mode_2fa_sms: Scalars['Boolean'];
  auth_password_settings?: InputMaybe<AuthPasswordSettingsInput>;
  auth_single_session_per_user: Scalars['Boolean'];
  auto_logout_timeout?: InputMaybe<Scalars['Int']>;
  calendar_driving_times: DrivingTimeSetting;
  country_id: Scalars['ID'];
  currency_id: Scalars['ID'];
  show_weekends_in_calendar: Scalars['Boolean'];
  tax: Scalars['Float'];
  theme: Theme;
  webservice_active: Scalars['Boolean'];
};

export type UpdateLoginPageInput = {
  conditions?: InputMaybe<Scalars['String']>;
  imprint?: InputMaybe<Scalars['String']>;
  login_welcome_text?: InputMaybe<Scalars['String']>;
  privacy?: InputMaybe<Scalars['String']>;
};

export type UpdateMailSettingsInput = {
  mail_active?: InputMaybe<Scalars['Boolean']>;
  mail_default_footer?: InputMaybe<Scalars['String']>;
  mail_encryption?: InputMaybe<MailEncryptionType>;
  mail_from_address?: InputMaybe<Scalars['String']>;
  mail_from_name?: InputMaybe<Scalars['String']>;
  mail_host?: InputMaybe<Scalars['String']>;
  mail_password?: InputMaybe<Scalars['String']>;
  mail_port?: InputMaybe<Scalars['Int']>;
  mail_prefix?: InputMaybe<Scalars['String']>;
  mail_send_failure_notice?: InputMaybe<Scalars['Boolean']>;
  mail_username?: InputMaybe<Scalars['String']>;
};

export type UpdateMapBoundsInput = {
  map_bounds?: InputMaybe<BoundsInput>;
  map_country_id?: InputMaybe<Scalars['Int']>;
};

export type UpdateModuleSettingsInput = {
  mobile_app_active: Scalars['Boolean'];
  mobile_can_accept: Scalars['Boolean'];
  mobile_can_set_appointments: Scalars['Boolean'];
  mobile_lock_tasks_when_mobile_active: Scalars['Boolean'];
  module_calculation_active: Scalars['Boolean'];
  module_calculation_default_net: Scalars['Boolean'];
  module_calculation_hide_column_accepted: Scalars['Boolean'];
  module_calculation_hide_column_payout_state: Scalars['Boolean'];
  module_calculation_hide_column_status: Scalars['Boolean'];
  module_customer_portal_active: Scalars['Boolean'];
  module_devices_active: Scalars['Boolean'];
  module_documents_active: Scalars['Boolean'];
  module_emails_active: Scalars['Boolean'];
  module_extended_forms_active: Scalars['Boolean'];
  module_images_active: Scalars['Boolean'];
  module_invoices_active: Scalars['Boolean'];
  module_logs_active: Scalars['Boolean'];
  module_qualifications_active: Scalars['Boolean'];
  module_room_plan_active: Scalars['Boolean'];
  module_statistic_active: Scalars['Boolean'];
  module_subtasks_active: Scalars['Boolean'];
};

export type UpdateNotificationSettingsInput = {
  notification_settings: Array<AppNotificationSettingInput>;
  notifications_leader_added_document?: InputMaybe<NotificationSetting>;
  notifications_leader_assigned_task?: InputMaybe<NotificationSetting>;
  notifications_leader_controlling_assigned?: InputMaybe<NotificationSetting>;
  notifications_leader_set_appointment?: InputMaybe<NotificationSetting>;
  notifications_leader_todo_expired?: InputMaybe<NotificationSetting>;
  notifications_leader_todo_will_expire?: InputMaybe<NotificationSetting>;
  notifications_leader_user_changed?: InputMaybe<NotificationSetting>;
  notifications_user_added_document?: InputMaybe<NotificationSetting>;
  /** notifications_XXX field are deprecated and will be removed */
  notifications_user_assigned_task?: InputMaybe<NotificationSetting>;
  notifications_user_todo_expired?: InputMaybe<NotificationSetting>;
  notifications_user_todo_will_expire?: InputMaybe<NotificationSetting>;
};

export type UpdatePolicyHolderInput = {
  additionalPolicyHolder?: InputMaybe<ClientAdditionalPolicyHolderInput>;
  common?: InputMaybe<PolicyHolderCommonInput>;
  primary_location?: InputMaybe<PolicyHolderLocationInput>;
  qualification_ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type UpdateReportLayoutInput = {
  bg_dark_color?: InputMaybe<Scalars['String']>;
  calculation_layout?: InputMaybe<CalculationLayout>;
  cover_layout?: InputMaybe<CoverLayout>;
  cover_title?: InputMaybe<Scalars['String']>;
  default?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  footer?: InputMaybe<Scalars['String']>;
  footer_paginate_title?: InputMaybe<Scalars['String']>;
  hide_primary_form?: InputMaybe<Scalars['Boolean']>;
  highlight_color?: InputMaybe<Scalars['String']>;
  images_layout_1?: InputMaybe<ImagesLayout1>;
  images_layout_2?: InputMaybe<ImagesLayout2>;
  images_layout_4?: InputMaybe<ImagesLayout4>;
  is_customer_report?: InputMaybe<Scalars['Boolean']>;
  order_identifiers?: InputMaybe<Array<PageType>>;
  question_filter?: InputMaybe<ReportQuestionFilterType>;
  questions_layout?: InputMaybe<QuestionLayout>;
  room_plan_layout?: InputMaybe<RoomPlanLayout>;
  show_notes?: InputMaybe<Scalars['Boolean']>;
  summary_layout?: InputMaybe<SummaryLayout>;
  tint_color?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  toc_layout?: InputMaybe<TOCLayout>;
  use_alternate_logo?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateServiceCatalogItemInput = {
  catalog_id?: InputMaybe<Scalars['ID']>;
  category_id?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  identifier?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Float']>;
  title?: InputMaybe<Scalars['String']>;
  unit_id?: InputMaybe<Scalars['ID']>;
};

export type UpdateTaskCalculationInput = {
  accepted_alias?: InputMaybe<Scalars['String']>;
  active_catalog_id?: InputMaybe<Scalars['ID']>;
  group_by?: InputMaybe<Array<InputMaybe<CalculationGroupType>>>;
  hide_column_accepted?: InputMaybe<Scalars['Boolean']>;
  net_calculation?: InputMaybe<Scalars['Boolean']>;
  status_alias?: InputMaybe<Scalars['String']>;
  sum_alias?: InputMaybe<Scalars['String']>;
  tax_deductible?: InputMaybe<Scalars['Boolean']>;
  time_value_alias?: InputMaybe<Scalars['String']>;
  time_value_column_active?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateTaskDriveLogInput = {
  drive_log_distance?: InputMaybe<Scalars['Float']>;
  drive_log_note?: InputMaybe<Scalars['String']>;
  final_mileage?: InputMaybe<Scalars['Float']>;
  initial_mileage?: InputMaybe<Scalars['Float']>;
};

export type UpdateTaskFolderPermissionsInput = {
  allowed_client_type_ids: Array<ClientTypeId>;
};

export type UpdateTaskFollowUpDateInput = {
  follow_up_date?: InputMaybe<Scalars['DateTimeTz']>;
};

export type UpdateTaskFormInput = {
  tag_ids?: InputMaybe<Array<Scalars['ID']>>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateTaskInput = {
  article_id?: InputMaybe<Scalars['ID']>;
  assigned_date?: InputMaybe<Scalars['DateTimeTz']>;
  client_contact_id?: InputMaybe<Scalars['ID']>;
  client_id?: InputMaybe<Scalars['ID']>;
  client_location_id?: InputMaybe<Scalars['ID']>;
  damage_date?: InputMaybe<Scalars['DateTimeTz']>;
  damage_note?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  external_number?: InputMaybe<Scalars['String']>;
  identifier?: InputMaybe<Scalars['String']>;
  magic_plan_id?: InputMaybe<Scalars['String']>;
  parallel_number?: InputMaybe<Scalars['String']>;
  policy_number?: InputMaybe<Scalars['String']>;
  reserve?: InputMaybe<Scalars['Float']>;
  task_visit_type_id?: InputMaybe<Scalars['ID']>;
  urgent?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateTaskInvoiceInput = {
  note?: InputMaybe<Scalars['String']>;
  reminder: Scalars['Boolean'];
};

export type UpdateTaskInvoiceItemsInput = {
  items: Array<TaskInvoiceItemInput>;
};

export type UpdateTaskReminderDateInput = {
  reminder_date?: InputMaybe<Scalars['DateTimeTz']>;
};

export type UpdateTaskSubTaskInput = {
  assigned_client_id?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  due_date?: InputMaybe<Scalars['DateTimeTz']>;
  file_id?: InputMaybe<Scalars['ID']>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateTaskTemplateAssignmentInput = {
  assignments?: InputMaybe<Array<TaskTemplateClientAssignmentInput>>;
};

export type UpdateTaskTemplateFormInput = {
  json: Scalars['JSON'];
};

export type UpdateTaskTemplateInput = {
  accepted_alias?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  assignments?: InputMaybe<Array<TaskTemplateClientAssignmentInput>>;
  classification_id?: InputMaybe<Scalars['ID']>;
  color?: InputMaybe<Scalars['String']>;
  default_categories?: InputMaybe<Array<DefaultCategoryInput>>;
  default_supervisor_id?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  enable_word_convert?: InputMaybe<Scalars['Boolean']>;
  identifier?: InputMaybe<Scalars['String']>;
  shortcut?: InputMaybe<Scalars['String']>;
  show_time_value_calculation?: InputMaybe<Scalars['Boolean']>;
  status_alias?: InputMaybe<Scalars['String']>;
  sum_alias?: InputMaybe<Scalars['String']>;
  time_value_alias?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  type_id?: InputMaybe<TaskTemplateTypeId>;
};

export type UpdateTasksSettingsInput = {
  activate_task_default_off?: InputMaybe<Scalars['Boolean']>;
  adjuster_can_change_task_location?: InputMaybe<Scalars['Boolean']>;
  adjuster_can_create_tasks?: InputMaybe<Scalars['Boolean']>;
  adjuster_can_duplicate_own_tasks?: InputMaybe<Scalars['Boolean']>;
  adjuster_can_send_interface?: InputMaybe<Scalars['Boolean']>;
  adjuster_can_set_rework?: InputMaybe<Scalars['Boolean']>;
  allow_adjuster_to_send_emails?: InputMaybe<Scalars['Boolean']>;
  auto_accept_task?: InputMaybe<Scalars['Boolean']>;
  create_task_auto_fill_client?: InputMaybe<Scalars['Boolean']>;
  create_task_auto_fill_client_address?: InputMaybe<Scalars['Boolean']>;
  disable_task_type_on_desk?: InputMaybe<Scalars['Boolean']>;
  disable_task_type_video?: InputMaybe<Scalars['Boolean']>;
  everybody_can_see_finished_tasks?: InputMaybe<Scalars['Boolean']>;
  global_mail?: InputMaybe<Scalars['String']>;
  global_phone?: InputMaybe<Scalars['String']>;
  hide_drive_log?: InputMaybe<Scalars['Boolean']>;
  hide_log_times?: InputMaybe<Scalars['Boolean']>;
  leader_can_assign?: InputMaybe<Scalars['Boolean']>;
  load_predefined_email?: InputMaybe<Scalars['Boolean']>;
  log_mails_in_task_log?: InputMaybe<Scalars['Boolean']>;
  max_export_pages?: InputMaybe<Scalars['Int']>;
  msg_convert_single_file?: InputMaybe<Scalars['Boolean']>;
  permissions_delete_files_admin_only?: InputMaybe<Scalars['Boolean']>;
  show_adjuster_email_service_provider_block?: InputMaybe<Scalars['Boolean']>;
  show_summary_service_provider_block?: InputMaybe<Scalars['Boolean']>;
  statistic_required_for_completion?: InputMaybe<Scalars['Boolean']>;
  statistic_required_for_controlling?: InputMaybe<Scalars['Boolean']>;
  tasks_auto_assign_leader_when_empty?: InputMaybe<Scalars['Boolean']>;
  tasks_auto_generate_info_file?: InputMaybe<Scalars['Boolean']>;
  tasks_delete_tags_on_complete?: InputMaybe<Scalars['Boolean']>;
  tasks_fields_client_contact_id_active?: InputMaybe<Scalars['Boolean']>;
  tasks_fields_external_number_active?: InputMaybe<Scalars['Boolean']>;
  tasks_fields_identifier_active?: InputMaybe<Scalars['Boolean']>;
  tasks_fields_parallel_number_active?: InputMaybe<Scalars['Boolean']>;
  tasks_fields_urgent_active?: InputMaybe<Scalars['Boolean']>;
  tasks_filter_cancelled?: InputMaybe<Scalars['Boolean']>;
  tasks_hide_duplicate?: InputMaybe<Scalars['Boolean']>;
  tasks_leader_visible_all?: InputMaybe<Scalars['Boolean']>;
  tasks_max_sub_owner_count?: InputMaybe<Scalars['Int']>;
  tasks_notify_owner_on_create?: InputMaybe<Scalars['Boolean']>;
  tasks_notify_owner_on_new_file?: InputMaybe<Scalars['Boolean']>;
  tasks_num_routes?: InputMaybe<Scalars['Int']>;
  tasks_state_rework_active?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateUserInput = {
  client_id?: InputMaybe<Scalars['ID']>;
  email: Scalars['String'];
  name: Scalars['String'];
};

export type UpdateUserPasswordInput = {
  password: Scalars['String'];
  password_confirmation: Scalars['String'];
};

export type UpdateUserRoleInput = {
  role_id: Scalars['ID'];
};

export type UploadFileInput = {
  file: Scalars['Upload'];
  properties?: InputMaybe<FileItemCustomPropertiesInput>;
  uploaded_at?: InputMaybe<Scalars['DateTimeTz']>;
};

export type User = {
  __typename?: 'User';
  client?: Maybe<Client>;
  client_id?: Maybe<Scalars['ID']>;
  /** User specific permissions for user */
  current_ip?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  email_verified_at?: Maybe<Scalars['DateTimeTz']>;
  fullname: Scalars['String'];
  globalPermissions: Array<Permission>;
  id: Scalars['ID'];
  last_login_at?: Maybe<Scalars['DateTimeTz']>;
  name: Scalars['String'];
  /** Global permissions for user */
  permissions: Array<Permission>;
  profile_image?: Maybe<FileItem>;
  /** Current ip of logged in user */
  role: UserRole;
  updated_at: Scalars['DateTimeTz'];
};

export type UserPaginator = {
  __typename?: 'UserPaginator';
  data: Array<User>;
  paginatorInfo: PaginatorInfo;
};

export type UserResponse = {
  __typename?: 'UserResponse';
  message: Scalars['String'];
};

export enum UserRole {
  ADJUSTER = 'ADJUSTER',
  ADMIN = 'ADMIN',
  CUSTOMER = 'CUSTOMER',
  LEADER = 'LEADER',
  NONE = 'NONE',
  SUPERVISOR = 'SUPERVISOR',
}

export type UsersFilter = {
  AND?: InputMaybe<Array<UsersFilter>>;
  OR?: InputMaybe<Array<UsersFilter>>;
  column?: InputMaybe<UsersSortAndFilterColumns>;
  operator?: InputMaybe<SQLOperator>;
  value?: InputMaybe<Scalars['Mixed']>;
};

export type UsersSort = {
  columns?: InputMaybe<Array<UsersSortOrder>>;
};

export enum UsersSortAndFilterColumns {
  EMAIL = 'EMAIL',
  FULLNAME = 'FULLNAME',
  ID = 'ID',
  NAME = 'NAME',
}

export type UsersSortOrder = {
  column: UsersSortAndFilterColumns;
  order: SortOrder;
};

export type VerifyEmailInput = {
  token: Scalars['String'];
};

export type VerifyEmailResponse = {
  __typename?: 'VerifyEmailResponse';
  message: Scalars['String'];
};

export type VerifyLoginInput = {
  code: Scalars['String'];
  email: Scalars['String'];
};

export type VideoMessageInput = {
  message: Scalars['String'];
  to: Scalars['String'];
};

/** Dynamic WHERE conditions for queries. */
export type WhereConditions = {
  /** A set of conditions that requires all conditions to match. */
  AND?: InputMaybe<Array<WhereConditions>>;
  /** Check whether a relation exists. Extra conditions or a minimum amount can be applied. */
  HAS?: InputMaybe<WhereConditionsRelation>;
  /** A set of conditions that requires at least one condition to match. */
  OR?: InputMaybe<Array<WhereConditions>>;
  /** The column that is used for the condition. */
  column?: InputMaybe<Scalars['String']>;
  /** The operator that is used for the condition. */
  operator?: InputMaybe<SQLOperator>;
  /** The value that is used for the condition. */
  value?: InputMaybe<Scalars['Mixed']>;
};

/** Dynamic HAS conditions for WHERE condition queries. */
export type WhereConditionsRelation = {
  /** The amount to test. */
  amount?: InputMaybe<Scalars['Int']>;
  /** Additional condition logic. */
  condition?: InputMaybe<WhereConditions>;
  /** The comparison operator to test against the amount. */
  operator?: InputMaybe<SQLOperator>;
  /** The relation that is checked. */
  relation: Scalars['String'];
};

export type WorkloadCounts = {
  __typename?: 'WorkloadCounts';
  available_owners: Scalars['Int'];
  daily_new_tasks_per_owner: Scalars['Float'];
  in_work_average_per_owner: Scalars['Float'];
};

export type previewIdentifier = {
  __typename?: 'previewIdentifier';
  identifier: Scalars['Int'];
};

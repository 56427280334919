import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { UserItemFragmentDoc } from '../../../users/graphql/generated/types.generated';
import { UserAbilityFragmentDoc } from '../../../casl/graphql/generated/UserAbilityFragment.generated';
export type ClientDetailFragment = {
  __typename?: 'Client';
  id: string;
  type_id: string;
  type: Types.ClientTypeId;
  salutation_id: string;
  leader_client_id?: string | null;
  name: string;
  email?: string | null;
  phone?: string | null;
  web?: string | null;
  notes?: string | null;
  assignment_notes?: string | null;
  state_id: Types.ClientStateId;
  state_notes?: string | null;
  external_number?: string | null;
  external_client_id?: string | null;
  permissions: Array<Types.Permission>;
  created_at: string;
  updated_at: string;
  primary_location?: {
    __typename?: 'ClientLocation';
    id: string;
    street?: string | null;
    street_no?: string | null;
    postcode?: string | null;
    city?: string | null;
    country_id?: string | null;
    different_policy_holder?: string | null;
    client_location_type: { __typename?: 'ClientLocationType'; title: string };
  } | null;
  client_additional_policy_holder?: {
    __typename?: 'ClientAdditionalPolicyHolder';
    insurance_policy_number?: string | null;
    customer_number?: string | null;
    customer_state_id?: string | null;
    customer_state_date?: string | null;
    customer_state?: { __typename?: 'ClientCustomerState'; title: string } | null;
  } | null;
  client_type?: {
    __typename?: 'ClientType';
    title: string;
    color: string;
    id: string;
    type_id: Types.ClientTypeId;
  } | null;
  user?: {
    __typename?: 'User';
    id: string;
    name: string;
    client_id?: string | null;
    email: string;
    updated_at: string;
    last_login_at?: string | null;
    email_verified_at?: string | null;
    role: Types.UserRole;
    permissions: Array<Types.Permission>;
  } | null;
  disabled_templates: Array<{
    __typename?: 'ClientQualificationFormDisabled';
    id: string;
    template_id: string;
  }>;
  hidden_insurances: Array<{
    __typename?: 'ClientQualificationHideInsurance';
    id: string;
    insurance_client_id: string;
  }>;
  assigned_email_templates?: Array<{
    __typename?: 'EmailTemplate';
    id: string;
    title: string;
  }> | null;
  assigned_interface_message_templates?: Array<{
    __typename?: 'InterfaceMessageTemplate';
    id: string;
    title: string;
  }> | null;
  direct_message_email_templates?: Array<{
    __typename?: 'EmailTemplate';
    id: string;
    title: string;
  }> | null;
  direct_message_interface_message_templates?: Array<{
    __typename?: 'InterfaceMessageTemplate';
    id: string;
    title: string;
  }> | null;
  assigned_templates: Array<
    | { __typename: 'EmailTemplate'; id: string; title: string; description?: string | null }
    | {
        __typename: 'InterfaceMessageTemplate';
        id: string;
        title: string;
        description?: string | null;
      }
  >;
};

export const ClientDetailFragmentDoc = gql`
  fragment ClientDetail on Client {
    id
    type_id
    type
    salutation_id
    leader_client_id
    name
    email
    phone
    web
    notes
    assignment_notes
    state_id
    state_notes
    external_number
    external_client_id
    primary_location {
      id
      street
      street_no
      postcode
      city
      country_id
      different_policy_holder
      client_location_type {
        title
      }
    }
    client_additional_policy_holder {
      insurance_policy_number
      customer_number
      customer_state_id
      customer_state {
        title
      }
      customer_state_date
    }
    client_type {
      title
      color
      id
      type_id
    }
    user {
      ...UserItem
    }
    disabled_templates {
      id
      template_id
    }
    hidden_insurances {
      id
      insurance_client_id
    }
    assigned_email_templates {
      id
      title
    }
    assigned_interface_message_templates {
      id
      title
    }
    direct_message_email_templates {
      id
      title
    }
    direct_message_interface_message_templates {
      id
      title
    }
    assigned_templates {
      __typename
      ... on EmailTemplate {
        id
        title
        description
      }
      ... on InterfaceMessageTemplate {
        id
        title
        description
      }
    }
    permissions
    created_at
    updated_at
    user {
      ...UserAbility
    }
  }
  ${UserItemFragmentDoc}
  ${UserAbilityFragmentDoc}
`;

import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { TaskImageFragmentDoc } from './taskImageFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UploadTaskImageMutationVariables = Types.Exact<{
  taskId: Types.Scalars['ID'];
  file: Types.UploadFileInput;
}>;

export type UploadTaskImageMutation = {
  __typename?: 'Mutation';
  uploadTaskImages: {
    __typename?: 'TaskImagesPaginator';
    data: Array<{
      __typename?: 'FileItem';
      id: string;
      name: string;
      file_name: string;
      original_file_name: string;
      url: string;
      url_thumb?: string | null;
      size: number;
      url_thumb_report?: string | null;
      url_download: string;
      is_extracting_pages: boolean;
      permissions: Array<Types.Permission>;
      custom_properties: {
        __typename?: 'FileItemCustomProperties';
        description?: string | null;
        is_cover?: boolean | null;
        visible?: boolean | null;
        number_of_pages?: number | null;
        export_pages?: Array<number> | null;
      };
    }>;
  };
};

export const UploadTaskImageDocument = gql`
  mutation UploadTaskImage($taskId: ID!, $file: UploadFileInput!) {
    uploadTaskImages(task_id: $taskId, files: [$file]) {
      data {
        ...TaskImage
      }
    }
  }
  ${TaskImageFragmentDoc}
`;

export function useUploadTaskImageMutation() {
  return Urql.useMutation<UploadTaskImageMutation, UploadTaskImageMutationVariables>(
    UploadTaskImageDocument,
  );
}

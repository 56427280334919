import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { TaskStateFieldsFragmentDoc } from './useTaskStates.generated';
import { ClientSelectFragmentDoc } from '../../../clients/graphql/generated/clientSelectFragment.generated';
import { ClassificationTagFragmentDoc } from '../../../classification-tags/graphql/generated/classificationTagFragment.generated';
import { SubformClassificationTagFragmentDoc } from '../../../classification-tags/graphql/generated/subformClassificationTagFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type TaskOverviewFiltersQueryVariables = Types.Exact<{ [key: string]: never }>;

export type TaskOverviewFiltersQuery = {
  __typename?: 'Query';
  taskTemplatesAll: Array<{
    __typename?: 'TaskTemplate';
    id: string;
    title: string;
    shortcut?: string | null;
    color: string;
    active?: boolean | null;
    json?: any | null;
    type_id: Types.TaskTemplateTypeId;
  }>;
  taskStates: Array<{
    __typename?: 'TaskState';
    id: string;
    title: string;
    color: string;
    state_id: Types.TaskStateEnum;
  }>;
  insurances: {
    __typename?: 'InsuranceList';
    data: Array<{ __typename?: 'Client'; id: string; name: string; email?: string | null }>;
  };
  classificationTags: {
    __typename?: 'ClassificationTagsPaginator';
    data: Array<{
      __typename?: 'ClassificationTag';
      id: string;
      title: string;
      color: string;
      alias: string;
      api_only: boolean;
    }>;
  };
  tags: {
    __typename?: 'TagPaginator';
    data: Array<{
      __typename?: 'Tag';
      id: string;
      title: string;
      color: string;
      type_id: string;
      type: { __typename?: 'TagType'; id: string; title: string; color: string };
    }>;
  };
};

export const TaskOverviewFiltersDocument = gql`
  query TaskOverviewFilters {
    taskTemplatesAll {
      id
      title
      shortcut
      color
      active
      json
      type_id
    }
    taskStates {
      ...TaskStateFields
    }
    insurances {
      data {
        ...ClientSelect
      }
    }
    classificationTags(
      filter: { AND: [] }
      sort: { columns: [] }
      pagination: { skip: 0, take: 10000 }
    ) {
      data {
        ...ClassificationTag
      }
    }
    tags(filter: { AND: [] }, sort: { columns: [] }, pagination: { skip: 0, take: 10000 }) {
      data {
        ...SubformClassificationTag
      }
    }
  }
  ${TaskStateFieldsFragmentDoc}
  ${ClientSelectFragmentDoc}
  ${ClassificationTagFragmentDoc}
  ${SubformClassificationTagFragmentDoc}
`;

export function useTaskOverviewFiltersQuery(
  options?: Omit<Urql.UseQueryArgs<TaskOverviewFiltersQueryVariables>, 'query'>,
) {
  return Urql.useQuery<TaskOverviewFiltersQuery, TaskOverviewFiltersQueryVariables>({
    query: TaskOverviewFiltersDocument,
    ...options,
  });
}

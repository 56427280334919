// TODO IN-3545
// Read from AppSettings query when API is ready, and remove this mock.
// See: https://inspcto.atlassian.net/browse/IN-3545

import { MAX_FILE_SIZE } from '../config/env';

export const mock_mutations_constraints = {
  uploadTaskFiles: {
    max_filesize_bytes: MAX_FILE_SIZE,
    allowed_extensions: [
      'doc',
      'docx',
      'eml',
      'gif',
      'htm',
      'html',
      'jpeg',
      'jpg',
      'json',
      'mp4',
      'msg',
      'odp',
      'odt',
      'pdf',
      'png',
      'ppt',
      'pptx',
      'rtf',
      'tif',
      'tiff',
      'txt',
      'xls',
      'xlsm',
      'xlsx',
    ],
  },
  uploadTaskImages: {
    max_filesize_bytes: MAX_FILE_SIZE,
    allowed_extensions: ['gif', 'jpeg', 'jpg', 'png', 'tif', 'tiff'],
  },
  uploadTaskDocuments: {
    max_filesize_bytes: MAX_FILE_SIZE,
    allowed_extensions: ['pdf'],
  },
};

import './UploadDropzone.scss';

import { useFormatPercentage } from '@module/shared/localization';
import { NotificationType, useNotifications } from '@module/shared/notifications';
import { Button } from '@progress/kendo-react-buttons';
import { classNames } from '@progress/kendo-react-common';
import { UploadFileStatus } from '@progress/kendo-react-upload';
import { uniqBy } from 'lodash';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { DropzoneOptions, ErrorCode, FileError, useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import { formatBytes } from '../../../shared/helpers';
import { MAX_FILE_SIZE } from '../../config/env';
import { fileTypeIcons, guessFileTypeFromFileName } from '../../helpers';
import { CustomProgressBar } from './CustomUpload';
import { UploadRestrictions } from './types';

enum UploadDropzoneFileStatus {
  Uploading = UploadFileStatus.Uploading,
  UploadFailed = UploadFileStatus.UploadFailed,
  Uploaded = UploadFileStatus.Uploaded,
}

interface UploadDropzoneFileBag {
  uid: string;
  status: UploadDropzoneFileStatus;
  progress: number;
  file: File;
}

const useTranslateDropzoneFileError = ({
  maxFileSize,
  allowedExtensions,
}: {
  allowedExtensions: NonNullable<UploadRestrictions['allowedExtensions']>;
  maxFileSize: NonNullable<UploadRestrictions['maxFileSize']>;
}) => {
  const { t } = useTranslation();

  return useCallback(
    (fileError: FileError): FileError => {
      if (fileError.code === ErrorCode.FileTooLarge) {
        return {
          ...fileError,
          message: t('common.components.uploadDropzone.errors.fileTooLarge', {
            maxFileSize: formatBytes(maxFileSize),
          }),
        };
      }

      if (fileError.code === ErrorCode.FileInvalidType) {
        return {
          ...fileError,
          message: t('common.components.uploadDropzone.errors.fileInvalidType', {
            allowedFileTypes: allowedExtensions.flat().join(', '),
          }),
        };
      }

      return fileError;
    },
    [t, allowedExtensions, maxFileSize],
  );
};

const getAllUploaded = (files: Array<UploadDropzoneFileBag>): boolean =>
  files.length > 0 && files.every(({ status }) => status === UploadDropzoneFileStatus.Uploaded);

const getAllSettled = (files: Array<UploadDropzoneFileBag>): boolean =>
  files.length > 0 &&
  files.every(
    ({ status }) =>
      status === UploadDropzoneFileStatus.Uploaded ||
      status === UploadDropzoneFileStatus.UploadFailed,
  );

const ProgressPanel = (props: {
  files: Array<UploadDropzoneFileBag>;
  onRetry: (fileBag: UploadDropzoneFileBag) => void;
  onClose: () => void;
}) => {
  const { files, onRetry, onClose } = props;

  const { t } = useTranslation();
  const formatPercentage = useFormatPercentage();

  const uploadedCount = files.filter(({ status }) => status === UploadDropzoneFileStatus.Uploaded);

  if (files.length === 0) {
    return null;
  }

  const allSettled = getAllSettled(files);

  return (
    <div className="UploadDropzone__Panel">
      {allSettled && (
        <Button
          className="!k-absolute k-top-2.5 k-right-2"
          iconClass="l-i-x"
          fillMode="flat"
          size="medium"
          onClick={onClose}
          aria-label={t('common.labels.close')}
        />
      )}

      <div className="k-d-flex-col k-gap-3 k-py-4">
        <div className="k-px-4">
          <p className="k-font-weight-semibold !k-m-0" style={{ outline: '1px solid transparent' }}>
            {t('common.components.uploadDropzone.title', {
              uploaded: uploadedCount.length,
              total: files.length,
            })}
          </p>
        </div>

        <ul className="UploadDropzone__List k-list-none k-px-4 k-m-0">
          {files.map((fileBag) => (
            <li
              key={fileBag.uid}
              className={classNames('k-d-flex k-align-items-center k-gap-3 k-py-2')}
            >
              <span className="k-flex-shrink-0">
                <span
                  className={classNames(
                    'u-text-4xl',
                    fileTypeIcons[guessFileTypeFromFileName(fileBag.file.name)],
                    fileBag.status === UploadDropzoneFileStatus.UploadFailed && 'u-text-error-600',
                  )}
                />
              </span>
              <div className="k-d-flex-col k-flex-grow k-gap-1 k-min-w-0">
                <div className="k-d-flex k-align-items-center k-justify-content-between">
                  <div
                    className={classNames(
                      'UploadDropzone__FileName',
                      fileBag.status === UploadDropzoneFileStatus.UploadFailed &&
                        'u-text-error-600',
                    )}
                  >
                    {fileBag.file.name}
                  </div>

                  {fileBag.status === UploadDropzoneFileStatus.UploadFailed && (
                    <Button
                      className="UploadDropzone__Retry"
                      iconClass="l-i-refresh-cw"
                      fillMode="flat"
                      size="small"
                      aria-label={t('common.labels.retry')}
                      onClick={() => onRetry(fileBag)}
                    />
                  )}

                  {fileBag.status === UploadDropzoneFileStatus.Uploaded && (
                    <span className="l-i-check UploadDropzone__SuccessIcon" />
                  )}
                </div>
                <div className="k-d-flex k-align-items-center k-gap-3">
                  <CustomProgressBar className="k-flex-grow" value={fileBag.progress} />
                  <div className="UploadDropzone__Percentage k-flex-shrink-0">
                    {formatPercentage(fileBag.progress / 100)}
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

interface UploadDropzoneUploadEvent {
  files: Array<UploadDropzoneFileBag>;
  onFileProgress: (uid: string, progress: number) => void;
  onFileUploaded: (uid: string) => void;
  onFileUploadFailed: (uid: string) => void;
}

export type UploadDropzoneOnUpload = (event: UploadDropzoneUploadEvent) => Promise<void>;

export interface UploadDropzoneProps extends UploadRestrictions {
  className?: string;
  children: ReactNode;
  validator?: DropzoneOptions['validator'];
  onUpload: UploadDropzoneOnUpload;
}

export const UploadDropzone = (props: UploadDropzoneProps) => {
  const {
    className,
    children,
    allowedExtensions,
    maxFileSize = MAX_FILE_SIZE,
    minFileSize,
    validator,
    onUpload,
  } = props;

  const accept = useMemo(
    () => ({
      // Using an empty string as key to not restrict by MIME type, only by file extension.
      '': allowedExtensions.flat().map((extension) => `.${extension}`),
    }),
    [allowedExtensions],
  );

  const { showNotification } = useNotifications();
  const translateFileError = useTranslateDropzoneFileError({ allowedExtensions, maxFileSize });

  const [files, setFiles] = useState<UploadDropzoneFileBag[]>([]);

  // Autoclose the panel after a timeout when all files are uploaded.
  useEffect(() => {
    const allUploaded = getAllUploaded(files);

    if (allUploaded) {
      const timeout = setTimeout(
        () =>
          setFiles((state) => {
            // Need to recheck the condition after the timeout has passed, because the user might
            // have dropped more files.
            const stillAllUploaded = getAllUploaded(state);

            if (stillAllUploaded) {
              return [];
            }

            return state;
          }),
        2000,
      );

      return () => clearTimeout(timeout);
    }
  }, [files]);

  const setFileProgress = useCallback(
    (uid: string, progress: number) =>
      setFiles((state) =>
        state.map((fileBag) => (fileBag.uid === uid ? { ...fileBag, progress } : fileBag)),
      ),
    [],
  );

  const setFileUploaded = useCallback(
    (uid: string) =>
      setFiles((state) =>
        state.map((fileBag) =>
          fileBag.uid === uid
            ? { ...fileBag, status: UploadDropzoneFileStatus.Uploaded, progress: 100 }
            : fileBag,
        ),
      ),
    [],
  );

  const setFileUploadFailed = useCallback(
    (uid: string) =>
      setFiles((state) =>
        state.map((fileBag) =>
          fileBag.uid === uid
            ? { ...fileBag, status: UploadDropzoneFileStatus.UploadFailed }
            : fileBag,
        ),
      ),
    [],
  );

  const upload = useCallback(
    (files: Array<UploadDropzoneFileBag>) =>
      onUpload({
        files,
        onFileProgress: setFileProgress,
        onFileUploaded: setFileUploaded,
        onFileUploadFailed: setFileUploadFailed,
      }),
    [onUpload, setFileProgress, setFileUploaded, setFileUploadFailed],
  );

  const handleDropAccepted = useCallback<NonNullable<DropzoneOptions['onDropAccepted']>>(
    (acceptedFiles) => {
      const addedFiles = acceptedFiles.map<UploadDropzoneFileBag>((file) => ({
        uid: uuid(),
        status: UploadDropzoneFileStatus.Uploading,
        progress: 0,
        file,
      }));

      setFiles((state) => [
        // For subsequent drops, only keep files that are still pending.
        ...state.filter(({ status }) => status === UploadDropzoneFileStatus.Uploading),
        ...addedFiles,
      ]);

      return upload(addedFiles);
    },
    [upload],
  );

  const handleDropRejected = useCallback<NonNullable<DropzoneOptions['onDropRejected']>>(
    (fileRejections) => {
      const uniqFileErrors = uniqBy(
        fileRejections.flatMap(({ errors }) => errors),
        ({ code }) => code,
      );

      const disabledError = uniqFileErrors.find(({ code }) => code === 'disabled');

      // No need to show subsequent errors if the upload is disabled
      if (disabledError) {
        showNotification(translateFileError(disabledError).message, NotificationType.Error);
        return;
      }

      uniqFileErrors.forEach((fileError) =>
        showNotification(translateFileError(fileError).message, NotificationType.Error),
      );
    },
    [showNotification, translateFileError],
  );

  const handleRetry = useCallback(
    (fileBag: UploadDropzoneFileBag) => {
      const fileToRetry = { ...fileBag, status: UploadDropzoneFileStatus.Uploading, progress: 0 };

      setFiles((state) =>
        state.map((fileBag) => (fileBag.uid === fileToRetry.uid ? fileToRetry : fileBag)),
      );

      upload([fileToRetry]);
    },
    [upload],
  );

  const handleClose = useCallback(() => setFiles([]), []);

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    noClick: true,
    accept,
    validator,
    onDropAccepted: handleDropAccepted,
    onDropRejected: handleDropRejected,
    maxSize: maxFileSize,
    minSize: minFileSize,
  });

  return (
    <>
      <div
        {...getRootProps({
          className: classNames(className, 'UploadDropzone', {
            isAccepted: isDragActive && isDragAccept,
            isRejected: isDragActive && isDragReject,
          }),
        })}
      >
        <input {...getInputProps()} />

        {children}
      </div>

      <ProgressPanel files={files} onRetry={handleRetry} onClose={handleClose} />
    </>
  );
};

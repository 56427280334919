import { MeFragment } from '@module/casl';
import {
  ClientNotificationSettingsInput,
  Loader,
  NotificationTypeId,
  useGroupedNotificationTypesQuery,
} from '@module/common';
import { Divider } from '@module/layout';
import { Checkbox } from '@module/shared/forms';
import { NotificationType, useNotifications } from '@module/shared/notifications';
import { Field, Form, FormElement } from '@progress/kendo-react-form';
import { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { boolean, mixed, object } from 'yup';

import { useUpdateClientNotificationSettingsMutation } from '../graphql';

const schemaItem = object().shape({
  type_id: mixed<NotificationTypeId>()
    .oneOf(Object.values(NotificationTypeId))
    .default(NotificationTypeId.CUSTOM),
  email: boolean().required().default(false),
  internal: boolean().required().default(false),
  push: boolean().required().default(false),
  sms: boolean().required().default(false),
});

function computeSchema() {
  const type_ids = Object.values(NotificationTypeId);
  const shape = type_ids.reduce<{ [key: string]: typeof schemaItem }>(
    (carry, curr) => ({ ...carry, [curr]: schemaItem }),
    {},
  );

  return object(shape);
}

interface NotificationSettingsFormProps {
  client: NonNullable<MeFragment['client']>;
  formId: string;
}

type NotificationSettingsFormValues = Record<string, ClientNotificationSettingsInput>;

const NotificationTypeFieldset = (props: { parentFieldName: string; title: string }) => {
  const { parentFieldName, title } = props;

  const { t } = useTranslation();

  return (
    <fieldset className="k-m-0 k-p-0 k-border-none">
      <legend className="k-p-0 k-mb-1 k-label">{title}</legend>

      <div className="k-d-flex k-flex-wrap k-gap-x-4 k-gap-y-2">
        <Field
          id="notification.settings.internal"
          name={`${parentFieldName}.internal`}
          label={t('common.notificationChannels.internal')}
          component={Checkbox}
        />
        <Field
          id="notification.settings.email"
          name={`${parentFieldName}.email`}
          label={t('common.notificationChannels.email')}
          component={Checkbox}
        />
        <Field
          id="notification.settings.sms"
          name={`${parentFieldName}.sms`}
          label={t('common.notificationChannels.sms')}
          component={Checkbox}
        />
        <Field
          id="notification.settings.push"
          name={`${parentFieldName}.push`}
          label={t('common.notificationChannels.push')}
          component={Checkbox}
        />
      </div>
    </fieldset>
  );
};

export const NotificationSettingsForm = (props: NotificationSettingsFormProps) => {
  const { client, formId } = props;

  const { t } = useTranslation();
  const { showNotification } = useNotifications();

  const { groupedNotificationTypes } = useGroupedNotificationTypesQuery();
  const [{ fetching: isSaving }, updateClientNotificationSettings] =
    useUpdateClientNotificationSettingsMutation();

  const initialValues: NotificationSettingsFormValues = useMemo(() => {
    const valuesFromDatabase = Object.fromEntries(
      client.notification_settings.data.map(({ type_id, internal, email, push, sms }) => [
        type_id,
        { type_id, internal, email, push, sms },
      ]),
    );
    const valuesWithDefaults = computeSchema().cast(valuesFromDatabase, { stripUnknown: true });

    // override default value specified in schemaItem
    for (const type_id in valuesWithDefaults) {
      valuesWithDefaults[type_id].type_id = type_id as NotificationTypeId;
    }
    return valuesWithDefaults;
  }, [client.notification_settings.data]);

  const handleSubmit = async (values: unknown) => {
    const formValues = values as NotificationSettingsFormValues;

    const mutationResult = await updateClientNotificationSettings({
      client_id: client.id,
      notification_settings: Object.values(formValues),
    });

    if (mutationResult.error) {
      showNotification(t('settings.general.notifications.error'), NotificationType.Error);
    } else {
      showNotification(t('settings.general.notifications.success'), NotificationType.Success);
    }
  };

  if (!groupedNotificationTypes) {
    return <Loader />;
  }

  return (
    <>
      {isSaving && <Loader />}

      <Form
        id={formId}
        onSubmit={handleSubmit}
        initialValues={initialValues}
        render={() => (
          <FormElement>
            {groupedNotificationTypes.map((notificationGroup, index) => (
              <Fragment key={notificationGroup.group}>
                {index > 0 && (
                  <div className="k-my-6">
                    <Divider />
                  </div>
                )}

                <h3 className="u-text-xl k-mb-2 !k-mt-4">{notificationGroup.title}</h3>
                <p className="k-mb-4">{notificationGroup.description}</p>

                <div className="k-d-flex-col k-gap-6">
                  {notificationGroup.notificationTypes.map(({ type_id, title }) => (
                    <NotificationTypeFieldset
                      key={type_id}
                      parentFieldName={type_id}
                      title={title}
                    />
                  ))}
                </div>
              </Fragment>
            ))}
          </FormElement>
        )}
      />
    </>
  );
};

import { useMemo } from 'react';

import {
  checkPasswordLength,
  checkPasswordNumbers,
  checkPasswordSpecial,
  checkPasswordUppercase,
} from '../../helpers';
import { usePasswordMessages, usePasswordSettings } from '../../hooks';

export interface PasswordRequirementsProps {
  value: string | number | undefined;
}

export const PasswordRequirements = (props: PasswordRequirementsProps) => {
  const { value } = props;

  const settings = usePasswordSettings();
  const messages = usePasswordMessages(settings);
  const valid = useMemo(() => {
    return {
      length: checkPasswordLength(value?.toString(), settings),
      special: checkPasswordSpecial(value?.toString(), settings),
      uppercase: checkPasswordUppercase(value?.toString(), settings),
      numbers: checkPasswordNumbers(value?.toString(), settings),
    };
  }, [settings, value]);

  return (
    <div className="PasswordRequirements k-fs-sm">
      <div className="k-display-inline-flex k-align-items-center">
        {valid.length && <span className="l-i-check-circle-2 k-text-success k-mr-1" />}
        {!valid.length && <span className="l-i-alert-circle k-text-error k-mr-1" />}
        {messages.length}
      </div>
      <div className="k-display-inline-flex k-align-items-center">
        {valid.special && <span className="l-i-check-circle-2 k-text-success k-mr-1" />}
        {!valid.special && <span className="l-i-alert-circle k-text-error k-mr-1" />}

        {messages.special}
      </div>
      <div className="k-display-inline-flex k-align-items-center">
        {valid.uppercase && <span className="l-i-check-circle-2 k-text-success k-mr-1" />}
        {!valid.uppercase && <span className="l-i-alert-circle k-text-error k-mr-1" />}

        {messages.uppercase}
      </div>
      <div className="k-display-inline-flex k-align-items-center">
        {valid.numbers && <span className="l-i-check-circle-2 k-text-success k-mr-1" />}
        {!valid.numbers && <span className="l-i-alert-circle k-text-error k-mr-1" />}
        {messages.numbers}
      </div>
    </div>
  );
};

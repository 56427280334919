import './SettingsSection.scss';

import { useMe } from '@module/casl';
import { Badge, DateFormat, getContrastColor, useFormatDate } from '@module/common';
import { Divider } from '@module/layout';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const SettingsSection = () => {
  const { t } = useTranslation();
  const user = useMe();
  const formatDate = useFormatDate();

  const date = useMemo(() => {
    if (!user.last_login_at) return null;
    return formatDate(new Date(user.last_login_at), DateFormat.DateTime);
  }, [user, formatDate]);

  return (
    <>
      <div className="k-d-flex-row k-gap-2 !k-mt-4 k-justify-content-between">
        <div className="k-font-weight-semibold">{t('common.labels.name')}</div>{' '}
        <div>{user.name} </div>
      </div>
      <Divider />
      <div className="k-d-flex-row k-gap-2 k-justify-content-between">
        <div className="k-font-weight-semibold">{t('common.labels.email')}</div>{' '}
        <div> {user.email} </div>
      </div>
      <Divider />
      <div className="k-d-flex-row k-gap-2 k-justify-content-between">
        <div className="k-font-weight-semibold"> {t('common.labels.street')}</div>
        <div>
          {user.client?.primary_location?.street} {user.client?.primary_location?.street_no}
        </div>
      </div>
      <Divider />
      <div className="k-d-flex-row k-gap-2 k-justify-content-between">
        <div className="k-font-weight-semibold">{t('common.labels.city')}</div>
        <div>
          {user.client?.primary_location?.postcode} {user.client?.primary_location?.city}
        </div>
      </div>
      <Divider />
      <div className="k-d-flex-row k-gap-2 k-justify-content-between">
        <div className="k-font-weight-semibold"> {t('common.labels.country')}</div>
        <div> {user.client?.primary_location?.country.title} </div>
      </div>
      <Divider />
      <div className="k-d-flex-row k-gap-2 k-justify-content-between">
        <div className="k-font-weight-semibold"> {t('common.labels.role')}</div>
        <Badge
          backgroundColor={user.client?.client_type?.color ?? ''}
          color={getContrastColor(user.client?.client_type?.color ?? '')}
        >
          {user.client?.client_type?.title}
        </Badge>
      </div>
      <Divider />
      <div className="k-d-flex-row k-gap-2 k-justify-content-between">
        <div className="k-font-weight-semibold"> {t('common.labels.lastLogin')}</div>
        {date}
      </div>
    </>
  );
};

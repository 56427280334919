import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { useCallback, useMemo, useState } from 'react';

import {
  addTypesToInitialFilterState,
  addTypeToFilter,
  computeFilterState,
  getInitialFilterState,
} from '../helpers';
import {
  CompositeFilterDescriptorWithType,
  FilterContextType,
  FilterDescriptorWithType,
  FilterField,
  FilterFieldType,
  FilterState,
  InitialFilterState,
} from '../types';

export function useFilters(
  searchFields: FilterField[],
  expandedFields: FilterField[] = [],
  additionalFields: FilterField[] = [],
  customFields: FilterField[] = [],
  fixedFilters?: CompositeFilterDescriptor | undefined,
  initialFilterStateOverride?: InitialFilterState,
): FilterContextType {
  // compute initial filter state with fixedFilters
  const initialFilterState = useMemo(() => {
    const initialWithType = initialFilterStateOverride
      ? addTypesToInitialFilterState(initialFilterStateOverride)
      : {};
    const initial = { ...getInitialFilterState(), ...initialWithType };

    const filterState = { ...initial, fixedFilters: fixedFilters ?? null };

    return computeFilterState(filterState);
  }, [fixedFilters, initialFilterStateOverride]);

  const [filterState, setFilterState] = useState<FilterState>(initialFilterState);
  const updateFilterState = useCallback((filterStateNew: FilterState) => {
    setFilterState(computeFilterState(filterStateNew));
  }, []);

  const changeSearch = useCallback(
    (search: string) => {
      if (search.length == 0) {
        updateFilterState({ ...filterState, search, searchFilters: null });
        return;
      }

      const filters = searchFields.map<FilterDescriptorWithType>((field) => {
        return {
          field: field.name,
          operator: field.operator,
          value: search,
          type: FilterFieldType.SEARCH,
        };
      });
      const searchFilters: CompositeFilterDescriptorWithType = {
        logic: 'or',
        filters,
      };

      updateFilterState({ ...filterState, search, searchFilters });
    },
    [updateFilterState, filterState, searchFields],
  );

  const changeExpandedFilter = useCallback(
    (expandedFilters: CompositeFilterDescriptor | null) => {
      const expandedFiltersWithType = addTypeToFilter(expandedFilters, FilterFieldType.EXPANDED);
      updateFilterState({ ...filterState, expandedFilters: expandedFiltersWithType });
    },
    [updateFilterState, filterState],
  );

  const changeAdditionalFilter = useCallback(
    (additionalFilters: CompositeFilterDescriptor | null) => {
      const additionalFiltersWithType = addTypeToFilter(
        additionalFilters,
        FilterFieldType.ADDITIONAL,
      );

      updateFilterState({ ...filterState, additionalFilters: additionalFiltersWithType });
    },
    [updateFilterState, filterState],
  );

  const changeCustomFilter = useCallback(
    (customFilters: CompositeFilterDescriptor | null) => {
      const customFiltersWithType = addTypeToFilter(customFilters, FilterFieldType.CUSTOM);
      updateFilterState({ ...filterState, customFilters: customFiltersWithType });
    },
    [updateFilterState, filterState],
  );

  const resetToInitialFilterState = useCallback(() => {
    const resetFilterState = { ...getInitialFilterState(), fixedFilters: fixedFilters ?? null };
    updateFilterState(resetFilterState);
  }, [fixedFilters, updateFilterState]);

  const toggleExpanded = useCallback(() => {
    updateFilterState({
      ...filterState,
      expanded: !filterState.expanded,
      expandedFilters: filterState.expanded ? null : filterState.expandedFilters,
    });
  }, [filterState, updateFilterState]);

  return useMemo(() => {
    const customFieldsWithType = customFields.map((field) => ({
      ...field,
      type: FilterFieldType.CUSTOM,
    }));
    const expandedFieldsWithType = expandedFields.map((field) => ({
      ...field,
      type: FilterFieldType.EXPANDED,
    }));
    const additionalFieldsWithType = additionalFields.map((field) => ({
      ...field,
      type: FilterFieldType.ADDITIONAL,
    }));
    const searchFieldsWithType = searchFields.map((field) => ({
      ...field,
      type: FilterFieldType.SEARCH,
    }));

    const hasActiveFiltersCheck = () => {
      const filtersToCheck = filterState.filter.filters;
      let hasActiveFilter = false;

      function checkForActiveFilterRecursive(
        filters: (CompositeFilterDescriptorWithType | FilterDescriptorWithType)[],
      ) {
        if (hasActiveFilter) return;
        filters.forEach((filter: CompositeFilterDescriptorWithType | FilterDescriptorWithType) => {
          // If filter is of type FilterDescriptor
          if ('value' in filter) {
            // Check if the value is not an empty string or empty array and if the filter is not of type FIXED (as these cant be removed)
            if (filter.value.length > 0 && filter.type !== FilterFieldType.FIXED)
              hasActiveFilter = true;
            // Special check for date range pickers nd if the filter is not of type FIXED (as these cant be removed)
            // Since the value is {start: null, end: null} if deselected
            if (
              filter.value.start?.length > 0 &&
              filter.value.end?.length > 0 &&
              filter.type !== FilterFieldType.FIXED
            )
              hasActiveFilter = true;
          }
          // If filter is of type CompositeFilterDescriptor
          if ('filters' in filter && Array.isArray(filter.filters) && filter.filters.length > 0) {
            filter.filters.forEach((subFilter) => checkForActiveFilterRecursive([subFilter]));
          }
        });
      }

      checkForActiveFilterRecursive(filtersToCheck);
      return hasActiveFilter;
    };
    return {
      changeSearch,
      changeExpandedFilter,
      changeAdditionalFilter,
      changeCustomFilter,
      toggleExpanded,
      filterState,
      searchFields: searchFieldsWithType,
      expandedFields: expandedFieldsWithType,
      additionalFields: additionalFieldsWithType,
      customFields: customFieldsWithType,
      resetToInitialFilterState,
      hasActiveFilters: hasActiveFiltersCheck(),
    };
  }, [
    changeSearch,
    changeExpandedFilter,
    changeAdditionalFilter,
    changeCustomFilter,
    toggleExpanded,
    filterState,
    searchFields,
    expandedFields,
    additionalFields,
    customFields,
    resetToInitialFilterState,
  ]);
}

import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AppSettingsFragment = {
  __typename?: 'AppSettings';
  id: string;
  currency_id: string;
  tax: number;
  app_title: string;
  activate_legacy_gdv: boolean;
  show_weekends_in_calendar: boolean;
  calendar_driving_times: Types.DrivingTimeSetting;
  activate_push_notifications: boolean;
  push_notifications_config?: string | null;
  auto_accept_task: boolean;
  statistic_required_for_completion: boolean;
  statistic_required_for_controlling: boolean;
  permissions_delete_files_admin_only: boolean;
  show_summary_service_provider_block: boolean;
  global_phone?: string | null;
  global_mail?: string | null;
  max_export_pages: number;
  hide_log_times: boolean;
  adjuster_can_create_tasks: boolean;
  hide_drive_log: boolean;
  mobile_app_active: boolean;
  mobile_can_accept: boolean;
  mobile_can_set_appointments: boolean;
  mobile_lock_tasks_when_mobile_active: boolean;
  everybody_can_see_finished_tasks: boolean;
  allow_adjuster_to_send_emails: boolean;
  adjuster_can_duplicate_own_tasks: boolean;
  adjuster_can_change_task_location: boolean;
  adjuster_can_set_rework: boolean;
  adjuster_can_send_interface: boolean;
  disable_task_type_on_desk: boolean;
  disable_task_type_video: boolean;
  show_adjuster_email_service_provider_block: boolean;
  theme: Types.Theme;
  login_welcome_text?: string | null;
  conditions?: string | null;
  imprint?: string | null;
  privacy?: string | null;
  mail_active: boolean;
  mail_host?: string | null;
  mail_port?: number | null;
  mail_username?: string | null;
  mail_encryption?: Types.MailEncryptionType | null;
  mail_from_address?: string | null;
  mail_from_name?: string | null;
  mail_default_footer?: string | null;
  mail_prefix?: string | null;
  mail_send_failure_notice: boolean;
  module_images_active: boolean;
  module_documents_active: boolean;
  module_calculation_active: boolean;
  module_room_plan_active: boolean;
  module_emails_active: boolean;
  module_customer_portal_active: boolean;
  module_statistic_active: boolean;
  module_invoices_active: boolean;
  module_calculation_hide_column_status: boolean;
  module_calculation_hide_column_accepted: boolean;
  module_calculation_hide_column_payout_state: boolean;
  module_calculation_default_net: boolean;
  module_logs_active: boolean;
  module_subtasks_active: boolean;
  module_extended_forms_active: boolean;
  module_devices_active: boolean;
  primary_color?: string | null;
  assignment_auto_active: boolean;
  module_qualifications_active: boolean;
  assignment_type: Types.AssignmentType;
  assignment_max_tasks_per_week: number;
  assignment_max_tasks_week_start_day: Types.AssignmentWeekDay;
  assignment_max_tasks_max_per_day: number;
  tasks_leader_visible_all: boolean;
  tasks_delete_tags_on_complete: boolean;
  tasks_fields_external_number_active: boolean;
  tasks_fields_identifier_active: boolean;
  tasks_fields_parallel_number_active: boolean;
  tasks_fields_urgent_active: boolean;
  tasks_fields_client_contact_id_active: boolean;
  tasks_state_rework_active: boolean;
  tasks_num_routes: number;
  tasks_notify_owner_on_create: boolean;
  tasks_notify_owner_on_new_file: boolean;
  tasks_auto_generate_info_file: boolean;
  tasks_auto_assign_leader_when_empty: boolean;
  webservice_active: boolean;
  invoice_from_name?: string | null;
  invoice_from_street?: string | null;
  invoice_from_postcode?: string | null;
  invoice_from_city?: string | null;
  invoice_from_country_id?: string | null;
  invoice_from_phone?: string | null;
  invoice_from_email?: string | null;
  invoice_from_web?: string | null;
  invoice_from_bank_title?: string | null;
  invoice_from_bank_iban?: string | null;
  invoice_from_bank_swift?: string | null;
  invoice_from_commercial_register?: string | null;
  invoice_from_ceo?: string | null;
  invoice_from_vat_title?: string | null;
  invoice_from_vat_id?: string | null;
  invoice_from_invoice_number_prefix?: string | null;
  auth_single_session_per_user: boolean;
  auth_max_session_duration?: number | null;
  auth_mode_2fa: boolean;
  auth_mode_2fa_sms: boolean;
  auth_mode_2fa_email: boolean;
  auth_password_min_length: number;
  auth_password_min_special: number;
  auth_password_min_upper_case: number;
  auth_password_min_numbers: number;
  activate_task_default_off: boolean;
  leader_can_assign: boolean;
  msg_convert_single_file: boolean;
  create_task_auto_fill_client: boolean;
  create_task_auto_fill_client_address: boolean;
  app_mode: Types.AppMode;
  auto_logout_timeout?: number | null;
  tasks_filter_cancelled: boolean;
  tasks_hide_duplicate: boolean;
  log_mails_in_task_log: boolean;
  load_predefined_email: boolean;
  auth_allowlist_active: boolean;
  tasks_max_sub_owner_count: number;
  currency: { __typename?: 'Currency'; id: string; title: string };
  country: {
    __typename?: 'Country';
    id: string;
    bounding_box_min_longitude: number;
    bounding_box_min_latitude: number;
    bounding_box_max_longitude: number;
    bounding_box_max_latitude: number;
  };
  login_logo?: { __typename?: 'FileItem'; file_name: string; url: string; id: string } | null;
  login_background?: { __typename?: 'FileItem'; file_name: string; url: string; id: string } | null;
  report_default_cover_background?: {
    __typename?: 'FileItem';
    file_name: string;
    url: string;
    id: string;
  } | null;
  report_logo?: { __typename?: 'FileItem'; file_name: string; url: string; id: string } | null;
  alternate_report_logo?: {
    __typename?: 'FileItem';
    file_name: string;
    url: string;
    id: string;
  } | null;
  menu_icon?: { __typename?: 'FileItem'; file_name: string; url: string; id: string } | null;
  notification_settings: Array<{
    __typename?: 'AppNotificationSetting';
    id: string;
    type_id: Types.NotificationTypeId;
    internal: boolean;
    email: boolean;
    email_template_id?: string | null;
    sms: boolean;
    push: boolean;
  }>;
  map_bounds?: {
    __typename?: 'Bounds';
    min: { __typename?: 'Point'; longitude: number; latitude: number };
    max: { __typename?: 'Point'; longitude: number; latitude: number };
  } | null;
};

export type AppSettingsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type AppSettingsQuery = {
  __typename?: 'Query';
  appSettings: {
    __typename?: 'AppSettings';
    id: string;
    currency_id: string;
    tax: number;
    app_title: string;
    activate_legacy_gdv: boolean;
    show_weekends_in_calendar: boolean;
    calendar_driving_times: Types.DrivingTimeSetting;
    activate_push_notifications: boolean;
    push_notifications_config?: string | null;
    auto_accept_task: boolean;
    statistic_required_for_completion: boolean;
    statistic_required_for_controlling: boolean;
    permissions_delete_files_admin_only: boolean;
    show_summary_service_provider_block: boolean;
    global_phone?: string | null;
    global_mail?: string | null;
    max_export_pages: number;
    hide_log_times: boolean;
    adjuster_can_create_tasks: boolean;
    hide_drive_log: boolean;
    mobile_app_active: boolean;
    mobile_can_accept: boolean;
    mobile_can_set_appointments: boolean;
    mobile_lock_tasks_when_mobile_active: boolean;
    everybody_can_see_finished_tasks: boolean;
    allow_adjuster_to_send_emails: boolean;
    adjuster_can_duplicate_own_tasks: boolean;
    adjuster_can_change_task_location: boolean;
    adjuster_can_set_rework: boolean;
    adjuster_can_send_interface: boolean;
    disable_task_type_on_desk: boolean;
    disable_task_type_video: boolean;
    show_adjuster_email_service_provider_block: boolean;
    theme: Types.Theme;
    login_welcome_text?: string | null;
    conditions?: string | null;
    imprint?: string | null;
    privacy?: string | null;
    mail_active: boolean;
    mail_host?: string | null;
    mail_port?: number | null;
    mail_username?: string | null;
    mail_encryption?: Types.MailEncryptionType | null;
    mail_from_address?: string | null;
    mail_from_name?: string | null;
    mail_default_footer?: string | null;
    mail_prefix?: string | null;
    mail_send_failure_notice: boolean;
    module_images_active: boolean;
    module_documents_active: boolean;
    module_calculation_active: boolean;
    module_room_plan_active: boolean;
    module_emails_active: boolean;
    module_customer_portal_active: boolean;
    module_statistic_active: boolean;
    module_invoices_active: boolean;
    module_calculation_hide_column_status: boolean;
    module_calculation_hide_column_accepted: boolean;
    module_calculation_hide_column_payout_state: boolean;
    module_calculation_default_net: boolean;
    module_logs_active: boolean;
    module_subtasks_active: boolean;
    module_extended_forms_active: boolean;
    module_devices_active: boolean;
    primary_color?: string | null;
    assignment_auto_active: boolean;
    module_qualifications_active: boolean;
    assignment_type: Types.AssignmentType;
    assignment_max_tasks_per_week: number;
    assignment_max_tasks_week_start_day: Types.AssignmentWeekDay;
    assignment_max_tasks_max_per_day: number;
    tasks_leader_visible_all: boolean;
    tasks_delete_tags_on_complete: boolean;
    tasks_fields_external_number_active: boolean;
    tasks_fields_identifier_active: boolean;
    tasks_fields_parallel_number_active: boolean;
    tasks_fields_urgent_active: boolean;
    tasks_fields_client_contact_id_active: boolean;
    tasks_state_rework_active: boolean;
    tasks_num_routes: number;
    tasks_notify_owner_on_create: boolean;
    tasks_notify_owner_on_new_file: boolean;
    tasks_auto_generate_info_file: boolean;
    tasks_auto_assign_leader_when_empty: boolean;
    webservice_active: boolean;
    invoice_from_name?: string | null;
    invoice_from_street?: string | null;
    invoice_from_postcode?: string | null;
    invoice_from_city?: string | null;
    invoice_from_country_id?: string | null;
    invoice_from_phone?: string | null;
    invoice_from_email?: string | null;
    invoice_from_web?: string | null;
    invoice_from_bank_title?: string | null;
    invoice_from_bank_iban?: string | null;
    invoice_from_bank_swift?: string | null;
    invoice_from_commercial_register?: string | null;
    invoice_from_ceo?: string | null;
    invoice_from_vat_title?: string | null;
    invoice_from_vat_id?: string | null;
    invoice_from_invoice_number_prefix?: string | null;
    auth_single_session_per_user: boolean;
    auth_max_session_duration?: number | null;
    auth_mode_2fa: boolean;
    auth_mode_2fa_sms: boolean;
    auth_mode_2fa_email: boolean;
    auth_password_min_length: number;
    auth_password_min_special: number;
    auth_password_min_upper_case: number;
    auth_password_min_numbers: number;
    activate_task_default_off: boolean;
    leader_can_assign: boolean;
    msg_convert_single_file: boolean;
    create_task_auto_fill_client: boolean;
    create_task_auto_fill_client_address: boolean;
    app_mode: Types.AppMode;
    auto_logout_timeout?: number | null;
    tasks_filter_cancelled: boolean;
    tasks_hide_duplicate: boolean;
    log_mails_in_task_log: boolean;
    load_predefined_email: boolean;
    auth_allowlist_active: boolean;
    tasks_max_sub_owner_count: number;
    currency: { __typename?: 'Currency'; id: string; title: string };
    country: {
      __typename?: 'Country';
      id: string;
      bounding_box_min_longitude: number;
      bounding_box_min_latitude: number;
      bounding_box_max_longitude: number;
      bounding_box_max_latitude: number;
    };
    login_logo?: { __typename?: 'FileItem'; file_name: string; url: string; id: string } | null;
    login_background?: {
      __typename?: 'FileItem';
      file_name: string;
      url: string;
      id: string;
    } | null;
    report_default_cover_background?: {
      __typename?: 'FileItem';
      file_name: string;
      url: string;
      id: string;
    } | null;
    report_logo?: { __typename?: 'FileItem'; file_name: string; url: string; id: string } | null;
    alternate_report_logo?: {
      __typename?: 'FileItem';
      file_name: string;
      url: string;
      id: string;
    } | null;
    menu_icon?: { __typename?: 'FileItem'; file_name: string; url: string; id: string } | null;
    notification_settings: Array<{
      __typename?: 'AppNotificationSetting';
      id: string;
      type_id: Types.NotificationTypeId;
      internal: boolean;
      email: boolean;
      email_template_id?: string | null;
      sms: boolean;
      push: boolean;
    }>;
    map_bounds?: {
      __typename?: 'Bounds';
      min: { __typename?: 'Point'; longitude: number; latitude: number };
      max: { __typename?: 'Point'; longitude: number; latitude: number };
    } | null;
  };
};

export const AppSettingsFragmentDoc = gql`
  fragment AppSettings on AppSettings {
    id
    currency_id
    currency {
      id
      title
    }
    tax
    app_title
    activate_legacy_gdv
    show_weekends_in_calendar
    calendar_driving_times
    activate_push_notifications
    push_notifications_config
    auto_accept_task
    statistic_required_for_completion
    statistic_required_for_controlling
    permissions_delete_files_admin_only
    show_summary_service_provider_block
    global_phone
    global_mail
    max_export_pages
    hide_log_times
    adjuster_can_create_tasks
    hide_drive_log
    mobile_app_active
    mobile_can_accept
    mobile_can_set_appointments
    mobile_lock_tasks_when_mobile_active
    everybody_can_see_finished_tasks
    allow_adjuster_to_send_emails
    adjuster_can_duplicate_own_tasks
    adjuster_can_change_task_location
    adjuster_can_set_rework
    adjuster_can_send_interface
    disable_task_type_on_desk
    disable_task_type_video
    show_adjuster_email_service_provider_block
    country {
      id
      bounding_box_min_longitude
      bounding_box_min_latitude
      bounding_box_max_longitude
      bounding_box_max_latitude
    }
    theme
    login_logo {
      file_name
      url
      id
    }
    login_background {
      file_name
      url
      id
    }
    report_default_cover_background {
      file_name
      url
      id
    }
    report_logo {
      file_name
      url
      id
    }
    alternate_report_logo {
      file_name
      url
      id
    }
    login_welcome_text
    conditions
    imprint
    privacy
    menu_icon {
      file_name
      url
      id
    }
    mail_active
    mail_host
    mail_port
    mail_username
    mail_encryption
    mail_from_address
    mail_from_name
    mail_default_footer
    mail_prefix
    mail_send_failure_notice
    module_images_active
    module_documents_active
    module_calculation_active
    module_room_plan_active
    module_emails_active
    module_customer_portal_active
    module_statistic_active
    module_invoices_active
    module_calculation_hide_column_status
    module_calculation_hide_column_accepted
    module_calculation_hide_column_payout_state
    module_calculation_default_net
    module_logs_active
    module_subtasks_active
    module_extended_forms_active
    module_devices_active
    notification_settings {
      id
      type_id
      internal
      email
      email_template_id
      sms
      push
    }
    primary_color
    assignment_auto_active
    module_qualifications_active
    assignment_type
    assignment_max_tasks_per_week
    assignment_max_tasks_week_start_day
    assignment_max_tasks_max_per_day
    map_bounds {
      min {
        longitude
        latitude
      }
      max {
        longitude
        latitude
      }
    }
    auto_accept_task
    tasks_leader_visible_all
    tasks_delete_tags_on_complete
    tasks_fields_external_number_active
    tasks_fields_identifier_active
    tasks_fields_parallel_number_active
    tasks_fields_urgent_active
    tasks_fields_client_contact_id_active
    tasks_state_rework_active
    tasks_num_routes
    tasks_notify_owner_on_create
    tasks_notify_owner_on_new_file
    tasks_auto_generate_info_file
    tasks_auto_assign_leader_when_empty
    statistic_required_for_completion
    statistic_required_for_controlling
    webservice_active
    invoice_from_name
    invoice_from_street
    invoice_from_postcode
    invoice_from_city
    invoice_from_country_id
    invoice_from_phone
    invoice_from_email
    invoice_from_web
    invoice_from_bank_title
    invoice_from_bank_iban
    invoice_from_bank_swift
    invoice_from_commercial_register
    invoice_from_ceo
    invoice_from_vat_title
    invoice_from_vat_id
    invoice_from_invoice_number_prefix
    auth_single_session_per_user
    auth_max_session_duration
    auth_mode_2fa
    auth_mode_2fa_sms
    auth_mode_2fa_email
    auth_password_min_length
    auth_password_min_special
    auth_password_min_upper_case
    auth_password_min_numbers
    activate_task_default_off
    leader_can_assign
    msg_convert_single_file
    create_task_auto_fill_client
    create_task_auto_fill_client_address
    app_mode
    auto_logout_timeout
    tasks_filter_cancelled
    tasks_hide_duplicate
    allow_adjuster_to_send_emails
    log_mails_in_task_log
    load_predefined_email
    auth_allowlist_active
    tasks_max_sub_owner_count
  }
`;
export const AppSettingsDocument = gql`
  query AppSettings {
    appSettings {
      ...AppSettings
    }
  }
  ${AppSettingsFragmentDoc}
`;

export function useAppSettingsQuery(
  options?: Omit<Urql.UseQueryArgs<AppSettingsQueryVariables>, 'query'>,
) {
  return Urql.useQuery<AppSettingsQuery, AppSettingsQueryVariables>({
    query: AppSettingsDocument,
    ...options,
  });
}

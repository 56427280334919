import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ClientLoginFragment = {
  __typename?: 'Client';
  id: string;
  name: string;
  email?: string | null;
  client_type?: { __typename?: 'ClientType'; id: string; color: string; title: string } | null;
  user?: { __typename?: 'User'; id: string } | null;
};

export type ClientsLoginQueryVariables = Types.Exact<{ [key: string]: never }>;

export type ClientsLoginQuery = {
  __typename?: 'Query';
  clientsLogin: {
    __typename?: 'ClientsLoginPaginator';
    data: Array<{
      __typename?: 'Client';
      id: string;
      name: string;
      email?: string | null;
      client_type?: { __typename?: 'ClientType'; id: string; color: string; title: string } | null;
      user?: { __typename?: 'User'; id: string } | null;
    }>;
  };
};

export const ClientLoginFragmentDoc = gql`
  fragment ClientLogin on Client {
    id
    name
    email
    client_type {
      id
      color
      title
    }
    user {
      id
    }
  }
`;
export const ClientsLoginDocument = gql`
  query ClientsLogin {
    clientsLogin {
      data {
        ...ClientLogin
      }
    }
  }
  ${ClientLoginFragmentDoc}
`;

export function useClientsLoginQuery(
  options?: Omit<Urql.UseQueryArgs<ClientsLoginQueryVariables>, 'query'>,
) {
  return Urql.useQuery<ClientsLoginQuery, ClientsLoginQueryVariables>({
    query: ClientsLoginDocument,
    ...options,
  });
}

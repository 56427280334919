import { Field, FieldProps, FieldRenderProps } from '@progress/kendo-react-form';
import { ReactNode } from 'react';

import { StricterFieldRenderProps } from '../inputs/types';

interface FieldComponentProps extends FieldRenderProps {
  children: (fieldRenderProps: StricterFieldRenderProps) => ReactNode;
}

const FieldComponent = (props: FieldComponentProps) => {
  const { children, ...rest } = props;

  return <>{children(rest)}</>;
};

export interface FieldAsFunctionProps {
  name: FieldProps['name'];
  validator?: FieldProps['validator'];
  onChange?: FieldProps['onChange'];
  children: (fieldRenderProps: StricterFieldRenderProps) => ReactNode;
}

export const FieldAsFunction = (props: FieldAsFunctionProps) => {
  const { children, ...rest } = props;

  return (
    <Field {...rest} component={FieldComponent}>
      {children}
    </Field>
  );
};

import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { AdditionalTaskFormFragmentDoc } from './additionalTaskFormFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateAdditionalTaskFormMutationVariables = Types.Exact<{
  task_id: Types.Scalars['ID'];
  input: Types.CreateTaskFormInput;
}>;

export type CreateAdditionalTaskFormMutation = {
  __typename?: 'Mutation';
  createTaskForm: {
    __typename?: 'TaskForm';
    id: string;
    updated_at: string;
    title: string;
    sort_index: number;
    type: { __typename?: 'TaskFormType'; id: string; type_id: Types.TaskFormTypeId };
    template: {
      __typename?: 'TaskTemplate';
      id: string;
      title: string;
      description?: string | null;
      type_id: Types.TaskTemplateTypeId;
    };
    tags?: Array<{
      __typename?: 'Tag';
      id: string;
      title: string;
      color: string;
      type_id: string;
      type: { __typename?: 'TagType'; id: string; title: string; color: string };
    }> | null;
  };
};

export const CreateAdditionalTaskFormDocument = gql`
  mutation CreateAdditionalTaskForm($task_id: ID!, $input: CreateTaskFormInput!) {
    createTaskForm(task_id: $task_id, input: $input) {
      ...AdditionalTaskForm
    }
  }
  ${AdditionalTaskFormFragmentDoc}
`;

export function useCreateAdditionalTaskFormMutation() {
  return Urql.useMutation<
    CreateAdditionalTaskFormMutation,
    CreateAdditionalTaskFormMutationVariables
  >(CreateAdditionalTaskFormDocument);
}

import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AdjustersWithDetailsQueryVariables = Types.Exact<{
  target_claim_lat?: Types.InputMaybe<Types.Scalars['Float']>;
  target_claim_lng?: Types.InputMaybe<Types.Scalars['Float']>;
  task_visit_type?: Types.InputMaybe<Types.Scalars['ID']>;
  client_id?: Types.InputMaybe<Types.Scalars['ID']>;
}>;

export type AdjustersWithDetailsQuery = {
  __typename?: 'Query';
  adjustersWithDetails: {
    __typename?: 'AdjusterWithDetailsPaginator';
    data: Array<{
      __typename?: 'AdjusterWithDetails';
      id: string;
      name: string;
      open_tasks: number;
      tasks_count_color: string;
      distance_to_claim?: number | null;
      assignment_note?: string | null;
      preferred_leader_client_id: string;
      availability: {
        __typename?: 'ClientAssignmentAvailabilityState';
        id: string;
        title: string;
        color: string;
      };
      leader?: { __typename?: 'Client'; id: string; name: string } | null;
    }>;
  };
};

export const AdjustersWithDetailsDocument = gql`
  query AdjustersWithDetails(
    $target_claim_lat: Float
    $target_claim_lng: Float
    $task_visit_type: ID
    $client_id: ID
  ) {
    adjustersWithDetails(
      target_claim_lat: $target_claim_lat
      target_claim_lng: $target_claim_lng
      task_visit_type: $task_visit_type
      client_id: $client_id
    ) {
      data {
        id
        name
        open_tasks
        tasks_count_color
        availability {
          id
          title
          color
        }
        distance_to_claim
        assignment_note
        preferred_leader_client_id
        leader {
          id
          name
        }
      }
    }
  }
`;

export function useAdjustersWithDetailsQuery(
  options?: Omit<Urql.UseQueryArgs<AdjustersWithDetailsQueryVariables>, 'query'>,
) {
  return Urql.useQuery<AdjustersWithDetailsQuery, AdjustersWithDetailsQueryVariables>({
    query: AdjustersWithDetailsDocument,
    ...options,
  });
}

import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
export type TaskContactFragment = {
  __typename?: 'Contact';
  id: string;
  role_id?: string | null;
  salutation_id?: string | null;
  function_id?: string | null;
  city?: string | null;
  country_id?: string | null;
  email?: string | null;
  email2?: string | null;
  fax_number?: string | null;
  first_name?: string | null;
  name?: string | null;
  mobile?: string | null;
  phone?: string | null;
  phone2?: string | null;
  postcode?: string | null;
  street?: string | null;
  street_no?: string | null;
  note?: string | null;
  country?: { __typename?: 'Country'; id: string; title: string } | null;
  salutation?: { __typename?: 'Salutation'; id: string; title: string } | null;
  function?: { __typename?: 'ContactFunction'; id: string; title: string } | null;
};

export const TaskContactFragmentDoc = gql`
  fragment TaskContact on Contact {
    id
    role_id
    salutation_id
    function_id
    city
    country {
      id
      title
    }
    country_id
    email
    email2
    fax_number
    first_name
    name
    mobile
    phone
    phone2
    postcode
    role_id
    street
    street_no
    salutation {
      id
      title
    }
    function {
      id
      title
    }
    note
  }
`;

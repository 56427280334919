import {
  useActivityAbility,
  useAddressBookAbility,
  useAdminAbility,
  useApiTokenAbility,
  useAppSettingsAbility,
  useAreaAssignmentAbility,
  useArticleAbility,
  useClassificationTagAbility,
  useDeviceAbility,
  useEmailTemplateAbility,
  useFileClassificationTagAbility,
  usePdfTemplateAbility,
  useQualificationAbility,
  useReportTemplateAbility,
  useServiceCatalogAbility,
} from '@module/casl';
import { useInterfaceMessageTemplateAbility } from '@module/casl/abilities/InterfaceMessageTemplateAbility';
import { useSubformClassificationTagAbility } from '@module/casl/abilities/SubformClassificationTagAbility';
import { useTaskSubTaskTemplateAbility } from '@module/casl/abilities/TaskSubTaskTemplateAbility';
import {
  NavigationData,
  NavigationSection,
  useAppSettings,
  useIsAppModeRisk,
} from '@module/common';
import { useSettingsOverviewItems } from '@module/settings/hooks/useSettingsOverviewItems';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

function useSettingsNavigationSectionPlatform() {
  const { t } = useTranslation();

  const [appSettingsAbility, appSettingsSubject] = useAppSettingsAbility();
  const appSettings = useAppSettings();
  const [apiTokenAbility, apiTokensSubject] = useApiTokenAbility();
  return useMemo<NavigationSection>(() => {
    return {
      id: 'platform',
      subHeader: {
        text: t('common.navigation.sections.platform'),
      },
      items: [
        {
          id: 'general',
          text: t('settings.subnavigation.general'),
          path: '/settings/platform/general',
          iconClass: 'l-i-settings-2',
          show: appSettingsAbility.can('updateGeneral', appSettingsSubject()),
        },
        {
          id: 'login',
          text: t('settings.subnavigation.login'),
          path: '/settings/platform/login',
          iconClass: 'l-i-log-in',
          show: appSettingsAbility.can('updateLogin', appSettingsSubject()),
        },
        {
          id: 'app-messages',
          text: t('settings.subnavigation.appMessages'),
          path: '/settings/platform/app-messages',
          iconClass: 'l-i-mail-question',
          show: appSettingsAbility.can('updateAppMessages', appSettingsSubject()),
        },

        {
          id: 'uploads',
          text: t('settings.subnavigation.uploads'),
          path: '/settings/platform/uploads',
          iconClass: 'l-i-upload',
          show: appSettingsAbility.can('updateImages', appSettingsSubject()),
        },
        {
          id: 'email',
          text: t('settings.subnavigation.email'),
          path: '/settings/platform/email',
          iconClass: 'l-i-mail',
          show: appSettingsAbility.can('updateEmail', appSettingsSubject()),
        },
        /* {
          id: 'email-transport',
          text: t('settings.subnavigation.emailTransport'),
          path: '/settings/platform/email-transport',
          iconClass: 'l-i-mail',
          show: appSettingsAbility.can('updateEmail', appSettingsSubject()),
        }, */
        // TODO: add permission
        {
          id: 'api-tokens',
          text: t('settings.subnavigation.apiTokens'),
          path: '/settings/platform/api-tokens',
          iconClass: 'l-i-key',
          show: apiTokenAbility.can('viewApiTokens', apiTokensSubject()),
        },
        {
          id: 'ip-allowlist',
          text: t('settings.subnavigation.ip'),
          path: '/settings/platform/ip',
          iconClass: 'l-i-shield-check',
          show: appSettingsAbility.can('viewAppAllowlist', appSettingsSubject()),
        },
        {
          id: 'notifications',
          text: t('settings.subnavigation.notification'),
          path: '/settings/platform/notification',
          iconClass: 'l-i-bell',
          show: appSettingsAbility.can('updateNotifications', appSettingsSubject()),
        },
        {
          id: 'pushNotifications',
          text: t('settings.subnavigation.push'),
          path: '/settings/platform/pushnotifications',
          iconClass: 'l-i-megaphone',
          show: appSettingsAbility.can('updatePushNotifications', appSettingsSubject()),
        },
        {
          id: 'modules',
          text: t('settings.subnavigation.modules'),
          path: '/settings/platform/modules',
          iconClass: 'l-i-component',
          show: appSettingsAbility.can('updateModules', appSettingsSubject()),
        },
        {
          id: 'appModules',
          text: t('settings.subnavigation.appModules'),
          path: '/settings/platform/app-modules',
          iconClass: 'l-i-file-key',
          show: appSettingsAbility.can('updateAppModules', appSettingsSubject()),
        },
        {
          id: 'assignments',
          text: t('settings.subnavigation.assignments'),
          path: '/settings/platform/assignments',
          iconClass: 'l-i-user-check',
          show: appSettingsAbility.can('updateAssignments', appSettingsSubject()),
        },
        {
          id: 'maps',
          text: t('settings.subnavigation.maps'),
          path: '/settings/platform/maps',
          iconClass: 'l-i-map',
          show: appSettingsAbility.can('updateMapBounds', appSettingsSubject()),
        },
        {
          id: 'tasksSettings',
          text: t('settings.subnavigation.tasks'),
          path: '/settings/platform/tasks',
          iconClass: 'l-i-list-checks',
          show: appSettingsAbility.can('updateTasksSettings', appSettingsSubject()),
        },
        {
          id: 'invoicesSettings',
          text: t('settings.subnavigation.invoices'),
          path: '/settings/platform/invoices',
          iconClass: 'l-i-banknote',
          show:
            appSettingsAbility.can('updateInvoicesSettings', appSettingsSubject()) &&
            appSettings.module_invoices_active,
        },
      ].filter((item) => item.show),
    };
  }, [appSettingsAbility, appSettingsSubject, t, appSettings, apiTokenAbility, apiTokensSubject]);
}

function useSettingsNavigationSectionTemplates() {
  const { t } = useTranslation();
  const isAppModeRisk = useIsAppModeRisk();

  const [emailTemplateAbility, emailTemplateSubject] = useEmailTemplateAbility();
  const [pdfTemplateAbility, pdfTemplateSubject] = usePdfTemplateAbility();
  const [reportTemplateAbility, reportTemplateSubject] = useReportTemplateAbility();
  const [appSettingsAbility, appSettingsSubject] = useAppSettingsAbility();
  const [taskSubTaskTemplateAbility, taskSubTaskTemplateSubject] = useTaskSubTaskTemplateAbility();
  const [addressBookAbility, addressBookSubject] = useAddressBookAbility();
  const [interfaceMessageTemplateAbility, interfaceMessageTemplateSubject] =
    useInterfaceMessageTemplateAbility();

  return useMemo<NavigationSection>(() => {
    return {
      id: 'templateSettings',
      subHeader: {
        text: t('common.navigation.sections.templateSettings'),
      },
      items: [
        {
          id: 'reportingTemplates',
          text: t('common.navigation.items.reportingTemplates'),
          path: '/settings/templates/reporting',
          iconClass: 'l-i-clipboard-type',
          show: reportTemplateAbility.can('updateReportTemplate', reportTemplateSubject()),
        },
        {
          id: 'statisticsTemplates',
          text: t('common.navigation.items.statisticsTemplates'),
          path: '/settings/templates/statistics',
          iconClass: 'l-i-clipboard-type',
          show: reportTemplateAbility.can('updateReportTemplate', reportTemplateSubject()),
        },
        {
          id: 'pdf-forms',
          text: t('common.navigation.items.pdfs'),
          path: '/settings/pdf-forms',
          iconClass: 'l-i-file-text',
          show: pdfTemplateAbility.can('updatePdfTemplate', pdfTemplateSubject()),
        },
        {
          id: 'email-templates',
          text: t('common.navigation.items.emailTemplates'),
          path: '/settings/email-templates',
          iconClass: 'l-i-mail',
          show: emailTemplateAbility.can('updateEmailTemplate', emailTemplateSubject()),
        },
        {
          id: 'interface-message-templates',
          text: t('common.navigation.items.interfaceMessageTemplates'),
          path: '/settings/interface-message-templates',
          iconClass: 'l-i-arrow-right-left',
          show:
            !isAppModeRisk &&
            interfaceMessageTemplateAbility.can(
              'updateInterfaceMessageTemplate',
              interfaceMessageTemplateSubject(),
            ),
        },
        {
          id: 'reports',
          text: t('common.navigation.items.reports'),
          path: '/settings/report-layouts',
          iconClass: 'l-i-book-open',
          show: reportTemplateAbility.can('updateReportTemplate', reportTemplateSubject()),
        },
        {
          id: 'subtasks',
          text: t('common.navigation.items.subtasks'),
          path: '/settings/sub-tasks-templates',
          iconClass: 'l-i-clipboard-list',
          show: taskSubTaskTemplateAbility.can(
            'indexTaskSubTaskTemplates',
            taskSubTaskTemplateSubject(),
          ),
        },
        {
          id: 'stamp-templates',
          text: t('common.navigation.items.stampTemplates'),
          path: '/settings/stamp-templates',
          iconClass: 'l-i-stamp',
          show: appSettingsAbility.can('updateStamps', appSettingsSubject()),
        },
        {
          id: 'addressBook',
          text: t('common.navigation.items.addressBook'),
          path: '/settings/address-book',
          iconClass: 'l-i-book-user',
          show: addressBookAbility.can('viewAddressBookEntries', addressBookSubject()),
        },
      ].filter((item) => item.show),
    };
  }, [
    emailTemplateAbility,
    emailTemplateSubject,
    pdfTemplateAbility,
    pdfTemplateSubject,
    reportTemplateAbility,
    reportTemplateSubject,
    appSettingsAbility,
    appSettingsSubject,
    taskSubTaskTemplateAbility,
    taskSubTaskTemplateSubject,
    t,
    addressBookAbility,
    addressBookSubject,
    interfaceMessageTemplateAbility,
    interfaceMessageTemplateSubject,
    isAppModeRisk,
  ]);
}

function useSettingsNavigationSectionServiceCatalog() {
  const { t } = useTranslation();

  const [serviceCatalogAbility, serviceCatalogSubject] = useServiceCatalogAbility();

  return useMemo<NavigationSection>(() => {
    return {
      id: 'serviceCatalogSettings',
      subHeader: {
        text: t('common.navigation.sections.serviceCatalog'),
      },
      items: [
        {
          id: 'serviceCatalogServices',
          text: t('common.navigation.items.serviceCatalogServices'),
          path: '/settings/service-catalog/services',
          iconClass: 'l-i-album',
          // TODO: add updateServiceCatalogItems permission
          show: serviceCatalogAbility.can('viewServiceCatalogItems', serviceCatalogSubject()),
        },
        {
          id: 'serviceCatalogPostcodeFactors',
          text: t('common.navigation.items.serviceCatalogPostcodeFactors'),
          path: '/settings/service-catalog/postcode-factors',
          iconClass: 'l-i-map',
          // TODO: add updatePostcodeFactors permission
          show: serviceCatalogAbility.can('viewPostcodeFactors', serviceCatalogSubject()),
        },
        {
          id: 'tradesStatus',
          text: t('common.navigation.items.tradesStatus'),
          path: '/settings/service-catalog/trades-status',
          iconClass: 'l-i-list-ordered',
          // TODO: add updateTradesStatus permission
          show: serviceCatalogAbility.can('viewTradesStatus', serviceCatalogSubject()),
        },
      ].filter((item) => item.show),
    };
  }, [serviceCatalogAbility, serviceCatalogSubject, t]);
}

function useSettingsNavigationSectionExtended() {
  const { t } = useTranslation();

  const appSettings = useAppSettings();

  const [adminAbility, adminSubject] = useAdminAbility();
  const [qualificationsAbility, qualificationSubject] = useQualificationAbility();
  const [classificationTagAbility, classificationTagSubject] = useClassificationTagAbility();

  const [areaAssignmentAbility, areaAssignmentSubject] = useAreaAssignmentAbility();
  const [articleAbility, articleSubject] = useArticleAbility();
  const [deviceAbility, deviceSubject] = useDeviceAbility();
  const [fileClassificationTagAbility, fileClassificationTagSubject] =
    useFileClassificationTagAbility();
  const [activityAbility, activitySubject] = useActivityAbility();
  const [subformClassificationTagAbility, subformClassificationTagSubject] =
    useSubformClassificationTagAbility();

  return useMemo<NavigationSection>(() => {
    return {
      id: 'extendedSettings',
      subHeader: {
        text: t('common.navigation.sections.extendedSettings'),
      },
      items: [
        {
          id: 'area-aasignments',
          text: t('common.navigation.items.map'),
          path: '/settings/area-assignments',
          iconClass: 'l-i-map',
          show: areaAssignmentAbility.can('updateAreaAssignments', areaAssignmentSubject()),
        },
        {
          id: 'articles',
          text: t('common.navigation.items.articles'),
          path: '/settings/articles',
          iconClass: 'l-i-boxes',
          show: articleAbility.can('updateArticle', articleSubject()),
        },
        // TODO: add permission
        {
          id: 'folderTemplates',
          text: t('common.navigation.items.folderTemplates'),
          path: '/settings/folder-templates',
          iconClass: 'l-i-folders',
          show: adminAbility.can('read', adminSubject()),
        },
        {
          id: 'qualifications',
          text: t('common.navigation.items.qualifications'),
          path: '/settings/qualifications',
          iconClass: 'l-i-tags',
          show: qualificationsAbility.can('updateQualification', qualificationSubject()),
        },
        {
          id: 'classificationTags',
          text: t('common.navigation.items.classificationTags'),
          path: '/settings/classification-tags',
          iconClass: 'l-i-tags',
          show: classificationTagAbility.can('updateClassificationTag', classificationTagSubject()),
        },
        {
          id: 'fileClassificationTags',
          text: t('common.navigation.items.fileClassificationTags'),
          path: '/settings/file-classification-tags',
          iconClass: 'l-i-file-cog',
          show: fileClassificationTagAbility.can(
            'updateFileClassificationTag',
            fileClassificationTagSubject(),
          ),
        },
        {
          id: 'subformClassificationTags',
          text: t('common.navigation.items.subformClassificationTags'),
          path: '/settings/subform-classification-tags',
          iconClass: 'l-i-tags',
          show: subformClassificationTagAbility.can(
            'updateSubformClassificationTag',
            subformClassificationTagSubject(),
          ),
        },
        {
          id: 'activityLog',
          text: t('common.navigation.items.activityLog'),
          path: '/settings/activity-log',
          iconClass: 'l-i-activity',
          show: activityAbility.can('updateActivity', activitySubject()),
        },
        {
          id: 'devices',
          text: t('common.navigation.items.devices'),
          path: '/settings/devices',
          iconClass: 'l-i-plug',
          show:
            deviceAbility.can('updateDevice', deviceSubject()) && appSettings.module_devices_active,
        },
      ].filter((item) => item.show),
    };
  }, [
    appSettings,
    adminAbility,
    adminSubject,
    areaAssignmentAbility,
    areaAssignmentSubject,
    articleAbility,
    articleSubject,
    deviceAbility,
    deviceSubject,
    qualificationSubject,
    qualificationsAbility,
    t,
    classificationTagAbility,
    classificationTagSubject,
    fileClassificationTagAbility,
    fileClassificationTagSubject,
    activityAbility,
    activitySubject,
    subformClassificationTagAbility,
    subformClassificationTagSubject,
  ]);
}

function useSettingsNavigationSectionDebug() {
  const { t } = useTranslation();
  // TODO: Add permissions when they exist in backend
  // const [adminAbility, adminSubject] = useAdminAbility();

  return useMemo<NavigationSection>(() => {
    return {
      id: 'debugSettings',
      subHeader: {
        text: t('common.navigation.sections.debug'),
      },
      items: [
        {
          id: 'demoData',
          text: t('common.navigation.items.demoData'),
          path: '/settings/demo-data',
          iconClass: 'l-i-database',
          show: true,
          // TODO: Add permissions when they exist in backend
          // show: adminAbility.can('createDemoData', adminSubject()),
        },
      ],
    };
  }, [
    // adminAbility,
    // adminSubject,
    t,
  ]);
}

const useSettingsNavigationSectionOverview = () => {
  const { t } = useTranslation();

  const items = useSettingsOverviewItems();

  return useMemo<NavigationSection>(() => {
    return {
      id: 'overview',
      items: [
        {
          id: 'overview',
          text: t('common.navigation.items.overview'),
          path: '/settings/overview',
          iconClass: 'l-i-settings',
          show: !!items.length,
        },
      ].filter((item) => item.show),
    };
  }, [t, items]);
};

export function useSettingsNavigationData() {
  const overview = useSettingsNavigationSectionOverview();
  const platform = useSettingsNavigationSectionPlatform();
  const templates = useSettingsNavigationSectionTemplates();
  const serviceCatalog = useSettingsNavigationSectionServiceCatalog();
  const extended = useSettingsNavigationSectionExtended();
  const debug = useSettingsNavigationSectionDebug();
  return useMemo<NavigationData>(
    () => ({
      id: 'navigation-settings',
      sections: [overview, platform, templates, serviceCatalog, extended, debug].filter(
        (section) => section.items.length,
      ),
    }),
    [extended, platform, templates, serviceCatalog, debug, overview],
  );
}

import { classNames } from '@progress/kendo-react-common';
import { difference, uniq, without } from 'lodash';
import { useCallback, useId } from 'react';

import { isStringArray } from '../../helpers';
import { Label } from '../components';
import { Checkbox } from './Checkbox';
import { FieldError } from './FieldError';
import { FieldHint } from './FieldHint';
import { CommonFieldRenderProps } from './types';

interface CheckboxGroupOption {
  value: string;
  label: string;
}

interface CheckboxGroupChangeEvent {
  value: string[];
}

interface CheckboxGroupProps {
  data: CheckboxGroupOption[];
  disabled?: boolean;
  name?: string;
  layout?: 'horizontal' | 'vertical';
  onChange?: (e: CheckboxGroupChangeEvent) => void;
  value?: string[];
}

const CheckboxGroup = (props: CheckboxGroupProps) => {
  const { data, layout = 'horizontal', onChange, disabled } = props;
  const values: string[] = props.value ?? [];

  return (
    <ul
      className={classNames(
        'k-checkbox-list',
        {
          'k-checkbox-list-horizontal': layout === 'horizontal',
          'k-checkbox-list-vertical': layout === 'vertical',
        },
        '!k-gap-y-1',
      )}
    >
      {data.map((option) => (
        <li key={`checkbox-${option.value}`}>
          <Checkbox
            disabled={disabled}
            value={values.includes(option.value) || false}
            label={option.label}
            className={classNames('!k-px-3  !k-py-2', {
              'k-rounded-lg u-bg-primary-25': values.includes(option.value) || false,
            })}
            onChange={(e) => {
              const valuesNew = e.value
                ? uniq([...values, option.value])
                : without(values, option.value);
              const hasChanged =
                difference(valuesNew, values).length > 0 ||
                difference(values, valuesNew).length > 0;
              if (hasChanged) {
                onChange?.({ value: valuesNew });
              }
            }}
          />
        </li>
      ))}
    </ul>
  );
};

type CheckboxGroupInputProps = CommonFieldRenderProps &
  Pick<CheckboxGroupProps, 'value' | 'disabled' | 'onChange'> & {
    id?: string;
    data: CheckboxGroupOption[];

    layout?: 'horizontal' | 'vertical';
    size?: null | 'small' | 'medium' | 'large';
    hintClass?: string;
  };

export const CheckboxGroupInput = (props: CheckboxGroupInputProps) => {
  const defaultId = useId();
  const {
    data,
    disabled,
    value,
    id = defaultId,
    label,
    layout,
    valid,
    onChange,
    name,
    tooltip,
    tooltipPosition,
  } = props;
  const labelId = label && `${id}_label`;

  const handleMultiselectChange = useCallback(
    (e: CheckboxGroupChangeEvent) => {
      onChange?.({ value: e.value });
    },
    [onChange],
  );

  const values = isStringArray(value) ? (value as string[]) : [];

  return (
    <div className="k-form-field" id={id} role="group" aria-labelledby={labelId}>
      {label && (
        <Label
          id={labelId}
          tooltip={tooltip}
          tooltipPosition={tooltipPosition}
          label={label}
          editorValid={valid}
          name={name}
        >
          {label}
        </Label>
      )}
      <div className={'k-d-flex-col k-gap-4'}>
        <CheckboxGroup
          disabled={disabled}
          data={data}
          layout={layout}
          onChange={handleMultiselectChange}
          value={values}
          name={name}
        />
      </div>
      <div className="HintAndError">
        <FieldHint {...props} />
        <FieldError {...props} />
      </div>
    </div>
  );
};

import {
  CompositeFilterDescriptor,
  FilterDescriptor,
  isCompositeFilterDescriptor,
} from '@progress/kendo-data-query';
import { noop } from '@progress/kendo-react-common';

import { isTruthy } from '../helpers';
import {
  CompositeFilterDescriptorWithType,
  FilterContextType,
  FilterDescriptorWithType,
  FilterFieldType,
  FilterState,
  InitialFilterState,
  isCompositeFilterDescriptorWithType,
} from './types';

export function getInitialFilterState(): FilterState {
  return {
    search: '',
    fixedFilters: null,
    searchFilters: null,
    expandedFilters: null,
    additionalFilters: null,
    customFilters: null,
    filter: { logic: 'and', filters: [] },
    expanded: false,
  };
}

export function getInitialFilterContext(): FilterContextType {
  return {
    changeSearch: noop,
    changeExpandedFilter: noop,
    changeAdditionalFilter: noop,
    changeCustomFilter: noop,
    toggleExpanded: noop,
    filterState: getInitialFilterState(),
    searchFields: [],
    expandedFields: [],
    additionalFields: [],
    customFields: [],
    hasActiveFilters: false,
    resetToInitialFilterState: noop,
  };
}

export const addType = (
  obj:
    | CompositeFilterDescriptor
    | FilterDescriptor
    | CompositeFilterDescriptorWithType
    | FilterDescriptorWithType
    | null,
  type: FilterFieldType,
): CompositeFilterDescriptorWithType | FilterDescriptorWithType | null => {
  if (!obj) return null;
  if (isCompositeFilterDescriptor(obj) || isCompositeFilterDescriptorWithType(obj)) {
    return addTypeToFilter(obj, type);
  }
  return { ...obj, type };
};

export const computeFilter = (filterState: Omit<FilterState, 'filter'>) => {
  const {
    fixedFilters,
    searchFilters,
    expandedFilters,
    additionalFilters,
    customFilters,
    expanded,
  } = filterState;

  // use only complex filters
  if (additionalFilters) {
    const flattened = addType(
      additionalFilters.filters.length === 1 ? additionalFilters.filters[0] : additionalFilters,
      FilterFieldType.ADDITIONAL,
    );

    if (flattened) {
      if (isCompositeFilterDescriptorWithType(flattened)) {
        return flattened;
      } else {
        return { logic: additionalFilters.logic ?? ('and' as const), filters: [flattened] };
      }
    }
  }

  const filter: CompositeFilterDescriptorWithType = {
    logic: 'and' as const,
    filters: [],
  };
  // append fixed filters
  if (fixedFilters && fixedFilters.filters.length > 0) {
    // flatten if only one filter
    const flattened = addType(
      fixedFilters.filters.length === 1 ? fixedFilters.filters[0] : fixedFilters,
      FilterFieldType.FIXED,
    );
    if (flattened) filter.filters.push(flattened);
  }

  // append search filters
  if (searchFilters && searchFilters.filters.length > 0) {
    // flatten if only one filter
    const flattened = addType(
      searchFilters.filters.length === 1 ? searchFilters.filters[0] : searchFilters,
      FilterFieldType.SEARCH,
    );
    if (flattened) filter.filters.push(flattened);
  }

  // append expanded filters
  if (expanded && expandedFilters && expandedFilters.filters.length > 0) {
    // flatten if only one filter
    const flattened = addType(
      expandedFilters.filters.length === 1 ? expandedFilters.filters[0] : expandedFilters,
      FilterFieldType.EXPANDED,
    );
    if (flattened) filter.filters.push(flattened);
  }

  // append custom filters
  if (customFilters && customFilters.filters.length > 0) {
    // flatten if only one filter
    const flattened = addType(
      customFilters.filters.length === 1 ? customFilters.filters[0] : customFilters,
      FilterFieldType.CUSTOM,
    );
    if (flattened) filter.filters.push(flattened);
  }
  return filter;
};

export const addTypeToFilter = (
  filter: CompositeFilterDescriptor | null | undefined,
  type: FilterFieldType,
): CompositeFilterDescriptorWithType | null => {
  if (!filter) return null;
  return {
    ...filter,
    filters: filter?.filters.map((f) => addType(f, type)).filter(isTruthy) ?? [],
  };
};

export const addTypesToInitialFilterState = (initialFilterState: InitialFilterState) => {
  const customFilters = addTypeToFilter(initialFilterState.customFilters, FilterFieldType.CUSTOM);
  const searchFilters = addTypeToFilter(initialFilterState.searchFilters, FilterFieldType.SEARCH);
  const expandedFilters = addTypeToFilter(
    initialFilterState.expandedFilters,
    FilterFieldType.EXPANDED,
  );
  const additionalFilters = addTypeToFilter(
    initialFilterState.additionalFilters,
    FilterFieldType.ADDITIONAL,
  );

  return {
    customFilters,
    searchFilters,
    expandedFilters,
    additionalFilters,
  };
};

export const computeFilterState = (filterStateNew: Omit<FilterState, 'filter'>) => {
  // filter is getting overwritten, because the "single source of truth" should always be computed
  return {
    ...filterStateNew,
    filter: computeFilter(filterStateNew),
  };
};

export const findFilterByField = (
  filter: FilterDescriptor | CompositeFilterDescriptor,
  fieldToFind: string,
): FilterDescriptor | undefined => {
  if ('field' in filter) {
    // It's a FilterDescriptor
    if (filter.field === fieldToFind) {
      return filter;
    }
  } else {
    // It's a CompositeFilterDescriptor
    for (const subFilter of (filter as CompositeFilterDescriptor).filters) {
      const result = findFilterByField(subFilter, fieldToFind);
      if (result) {
        return result;
      }
    }
  }
  return undefined;
};

export default {
  tasks: {
    generalSection: {
      labels: {
        adjuster_can_create_tasks: 'Besichtiger kann Besichtigungen erstellen',
        hide_drive_log: 'Verstecke Fahrtzeiten-Eingabe unter Besichtigungen',
        tasks_leader_visible_all:
          'Teamleiter können alle Besichtigungen sehen (nicht nur ihnen zugewiesene Besichtigungen)',

        statistic_required_for_controlling:
          'Die Statistik muss ausgefüllt sein (Pflichtfelder), bevor die Besichtigung ins Controlling übermittelt werden kann.',
        statistic_required_for_completion:
          'Die Statistik muss ausgefüllt sein (Pflichtfelder), bevor die Besichtigung als abgeschlossen markiert werden kann.',
        adjuster_can_duplicate_own_tasks: 'Besichtiger kann eigene Besichtigungen duplizieren',
        activate_task_default_off: 'Besichtigungen direkt freigeben ausschalten',
        leader_can_assign: 'Teamleiter kann Besichtigungen zuweisen',
        everybody_can_see_finished_tasks: 'Jeder kann abgeschlossene Besichtigungen sehen',
        create_task_auto_fill_client:
          'Beim Erstellen einer Besichtigung, übernehme von Block Basisdaten den Ansprechpartner/Versicherungsnehmer',
        create_task_auto_fill_client_address:
          'Beim Erstellen einer Besichtigung, übernehme von Block Basisdaten den Besichtigungsort',
        adjuster_can_set_rework: 'Besichtiger kann die Besichtigung in Nacherfassung setzen',
        disable_task_type_on_desk: 'Deaktiviere Besichtigungstyp On-Desk',
        disable_task_type_video: 'Deaktiviere Besichtigungstyp Video',
        allow_adjuster_to_send_emails: 'Besichtiger darf E-Mails senden',
        adjuster_can_change_task_location: 'Besichtiger kann Besichtigungsort anpassen',
        adjuster_can_send_interface: 'Besichtiger kann Dateien per Schnittstelle senden',
      },
    },
    tasksSection: {
      title: 'Besichtigungen Einstellungen',
      description: 'Hier können Sie allgemeine Besichtigungseinstellungen finden',
      labels: {
        tasks_delete_tags_on_complete:
          'Besichtigungs-Klassifizierung bei Abschluss automatisch löschen.',
        tasks_fields_parallel_number_active: 'Parallel- / Folgebesichtigung im Formular anzeigen',
        tasks_fields_urgent_active: 'Eilbesichtigung im Formular anzeigen',
        tasks_hide_duplicate: 'Deaktiviere Besichtigung duplizieren Funktion',
        tasks_filter_cancelled: 'Stornierte Besichtigungen ausblenden',
        tasks_notify_owner_on_create:
          'Besichtiger erhält eine Nachricht, wenn eine Besichtigung für ihn angelegt wurde',
        tasks_notify_owner_on_new_file:
          'Besichtiger erhält eine Nachricht, wenn eine Datei in den Ordner Nacharbeiten hochgeladen wurde',
        tasks_num_routes: 'Anzahl der Routen, die als Besichtiger auf der Karte angezeigt werden',
      },
    },
    reportSection: {
      labels: {
        show_adjuster_email_service_provider_block:
          'Zeige Besichtiger E-Mail in Dienstleister-Block',
      },
    },
  },
  assignments: {
    labels: {
      assignment_max_tasks_per_week: ' Maximale Besichtigungen pro Woche pro User',
      assignment_max_tasks_max_per_day: 'Maximale Besichtigungen pro Tag',
    },
  },
  modules: {
    title: 'Module für die Bearbeitung von Besichtigungen',
    description:
      'Hier können Sie die Sichtbarkeit der Module in der Besichtigungs-Detailseite konfigurieren.',
    labels: {
      qualifications: 'Verwaltung',
    },
  },
  calculationModule: {
    description:
      'Hier können Sie die Sichtbarkeit einzelner Spalten in der Kalkulation ändern. Die Änderungen wirken sich auf alle zukünftigen Besichtigungen mit Kalkulationen aus.',
  },
  appSettingsModule: {
    labels: {
      mobile_can_accept: 'App Nutzer darf Besichtigungen annehmen',
      mobile_lock_tasks_when_mobile_active: 'Besichtigungen sind bei Benutzung der App gesperrt',
    },
  },
  pages: {
    classificationTags: {
      title: 'Besichtigungs-Klassifikation',
      description:
        'Hier können Sie eigene Kategorien anlegen um Ihre Besichtigungen mit einer Kategorie zu versehen. Nach diesen Kriterien können Sie auch filtern.',
      dialogs: {
        exports: {
          title: 'Besichtigungs-Klassifizierung exportieren',
          note: 'Laden Sie die exportierte Besichtigungs-Klassifizierung herunter.',
        },
      },
      notifications: {
        create: {
          success: 'Besichtigungs-Klassifizierung gespeichert!',
        },
        delete: {
          success: 'Besichtigungs-Klassifizierung gelöscht!',
          error:
            'Besichtigungs-Klassifizierung konnte nicht gelöscht werden, da sie noch verwendet wird!',
        },
      },
    },
    qualifications: {
      title: 'Verwalter',
      description:
        'Hier können Sie eigene Verwalter anlegen um Ihre Besichtigungen mit einer Kategorien zu versehen. Nach diesen Kriterien können Sie auch filtern.',
      dialogs: {
        create: {
          title: 'Verwalter anlegen',
          note: 'Wählen Sie einen Namen und eine Farbe.',
        },
        update: {
          title: 'Verwalter bearbeiten',
          note: 'Ändern Sie den Namen oder die Farbe.',
        },
        delete: {
          title: 'Verwalter löschen',
          note: 'Wollen Sie den Verwalter wirklich löschen?',
        },
        exports: {
          title: 'Verwalter exportieren',
          note: 'Laden Sie die exportierten Verwalter herunter.',
        },
      },
      notifications: {
        create: {
          success: 'Verwalter gespeichert!',
        },
        delete: {
          success: 'Verwalter gelöscht!',
          error: 'Verwalter konnte nicht gelöscht werden, da dieser noch verwendet wird!',
        },
      },
    },
  },
  browserSettings: {
    taskAfterLogin: 'Besichtigungsliste/Karte soll nach Login angezeigt werden',
    openTaskInNewTab: 'Besichtigungen als Standard in einem neuen Tab öffnen',
  },
};

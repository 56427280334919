import './Navigation.scss';

import { isString } from '@module/shared/helpers';
import { classNames } from '@progress/kendo-react-common';
import { ReactNode } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';

import { InlineBadge } from '../badges';
import { NavigationData } from './types';
import { useNavigationBarState } from './useNavigationBarState';

export const Navigation = (props: { children: ReactNode }) => (
  <div {...props} className="Navigation noselect" />
);

export const NavigationList = (props: { children: ReactNode }) => (
  <nav {...props} className="NavigationList" />
);

export const NavigationListItem = (props: { children: ReactNode }) => <ul {...props} />;

interface NavigationSubheaderProps {
  children: ReactNode;
  collapsed: boolean;
  onClick?: () => void;
}

export const NavigationSubheader = (props: NavigationSubheaderProps) => {
  const { children, collapsed, onClick } = props;

  return (
    <h3 className="NavigationSubheader k-cursor-pointer" onClick={onClick}>
      <div className="NavigationSubheader__Content">
        <span>{children}</span>
      </div>
      <div className="NavigationSubheader__Collapse">
        <span
          className={classNames({
            'l-i-plus-square': collapsed,
            'l-i-minus-square': !collapsed,
          })}
        />
      </div>
    </h3>
  );
};

type NavigationLinkProps = Omit<NavLinkProps, 'className' | 'children'> & {
  iconClass?: string;
  badge?: string | ReactNode;
  children: ReactNode;
};

export const NavigationLink = (props: NavigationLinkProps) => {
  const { children, iconClass, badge, ...restProps } = props;
  return (
    <li>
      <NavLink {...restProps} className="NavigationLink">
        <div className="NavigationLink__Content">
          {iconClass && <span className={classNames('k-mr-2 NavigationLink__Icon', iconClass)} />}
          <span>{children}</span>
        </div>
        {badge && (
          <div className="NavigationLink__Badge">
            {badge && isString(badge) && <InlineBadge title={badge} />}
            {badge && !isString(badge) && badge}
          </div>
        )}
      </NavLink>
    </li>
  );
};

interface NavigationBarProps {
  data: NavigationData;
}

export const NavigationBar = (props: NavigationBarProps) => {
  const { data } = props;

  const { isSectionCollapsed, toggleSectionCollapse } = useNavigationBarState(data);

  return (
    <Navigation>
      <NavigationList>
        {data.sections.map((section, index) => (
          <>
            {section.subHeader && (
              <NavigationSubheader
                collapsed={isSectionCollapsed(section.id)}
                onClick={() => toggleSectionCollapse(section.id)}
              >
                {section.subHeader.text}
              </NavigationSubheader>
            )}
            {!isSectionCollapsed(section.id) && (
              <NavigationListItem key={index}>
                {section.items.map((item) => (
                  <NavigationLink
                    key={item.id}
                    to={item.path}
                    iconClass={item.iconClass}
                    badge={item?.badge}
                  >
                    {item.text}
                  </NavigationLink>
                ))}
              </NavigationListItem>
            )}
          </>
        ))}
      </NavigationList>
    </Navigation>
  );
};

import {
  Ability,
  AbilityBuilder,
  AbilityClass,
  ForcedSubject,
  subject as subjectHelper,
} from '@casl/ability';
import { useCallback, useMemo } from 'react';

import { Permission } from '../../../types/graphql.generated';
import { GenericCan, GenericCanSharedProps } from '../components/GenericCan';
import { CurrentUserAbilityFragment } from '../graphql';
import { detectSubjectTypeByTypename as detectSubjectType } from '../helpers';
import { useMeOrDefault } from '../hooks';

interface SubformClassificationTagSubject {
  me: CurrentUserAbilityFragment;
}

type SubformClassificationTagSubjectArg = Partial<SubformClassificationTagSubject>;

type Actions =
  | 'viewSubformClassificationTags'
  | 'createSubformClassificationTag'
  | 'updateSubformClassificationTag'
  | 'deleteSubformClassificationTag';
type Subjects = SubformClassificationTagSubject | 'SubformClassificationTagSubject';

type SubformClassificationTagAbility = Ability<[Actions, Subjects]>;
const taskTemplateAbility = Ability as AbilityClass<SubformClassificationTagAbility>;

export const useSubformClassificationTagAbility = (): [
  SubformClassificationTagAbility,
  (
    sub?: SubformClassificationTagSubjectArg,
  ) => SubformClassificationTagSubject & ForcedSubject<'SubformClassificationTagSubject'>,
] => {
  const ability = useMemo(() => {
    const { can, build } = new AbilityBuilder(taskTemplateAbility);

    can('viewSubformClassificationTags', 'SubformClassificationTagSubject', {
      'me.globalPermissions': { $in: [Permission.TAG_INDEX] },
    });
    can('createSubformClassificationTag', 'SubformClassificationTagSubject', {
      'me.globalPermissions': { $in: [Permission.TAG_CREATE] },
    });
    can('updateSubformClassificationTag', 'SubformClassificationTagSubject', {
      'me.globalPermissions': { $in: [Permission.TAG_UPDATE] },
    });
    can('deleteSubformClassificationTag', 'SubformClassificationTagSubject', {
      'me.globalPermissions': { $in: [Permission.TAG_DELETE] },
    });

    return build({ detectSubjectType });
  }, []);

  const me = useMeOrDefault();
  const subject = useCallback(
    (sub?: SubformClassificationTagSubjectArg) => {
      return subjectHelper('SubformClassificationTagSubject', { me, ...sub });
    },
    [me],
  );

  return [ability, subject];
};

type CanSubformClassificationTagProps = GenericCanSharedProps<Actions>;

export const CanSubformClassificationTag = (props: CanSubformClassificationTagProps) => {
  const [fileClassificationTagAbility, fileClassificationTagSubject] =
    useSubformClassificationTagAbility();

  return (
    <GenericCan<Actions, Subjects, SubformClassificationTagAbility>
      ability={fileClassificationTagAbility}
      subject={fileClassificationTagSubject()}
      {...props}
    />
  );
};

import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { ClassificationTagFragmentDoc } from '../../../classification-tags/graphql/generated/classificationTagFragment.generated';
import { TaskMemberFragmentDoc } from './taskMemberFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type TaskItemFieldsFragment = {
  __typename?: 'Task';
  id: string;
  identifier?: string | null;
  external_number?: string | null;
  policy_number?: string | null;
  urgent: boolean;
  task_visit_type_id?: string | null;
  damage_date?: string | null;
  reserve?: number | null;
  created_at: string;
  accepted_date?: string | null;
  follow_up_date?: string | null;
  reminder_date?: string | null;
  set_initial_contact_date?: string | null;
  set_on_site_appointment_date?: string | null;
  set_report_sent_date?: string | null;
  completion_date?: string | null;
  controlling_date?: string | null;
  rework_date?: string | null;
  invoice_date?: string | null;
  state_changed_date?: string | null;
  permissions: Array<Types.Permission>;
  open_subtasks_count: number;
  open_subtasks_color: Types.OpenSubTaskStateColor;
  claimant?: { __typename?: 'Contact'; id: string; name?: string | null } | null;
  customer?: {
    __typename?: 'Contact';
    id: string;
    first_name?: string | null;
    name?: string | null;
    full_name?: string | null;
    phone?: string | null;
    phone2?: string | null;
    mobile?: string | null;
    mobile2?: string | null;
    email?: string | null;
    email2?: string | null;
  } | null;
  primary_location?: {
    __typename?: 'TaskLocation';
    street?: string | null;
    street_no?: string | null;
    postcode?: string | null;
    city?: string | null;
    country_id?: string | null;
    latitude?: number | null;
    longitude?: number | null;
    country: { __typename?: 'Country'; title: string };
  } | null;
  primary_form?: {
    __typename?: 'TaskForm';
    id: string;
    template: {
      __typename?: 'TaskTemplate';
      id: string;
      color: string;
      title: string;
      shortcut?: string | null;
      estimated_appointment_time_seconds: number;
    };
  } | null;
  visit_type?: {
    __typename?: 'TaskVisitType';
    id: string;
    title: string;
    need_location: boolean;
  } | null;
  state: { __typename?: 'TaskState'; id: string; color: string; title: string };
  appointment?: {
    __typename?: 'TaskEvent';
    id: string;
    date_from: string;
    date_to: string;
    description?: string | null;
    event_type: { __typename?: 'TaskEventType'; id: string; color: string; title: string };
    calendar_event?: { __typename?: 'CalendarEvent'; id: string } | null;
  } | null;
  owner?: { __typename?: 'Client'; id: string; name: string } | null;
  leader?: { __typename?: 'Client'; id: string; name: string } | null;
  classification_tags: {
    __typename?: 'TaskClassificationTagList';
    id: string;
    data: Array<{
      __typename?: 'ClassificationTag';
      id: string;
      title: string;
      color: string;
      alias: string;
      api_only: boolean;
    }>;
  };
  client?: {
    __typename?: 'Client';
    id: string;
    name: string;
    client_additional_policy_holder?: {
      __typename?: 'ClientAdditionalPolicyHolder';
      insurance_policy_number?: string | null;
      customer_number?: string | null;
    } | null;
    qualifications: {
      __typename?: 'ClientQualificationList';
      data: Array<{ __typename?: 'Qualification'; title: string; id: string; color: string }>;
    };
  } | null;
  sub_owners: Array<{
    __typename?: 'TaskMember';
    id: string;
    type: Types.TaskMemberType;
    task_id: string;
    client_id: string;
    client?: {
      __typename?: 'Client';
      id: string;
      name: string;
      phone?: string | null;
      email?: string | null;
      notes?: string | null;
      client_type?: { __typename?: 'ClientType'; id: string; title: string; color: string } | null;
    } | null;
  }>;
};

export type TasksQueryVariables = Types.Exact<{
  filter: Types.TasksFilter;
  sort: Types.TasksSort;
  pagination: Types.PaginationInput;
}>;

export type TasksQuery = {
  __typename?: 'Query';
  tasks: {
    __typename?: 'TaskRowPaginator';
    data: Array<{
      __typename?: 'Task';
      id: string;
      identifier?: string | null;
      external_number?: string | null;
      policy_number?: string | null;
      urgent: boolean;
      task_visit_type_id?: string | null;
      damage_date?: string | null;
      reserve?: number | null;
      created_at: string;
      accepted_date?: string | null;
      follow_up_date?: string | null;
      reminder_date?: string | null;
      set_initial_contact_date?: string | null;
      set_on_site_appointment_date?: string | null;
      set_report_sent_date?: string | null;
      completion_date?: string | null;
      controlling_date?: string | null;
      rework_date?: string | null;
      invoice_date?: string | null;
      state_changed_date?: string | null;
      permissions: Array<Types.Permission>;
      open_subtasks_count: number;
      open_subtasks_color: Types.OpenSubTaskStateColor;
      claimant?: { __typename?: 'Contact'; id: string; name?: string | null } | null;
      customer?: {
        __typename?: 'Contact';
        id: string;
        first_name?: string | null;
        name?: string | null;
        full_name?: string | null;
        phone?: string | null;
        phone2?: string | null;
        mobile?: string | null;
        mobile2?: string | null;
        email?: string | null;
        email2?: string | null;
      } | null;
      primary_location?: {
        __typename?: 'TaskLocation';
        street?: string | null;
        street_no?: string | null;
        postcode?: string | null;
        city?: string | null;
        country_id?: string | null;
        latitude?: number | null;
        longitude?: number | null;
        country: { __typename?: 'Country'; title: string };
      } | null;
      primary_form?: {
        __typename?: 'TaskForm';
        id: string;
        template: {
          __typename?: 'TaskTemplate';
          id: string;
          color: string;
          title: string;
          shortcut?: string | null;
          estimated_appointment_time_seconds: number;
        };
      } | null;
      visit_type?: {
        __typename?: 'TaskVisitType';
        id: string;
        title: string;
        need_location: boolean;
      } | null;
      state: { __typename?: 'TaskState'; id: string; color: string; title: string };
      appointment?: {
        __typename?: 'TaskEvent';
        id: string;
        date_from: string;
        date_to: string;
        description?: string | null;
        event_type: { __typename?: 'TaskEventType'; id: string; color: string; title: string };
        calendar_event?: { __typename?: 'CalendarEvent'; id: string } | null;
      } | null;
      owner?: { __typename?: 'Client'; id: string; name: string } | null;
      leader?: { __typename?: 'Client'; id: string; name: string } | null;
      classification_tags: {
        __typename?: 'TaskClassificationTagList';
        id: string;
        data: Array<{
          __typename?: 'ClassificationTag';
          id: string;
          title: string;
          color: string;
          alias: string;
          api_only: boolean;
        }>;
      };
      client?: {
        __typename?: 'Client';
        id: string;
        name: string;
        client_additional_policy_holder?: {
          __typename?: 'ClientAdditionalPolicyHolder';
          insurance_policy_number?: string | null;
          customer_number?: string | null;
        } | null;
        qualifications: {
          __typename?: 'ClientQualificationList';
          data: Array<{ __typename?: 'Qualification'; title: string; id: string; color: string }>;
        };
      } | null;
      sub_owners: Array<{
        __typename?: 'TaskMember';
        id: string;
        type: Types.TaskMemberType;
        task_id: string;
        client_id: string;
        client?: {
          __typename?: 'Client';
          id: string;
          name: string;
          phone?: string | null;
          email?: string | null;
          notes?: string | null;
          client_type?: {
            __typename?: 'ClientType';
            id: string;
            title: string;
            color: string;
          } | null;
        } | null;
      }>;
    }>;
    paginatorInfo: { __typename?: 'PaginatorInfo'; total: number };
  };
};

export const TaskItemFieldsFragmentDoc = gql`
  fragment TaskItemFields on Task {
    id
    identifier
    external_number
    policy_number
    urgent
    task_visit_type_id
    damage_date
    reserve
    created_at
    accepted_date
    follow_up_date
    reminder_date
    set_initial_contact_date
    set_on_site_appointment_date
    set_report_sent_date
    completion_date
    controlling_date
    rework_date
    invoice_date
    state_changed_date
    permissions
    claimant {
      id
      name
    }
    customer {
      id
      first_name
      name
      full_name
      phone
      phone2
      mobile
      mobile2
      email
      email2
    }
    primary_location {
      street
      street_no
      postcode
      city
      country_id
      latitude
      longitude
      country {
        title
      }
    }
    primary_form {
      id
      template {
        id
        color
        title
        shortcut
        estimated_appointment_time_seconds
      }
    }
    visit_type {
      id
      title
      need_location
    }
    state {
      id
      color
      title
    }
    appointment {
      id
      date_from
      date_to
      description
      event_type {
        id
        color
        title
      }
      calendar_event {
        id
      }
    }
    owner {
      id
      name
    }
    leader {
      id
      name
    }
    open_subtasks_count
    open_subtasks_color
    classification_tags {
      id
      data {
        ...ClassificationTag
      }
    }
    client {
      id
      name
      client_additional_policy_holder {
        insurance_policy_number
        customer_number
      }
      qualifications {
        data {
          title
          id
          color
        }
      }
    }
    sub_owners {
      ...TaskMember
    }
  }
  ${ClassificationTagFragmentDoc}
  ${TaskMemberFragmentDoc}
`;
export const TasksDocument = gql`
  query Tasks($filter: TasksFilter!, $sort: TasksSort!, $pagination: PaginationInput!) {
    tasks(filter: $filter, sort: $sort, pagination: $pagination) {
      data {
        ...TaskItemFields
      }
      paginatorInfo {
        total
      }
    }
  }
  ${TaskItemFieldsFragmentDoc}
`;

export function useTasksQuery(options: Omit<Urql.UseQueryArgs<TasksQueryVariables>, 'query'>) {
  return Urql.useQuery<TasksQuery, TasksQueryVariables>({ query: TasksDocument, ...options });
}

import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
export type TaskInvoiceItemFragment = {
  __typename?: 'TaskInvoiceItem';
  id: string;
  position: string;
  title: string;
  unit?: string | null;
  unit_price: number;
  amount: number;
  sum_net: number;
  sum_gross: number;
  tax: number;
};

export type TaskInvoiceFragment = {
  __typename?: 'TaskInvoice';
  id: string;
  task_id: string;
  external_invoice_id?: string | null;
  external_invoice_number?: string | null;
  external_invoice_url?: string | null;
  external_id?: number | null;
  date: string;
  state_id: Types.TaskInvoiceStateId;
  note?: string | null;
  reminder?: boolean | null;
  external_number: string;
  identifier: string;
  address: string;
  sum_gross: number;
  sum_net: number;
  sum_tax: number;
  url_preview: string;
  task: { __typename?: 'Task'; identifier?: string | null };
  state: { __typename?: 'TaskInvoiceState'; id: string; title: string; color: string };
  items: Array<{
    __typename?: 'TaskInvoiceItem';
    id: string;
    position: string;
    title: string;
    unit?: string | null;
    unit_price: number;
    amount: number;
    sum_net: number;
    sum_gross: number;
    tax: number;
  }>;
  payouts?: Array<{
    __typename?: 'TaskInvoicePayout';
    id: string;
    title: string;
    sum_net: number;
    state: {
      __typename?: 'TaskInvoicePayoutState';
      id: string;
      title: string;
      color: string;
      state_id: Types.TaskInvoicePayoutStateId;
    };
  }> | null;
  file?: { __typename?: 'FileItem'; id: string; file_name: string; url: string } | null;
};

export const TaskInvoiceItemFragmentDoc = gql`
  fragment TaskInvoiceItem on TaskInvoiceItem {
    id
    position
    title
    unit
    unit_price
    amount
    sum_net
    sum_gross
    tax
  }
`;
export const TaskInvoiceFragmentDoc = gql`
  fragment TaskInvoice on TaskInvoice {
    id
    task_id
    task {
      identifier
    }
    external_invoice_id
    external_invoice_number
    external_invoice_url
    external_id
    date
    state_id
    note
    reminder
    state {
      id
      title
      color
    }
    external_number
    identifier
    address
    sum_gross
    sum_net
    sum_tax
    items {
      ...TaskInvoiceItem
    }
    payouts {
      id
      title
      state {
        id
        title
        color
        state_id
      }
      sum_net
    }
    file {
      id
      file_name
      url
    }
    url_preview
  }
  ${TaskInvoiceItemFragmentDoc}
`;

import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { ClientGridFragmentDoc } from './clientGridFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ClientsQueryVariables = Types.Exact<{
  filter: Types.ClientsFilter;
  sort: Types.ClientsSort;
  pagination: Types.PaginationInput;
}>;

export type ClientsQuery = {
  __typename?: 'Query';
  clients: {
    __typename?: 'ClientPaginator';
    data: Array<{
      __typename?: 'Client';
      id: string;
      name: string;
      email?: string | null;
      phone?: string | null;
      type: Types.ClientTypeId;
      state_id: Types.ClientStateId;
      permissions: Array<Types.Permission>;
      state: {
        __typename?: 'ClientState';
        id: string;
        color: string;
        title: string;
        state_id: Types.ClientStateId;
      };
      client_type?: { __typename?: 'ClientType'; id: string; title: string; color: string } | null;
      qualifications: {
        __typename?: 'ClientQualificationList';
        data: Array<{ __typename?: 'Qualification'; title: string; id: string; color: string }>;
      };
      primary_location?: {
        __typename?: 'ClientLocation';
        street?: string | null;
        street_no?: string | null;
        street_full?: string | null;
        postcode?: string | null;
        city?: string | null;
        country: { __typename?: 'Country'; title: string };
      } | null;
    }>;
    paginatorInfo: { __typename?: 'PaginatorInfo'; total: number };
  };
};

export const ClientsDocument = gql`
  query Clients($filter: ClientsFilter!, $sort: ClientsSort!, $pagination: PaginationInput!) {
    clients(filter: $filter, sort: $sort, pagination: $pagination) {
      data {
        ...ClientGrid
      }
      paginatorInfo {
        total
      }
    }
  }
  ${ClientGridFragmentDoc}
`;

export function useClientsQuery(options: Omit<Urql.UseQueryArgs<ClientsQueryVariables>, 'query'>) {
  return Urql.useQuery<ClientsQuery, ClientsQueryVariables>({ query: ClientsDocument, ...options });
}

import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { ClientDetailFragmentDoc } from './clientDetailFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ClientDetailQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type ClientDetailQuery = {
  __typename?: 'Query';
  client?: {
    __typename?: 'Client';
    id: string;
    type_id: string;
    type: Types.ClientTypeId;
    salutation_id: string;
    leader_client_id?: string | null;
    name: string;
    email?: string | null;
    phone?: string | null;
    web?: string | null;
    notes?: string | null;
    assignment_notes?: string | null;
    state_id: Types.ClientStateId;
    state_notes?: string | null;
    external_number?: string | null;
    external_client_id?: string | null;
    permissions: Array<Types.Permission>;
    created_at: string;
    updated_at: string;
    primary_location?: {
      __typename?: 'ClientLocation';
      id: string;
      street?: string | null;
      street_no?: string | null;
      postcode?: string | null;
      city?: string | null;
      country_id?: string | null;
      different_policy_holder?: string | null;
      client_location_type: { __typename?: 'ClientLocationType'; title: string };
    } | null;
    client_additional_policy_holder?: {
      __typename?: 'ClientAdditionalPolicyHolder';
      insurance_policy_number?: string | null;
      customer_number?: string | null;
      customer_state_id?: string | null;
      customer_state_date?: string | null;
      customer_state?: { __typename?: 'ClientCustomerState'; title: string } | null;
    } | null;
    client_type?: {
      __typename?: 'ClientType';
      title: string;
      color: string;
      id: string;
      type_id: Types.ClientTypeId;
    } | null;
    user?: {
      __typename?: 'User';
      id: string;
      name: string;
      client_id?: string | null;
      email: string;
      updated_at: string;
      last_login_at?: string | null;
      email_verified_at?: string | null;
      role: Types.UserRole;
      permissions: Array<Types.Permission>;
    } | null;
    disabled_templates: Array<{
      __typename?: 'ClientQualificationFormDisabled';
      id: string;
      template_id: string;
    }>;
    hidden_insurances: Array<{
      __typename?: 'ClientQualificationHideInsurance';
      id: string;
      insurance_client_id: string;
    }>;
    assigned_email_templates?: Array<{
      __typename?: 'EmailTemplate';
      id: string;
      title: string;
    }> | null;
    assigned_interface_message_templates?: Array<{
      __typename?: 'InterfaceMessageTemplate';
      id: string;
      title: string;
    }> | null;
    direct_message_email_templates?: Array<{
      __typename?: 'EmailTemplate';
      id: string;
      title: string;
    }> | null;
    direct_message_interface_message_templates?: Array<{
      __typename?: 'InterfaceMessageTemplate';
      id: string;
      title: string;
    }> | null;
    assigned_templates: Array<
      | { __typename: 'EmailTemplate'; id: string; title: string; description?: string | null }
      | {
          __typename: 'InterfaceMessageTemplate';
          id: string;
          title: string;
          description?: string | null;
        }
    >;
  } | null;
};

export const ClientDetailDocument = gql`
  query ClientDetail($id: ID!) {
    client(id: $id) {
      ...ClientDetail
    }
  }
  ${ClientDetailFragmentDoc}
`;

export function useClientDetailQuery(
  options: Omit<Urql.UseQueryArgs<ClientDetailQueryVariables>, 'query'>,
) {
  return Urql.useQuery<ClientDetailQuery, ClientDetailQueryVariables>({
    query: ClientDetailDocument,
    ...options,
  });
}

import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
export type TaskDocumentFragment = {
  __typename?: 'FileItem';
  id: string;
  name: string;
  file_name: string;
  original_file_name: string;
  url: string;
  url_download: string;
  url_thumb?: string | null;
  size: number;
  updated_at?: string | null;
  created_at?: string | null;
  is_extracting_pages: boolean;
  permissions: Array<Types.Permission>;
  url_pages?: Array<{ __typename?: 'FileItemPage'; page_index: number; url: string }> | null;
  custom_properties: {
    __typename?: 'FileItemCustomProperties';
    description?: string | null;
    is_cover?: boolean | null;
    visible?: boolean | null;
    number_of_pages?: number | null;
    export_pages?: Array<number> | null;
    extracted_pages?: Array<number> | null;
  };
};

export const TaskDocumentFragmentDoc = gql`
  fragment TaskDocument on FileItem {
    id
    name
    file_name
    original_file_name
    url
    url_download
    url_thumb
    size
    updated_at
    created_at
    url_pages {
      page_index
      url
    }
    custom_properties {
      description
      is_cover
      visible
      number_of_pages
      export_pages
      extracted_pages
    }
    is_extracting_pages
    permissions
  }
`;

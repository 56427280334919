import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
export type ClientSelectFragment = {
  __typename?: 'Client';
  id: string;
  name: string;
  email?: string | null;
};

export const ClientSelectFragmentDoc = gql`
  fragment ClientSelect on Client {
    id
    name
    email
  }
`;

import {
  EmailInput,
  FormGroupHorizontal,
  FormGroupVertical,
  PhoneInput,
  RemainingCharacterIndicator,
  TextAreaInput,
  TextInput,
  useFormContext,
} from '@module/shared/forms';
import { Field } from '@progress/kendo-react-form';
import { useTranslation } from 'react-i18next';

import { CountryFragment } from '../../graphql';
import { useFillCityByPostcode } from '../../hooks';
import { CountrySelect, SalutationSelect } from '../inputs';
import { FunctionSelect } from '../inputs/FunctionSelect';
import { ContactSchema } from './contactSchema';

export const ContactFields = (props: {
  idPrefix: string;
  parentFieldName?: string;
  countries?: CountryFragment[];
  isLoadingCountries?: boolean;
}) => {
  const { idPrefix, parentFieldName, countries, isLoadingCountries } = props;
  const { t } = useTranslation();
  const { valueGetter } = useFormContext();
  const getFieldPath = (fieldName: keyof ContactSchema) =>
    parentFieldName ? `${parentFieldName}.${fieldName}` : fieldName;
  const cityFieldPath = getFieldPath('city');
  const { handlePostcodeChange } = useFillCityByPostcode(cityFieldPath);
  const MAX_NOTE_LENGTH = 2000;
  const currentLength = valueGetter(getFieldPath('note'))
    ? valueGetter(getFieldPath('note')).length
    : 0;
  return (
    <FormGroupVertical>
      <FormGroupHorizontal className="k-mt-4">
        <Field
          id={`${idPrefix}.salutation_id`}
          name={getFieldPath('salutation_id')}
          label={t('common.labels.salutation')}
          component={SalutationSelect}
        />
      </FormGroupHorizontal>

      <FormGroupHorizontal>
        <Field
          id={`${idPrefix}.name`}
          name={getFieldPath('name')}
          label={t('common.labels.contact_name')}
          component={TextInput}
        />

        <Field
          id={`${idPrefix}.first_name`}
          name={getFieldPath('first_name')}
          label={t('common.labels.firstname')}
          component={TextInput}
        />

        <Field
          id={`${idPrefix}.function_id`}
          name={getFieldPath('function_id')}
          label={t('common.labels.function')}
          component={FunctionSelect}
        />
      </FormGroupHorizontal>

      <FormGroupHorizontal>
        <Field
          id={`${idPrefix}.street`}
          name={getFieldPath('street')}
          label={t('common.labels.street')}
          component={TextInput}
        />

        <Field
          id={`${idPrefix}.street_no`}
          name={getFieldPath('street_no')}
          label={t('common.labels.street_no')}
          component={TextInput}
        />
      </FormGroupHorizontal>

      <FormGroupHorizontal>
        <Field
          id={`${idPrefix}.postcode`}
          name={getFieldPath('postcode')}
          label={t('common.labels.postcode')}
          component={TextInput}
          onChange={handlePostcodeChange}
        />

        <Field
          id={`${idPrefix}.city`}
          name={cityFieldPath}
          label={t('common.labels.city')}
          component={TextInput}
        />

        <Field
          id={`${idPrefix}.country_id`}
          name={getFieldPath('country_id')}
          label={t('common.labels.country')}
          component={CountrySelect}
          countries={countries}
          isLoading={isLoadingCountries}
        />
      </FormGroupHorizontal>

      <FormGroupHorizontal>
        <Field
          id={`${idPrefix}.phone`}
          name={getFieldPath('phone')}
          label={t('common.labels.primary_phone')}
          component={PhoneInput}
        />

        <Field
          id={`${idPrefix}.phone2`}
          name={getFieldPath('phone2')}
          label={t('common.labels.secondary_phone')}
          component={PhoneInput}
        />

        <Field
          id={`${idPrefix}.mobile`}
          name={getFieldPath('mobile')}
          label={t('common.labels.mobile')}
          component={PhoneInput}
        />
      </FormGroupHorizontal>

      <FormGroupHorizontal>
        <Field
          id={`${idPrefix}.email`}
          name={getFieldPath('email')}
          label={t('common.labels.primary_email')}
          component={EmailInput}
        />

        <Field
          id={`${idPrefix}.email2`}
          name={getFieldPath('email2')}
          label={t('common.labels.secondary_email')}
          component={EmailInput}
        />

        <Field
          id={`${idPrefix}.fax_number`}
          name={getFieldPath('fax_number')}
          label={t('common.labels.fax')}
          component={TextInput}
        />
      </FormGroupHorizontal>

      <Field
        id="contact.create.contact_fields.notes"
        name={getFieldPath('note')}
        label={t('common.labels.notes')}
        component={TextAreaInput}
        autoSize={true}
        maxLength={MAX_NOTE_LENGTH}
      />
      <RemainingCharacterIndicator current={currentLength} max={MAX_NOTE_LENGTH} />
    </FormGroupVertical>
  );
};

export default {
  labels: {
    createdAtDate: 'Erstellt am',
    qualification: 'Verwaltung',
    clientType: 'Kunden-Art',
    customerNumber: 'Kundennummer',
    locationType: 'Risikoort-Typ',
    differentPolicyHolder: 'Abweichender Versicherungsnehmer',
    differentInsurancePolicyNumber: 'Abweichende Versicherungsscheinnummer',
    locationNotes: 'Anmerkungen zum Standort',
  },
  policyHolderTypeId: {
    BUSINESS: 'Gewerbe',
    INDUSTRY: 'Industrie',
  },
  components: {
    policyHolderPolicyHolderFields: {
      insurance_policy_number: 'Versicherungsscheinnummer',
      customer_number: 'Kundennummer',
      customer_state_id: 'Kunden-Status',
      customer_state_date: 'Kunden-Status seit',
      qualification: 'Verwaltung',
      policy_holder_type_id: 'Kunden-Art',
    },
    policyHolderPolicyHolderTab: {
      header: {
        title: 'Kundendaten',
        description: 'Hier sehen Sie die Kundendaten',
      },
      section: {
        title: 'Kundendaten',
        description: 'Hier können Sie die Kundendaten anpassen',
      },
    },
    policyHolderCommonTab: {
      header: {
        title: 'Allgemein',
        description:
          'Hier können Sie die allgemeinen Stammdaten des Versicherungsnehmers anpassen.',
      },
      sections: {
        common: {
          title: 'Stammdaten',
          description: 'Allgemeine Daten zum Versicherungsnehmer',
        },
        qualifications: {
          title: 'Verwaltung',
          description: 'Hier können Sie die Verwaltung festlegen',
        },
      },
    },
    policyHolderAddressTab: {
      header: {
        title: 'Anschrift',
        description: 'Hier können Sie die Anschrift des Versicherungsnehmers anpassen.',
      },
      sections: {
        address: {
          title: 'Adresse',
          description: 'Hauptadresse des Versicherungsnehmers',
        },
      },
    },
    policyHolderLocationsTab: {
      header: {
        title: 'Risikoorte',
        description:
          'Hier können Sie die Haupt- und Substandorte des Versicherungsnehmers bearbeiten.',
      },
    },
  },
  pages: {
    policyHolderOverview: {
      title: 'Versicherungsnehmer - Übersicht',
      description: 'Hier sehen Sie eine Liste aller Versicherungsnehmer.',
      toolbar: {
        create: 'Erstellen',
        import: 'Importieren',
      },
    },
    policyHolderDetail: {
      sections: {
        common: 'Allgemein',
      },
      tabs: {
        common: 'Allgemein',
        address: 'Anschrift',
        contacts: 'Kontakte',
        locations: 'Risikoorte',
        policyHolder: 'Kundendaten',
      },
    },
  },
  dialogs: {
    createPolicyHolder: {
      title: 'Versicherungsnehmer erstellen',
      description: 'Geben Sie die Informationen zum neuen Versicherungsnehmer an.',
    },
    policyHolderExport: {
      title: 'Versicherungsnehmer exportieren',
      description: 'Laden Sie die exportierten Versicherungsnehmer herunter.',
    },
    importPolicyHolders: {
      title: 'Versicherungsnehmer importieren',
      description: 'Hier können Sie Versicherungsnehmer importieren.',
    },
    updateQualification: {
      title: 'Verwaltung anpassen',
      description: 'Wählen Sie die eine Verwaltung aus.',
    },
    deletePolicyHolder: {
      title: 'Versicherungsnehmer löschen',
      description: 'Möchten Sie den Versicherungsnehmer wirklich löschen?',
    },
    createPolicyHolderLocation: {
      title: 'Risikoort erstellen',
    },
    editPolicyHolderLocation: {
      title: 'Risikoort bearbeiten',
    },
    deletePolicyHolderLocation: {
      title: 'Risikoort löschen',
      description: 'Möchten Sie den Risikoort wirklich löschen?',
    },
  },
  notifications: {
    policyHolderCreated: 'Versicherungsnehmer wurde erstellt.',
    openCreatedPolicyHolder: 'Eintrag zum Bearbeiten öffnen',
    policyHolderUpsertSuccess: 'Kundendaten wurde aktualisiert.',
    qualificationUpdated: 'Verwaltung wurde aktualisiert.',
    policyHolderDeleted: 'Versicherungsnehmer wurde gelöscht.',
    policyHolderUpdated: 'Versicherungsnehmer wurde aktualisiert.',
    policyHolderLocationCreateSuccess: 'Risikoort wurde erstellt.',
    policyHolderLocationDeleteSuccess: 'Risikoort wurde gelöscht.',
  },
  errors: {
    notFound: {
      title: 'Versicherungsnehmer nicht gefunden',
      note: 'Der von Ihnen angeforederte Versicherungsnehmer konnte nicht gefunden werden.',
    },
  },
};

import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
export type InsuranceFragment = {
  __typename?: 'Client';
  id: string;
  salutation_id: string;
  name: string;
  email?: string | null;
  phone?: string | null;
  notes?: string | null;
  locations: Array<{
    __typename?: 'ClientLocation';
    id: string;
    street?: string | null;
    street_no?: string | null;
    postcode?: string | null;
    city?: string | null;
    contacts: Array<{
      __typename?: 'Contact';
      id: string;
      first_name?: string | null;
      name?: string | null;
    }>;
  }>;
  client_additional_insurance?: {
    __typename?: 'ClientAdditionalInsurance';
    damage_number_mask?: string | null;
  } | null;
  client_additional_policy_holder?: {
    __typename?: 'ClientAdditionalPolicyHolder';
    insurance_policy_number?: string | null;
    customer_number?: string | null;
    customer_state_date?: string | null;
    policy_holder_type_id?: Types.PolicyHolderType | null;
    customer_state?: { __typename?: 'ClientCustomerState'; title: string } | null;
  } | null;
  qualifications: {
    __typename?: 'ClientQualificationList';
    data: Array<{ __typename?: 'Qualification'; title: string; id: string; color: string }>;
  };
};

export const InsuranceFragmentDoc = gql`
  fragment Insurance on Client {
    id
    salutation_id
    name
    email
    phone
    notes
    locations {
      id
      street
      street_no
      postcode
      city
      contacts {
        id
        first_name
        name
      }
    }
    client_additional_insurance {
      damage_number_mask
    }
    client_additional_policy_holder {
      insurance_policy_number
      customer_number
      customer_state {
        title
      }
      customer_state_date
      policy_holder_type_id
    }
    qualifications {
      data {
        title
        id
        color
      }
    }
  }
`;

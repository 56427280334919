import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { TaskDocumentFragmentDoc } from './taskDocumentFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type TaskDocumentsQueryVariables = Types.Exact<{
  taskId: Types.Scalars['ID'];
}>;

export type TaskDocumentsQuery = {
  __typename?: 'Query';
  taskDocuments: {
    __typename?: 'TaskDocumentsPaginator';
    data: Array<{
      __typename?: 'FileItem';
      id: string;
      name: string;
      file_name: string;
      original_file_name: string;
      url: string;
      url_download: string;
      url_thumb?: string | null;
      size: number;
      updated_at?: string | null;
      created_at?: string | null;
      is_extracting_pages: boolean;
      permissions: Array<Types.Permission>;
      url_pages?: Array<{ __typename?: 'FileItemPage'; page_index: number; url: string }> | null;
      custom_properties: {
        __typename?: 'FileItemCustomProperties';
        description?: string | null;
        is_cover?: boolean | null;
        visible?: boolean | null;
        number_of_pages?: number | null;
        export_pages?: Array<number> | null;
        extracted_pages?: Array<number> | null;
      };
    }>;
  };
};

export const TaskDocumentsDocument = gql`
  query TaskDocuments($taskId: ID!) {
    taskDocuments(task_id: $taskId) {
      data {
        ...TaskDocument
      }
    }
  }
  ${TaskDocumentFragmentDoc}
`;

export function useTaskDocumentsQuery(
  options: Omit<Urql.UseQueryArgs<TaskDocumentsQueryVariables>, 'query'>,
) {
  return Urql.useQuery<TaskDocumentsQuery, TaskDocumentsQueryVariables>({
    query: TaskDocumentsDocument,
    ...options,
  });
}

import { PasswordFieldExtension, UpdateUserPasswordInput, useFormBlocker } from '@module/common';
import {
  Form,
  PasswordField,
  PasswordFieldExtensionProps,
  useSchemaValidator,
} from '@module/shared/forms';
import { useYupPasswordValidation } from '@module/shared/hooks';
import { NotificationType, useNotifications } from '@module/shared/notifications';
import { Field, FormElement } from '@progress/kendo-react-form';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { object, ObjectSchema, ref, string } from 'yup';

import { useUpdateUserPasswordSelfMutation } from '../graphql/generated/updateUserPasswordSelfMutation.generated';

const useUpdateUserPasswordSelfValidator = () => {
  const { t } = useTranslation();
  const password = useYupPasswordValidation();

  const schema: ObjectSchema<UpdateUserPasswordInput> = useMemo(() => {
    return object({
      password: password().required(),
      password_confirmation: string()
        .required()
        .oneOf([ref('password')], t('validation.password.confirmation')),
    });
  }, [t, password]);

  const validator = useSchemaValidator(schema);

  return { schema, validator };
};

export const ChangePasswordForm = (props: { formId: string }) => {
  const { formId } = props;

  const { t } = useTranslation();
  const { validator, schema } = useUpdateUserPasswordSelfValidator();
  const [, updateUserPasswordSelf] = useUpdateUserPasswordSelfMutation();
  const { showNotification } = useNotifications();

  const renderPasswordStrength = (props: PasswordFieldExtensionProps) => {
    return <PasswordFieldExtension {...props} />;
  };

  const { ref, Blocker, resetModified } = useFormBlocker();

  const handleSubmit = async (values: { [name: string]: unknown }) => {
    const input = values as UpdateUserPasswordInput;

    const mutationResult = await updateUserPasswordSelf({ input });
    if (!mutationResult.error) {
      showNotification(t('settings.changePasswordSelf.success'), NotificationType.Success);
      resetModified();
      return;
    }
    showNotification(t('common.errors.generic'), NotificationType.Error);
  };

  return (
    <div className="k-my-3">
      <b>{t('settings.changePasswordSelf.title')}</b>
      <div>{t('settings.changePasswordSelf.description')}</div>
      <Form
        id={formId}
        ref={ref}
        schema={schema}
        onSubmit={handleSubmit}
        validator={validator}
        render={() => (
          <>
            <FormElement className="k-my-3">
              <Field
                id="password.change.password"
                name={'password'}
                label={t('common.labels.password')}
                component={PasswordField}
                extension={renderPasswordStrength}
              />
              <Field
                id="password.change.password_confirmation"
                name={'password_confirmation'}
                label={t('common.labels.password_confirmation')}
                component={PasswordField}
                extension={renderPasswordStrength}
              />
            </FormElement>
            <Blocker />
          </>
        )}
      />
    </div>
  );
};

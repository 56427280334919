import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { TaskMemberFragmentDoc } from './taskMemberFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type TaskMapPopupQueryVariables = Types.Exact<{
  taskIds: Types.Scalars['Mixed'];
}>;

export type TaskMapPopupQuery = {
  __typename?: 'Query';
  tasks: {
    __typename?: 'TaskRowPaginator';
    data: Array<{
      __typename?: 'Task';
      id: string;
      identifier?: string | null;
      permissions: Array<Types.Permission>;
      state: { __typename?: 'TaskState'; id: string; title: string; color: string };
      owner?: { __typename?: 'Client'; id: string; name: string } | null;
      customer?: { __typename?: 'Contact'; full_name?: string | null } | null;
      classification_tags: {
        __typename?: 'TaskClassificationTagList';
        id: string;
        data: Array<{ __typename?: 'ClassificationTag'; id: string; title: string; color: string }>;
      };
      qualifications: {
        __typename?: 'TaskQualificationList';
        data: Array<{ __typename?: 'Qualification'; id: string; title: string; color: string }>;
      };
      primary_form?: {
        __typename?: 'TaskForm';
        id: string;
        template: { __typename?: 'TaskTemplate'; color: string; title: string };
      } | null;
      primary_location?: {
        __typename?: 'TaskLocation';
        latitude?: number | null;
        longitude?: number | null;
      } | null;
      visit_type?: { __typename?: 'TaskVisitType'; title: string } | null;
      sub_owners: Array<{
        __typename?: 'TaskMember';
        id: string;
        type: Types.TaskMemberType;
        task_id: string;
        client_id: string;
        client?: {
          __typename?: 'Client';
          id: string;
          name: string;
          phone?: string | null;
          email?: string | null;
          notes?: string | null;
          client_type?: {
            __typename?: 'ClientType';
            id: string;
            title: string;
            color: string;
          } | null;
        } | null;
      }>;
    }>;
  };
};

export const TaskMapPopupDocument = gql`
  query TaskMapPopup($taskIds: Mixed!) {
    tasks(
      filter: { AND: [{ column: ID, operator: IN, value: $taskIds }] }
      pagination: { skip: 0, take: 999 }
      sort: { columns: [] }
    ) {
      data {
        id
        identifier
        state {
          id
          title
          color
        }
        owner {
          id
          name
        }
        customer {
          full_name
        }
        classification_tags {
          id
          data {
            id
            title
            color
          }
        }
        qualifications {
          data {
            id
            title
            color
          }
        }
        primary_form {
          id
          template {
            color
            title
          }
        }
        primary_location {
          latitude
          longitude
        }
        visit_type {
          title
        }
        permissions
        sub_owners {
          ...TaskMember
        }
      }
    }
  }
  ${TaskMemberFragmentDoc}
`;

export function useTaskMapPopupQuery(
  options: Omit<Urql.UseQueryArgs<TaskMapPopupQueryVariables>, 'query'>,
) {
  return Urql.useQuery<TaskMapPopupQuery, TaskMapPopupQueryVariables>({
    query: TaskMapPopupDocument,
    ...options,
  });
}

import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type InvoicesForClientQueryVariables = Types.Exact<{
  client_id: Types.Scalars['ID'];
  filter: Types.InvoiceFilter;
  sort: Types.InvoiceSort;
  pagination: Types.PaginationInput;
}>;

export type InvoicesForClientQuery = {
  __typename?: 'Query';
  invoicesForClient: {
    __typename?: 'InvoicePaginator';
    sum_gross?: number | null;
    sum_net?: number | null;
    data: Array<{
      __typename?: 'TaskInvoice';
      identifier: string;
      external_number: string;
      date: string;
      task_id: string;
      address: string;
      sum_net: number;
      sum_gross: number;
      state_id: Types.TaskInvoiceStateId;
      task: {
        __typename?: 'Task';
        id: string;
        client_id?: string | null;
        client?: { __typename?: 'Client'; name: string } | null;
      };
      state: { __typename?: 'TaskInvoiceState'; color: string; title: string; id: string };
    }>;
    paginatorInfo: { __typename?: 'PaginatorInfo'; total: number };
  };
};

export const InvoicesForClientDocument = gql`
  query InvoicesForClient(
    $client_id: ID!
    $filter: InvoiceFilter!
    $sort: InvoiceSort!
    $pagination: PaginationInput!
  ) {
    invoicesForClient(
      client_id: $client_id
      filter: $filter
      sort: $sort
      pagination: $pagination
    ) {
      data {
        identifier
        external_number
        date
        task {
          id
          client_id
          client {
            name
          }
        }
        task_id
        address
        sum_net
        sum_gross
        state_id
        state {
          color
          title
          id
        }
      }
      sum_gross
      sum_net
      paginatorInfo {
        total
      }
    }
  }
`;

export function useInvoicesForClientQuery(
  options: Omit<Urql.UseQueryArgs<InvoicesForClientQueryVariables>, 'query'>,
) {
  return Urql.useQuery<InvoicesForClientQuery, InvoicesForClientQueryVariables>({
    query: InvoicesForClientDocument,
    ...options,
  });
}

import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
export type TaskDetailClientFragment = {
  __typename?: 'Client';
  id: string;
  name: string;
  email?: string | null;
  alternate_email?: string | null;
  phone?: string | null;
  notes?: string | null;
  client_type?: {
    __typename?: 'ClientType';
    id: string;
    title: string;
    color: string;
    type_id: Types.ClientTypeId;
  } | null;
  user?: { __typename?: 'User'; id: string } | null;
  primary_location?: {
    __typename?: 'ClientLocation';
    longitude?: number | null;
    latitude?: number | null;
    street?: string | null;
    street_no?: string | null;
    postcode?: string | null;
    city?: string | null;
  } | null;
  client_additional_policy_holder?: {
    __typename?: 'ClientAdditionalPolicyHolder';
    insurance_policy_number?: string | null;
    customer_number?: string | null;
    customer_state_date?: string | null;
    customer_state?: {
      __typename?: 'ClientCustomerState';
      id: string;
      title: string;
      color: string;
    } | null;
  } | null;
  qualifications: {
    __typename?: 'ClientQualificationList';
    data: Array<{ __typename?: 'Qualification'; id: string; title: string; color: string }>;
  };
  assigned_email_templates?: Array<{
    __typename?: 'EmailTemplate';
    id: string;
    title: string;
    description?: string | null;
  }> | null;
  direct_message_email_templates?: Array<{
    __typename?: 'EmailTemplate';
    id: string;
    title: string;
    description?: string | null;
  }> | null;
  direct_message_interface_message_templates?: Array<{
    __typename?: 'InterfaceMessageTemplate';
    id: string;
    title: string;
    description?: string | null;
  }> | null;
  assigned_templates: Array<
    | { __typename: 'EmailTemplate'; id: string; title: string; description?: string | null }
    | {
        __typename: 'InterfaceMessageTemplate';
        id: string;
        title: string;
        description?: string | null;
      }
  >;
};

export const TaskDetailClientFragmentDoc = gql`
  fragment TaskDetailClient on Client {
    id
    name
    email
    alternate_email
    phone
    client_type {
      id
      title
      color
      type_id
    }
    notes
    user {
      id
    }
    primary_location {
      longitude
      latitude
      street
      street_no
      postcode
      city
    }
    client_additional_policy_holder {
      insurance_policy_number
      customer_number
      customer_state {
        id
        title
        color
      }
      customer_state_date
    }
    qualifications {
      data {
        id
        title
        color
      }
    }
    assigned_email_templates {
      id
      title
      description
    }
    direct_message_email_templates {
      id
      title
      description
    }
    direct_message_interface_message_templates {
      id
      title
      description
    }
    assigned_templates {
      __typename
      ... on EmailTemplate {
        id
        title
        description
      }
      ... on InterfaceMessageTemplate {
        id
        title
        description
      }
    }
  }
`;

import { load, loadMessages } from '@progress/kendo-react-intl';
import currencyData from 'cldr-core/supplemental/currencyData.json';
import likelySubtags from 'cldr-core/supplemental/likelySubtags.json';
import weekData from 'cldr-core/supplemental/weekData.json';
import deGregorian from 'cldr-dates-full/main/de/ca-gregorian.json';
import deDateFields from 'cldr-dates-full/main/de/dateFields.json';
import deTimeZoneNames from 'cldr-dates-full/main/de/timeZoneNames.json';
import hrGregorian from 'cldr-dates-full/main/hr/ca-gregorian.json';
import hrDateFields from 'cldr-dates-full/main/hr/dateFields.json';
import hrTimeZoneNames from 'cldr-dates-full/main/hr/timeZoneNames.json';
import deCurrencies from 'cldr-numbers-full/main/de/currencies.json';
import deNumbers from 'cldr-numbers-full/main/de/numbers.json';
import hrCurrencies from 'cldr-numbers-full/main/hr/currencies.json';
import hrNumbers from 'cldr-numbers-full/main/hr/numbers.json';
import { Locale, setDefaultOptions } from 'date-fns';
import { de, enUS, hr } from 'date-fns/locale';
import i18n, { Resource } from 'i18next';
import { merge } from 'lodash';
import { initReactI18next } from 'react-i18next';

// As german translations provided by Kendo are incomplete, we need to load an additional file.
// Reference: https://github.com/telerik/kendo-react-messages/blob/master/messages/en-US/en-US.json
import deKendoCustom from './de/kendo.custom.json';
// There is currently no NPM package for the "official" translations.
// JSON needs to be pulled from https://github.com/telerik/kendo-react-messages/tree/master/messages/de-DE
import deKendoVendor from './de/kendo.vendor.json';
import hrKendoCustom from './hr/kendo.custom.json';
import hrKendoVendor from './hr/kendo.vendor.json';

export interface LocaleDefinition {
  text: string;
  localeId: string;
  id: string;
}

export const locales: LocaleDefinition[] = [
  {
    text: 'Deutsch',
    localeId: 'de-DE',
    id: '54', // id based on the CountryQueryID
  },
  {
    text: 'English',
    localeId: 'en-US',
    id: '6',
  },
  {
    text: 'Hrvatski',
    localeId: 'hr-HR',
    id: '91',
  },
];

import { LOCALE, LOCALE_OVERRIDE } from '@env';
import { setLocale } from 'yup';

import { localsDE } from './de';
import { localsEN } from './en';
import { localsHR } from './hr';
import { localeOverrideRISK } from './overrides/risk';
import { localeOverrideSTP } from './overrides/stp';
import { localeOverrideTwentyfour } from './overrides/twentyfour';

const localeMapDefaults: { [languageKey: string]: Resource } = {
  'de-DE': localsDE,
  'en-US': localsEN,
  'hr-HR': localsHR,
};

const localeMapOverrides: { [languageKey: string]: Resource } = {
  'de-DE': {
    stp: localeOverrideSTP,
    risk: localeOverrideRISK,
    twentyfour: localeOverrideTwentyfour,
  },
};

const mergeLocaleOverrides = (locale: string, localeOverride: string) => {
  if (
    localeMapDefaults[locale] &&
    localeMapOverrides[locale] &&
    localeMapOverrides[locale][localeOverride]
  ) {
    return merge(localeMapDefaults[locale], localeMapOverrides[locale][localeOverride]);
  }

  if (localeMapDefaults[locale]) {
    return localeMapDefaults[locale];
  }

  return {};
};

const localeMerged = {
  'de-DE': { common: mergeLocaleOverrides('de-DE', LOCALE_OVERRIDE) },
  'en-US': { common: mergeLocaleOverrides('en-US', LOCALE_OVERRIDE) },
  'hr-HR': { common: mergeLocaleOverrides('hr-HR', LOCALE_OVERRIDE) },
};

const localesMapForDateFns: { [languageKey: string]: Locale } = {
  'de-DE': de,
  'en-US': enUS,
  'hr-HR': hr,
};

// Used to set lang attribute on HTML tag
const localesMapForHtmlTag: { [languageKey: string]: string } = {
  'de-DE': 'de',
  'en-US': 'en',
  'hr-HR': 'hr',
};
document.documentElement.setAttribute('lang', localesMapForHtmlTag[LOCALE]);

i18n.use(initReactI18next).init({
  lng: LOCALE,
  fallbackLng: 'de-DE', // use en if detected lng is not available
  keySeparator: '.', // we do not use keys in form messages.welcome
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
  react: {
    useSuspense: false,
  },
  returnNull: false,

  resources: localeMerged,
  // have a common namespace used around the full app
  ns: ['common'],
  defaultNS: 'common',
});

import { yupLocale } from './yup';

setLocale(yupLocale);

setDefaultOptions({ locale: localesMapForDateFns[LOCALE] });

load(
  likelySubtags,
  currencyData,
  weekData,
  deCurrencies,
  deNumbers,
  deGregorian,
  deTimeZoneNames,
  deDateFields,
  hrGregorian,
  hrDateFields,
  hrTimeZoneNames,
  hrCurrencies,
  hrNumbers,
);
loadMessages(deKendoVendor, 'de-DE');
loadMessages(deKendoCustom, 'de-DE');
loadMessages(hrKendoVendor, 'hr-HR');
loadMessages(hrKendoCustom, 'hr-HR');

export default i18n;

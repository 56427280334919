import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
export type TaskImageFragment = {
  __typename?: 'FileItem';
  id: string;
  name: string;
  file_name: string;
  original_file_name: string;
  url: string;
  url_thumb?: string | null;
  size: number;
  url_thumb_report?: string | null;
  url_download: string;
  is_extracting_pages: boolean;
  permissions: Array<Types.Permission>;
  custom_properties: {
    __typename?: 'FileItemCustomProperties';
    description?: string | null;
    is_cover?: boolean | null;
    visible?: boolean | null;
    number_of_pages?: number | null;
    export_pages?: Array<number> | null;
  };
};

export const TaskImageFragmentDoc = gql`
  fragment TaskImage on FileItem {
    id
    name
    file_name
    original_file_name
    url
    url_thumb
    size
    url_thumb_report
    url_download
    custom_properties {
      description
      is_cover
      visible
      number_of_pages
      export_pages
    }
    is_extracting_pages
    permissions
  }
`;

import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
export type TaskMemberFragment = {
  __typename?: 'TaskMember';
  id: string;
  type: Types.TaskMemberType;
  task_id: string;
  client_id: string;
  client?: {
    __typename?: 'Client';
    id: string;
    name: string;
    phone?: string | null;
    email?: string | null;
    notes?: string | null;
    client_type?: { __typename?: 'ClientType'; id: string; title: string; color: string } | null;
  } | null;
};

export const TaskMemberFragmentDoc = gql`
  fragment TaskMember on TaskMember {
    id
    type
    task_id
    client_id
    client {
      id
      name
      phone
      email
      notes
      client_type {
        id
        title
        color
      }
    }
  }
`;

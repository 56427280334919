import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { ClientSelectFragmentDoc } from './clientSelectFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AdjustersQueryVariables = Types.Exact<{ [key: string]: never }>;

export type AdjustersQuery = {
  __typename?: 'Query';
  adjusters: {
    __typename?: 'AdjusterList';
    data: Array<{ __typename?: 'Client'; id: string; name: string; email?: string | null }>;
  };
};

export const AdjustersDocument = gql`
  query Adjusters {
    adjusters {
      data {
        ...ClientSelect
      }
    }
  }
  ${ClientSelectFragmentDoc}
`;

export function useAdjustersQuery(
  options?: Omit<Urql.UseQueryArgs<AdjustersQueryVariables>, 'query'>,
) {
  return Urql.useQuery<AdjustersQuery, AdjustersQueryVariables>({
    query: AdjustersDocument,
    ...options,
  });
}

import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type TasksMapTaskFragment = {
  __typename?: 'Task';
  id: string;
  identifier?: string | null;
  created_at: string;
  policy_number?: string | null;
  customer?: { __typename?: 'Contact'; id: string; full_name?: string | null } | null;
  client?: {
    __typename?: 'Client';
    name: string;
    client_additional_policy_holder?: {
      __typename?: 'ClientAdditionalPolicyHolder';
      insurance_policy_number?: string | null;
      customer_number?: string | null;
    } | null;
  } | null;
  claimant?: { __typename?: 'Contact'; full_name?: string | null } | null;
  geoData?: {
    __typename?: 'TaskGeoData';
    layer_id: string;
    location: {
      __typename?: 'TaskLocation';
      id: string;
      street_full?: string | null;
      postcode?: string | null;
      city?: string | null;
      longitude?: number | null;
      latitude?: number | null;
    };
  } | null;
  appointment?: { __typename?: 'TaskEvent'; id: string; date_from: string; date_to: string } | null;
  state: { __typename?: 'TaskState'; id: string; title: string; color: string };
  primary_form?: {
    __typename?: 'TaskForm';
    template: { __typename?: 'TaskTemplate'; shortcut?: string | null; color: string };
  } | null;
};

export type TasksMapQueryVariables = Types.Exact<{
  filter: Types.TasksFilter;
  sort: Types.TasksSort;
}>;

export type TasksMapQuery = {
  __typename?: 'Query';
  tasksMap: {
    __typename?: 'TasksGeoJSON';
    data: Array<{
      __typename?: 'Task';
      id: string;
      identifier?: string | null;
      created_at: string;
      policy_number?: string | null;
      customer?: { __typename?: 'Contact'; id: string; full_name?: string | null } | null;
      client?: {
        __typename?: 'Client';
        name: string;
        client_additional_policy_holder?: {
          __typename?: 'ClientAdditionalPolicyHolder';
          insurance_policy_number?: string | null;
          customer_number?: string | null;
        } | null;
      } | null;
      claimant?: { __typename?: 'Contact'; full_name?: string | null } | null;
      geoData?: {
        __typename?: 'TaskGeoData';
        layer_id: string;
        location: {
          __typename?: 'TaskLocation';
          id: string;
          street_full?: string | null;
          postcode?: string | null;
          city?: string | null;
          longitude?: number | null;
          latitude?: number | null;
        };
      } | null;
      appointment?: {
        __typename?: 'TaskEvent';
        id: string;
        date_from: string;
        date_to: string;
      } | null;
      state: { __typename?: 'TaskState'; id: string; title: string; color: string };
      primary_form?: {
        __typename?: 'TaskForm';
        template: { __typename?: 'TaskTemplate'; shortcut?: string | null; color: string };
      } | null;
    }>;
  };
};

export const TasksMapTaskFragmentDoc = gql`
  fragment TasksMapTask on Task {
    id
    identifier
    customer {
      id
      full_name
    }
    client {
      name
      client_additional_policy_holder {
        insurance_policy_number
        customer_number
      }
    }
    claimant {
      full_name
    }
    geoData {
      layer_id
      location {
        id
        street_full
        postcode
        city
        longitude
        latitude
      }
    }
    appointment {
      id
      date_from
      date_to
    }
    state {
      id
      title
      color
    }
    created_at
    policy_number
    primary_form {
      template {
        shortcut
        color
      }
    }
  }
`;
export const TasksMapDocument = gql`
  query TasksMap($filter: TasksFilter!, $sort: TasksSort!) {
    tasksMap(filter: $filter, sort: $sort) {
      data {
        ...TasksMapTask
      }
    }
  }
  ${TasksMapTaskFragmentDoc}
`;

export function useTasksMapQuery(
  options: Omit<Urql.UseQueryArgs<TasksMapQueryVariables>, 'query'>,
) {
  return Urql.useQuery<TasksMapQuery, TasksMapQueryVariables>({
    query: TasksMapDocument,
    ...options,
  });
}

import { DataTestIds } from '@const';
import { useAuthLogic } from '@module/auth';
import { useMe } from '@module/casl';
import { useDetectClickOutside } from '@module/common';
import { ProfileSettingsDialog } from '@module/settings/dialogs/ProfileSettingsDialog';
import { useDialogs } from '@module/shared/dialogs';
import { Button } from '@progress/kendo-react-buttons';
import { Avatar } from '@progress/kendo-react-layout';
import { Popup } from '@progress/kendo-react-popup';
import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const PageHeaderAvatar = () => {
  const { t } = useTranslation();
  const { showDialog } = useDialogs();
  const navigate = useNavigate();

  const { logout } = useAuthLogic();

  const me = useMe();

  const avatarPopupAnchor = useRef<HTMLDivElement | null>(null);
  const avatarPopupRef = useDetectClickOutside<HTMLDivElement>(
    () => setAvatarPopupOpen(false),
    100,
  );
  const [avatarPopupOpen, setAvatarPopupOpen] = useState(false);

  const handleLogout = useCallback(() => {
    logout().then(() => {
      navigate('/');
    });
  }, [logout, navigate]);

  return (
    <div ref={avatarPopupAnchor}>
      <div ref={avatarPopupRef}>
        <Button
          onClick={() => setAvatarPopupOpen(!avatarPopupOpen)}
          fillMode="flat"
          data-testid={DataTestIds.PAGE_HEADER_AVATAR}
          aria-label={t('common.navigation.items.profileSettings')}
        >
          {me.profile_image && (
            <Avatar type="image" size="medium">
              <img src={me.profile_image.url} alt={t('settings.profileImage.title')} />
            </Avatar>
          )}
          {me.name && !me.profile_image && (
            <Avatar type="text" size="medium" className="k-font-weight-semibold">
              {me.name.substring(0, 1).toUpperCase()}
            </Avatar>
          )}
          <span className="l-i-chevron-down" />
        </Button>
        <Popup
          anchor={avatarPopupAnchor.current}
          popupClass="page-header-nav-popup"
          show={avatarPopupOpen}
        >
          <div className="k-pl-2 k-pb-2 k-pt-2 k-border-left-0 k-border-top-0 k-border-right-0 k-border-bottom-1 k-border-solid k-border-light">
            <p className="k-fs-xs !k-mb-0">{t('common.labels.loggedInAs')}</p>
            <p className="!k-mb-0">{me.name}</p>
          </div>

          <Button
            className="page-header-nav-popup-button k-p-2"
            fillMode="flat"
            onClick={() => showDialog({}, ProfileSettingsDialog)}
          >
            {t('common.navigation.items.profileSettings')}
          </Button>

          <Button
            className="page-header-nav-popup-button k-p-2"
            fillMode="flat"
            onClick={handleLogout}
            data-testid={DataTestIds.PAGE_HEADER_AVATAR_LOGOUT}
          >
            {t('common.navigation.items.logout')}
            <span className="l-i-log-out k-mr-1" />
          </Button>
        </Popup>
      </div>
    </div>
  );
};

import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AdjusterFilterOptionsQueryVariables = Types.Exact<{
  state_ids?: Types.InputMaybe<Array<Types.ClientStateId> | Types.ClientStateId>;
}>;

export type AdjusterFilterOptionsQuery = {
  __typename?: 'Query';
  adjusters: {
    __typename?: 'AdjusterList';
    data: Array<{
      __typename?: 'Client';
      id: string;
      name: string;
      state_id: Types.ClientStateId;
      leader?: { __typename?: 'Client'; id: string; name: string } | null;
    }>;
  };
};

export const AdjusterFilterOptionsDocument = gql`
  query AdjusterFilterOptions($state_ids: [ClientStateId!]) {
    adjusters(state_ids: $state_ids) {
      data {
        id
        name
        state_id
        leader {
          id
          name
        }
      }
    }
  }
`;

export function useAdjusterFilterOptionsQuery(
  options?: Omit<Urql.UseQueryArgs<AdjusterFilterOptionsQueryVariables>, 'query'>,
) {
  return Urql.useQuery<AdjusterFilterOptionsQuery, AdjusterFilterOptionsQueryVariables>({
    query: AdjusterFilterOptionsDocument,
    ...options,
  });
}

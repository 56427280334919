export default {
  dialogs: {
    confirmCreateRandomTasks: {
      title: 'Testbesichtigungen erzeugen',
      description:
        'Achtung, damit erzeugen Sie die gewählten Besichtigungen in dem Portal. Wollen Sie fortfahren?',
    },
  },
  forms: {
    createRandomTasks: {
      title: 'Test-Besichtigungen erzeugen',
      description:
        'Definieren Sie hier die Anzahl und Eigenschaften der zu erzeugenden Test-Besichtigungen.',
      numTasks: 'Anzahl der Testbesichtigungen',

      numTasksTooltip:
        'Beachten Sie, dass für das erstellen von Besichtigungen zumindest eine Versicherung, ein Sachverständiger und ein Teamleiter existieren muss.',
    },
  },
  notifications: {
    createRandomTasks: {
      success: 'Testbesichtigungen erfolgreich erzeugt.',
      error: 'Fehler beim Erzeugen der Testbesichtigungen.',
    },
  },
};

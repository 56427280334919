import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { CalendarEventTypeFragmentDoc } from './calendarEventTypeFragment.generated';
export type CalendarEventLocationFragment = {
  __typename?: 'TaskLocation';
  city?: string | null;
  postcode?: string | null;
  street_full?: string | null;
};

export type CalendarEventFragment = {
  __typename?: 'CalendarEvent';
  date_from: string;
  date_to: string;
  description?: string | null;
  id: string;
  task_id?: string | null;
  is_all_day: boolean;
  title: string;
  type_id: Types.CalendarEventTypeId;
  permissions?: Array<Types.Permission> | null;
  task_event?: {
    __typename?: 'TaskEvent';
    id: string;
    task: {
      __typename?: 'Task';
      identifier?: string | null;
      permissions: Array<Types.Permission>;
      owner?: { __typename?: 'Client'; name: string } | null;
      customer?: { __typename?: 'Contact'; full_name?: string | null } | null;
      primary_location?: {
        __typename?: 'TaskLocation';
        city?: string | null;
        postcode?: string | null;
        street_full?: string | null;
      } | null;
    };
  } | null;
  type: {
    __typename?: 'CalendarEventType';
    id: string;
    title: string;
    color: string;
    type_id: Types.CalendarEventTypeId;
  };
};

export const CalendarEventLocationFragmentDoc = gql`
  fragment CalendarEventLocation on TaskLocation {
    city
    postcode
    street_full
  }
`;
export const CalendarEventFragmentDoc = gql`
  fragment CalendarEvent on CalendarEvent {
    date_from
    date_to
    description
    id
    task_id
    is_all_day
    task_event {
      id
      task {
        identifier
        owner {
          name
        }
        customer {
          full_name
        }
        primary_location {
          ...CalendarEventLocation
        }
        permissions
      }
    }
    title
    type {
      ...CalendarEventType
    }
    type_id
    permissions
  }
  ${CalendarEventLocationFragmentDoc}
  ${CalendarEventTypeFragmentDoc}
`;

export default {
  components: {
    noteField: {
      showDialog: 'Notizdialog öffnen',
    },
    pageBreakIndicator: {
      pageBreak: 'Seitenumbruch',
    },
    templateDesignerToolbar: {
      title: 'Formular-Designer',
      buttons: {
        designer: 'Designer',
        grid: 'Table',
        debug: 'Debug',
        json: 'JSON',
      },
      extended: {
        import: 'Importiere aus Rocketform',
        internalActivate: 'Alle Fragen für internen Bericht aktivieren',
        internalDeactivate: 'Alle Fragen für internen Bericht deaktivieren',
        externalActivate: 'Alle Fragen für Extern aktivieren',
        externalDeactivate: 'Alle Fragen für Extern deaktivieren',
      },
      saveStatus: {
        saved: 'Gespeichert',
        unsaved: 'Ungespeicherte Änderungen',
      },
    },
    imageColorPicker: {
      title: 'Wählen Sie eine Farbe',
      openDialog: 'Farbwähler öffnen',
    },
    assignAllTemplates: {
      title: 'Berichtsfragen-Zuweisung',
      description:
        'Achtung, damit werden alle Formulare für alle Kunden zugewiesen. Wollen Sie fortfahren?',
    },
    templateRenderer: {
      noRecords: 'Es wurden keine Einträge zu deiner Suche gefunden',
      labels: {
        title: 'Fügen Sie eine H1 Überschrift ein, um mit einem neuen Formular zu starten.',
      },
    },
    templatePreview: {
      title: 'Vorschau',
      description: 'Das ist eine Vorschau des Formulars',
      alert: 'Die Tabellenbreite muss 100% ergeben, bitte überprüfen Sie links Ihre Eingaben.',
      setTableDefaultValuesSuccess: 'Standardwerte wurden gespeichert',
      maxLength: 'Text darf maximal {{length}} Zeichen lang sein.',
      scrollToQuestion: 'Zur Frage scrollen',
      table: {
        addNew: 'Neuer Eintrag',
        saveDefaultValues: 'Standardwerte speichern',
        deleteRowConfirm: {
          title: 'Eintrag löschen?',
          description: 'Möchten Sie diesen Eintrag wirklich löschen?',
        },
      },
    },
    toolbar: {
      textField: 'Text',
      selectField: 'Auswahl',
      dateField: 'Datum',
      dateTimeField: 'Datum & Uhrzeit',
      timeField: 'Zeit',
      numericField: 'Zahlen',
      yesNoField: 'Ja/Nein',
      headlineField: 'Überschrift',
      subHeadlineField: 'Unter-Überschrift',
      tableField: 'Tabelle',
      richtextField: 'Richtext',
      imageField: 'Bilder',
      paste: 'Kopie einfügen',
    },
    emptyDropZone: {
      title: 'Keine Einträge gefunden',
      description: 'Hier können Sie Elemente von links ablegen, um das Formular aufzubauen.',
    },
    emptyPreview: {
      title: 'Noch keine Vorschau möglich',
      description: 'Hier wird die Vorschau angezeigt, sobald es Elemente im Formular gibt.',
    },
  },
  dialogs: {
    templateImport: {
      title: 'Berichtsfragen importieren',
      note: 'Hier können Sie eine templates.json Datei von Rocketform hochladen, um die Formulardaten zu importieren.',
      jsonCode:
        'Hier können Sie den JSON Code von Rocketform kopieren, um den Inhalt des Formulares zu importieren.',
      save: 'Importieren',
      cancel: 'Abbrechen',
    },
    confirmDebug: {
      title: 'Formular debuggen',
      note: 'Möchten Sie wirklich überall in diesem Formular den fehlenden Identifier hinzufügen?',
    },
    templateDelete: {
      title: 'Fragen-Template löschen?',
      note: 'Möchten Sie dieses Fragen-Template wirklich löschen?',
    },
    templateEdit: {
      title: 'Fragen-Template editieren?',
      note: 'Möchten Sie dieses Fragen-Template wirklich editieren?\n Es wird dabei eine Kopie angelegt.',
    },
    templateSaveAsCopy: {
      title: 'Fragen-Template als Kopie speichern?',
      note:
        'Möchten Sie diese Fragen-Template wirklich speichern?\n' +
        ' Dabei wird eine Kopie erstellt',
    },
    templateUpdate: {
      reportingTitle: 'Update Berichtsfragen-Vorlage',
      statisticsTitle: 'Update Statistikfragen-Vorlage',
      all: 'Alle Versicherungen',
      active: 'Aktiv für diese Versicherungen',
    },
    templateAssign: {
      title: 'Berichtsfragen Zuweisung ändern.',
      note: 'Hier können Sie die Berichtsfragen den Versicherungen zuordnen.',
    },
    templateExport: {
      primary: {
        title: 'Berichtsfragen exportieren',
        description: 'Laden Sie die exportierten Berichtsfragen herunter.',
      },
      statistics: {
        title: 'Statistikfragen exportieren',
        description: 'Laden Sie die exportierten Statistikfragen herunter.',
      },
    },
    templateCreate: {
      reportingTitle: 'Berichtsfragen-Vorlage erstellen',
      statisticsTitle: 'Statistikfragen-Vorlage erstellen',
      tabs: {
        general: 'Allgemein',
        assignment: 'Zuweisung',
      },
      values: {
        form: 'Formular',
        additional: 'Unterformular',
      },
      form: {
        shortcut: 'Kürzel',
        identifier: 'Interne Kennung',
        type: 'Typ',
        color: 'Farbe',
        fieldset: 'Template',
        active: 'Aktiv',
        sum_alias: ' Summe Alias',
        accepted_alias: 'Akzeptiert Alias',
        time_value_alias: 'Zeit Alias',
        status_alias: 'Status Alias',
        types: {
          label: 'Typ',
          form: 'Formular',
          statistic: 'Statistik',
        },
        classification: {
          label: 'Klassifizierung',
          property: 'Sach',
          liability: 'Haftpflicht',
          business: 'Gewerbe',
        },
        showTimeValueCalculation: 'Zeitwert-Berechnung aktiviert',
        category: {
          label: 'Standard Kalkulation Kategorien',
          description:
            'Sie können hier Standard Kalkulation Kategorien festlegen, welche bei einer neuer Kalkulation des gewählten Formalers gleich zur Verfügung stehen.',
        },
        enableWordConvert: 'MS-Word herunterladen erlauben',
        defaultSupervisorId: 'Vordefinierter Teamleiter',
      },
    },
  },
  grid: {
    fields: {
      title: 'Titel',
      type: 'Typ',
      description: 'Beschreibung',
      mandatory: 'Pfichtfeld',
      identifier: 'Identifier',
      selectables: 'Antworten',
    },
    toolbar: {
      exportExcel: 'Excel exportieren',
    },
  },
  pages: {
    breadcrumbs: {
      STATISTICS: 'Statistik',
      PRIMARY: 'Formular',
      ADDITIONAL: 'Unterformular',
    },
    overview: {
      create: 'Vorlage erstellen',
      reportingTitle: 'Berichtsfragen',
      statisticsTitle: 'Statistikfragen',
      reportingDescription: 'Hier finden Sie eine Auflistung aller Berichtsfragen.',
      statisticsDescription: 'Hier finden Sie eine Auflistung aller Statistikfragen.',
      showInactive: 'Zeige inaktive',
      designer: 'Designer',
      import: 'Importieren',
      export: 'Exportieren',
      assignAll: 'Alle Formulare für alle Kunden zuweisen',
      grid: {
        titlePostfix: 'Kopie',
        columns: {
          id: 'ID',
          title: 'Name',
          color: 'Farbe',
          version: 'Version',
          updated_at: 'Letzte Änderung am',
          stateId: 'Status',
          shortcut: 'Kürzel',
          identifier: 'Interne ID',
          description: 'Beschreibung',
          state: 'Status',
          template: 'Template',
          type: 'Typ',
          classification: 'Klassifizierung',
          insurance: 'Versicherer',
          actions: 'Aktionen',
          usage_count: 'Verwendungen',
        },
        values: {
          active: 'Aktiv',
          notActive: 'Nicht Aktiv',
          all: 'Alle',
          none: 'Keine',
          form: 'Formular',
          statistics: 'Statistik',
          additional: 'Unterformular',
        },
      },
    },
    designer: {
      title: 'Formular-Designer',
      viewNotificationTitle: 'Ansichtsmodus',
      viewNotificationDescription:
        'Hier können Sie das Template sehen und prüfen, Änderungen werden aber nicht gespeichert',
      exportPdf: {
        filename: '{{name}} Formularstruktur',
      },
    },
  },
  fields: {
    connectionActivates: 'Aktiviert:',
    text: {
      mask: 'Maske',
      maskValidationMessage: 'Eingabe entspricht nicht dem Format {{mask}} -',
      minLengthValidation: 'Text muss mindestens {{min}} Zeichen lang sein.',
      maxLengthValidation: 'Text darf maximal {{max}} Zeichen lang sein.',
      textFieldType: {
        label: 'Feld-Typ',
        options: {
          text: 'Text',
          mask: 'Text mit Maske',
          email: 'E-Mail',
          phone: 'Telefonnummer',
        },
      },
    },
    table: {
      alert: 'Die Tabellenbreite muss 100% ergeben, bitte überprüfen Sie Ihre Größeneingaben.',
      operations: {
        none: 'Keine',
        sum: 'Summe',
        average: 'Durchschnitt',
      },
      types: {
        text: 'Text',
        number: 'Number',
        currency: 'Währung',
        select: 'Auswahl',
      },
      cols: {
        headline: 'Spalten',
        type: {
          label: 'Typ',
        },
        operation: {
          label: 'Operation',
        },
        name: {
          label: 'Titel',
          placeholder: 'Wert eingeben',
        },
        size: {
          label: 'Größe',
        },
        selectOptions: {
          label: 'Auswahlfelder',
          placeholder: 'Wert mit Semikolon getrennt eingeben',
        },
        alignment: {
          alignLeft: 'Linksbündig',
          alignRight: 'Rechtsbündig',
          alignCenter: 'Zentriert',
        },
      },
      addCol: 'Spalte hinzufügen',
      tax: {
        label: 'Steuern',
        tooltip:
          'Dadurch wird im Bericht automatisch noch eine MwSt.: und Brutto Zeile hinzugefügt, wobei die MwSt. und Brutto-Summe automatisch errechnet wird.',
      },
    },
    additionalSettings: {
      button: 'Erweiterte Einstellungen',
      copy: 'Frage kopieren',
      key: {
        label: 'Identifier',
        placeholder: 'Wert',
        notUnique: 'Identifier ist nicht einzigartig',
      },
      footer: {
        label: 'Berichts Fußzeile',
        description: 'Dieser Text wird automatisch im Bericht unter der Antwort angezeigt.',
      },
      alternateQuestionTitle: {
        label: 'Alternativer Fragentitel für Bericht',
        description:
          'Hier können Sie den Fragentitel überschreiben, um im Bericht einen alternativen Titel für die Frage anzuzeige.',
      },
      value: {
        label: 'Vordefinierter Text',
        placeholder: 'Wert',
        numberLabel: 'Vordefinierte Zahl',
      },

      showNow: {
        label: 'Aktuelles Datum / Uhrzeit automatisch eintragen',
      },
    },
    validation: {
      doubleHeadline:
        'Achtung, eine Hauptüberschrift (H1) ohne Fragen wird im Bericht nicht angezeigt, wenn direkt unterhalb noch eine weitere Hauptüberschrift im Designer eingetragen wird.',
      headline: 'Validierungen',
      title: 'Titel darf nicht leer sein!',
      addValidation: 'Validierung hinzufügen',
      notImplemented: 'Validator steht aktuell nicht zur Verfügung.',
      minMax: {
        min: {
          label: 'Min',
          placeholder: 'Min',
        },
        max: {
          label: 'Max',
          placeholder: 'Max',
        },
      },
      select: {
        label: 'Typ der Validierung',
        options: {
          minMax: 'Min/Max',
        },
      },
    },
    common: {
      new: 'Neues Feld',
      new_clean: 'neues-feld',
      title: {
        label: 'Titel',
      },
      description: {
        label: 'Beschreibung',
      },
      mandatory: {
        label: 'Pflichtfeld',
      },
      external: {
        label: 'Extern',
      },
      hideReport: {
        label: 'Im Bericht verstecken',
      },
      pageBreak: {
        label: 'Seitenumbruch',
      },
      summary: {
        label: 'Zusammenfassung',
      },
      internal: {
        label: 'Intern',
      },
    },
    numeric: {
      integer: 'Nur Ganzzahlen',
      currency: 'Währungszeichen hinzufügen',
    },
    yesNo: {
      options: {
        yes: 'Ja',
        no: 'Nein',
      },
    },
    image: {
      allowMultiple: 'Mehrere Fotos erlauben',
      allowImageDescription: 'Fotobeschreibung erlauben',
      addRequirement: 'Anforderung hinzufügen',
      assigned: 'Zugewiesen',
      notAssigned: 'Nicht zugewiesen',
      grid: {
        status: 'Status',
        image: 'Bild',
        description: 'Beschreibung',
        assignment: 'Zuweisung',
      },
      dialogs: {
        updateDescription: {
          title: 'Beschreibung bearbeiten',
          description: 'Gib hier deine Bildbeschreibung ein',
        },
        delete: {
          title: 'Bild löschen',
          description: 'Möchten Sie dieses Bild wirklich löschen?',
        },
      },
      notifications: {
        updateDescriptionSuccess: 'Beschreibung wurde gespeichert',
        deleteSuccess: 'Bild wurde gelöscht',
      },
    },
    select: {
      multiple: 'Mehrfachauswahl',
      renderDropdown: 'Als Dropdown anzeigen',
      connectedValues: {
        hint: 'Wenn Sie Fragen miteinander verknüpfen, dann wird die verknüpfte Frage erst angezeigt, wenn die von Ihnen gewählte Auswahlmöglichkeit gewählt wurde.',
        title: 'Zu verknüpfende Frage(n) wählen',
        fieldSearch: {
          placeholder: 'Suche nach Frage ...',
          label: 'Fragentitel',
        },
        done: 'Ok',
      },
      connectedValuesWeight: {
        hint: 'Hier können Sie eine Gewichtung von Fragen eingeben, damit wird über ein Formular automatisch ein Punkteergebnis je nach Auswahlmöglichkeiten erstellt. Auf Basis dieses “Scorings” können z.B.: Ergebnisse aus dem Bericht gezogen werden.',
        title: 'Gewichtung der Frage(n) wählen',
        done: 'Ok',
        input: {
          label: 'Punkte',
        },
      },
      hiddenAnswers: {
        hint: 'Sie können diese Frage verstecken, sofern spezifische Antworten ausgewählt wurden. Dies können Sie hier konfigurieren.',
        title: 'Zu versteckende Antworten wählen',
        done: 'Ok',
        input: {
          label: 'Verstecken',
        },
      },
      editConnectedValues: 'Verknüpfte',
      addOption: {
        button: 'Antwortmöglichkeit hinzufügen',
        label: 'Frage',
      },
      options: {
        header: 'Fragen',
      },
    },
  },
  notifications: {
    templateCreate: {
      success: 'Template erfolgreich erstellt.',
      error: 'Template konnte nicht angelegt werden.',
    },
    templateDelete: {
      success: 'Template wurde gelöscht.',
    },
    templateDuplicate: {
      success: 'Template wurde dupliziert.',
    },
    templateAssign: {
      success: 'Template erfolgreich zugewiesen.',
      error: 'Template konnte nicht zugewiesen werden.',
    },
    templateAssignAll: {
      success: 'Templates erfolgreich zugewiesen.',
      error: 'Templates konnten nicht zugewiesen werden.',
    },
    templateEdit: {
      success: 'Template wurde dupliziert.',
    },
    templateUpdate: {
      success: 'Template erfolgreich gespeichert.',
      error: 'Template konnte nicht gespeichert werden.',
    },
    copyQuestion: {
      success: '{{title}} wurde erfolgreich kopiert.',
    },
  },
};

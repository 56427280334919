export default {
  labels: {
    owner: 'Besichtiger',
    insurance: 'Betrieb/Unternehmen',
    selectedTasksCount: '{{couter}} Besichtigungen ausgewählt',
    client: 'Versicherungsnehmer',
    revertControlling: 'Besichtigung zurück zum Besichtiger',
    createTaskAppointment: 'Besichtigung terminieren',
    visit_type_id: 'Besichtigungs-Typ',
    activateTask: 'Besichtigung aktivieren',
    id: 'Besichtigungsnummer',
    assignTask: 'Besichtigung zuweisen',
    completeTask: 'Besichtigung abschließen',
    task: 'Besichtigung',
    reworkTask: 'Besichtigung nachbearbeiten',
    assignTasks: '{{count}} Besichtigungen zuweisen',
    loadInactiveAdjusters: 'Inaktive Besichtiger laden',
    inactiveAdjustersLoaded: 'Inaktive Besichtiger geladen',
  },
  components: {
    taskMapAdjusterPopup: {
      adjusterSelectSuccess: 'Besichtiger wurde ausgewählt',
      numTasksOpen: '{{numTasksOpen}} offene Besichtigungen',
      numTasksRework: '{{numTasksRework}} Besichtigungen in Nacharbeiten',
    },

    taskMapTaskPopup: {
      assignedOwner: 'Aktueller Besichtiger',
    },
    tasksMapSidebar: {
      nav: {
        tasks: 'Besichtigungen',
      },
    },
    sidebar: {
      basic: 'Besichtigfungsdaten',
      show: 'Besichtigung öffnen',
    },
    noRecords: {
      files: {
        text: 'Noch sind keine Dateien für die Besichtigung v.\nSie können hier gerne neue Dateien von Ihrem Computer hochladen.',
      },
      images: {
        text: 'Noch sind keine Bilder für die Besichtigung vorhanden.\nSie können hier gerne neue Bilder von Ihrem Computer hochladen.',
      },
      emails: {
        text: 'Noch sind keine E-Mails für die Besichtigung vorhanden.\nSie können hier gerne neue E-Mails versenden.',
      },
      documents: {
        text: 'Noch sind keine Dokumente für die Besichtigung vorhanden.\nSie können hier gerne neue Dokumente von Ihrem Computer hochladen.',
      },
      reports: {
        title: 'Keine Berichte gefunden',
        text: 'Noch sind keine Berichte für die Besichtigung vorhanden.\nSie können hier gerne neue Berichte erstellen.',
      },
    },
    taskReportForm: {
      custom_report_settings: {
        general: {
          manual_task_id: 'Alternative Besichtigungsnummer',
        },
      },
    },
  },
  pages: {
    createTask: {
      title: 'Neue Besichtigung',
      description: 'Hier können Sie neue Besichtigungen anlegen.',
      indentifier: 'Vorläufige Besichtigungsnummer:',
    },
    overview: {
      title: 'Besichtigungen',
      description: 'Hier finden Sie eine Liste mit detailierten Besichtigungs-Details.',

      numTasksFound: '{{numTasks}} Besichtigungen gefunden',
      toolbar: {
        create: 'Besichtigung anlegen',
      },
      mapSettings: {
        adjusterNames: 'Besichtigername',
      },
    },
    common: {
      title: 'Besichtigungsdaten',
      description:
        'Hier sehen Sie alle allgemeinen Daten zur Besichtigung. Notwendige Daten können pro Block noch angepasst werden.',
      baseData: {
        title: 'Versicherungsnehmer',
        dialogs: {
          editBaseData: {
            title: 'Versicherungsnehmer',
            note: 'Hier können Sie den Versicherungsnehmer bearbeiten.',
          },
        },
      },
      template: {
        dialogs: {
          editOwner: {
            title: 'Besichtiger',
            description: 'Hier können Sie den Besichtiger bearbeiten.',
          },
        },
      },
    },
    todos: {
      description:
        'Hier finden Sie alle relevanten Aufgaben zu dieser Besichtigung. Sie können auch weitere Aufgaben selbst anlegen.',
    },
    form: {
      description:
        'Hier finden Sie das Berichtsformular für dieser Besichtigung. Dieses wird beim Erzeugen der Besichtigung dynamisch zugewiesen.',
    },
    subforms: {
      title: 'Unterformulare',
      description: 'Hier finden Sie weitere Unterformulare zu dieser Besichtigung.',
    },
    statistics: {
      description:
        'Hier finden Sie die Statistik zu der Besichtigung. Diese enthält relevante Fragen für einen Export an Statistikdaten und kann bei der Besichtigung selbst dynamisch zugewiesen werden.',
    },
    images: {
      description:
        'Hier finden Sie alle Bilder zur Besichtigung. Sie können auch weitere Bilder ganz einfach mit der Upload-Funktion zur Besichtigung hinzufügen.',
      dialogs: {
        upload: {
          note: 'Laden Sie hier weitere Bilder für Ihre Besichtigung hoch. Bilder werden automatische auf die maximale Größe verkleinert',
        },
      },
    },
    documents: {
      description:
        'Hier finden Sie alle PDF Dokumente zur Besichtigung. Über Vorlagen können Sie ganz einfach weitere Dokumente (wie Entschädigungsvereinbarungen) hinzufügen.',
      dialogs: {
        upload: {
          note: 'Laden Sie hier weitere Dokumente für Ihre Besichtigung hoch.',
        },
        addPdfTemplate: {
          note: 'Sie können hier direkt über eine Vorlage weitere Elemente als Basis für ihre Besichtigung hinzufügen.',
        },
      },
    },
    emails: {
      description:
        'Hier finden Sie alle E-Mails von diesere Besichtigung. Man kann auch hier direkt E-Mails an beteiligte Personen verschicken.',
    },
    invoices: {
      description: 'Hier finden Sie alle Rechnungen zur Besichtigung.',
    },
    calculation: {
      description:
        'Hier finden Sie die Kalkulation zu dieser Besichtigung. Es können hier ganz einfach Gewerke kalkuliert werden und eine Übersicht der Daten als PDF angezeigt werden.',
    },
    files: {
      description:
        'Hier finden Sie alle Dateien und Dokumente zu dieser Besichtigung in einer Ordnerstruktur. Hier können Sie auch weitere Dokumente hochladen.',
    },
    appointment: {
      description:
        'Hier finden Sie alle Ihre Termine. Relevante Termine für die Besichtigung können hier hinzugefügt werden.',
    },
    logs: {
      description: 'Hier sehen Sie alle relevanten Statusänderungen zu dieser Besichtigung.',
    },
    customerPortal: {
      description:
        'Hier können Sie weitere beteiligte Personen zu dieser Besichtigung einladen. Dies können z.B.: Kundenlinks als auch Einladungen für eine Videokonferenz sein.',
    },
    compareTasks: {
      title: 'Besichtigungsformulare vergleichen',
      flipOrderTooltip: 'Reihenfolge tauschen',
    },
    listTask: {
      title: 'Tabelarisches Besichtigungsformular',
    },
  },
  dialogs: {
    reAssignTaskOwner: {
      title: 'Besichtigung zuweisen',
      description: 'Hier können Sie die Besichtigung einem anderen Besichtiger zuweisen.',
      owner: 'Besichtiger',
    },
    confirmRevertControlling: {
      title: 'Besichtigung zurück zum Besichtiger',
      note: 'Damit übergeben Sie die Besichtigung zurück an den Besichtiger. Wollen Sie fortfahren?',
    },
    changeTaskAssignments: {
      tasks: 'Besichtigungen',
      adjuster: 'Besichtiger',
      note: 'Wählen Sie einen neuen Besichtiger aus, der die Besichtigung bearbeiten soll.',
      assignedOwner: 'Aktueller Besichtiger: {{owner}}',
      noOwnerAssigned: 'Kein Besichtiger zugewiesen',
      selectAdjuster: 'Besichtiger auswählen...',
      notAllTasksReassigned:
        'Aufgrund von Änderungen während ihrer Aktion konnten nicht alle Besichtigungen zugewiesen werden.',
    },
    declineTask: {
      title: 'Besichtigung ablehnen',
    },
    reworkTask: {
      title: 'Besichtigung nachbearbeiten',
      note: 'Sind Sie sicher, dass Sie die Besichtigung nachbearbeiten wollen?',
    },
    confirmControlling: {
      title: 'Besichtigung zur Kontrolle übergeben',
      note: 'Damit übergeben Sie die Besichtigung an den zugewiesen Teamleiter. Wollen Sie fortfahren?',
    },
    completeTask: {
      title: 'Besichtigung abschließen',
      note: 'Achtung, damit setzen Sie die Besichtigung als abgeschlossen. Eine weitere Bearbeitung ist damit nicht mehr möglich. Wollen Sie fortfahren?',
    },
    cancelTask: {
      title: 'Besichtigung stornieren',
      note: 'Eine weitere Bearbeitung ist nach der Stornierung nicht mehr möglich. Wollen Sie fortfahren?',
    },
    createAppointment: {
      appointmentTitle: 'Ortstermin zur Besichtigung {{identifier}}',
    },
    exportTasks: {
      title: 'Besichtigungsliste herunterladen',
      note: 'Laden Sie die exportierte Besichtigungsliste herunter',
    },
    sendReport: {
      title: 'Berichtsversand bestätigen',
      note: 'Hiermit bestätigen Sie, dass Sie den Bericht versendet haben, damit wird das für die Besichtigung vermerkt.\nWollen Sie fortfahren?',
    },
    editTaskClassificationTags: {
      title: 'Besichtigungs-Klassifizierung bearbeiten',
      note: 'Bearbeiten Sie die Klassifizierung der Besichtigung  .',
    },
    selectFormToCompare: {
      title: 'Besichtigungsformulare vergleichen',
    },
    serviceLevelTimeline: {
      description: 'Hier sehen Sie die wichtigen Daten zu dieser Besichtigung.',
    },
  },
  notifications: {
    acceptTask: {
      success: 'Besichtigung wurde geplant',
      error: 'Die Besichtigung kann nicht geplant werden.',
    },
    duplicateTask: {
      success: 'Besichtigung wurde dupliziert',
      error: 'Besichtigung konnte nicht dupliziert werden.',
    },
    exportTask: {
      success: 'Besichtigungsliste erfolgreich exportiert.',
      error: 'Besichtigungsliste konnte nicht exportiert werden.',
    },
    updateTask: {
      success: 'Besichtigung wurde gespeichert.',
      error: 'Besichtigung konnte nicht gespeichert werden.',
    },
    editTaskClassificationTags: {
      success: 'Besichtigungs-Klassifizierung gespeichert!',
    },
  },
  invoice: {
    internalNumber: 'Interne Besichtigungsnummer',
  },
  forms: {
    createTask: {
      submit: 'Besichtigung erstellen',
      notifications: {
        submitSuccess: 'Besichtigung wurde erstellt.',
        submitError: 'Besichtigung konnte nicht erstellt werden.',
        openEntry: 'Besichtigung öffnen',
      },
      fields: {
        qualifications: {
          label: 'Verwaltung',
        },
        activate: {
          label: 'Besichtigung direkt freigeben',
        },
        client: {
          label: 'Name',
        },
        owner: {
          label: 'Besichtiger',
          noLocationHint: 'Trage einen Ort ein um alle Besichtiger zu sehen.',
        },
        damageNote: {
          label: 'Zu prüfende Sachen',
        },
        parallelNumber: {
          label: 'Parallel- / Folgebesichtigung',
        },
        visitType: {
          label: 'Besichtigungstyp',
          nullOption: 'Nicht festgelegt',
        },
        assignedDate: {
          label: 'Datum Besichtigungsanfrage',
        },
      },
      sections: {
        meta: 'Versicherungsnehmer',
      },
      warnings: {
        identifierExists: {
          message:
            'Für die von Ihnen gewählte Schadennummer “{{identifier}}” existiert bereits eine Besichtigungsnummer. Wollen Sie diese Besichtigung wirklich mit der selben Schadennummer erzeugen?',
        },
      },
    },
  },
};

import './PasswordStrength.scss';

import { classNames } from '@progress/kendo-react-common';
import { times } from 'lodash';
import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { getPasswordStrength } from '../../helpers';
import { usePasswordSettings } from '../../hooks';

interface StrengthBarProps {
  strength: number;
}

const StrengthBar = (props: StrengthBarProps) => {
  const { t } = useTranslation();
  const { strength } = props;

  const strengthBarItems = useMemo(
    () =>
      times(5, (n) => {
        return (
          <div
            key={n}
            className={classNames('strength-bar-item', {
              [`strength-bar-item-${strength}`]: n < strength,
            })}
          />
        );
      }),
    [strength],
  );
  return (
    <div className="k-my-1">
      <div className="strength-bar">{strengthBarItems}</div>
      <div className="k-text-center k-text-secondary k-p-1 k-fs-sm">
        <span>{t('common.components.strength_bar.security')}: </span>
        <i>{t(`common.components.strength_bar.strength_${strength}`)}</i>
      </div>
    </div>
  );
};

export interface PasswordStrengthMeterProps {
  value: string | number | undefined;
}

export const PasswordStrength = (props: PasswordStrengthMeterProps) => {
  const anchor = useRef<HTMLDivElement>(null);
  const { value } = props;

  const settings = usePasswordSettings();
  const strength = useMemo(
    () => getPasswordStrength(value?.toString(), settings),
    [settings, value],
  );

  return (
    <div ref={anchor} className="PasswordStrengthMeter">
      <StrengthBar strength={strength} />
    </div>
  );
};

import './ScrollList.scss';

import { Button } from '@progress/kendo-react-buttons';
import { classNames } from '@progress/kendo-react-common';
import { clamp } from 'lodash';
import {
  ReactNode,
  RefCallback,
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

interface ScrollListItemProps {
  className?: string;
  children?: ReactNode;
  onClick?: () => void;
  refCallback?: RefCallback<HTMLLIElement>;
}

export const ScrollListItem = (props: ScrollListItemProps) => {
  const { className, children, onClick, refCallback } = props;

  return (
    <li ref={refCallback} className={classNames('scroll-list-item', className)} onClick={onClick}>
      {children}
    </li>
  );
};

interface ScrollListProps {
  className?: string;
  children: ReadonlyArray<ReactNode>;
  itemWidth: number;
  gap?: number;
}

export const ScrollList = (props: ScrollListProps) => {
  const { className, children, itemWidth, gap = 16 } = props;
  const containerRef = useRef<HTMLUListElement | null>(null);
  const [scrollIndex, setScrollIndex] = useState(0);
  const [containerWidth, setContainerWidth] = useState<number>();
  const { t } = useTranslation();

  const handleContainerRef = useCallback((element: HTMLUListElement) => {
    if (element) {
      setContainerWidth(element.clientWidth);
      containerRef.current = element;
    }
  }, []);

  const maxScrollIndex = useMemo(() => {
    if (containerWidth) {
      return clamp(
        children.length - Math.round(containerWidth / (itemWidth + gap)),
        0,
        children.length - 1,
      );
    }

    return 0;
  }, [children.length, containerWidth, gap, itemWidth]);
  const disableScroll = useMemo(() => {
    if (containerWidth) {
      const spacing = Math.max((children.length - 1) * gap, 0);
      const itemsWidth = children.length * itemWidth;
      return itemsWidth + spacing < containerWidth;
    }
    return false;
  }, [children.length, containerWidth, gap, itemWidth]);

  const scrollLeft = useCallback(() => {
    setScrollIndex((prevState) => clamp(prevState - 1, 0, maxScrollIndex));
  }, [maxScrollIndex]);

  const scrollRight = useCallback(() => {
    setScrollIndex((prevState) => clamp(prevState + 1, 0, maxScrollIndex));
  }, [maxScrollIndex]);

  useLayoutEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollLeft = scrollIndex * (itemWidth + gap);
    }
  }, [gap, itemWidth, scrollIndex]);

  return (
    <div
      className={classNames(
        'scroll-list',
        'k-pos-relative',
        'k-border k-border-solid k-border-light k-rounded k-p-2',
        'k-display-flex k-flex-row',
        className,
      )}
    >
      <Button
        iconClass="l-i-chevron-left"
        className="k-flex-none"
        onClick={scrollLeft}
        disabled={disableScroll}
        aria-label={t('common.labels.cancel')}
      />
      <ul
        ref={handleContainerRef}
        className={classNames('k-overflow-hidden k-flex-grow', 'k-display-flex k-flex-row')}
        style={{ gap }}
      >
        {children}
      </ul>
      <Button
        iconClass="l-i-chevron-right"
        className="k-flex-none"
        onClick={scrollRight}
        disabled={disableScroll}
        aria-label={t('common.labels.cancel')}
      />
    </div>
  );
};
